import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import AssetRepository from '../api/repositories/AssetRepository';
import {
  Asset,
  AssetRequest,
  AssetsApiResponse,
  AssetsAPIResponseSchema,
  AssetSchema
} from '../entities/Asset';
import { assetActions } from '../redux/be_interaction_store/slices/asset';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type AssetState = RootState['BEInteraction']['asset'];
type UseAssets = {
  assets: AssetState['assets'];
  getAssets: (brandId?: number, name?: string, isDefault?: boolean) => Promise<void>;
  getAssetsRequest: AssetState['requests']['getAssets'];

  findAsset: (assetId: number) => Promise<Asset | void>;
  findAssetRequest: AssetState['requests']['findAsset'];

  defaultAsset: AssetState['defaultAsset'];
  findDefaultAsset: (brandId: number) => Promise<Asset | void>;
  findDefaultAssetRequest: AssetState['requests']['findDefaultAsset'];

  updateAsset: (assetId: number, requestData: AssetRequest) => Promise<boolean>;
  updateAssetRequest: AssetState['requests']['updateAsset'];

  addAsset: (requestData: AssetRequest) => Promise<boolean>;
  addAssetRequest: AssetState['requests']['addAsset'];

  deleteAsset: (assetId: number) => Promise<boolean>;
  deleteAssetRequest: AssetState['requests']['deleteAsset'];

  resetAssets: () => void;
};

export default function useAssets(): UseAssets {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      AssetRepository.updateAuthToken(accessToken);
      AssetRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const assets = useSelector((state: RootState) => state.BEInteraction.asset.assets);
  const defaultAsset = useSelector((state: RootState) => state.BEInteraction.asset.defaultAsset);

  const getAssetsRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.getAssets
  );
  const findAssetRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.findAsset
  );
  const findDefaultAssetRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.findDefaultAsset
  );
  const addAssetRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.addAsset
  );
  const updateAssetRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.updateAsset
  );
  const deleteAssetRequest = useSelector(
    (state: RootState) => state.BEInteraction.asset.requests.deleteAsset
  );

  const getAssets: UseAssets['getAssets'] = useCallback(
    async (brandId?: number, name?: string, isDefault?: boolean) => {
      dispatch(assetActions.getAssetsRequest());
      let response: unknown;

      try {
        response = await AssetRepository.get(brandId, name, isDefault);
      } catch (error) {
        dispatch(
          assetActions.getAssetsError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }

      const parseResult = AssetsAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          assetActions.getAssetsParseError({
            parseError: new RequestParseError(parseResult.error, 'UseAssets.getAssets')
          })
        );
        return;
      }
      dispatch(assetActions.getAssetsSuccess(response as AssetsApiResponse));
    },
    [dispatch]
  );

  const findAsset: UseAssets['findAsset'] = useCallback(
    async (assetId: number) => {
      dispatch(assetActions.findAssetRequest());
      let response: unknown;

      try {
        response = await AssetRepository.find(assetId);
      } catch (error) {
        dispatch(
          assetActions.findAssetError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }

      const parseResult = AssetSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          assetActions.findAssetParseError({
            parseError: new RequestParseError(parseResult.error, 'UseAssets.findAsset')
          })
        );
        return;
      }
      dispatch(assetActions.findAssetSuccess());
      return response as Asset;
    },
    [dispatch]
  );

  const findDefaultAsset: UseAssets['findDefaultAsset'] = useCallback(
    async (brandId: number) => {
      dispatch(assetActions.findDefaultAssetRequest());
      let response: unknown;

      try {
        response = await AssetRepository.get(brandId, undefined, true);
      } catch (error) {
        dispatch(
          assetActions.findDefaultAssetError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }

      const parseResult = AssetsAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          assetActions.findDefaultAssetParseError({
            parseError: new RequestParseError(parseResult.error, 'UseAssets.findAsset')
          })
        );
        return;
      }

      const asset = (response as AssetsApiResponse).content[0];
      dispatch(assetActions.findDefaultAssetSuccess(asset ?? null));
      return asset as Asset;
    },
    [dispatch]
  );

  const addAsset: UseAssets['addAsset'] = useCallback(
    async (requestData: AssetRequest) => {
      dispatch(assetActions.addAssetRequest());

      try {
        await AssetRepository.add(requestData);
      } catch (error) {
        dispatch(
          assetActions.addAssetError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(assetActions.addAssetSuccess());
      return true;
    },
    [dispatch]
  );

  const updateAsset: UseAssets['updateAsset'] = useCallback(
    async (assetId: number, requestData: AssetRequest) => {
      dispatch(assetActions.updateAssetRequest());

      try {
        await AssetRepository.update(assetId, requestData);
      } catch (error) {
        dispatch(
          assetActions.updateAssetError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(assetActions.updateAssetSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteAsset: UseAssets['deleteAsset'] = useCallback(
    async (assetId: number) => {
      dispatch(assetActions.deleteAssetRequest());

      try {
        await AssetRepository.delete(assetId);
      } catch (error) {
        dispatch(
          assetActions.deleteAssetError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(assetActions.deleteAssetSuccess());
      return true;
    },
    [dispatch]
  );

  const resetAssets: UseAssets['resetAssets'] = useCallback(() => {
    dispatch(assetActions.reset());
  }, [dispatch]);

  return {
    assets,
    getAssets,
    getAssetsRequest,

    findAsset,
    findAssetRequest,

    defaultAsset,
    findDefaultAsset,
    findDefaultAssetRequest,

    addAsset,
    addAssetRequest,

    updateAsset,
    updateAssetRequest,

    deleteAsset,
    deleteAssetRequest,

    resetAssets
  };
}
