import React, { useEffect, useState } from 'react';
import { MarkEmailRead } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/wakapapa_monogram.svg';
import { ReactComponent as TextLogo } from '../../../assets/wakapapa_text_logo.svg';
import useAuthentication from '../../hooks/useAuthentication';
import { DarkBox, FlexBox } from '../components/StyledComponents';

export default function MailVerification() {
  const { t } = useTranslation();
  const { mailVerification, mailVerificationRequest } = useAuthentication();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | undefined>();
  const [dialogData, setDialogData] = useState({
    visible: false,
    severity: 'success' as AlertColor,
    msg: ''
  });

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) setToken(token);
  }, [searchParams]);

  const triggerMailVerification = async () => {
    const visible = true;
    let severity = 'success' as AlertColor;
    let msg = '';
    if (!token) {
      severity = 'warning' as AlertColor;
      msg = 'Missing Token';
      setDialogData({ visible, severity, msg });
      return false;
    }

    const success = await mailVerification(token);
    severity = (success ? 'success' : 'warning') as AlertColor;
    if (!success) {
      msg =
        (mailVerificationRequest.httpError?.axiosError?.response?.data as any)?.error?.message ??
        'Unknown';
    }
    setDialogData({ visible, severity, msg });
  };

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#43c4ec',
            light: '#90DDF4',
            dark: '#16B0DF'
          },
          secondary: {
            main: '#43ebb5',
            light: '#91F3D4',
            dark: '#17DE9F'
          },
          tertiary: {
            main: '#002a4d',
            light: '#0065B8',
            dark: '#001629'
          },
          background: {
            default: '#001b32',
            paper: '#002a4d'
          },
          mode: 'dark'
        }
      })}>
      <DarkBox
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <FlexBox
          flexDirection="column"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '25%',
            minWidth: 400,
            mb: '20vh'
          }}>
          {/* Logo */}
          <Logo />
          <TextLogo style={{ marginTop: 18 }} />
          <Card
            sx={{
              padding: 6,
              marginTop: 4,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4
            }}>
            <Box>
              <Typography textAlign="center" fontSize={26} letterSpacing={1.2} fontWeight={600}>
                {t('mailVerification:title')}
              </Typography>
              <Typography textAlign="center">{t('mailVerification:info')}</Typography>
            </Box>

            <LoadingButton
              loading={mailVerificationRequest.inProgress}
              startIcon={<MarkEmailRead />}
              onClick={() => triggerMailVerification()}>
              {t('common:verify')}
            </LoadingButton>
          </Card>
        </FlexBox>
      </DarkBox>

      {/* Dialog */}
      <Dialog
        open={dialogData.visible}
        onClose={() => setDialogData({ ...dialogData, visible: false })}>
        <Alert sx={{ p: 3 }} severity={dialogData.severity} variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {dialogData.severity === 'success' ? t('common:congrats') : t('common:attention')}
          </AlertTitle>
          <DialogContentText>
            {dialogData.severity === 'success'
              ? t('mailVerification:success')
              : t('mailVerification:failed')}
          </DialogContentText>
          <DialogContentText
            sx={{
              display: dialogData.msg ? 'block' : 'none',
              marginTop: '0.75rem'
            }}>
            <span>Error: </span>
            <span style={{ fontStyle: 'italic', color: '#ffb74d' }}>{dialogData.msg}</span>
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button
              color="inherit"
              onClick={() => setDialogData({ ...dialogData, visible: false })}>
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
    </ThemeProvider>
  );
}
