import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  amber,
  orange,
  deepOrange,
  brown,
  blueGrey
} from '@mui/material/colors';

export const MaterialColors = [
  red[300],
  pink[300],
  purple[300],
  deepPurple[300],
  indigo[300],
  blue[300],
  lightBlue[300],
  cyan[300],
  teal[300],
  green[300],
  lightGreen[300],
  lime[700],
  amber[500],
  orange[600],
  deepOrange[300],
  brown[300],
  blueGrey[300]
];
