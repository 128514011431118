import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AccessTime, AccountCircleRounded, DeleteForever, Key } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../hooks/useAuthentication';
import useUser from '../../../hooks/useUser';
import { useBreadcrumbs } from '../../components/Breadcrumbs';
import EditableCard from '../../components/EditableCard';
import { SnackbarContext } from '../../components/Snackbar/SnackbarProvider';
import { FlexBox } from '../../components/StyledComponents';
import Toolbar from '../../components/Toolbar';

export default function Account() {
  const { t } = useTranslation();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);
  const [deleteUserDialogOpened, setDeleteUserDialogOpen] = React.useState(false);
  const { deleteUser, deleteUserRequest, me, readMe } = useUser();
  const { resetBusinessPassword, resetBusinessPasswordRequest, signOut } = useAuthentication();
  const [profileDetailsCardMode, setProfileDetailsCardMode] = useState<'read' | 'edit' | 'loading'>(
    'read'
  );

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:account'),
        path: '/account'
      };
    }, [t])
  );

  const fecthUserData = async () => {
    await readMe();
  };

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  useEffect(() => {
    if (!me) fecthUserData();
  }, [me]);

  const sendPswReecoveryEmail = async () => {
    if (me?.email) {
      const success = await resetBusinessPassword(me?.email);
      if (success) setSuccessDialogVisible(true);
    }
  };

  const triggerDeleteUser = async () => {
    if (me?.id) {
      const success = await deleteUser('business', me?.id);
      openSnackBar(
        success,
        t('users:snackbar:deleteUserSuccess'),
        t('users:snackbar:deleteUserError')
      );
      setDeleteUserDialogOpen(false);
      if (success) signOut();
    }
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      <Box mb={2}>
        <Typography variant="h6" fontWeight="bold">
          {t('account:pageTitle')}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
          {t('account:pageSubtitle')}
        </Typography>

        <Toolbar mt={4} mb={2} />
      </Box>

      {/* Body */}
      <FlexBox
        flexDirection={tabletWidth ? 'column' : 'row'}
        flex={1}
        gap={4}
        width="90%"
        m="auto"
        alignItems={tabletWidth ? undefined : 'flex-start'}>
        <EditableCard
          icon={<AccountCircleRounded sx={{ color: (theme) => theme.palette.tertiary.main }} />}
          hideHeaderDivider
          sx={{ flex: 2 }}
          bodySx={{ p: 1 }}
          title={t('account:pageSections:profileDetails')}
          onEditModeConfirm={() => {
            setProfileDetailsCardMode('read');
          }}
          onEditModeDiscard={() => {
            setProfileDetailsCardMode('read');
          }}
          onEditMode={() => {
            setProfileDetailsCardMode('edit');
          }}
          // mode={profileDetailsCardMode}
        >
          <FlexBox flexDirection="row">
            <Avatar
              sx={{
                bgcolor: (theme) => theme.palette.tertiary.main,
                height: 90,
                width: 90,
                m: 2
              }}
              src={me?.image ?? undefined}>
              {me?.email?.charAt(0).toLocaleUpperCase()}
            </Avatar>
          </FlexBox>
          <FlexBox flexDirection="row" mt={2}>
            <FlexBox flexDirection="column" flex={1}>
              <Typography fontWeight="bold">{t('account:email')}</Typography>
              <Typography>{me?.email}</Typography>
            </FlexBox>
            <FlexBox flexDirection="column" flex={1}>
              <Typography fontWeight="bold">{t('account:role')}</Typography>
              <Typography>{me?.role?.slug}</Typography>
            </FlexBox>
          </FlexBox>

          {me?.createdAt && (
            <FlexBox
              flexDirection="row"
              flex={1}
              gap={1}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ opacity: 0.4, mb: -1, mt: 2 }}>
              <AccessTime fontSize="small" />
              <Typography fontWeight="bold" fontSize={12}>
                {t('account:createdAt')}:
              </Typography>
              <Typography fontSize={12}>{new Date(me.createdAt).toLocaleDateString()}</Typography>
            </FlexBox>
          )}
        </EditableCard>
        <FlexBox flex={1} flexDirection="column" gap={2}>
          <EditableCard
            icon={<Key sx={{ color: (theme) => theme.palette.tertiary.main }} />}
            hideHeaderDivider
            title={t('account:pageSections:changePassword')}
            bodySx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('account:pageSections:changePasswordDescription')}
            </Typography>

            <LoadingButton
              loading={resetBusinessPasswordRequest.inProgress}
              variant="outlined"
              onClick={() => sendPswReecoveryEmail()}>
              {t('authentication:changePassword')}
            </LoadingButton>
          </EditableCard>
          {/* <EditableCard
            icon={<PersonRemove sx={{ color: (theme) => theme.palette.tertiary.main }} />}
            hideHeaderDivider
            title={t('account:pageSections:closeAccount')}
            bodySx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('account:pageSections:closeAccountDescription')}
            </Typography>

            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteForever />}
              onClick={() => setDeleteUserDialogOpen(true)}>
              {t('authentication:deleteAccount')}
            </Button>
          </EditableCard> */}
        </FlexBox>
      </FlexBox>

      {/* Change Password Dialog */}
      <Dialog
        open={successDialogVisible}
        onClose={() => {
          setSuccessDialogVisible(false);
        }}>
        <Alert sx={{ p: 3 }} severity="success" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>{t('common:congrats')}</AlertTitle>
          <DialogContentText>
            {t('authentication:resetPasswordRequestSuccessInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button
              onClick={() => {
                setSuccessDialogVisible(false);
              }}
              color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog open={deleteUserDialogOpened} onClose={() => setDeleteUserDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:deleteUserInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteUserDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteUserRequest.inProgress}
              onClick={triggerDeleteUser}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
