import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

interface IncrementDecrementBtnProps {
  onIncrement?: () => void;
  onDecrement?: () => void;
  disabled?: boolean;
}

export default function IncrementDecrementBtn(props: IncrementDecrementBtnProps) {
  return (
    <ButtonGroup
      disabled={props.disabled}
      disableElevation
      orientation="vertical"
      sx={{ minHeight: '100%' }}>
      <Button
        onClick={() => {
          if (props.onIncrement) props.onIncrement();
        }}
        sx={{ p: 0, lineHeight: 1, height: '50%' }}>
        +
      </Button>

      <Button
        onClick={() => {
          if (props.onDecrement) props.onDecrement();
        }}
        sx={{ p: 0, lineHeight: 1, height: '50%' }}>
        -
      </Button>
    </ButtonGroup>
  );
}
