import { FlyerRequest } from '../../entities/Flyer';
import { Repository } from '../Repository';

class FlyerRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api/flyers'
    });
  }

  async getAll() {
    const { data } = await this.axios.get('');
    return data;
  }

  async getByBrand(
    brandId: number,
    page?: number,
    sort?: string,
    expiredAt?: string,
    notUsed?: boolean
  ) {
    const { data } = await this.axios.get('', {
      params: { brand_id: brandId, page, sort, expired_at: expiredAt, not_used: notUsed }
    });
    return data;
  }

  async find(flyerId: number) {
    const { data } = await this.axios.get(`/${flyerId}`);
    return data;
  }

  async add(flyerRequestData: FlyerRequest) {
    const { data } = await this.axios.post('', flyerRequestData);
    return data;
  }

  async update(flyerId: number, flyerRequestData: FlyerRequest) {
    const { data } = await this.axios.put(`/${flyerId}`, flyerRequestData);
    return data;
  }

  async delete(flyerId: number) {
    const { data } = await this.axios.delete(`/${flyerId}`);
    return data;
  }
}

export default new FlyerRepository();
