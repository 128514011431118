import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthenticationRepository from '../api/repositories/AuthenticationRepository';
import { AuthData, AuthDataSchema } from '../entities/Authentication';
import { authenticationActions } from '../redux/be_interaction_store/slices/authentication';
import { brandActions } from '../redux/be_interaction_store/slices/brand';
import { userActions } from '../redux/be_interaction_store/slices/user';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = '@Wakapapa.token';
const LOCAL_STORAGE_ACCESS_TOKEN_EXPIRE_KEY = '@Wakapapa.token.expire';
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = '@Wakapapa.refreshToken';
const LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY = '@Wakapapa.refreshToken.expire';

const EXPIRATION_TOLERANCE = 300000; // milliseconds => 5 minutes

type AuthenticationState = RootState['BEInteraction']['authentication'];
type UseAuthentication = {
  signedIn: AuthenticationState['signedIn'];
  signIn: (username: string, password: string) => Promise<void>;
  signInRequest: AuthenticationState['requests']['signIn'];

  signOut: () => void;

  checkToken: () => void;
  tokenChecked: AuthenticationState['tokenChecked'];

  refreshToken: () => Promise<string | void>;
  accessToken: AuthenticationState['accessToken'];

  mailVerification: (token: string) => Promise<boolean>;
  mailVerificationRequest: AuthenticationState['requests']['mailVerification'];

  resetPassword: (email: string, password: string) => Promise<boolean>;
  resetPasswordRequest: AuthenticationState['requests']['resetPassword'];

  resetBusinessPassword: (email: string) => Promise<boolean>;
  resetBusinessPasswordRequest: AuthenticationState['requests']['resetPassword'];
};

export default function useAuthentication(): UseAuthentication {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: RootState) => state.BEInteraction.authentication.accessToken
  );
  const tokenChecked = useSelector(
    (state: RootState) => state.BEInteraction.authentication.tokenChecked
  );
  const signedIn = useSelector((state: RootState) => state.BEInteraction.authentication.signedIn);
  const signInRequest = useSelector(
    (state: RootState) => state.BEInteraction.authentication.requests.signIn
  );

  const signIn = useCallback(
    async (username: string, password: string) => {
      dispatch(authenticationActions.signInRequest());
      let response: unknown;

      try {
        response = await AuthenticationRepository.signIn(username, password);
      } catch (error) {
        dispatch(
          authenticationActions.signInError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }
      const parseResult = AuthDataSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          authenticationActions.signInParseError({
            parseError: new RequestParseError(parseResult.error, 'UseAuthentication.signIn')
          })
        );
        return;
      }

      const tokensData = response as AuthData;
      window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, tokensData.access_token);
      window.localStorage.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN_EXPIRE_KEY,
        new Date(tokensData.expired_at).toISOString()
      );
      window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, tokensData.refresh_token);
      window.localStorage.setItem(
        LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY,
        new Date(tokensData.refresh_expired_at).toISOString()
      );
      dispatch(authenticationActions.signInSuccess(tokensData));
      navigate('/home');
    },
    [dispatch]
  );

  const checkToken = useCallback(async () => {
    dispatch(authenticationActions.checkTokenRequest());

    const accesTkn = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    const accesTknExpire = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRE_KEY);
    const refreshTkn = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    const refreshTknExpire = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);

    if (!accesTkn || !accesTknExpire || !refreshTkn || !refreshTknExpire) {
      const error = new RequestError(
        new AxiosError('Tokens not stored correctly in local storage.')
      );
      dispatch(authenticationActions.checkTokenError({ error }));
      console.warn(error);
      signOut();
      return;
    }

    const expirationTimestamp = new Date(accesTknExpire).getTime();
    const refreshExpirationTimestamp = new Date(refreshTknExpire).getTime();

    if (Date.now() >= expirationTimestamp - EXPIRATION_TOLERANCE) {
      // Token expired
      const error = new RequestError(new AxiosError('Access token expired.'));
      console.warn(error);
      dispatch(authenticationActions.checkTokenError({ error }));
      await refreshToken();
      return;
    }

    dispatch(
      authenticationActions.checkTokenSuccess({
        access_token: accesTkn,
        expired_at: expirationTimestamp,
        refresh_token: refreshTkn,
        refresh_expired_at: refreshExpirationTimestamp
      })
    );
  }, [dispatch]);

  const refreshToken = useCallback(async () => {
    dispatch(authenticationActions.refreshTokenRequest());

    const refreshTkn = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    const refreshTknExpire = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);

    if (!refreshTkn || !refreshTknExpire) {
      const error = new RequestError(new AxiosError('Refresh token not stored locally'));
      dispatch(authenticationActions.refreshTokenError({ error }));
      signOut();
      return;
    }

    const refreshExpirationTimestamp = new Date(refreshTknExpire).getTime();

    if (Date.now() >= refreshExpirationTimestamp - EXPIRATION_TOLERANCE) {
      // Refresh Token expired
      const error = new RequestError(new AxiosError('Refresh token expired.'));
      console.warn(error);
      dispatch(authenticationActions.refreshTokenError({ error }));
      signOut();
      return;
    }

    let response: unknown;
    try {
      response = await AuthenticationRepository.refreshToken(refreshTkn);
    } catch (error) {
      console.warn('Http Error occurred while refreshing token.');
      dispatch(
        authenticationActions.refreshTokenError({ error: new RequestError(error as AxiosError) })
      );
      signOut();
      return;
    }
    const parseResult = AuthDataSchema.safeParse(response);
    if (!parseResult.success) {
      console.warn('Parse Error occurred while refreshing token.');
      dispatch(
        authenticationActions.refreshTokenParseError({
          parseError: new RequestParseError(parseResult.error, 'UseAuthentication.refreshToken')
        })
      );
      signOut();
      return;
    }
    const tokensData = response as AuthData;
    window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, tokensData.access_token);
    window.localStorage.setItem(
      LOCAL_STORAGE_ACCESS_TOKEN_EXPIRE_KEY,
      new Date(tokensData.expired_at).toISOString()
    );
    window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, tokensData.refresh_token);
    window.localStorage.setItem(
      LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY,
      new Date(tokensData.refresh_expired_at).toISOString()
    );
    dispatch(authenticationActions.refreshTokenSuccess(tokensData));
    return tokensData.access_token;
  }, [dispatch]);

  const signOut = useCallback(async () => {
    if (accessToken) {
      AuthenticationRepository.updateAuthToken(accessToken);
      try {
        await AuthenticationRepository.signOut();
      } catch (error) {
        console.warn('SignOut() api failed. Access Token could be expired.');
      }
    }

    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_EXPIRE_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_EXPIRE_KEY);
    dispatch(userActions.clearMe());
    dispatch(brandActions.clearCurrentBrand());
    dispatch(authenticationActions.signOut());
  }, [dispatch]);

  const mailVerificationRequest = useSelector(
    (state: RootState) => state.BEInteraction.authentication.requests.mailVerification
  );

  const mailVerification = useCallback(
    async (token: string) => {
      dispatch(authenticationActions.mailVerificationRequest());
      try {
        await AuthenticationRepository.mailVerification(token);
      } catch (error) {
        dispatch(
          authenticationActions.mailVerificationError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }
      dispatch(authenticationActions.mailVerificationSuccess());
      return true;
    },
    [dispatch]
  );

  const resetPasswordRequest = useSelector(
    (state: RootState) => state.BEInteraction.authentication.requests.resetPassword
  );

  const resetPassword = useCallback(
    async (email: string, password: string) => {
      dispatch(authenticationActions.resetPasswordRequest());

      try {
        await AuthenticationRepository.resetPassword(email, password);
      } catch (error) {
        dispatch(
          authenticationActions.resetPasswordError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }
      dispatch(authenticationActions.resetPasswordSuccess());
      return true;
    },
    [dispatch]
  );

  const resetBusinessPasswordRequest = useSelector(
    (state: RootState) => state.BEInteraction.authentication.requests.resetBusinessPassword
  );

  const resetBusinessPassword = useCallback(
    async (email: string) => {
      dispatch(authenticationActions.resetBusinessPasswordRequest());

      try {
        await AuthenticationRepository.resetBusinessPassword(email);
      } catch (error) {
        dispatch(
          authenticationActions.resetBusinessPasswordError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }
      dispatch(authenticationActions.resetBusinessPasswordSuccess());
      return true;
    },
    [dispatch]
  );

  return {
    signedIn,
    signIn,
    signInRequest,
    signOut,
    checkToken,
    refreshToken,
    accessToken,
    tokenChecked,
    mailVerification,
    mailVerificationRequest,
    resetPassword,
    resetPasswordRequest,
    resetBusinessPassword,
    resetBusinessPasswordRequest
  };
}
