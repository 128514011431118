import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './app/App';
import store from './app/redux/store';
import './app/i18n';
import FlyerShare from './app/ui/screens/FlyerShare';
import MailVerification from './app/ui/screens/MailVerification';
import ResetPassword from './app/ui/screens/ResetPassword';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

console.log(`▶️ Running app: ${process.env.REACT_APP_NAME}`);
console.log(`🔢 Version: ${process.env.REACT_APP_VERSION}`);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/flyer/flyer_share" element={<FlyerShare />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/confirm_email" element={<MailVerification />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Provider>
  </BrowserRouter>
);
