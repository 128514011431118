import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AccessTime,
  Check,
  DeleteForever,
  EmojiEventsOutlined,
  Filter1,
  FirstPage,
  InfoOutlined,
  KeyboardArrowLeft,
  LastPage,
  PinDrop,
  Room,
  Shuffle,
  Tune,
  VerifiedOutlined
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DictionaryString, Language } from '../../../../../entities/Common';
import { Flyer } from '../../../../../entities/Flyer';
import { Quest } from '../../../../../entities/Initiatives';
import { LocationRequest } from '../../../../../entities/Location';
import { TokenAssignmentMode, TokenRequest } from '../../../../../entities/Token';
import useInitiatives from '../../../../../hooks/useInitiatives';
import { currentLanguage } from '../../../../../i18n';
import { questFormActions } from '../../../../../redux/local_store/slices/questForm';
import { RootState } from '../../../../../redux/store';
import { WAKAPAPA_ID } from '../../../../../utils/Constants';
import PathFormatter from '../../../../../utils/PathFormatter';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import AssetsCard from '../../../../components/AssetsCard';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import LocationsCard from '../../../../components/LocationsCard';
import { DateTimePicker } from '../../../../components/DateTimePicker';
import EditableCard from '../../../../components/EditableCard';
import FlyersContainer from '../../../../components/FlyersContainer';
import IncrementDecrementBtn from '../../../../components/IncrementDecrementBtn';
import LanguageSelection from '../../../../components/LanguageSelection';
import { SnackbarContext } from '../../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';
import UploadImg from '../../../../components/UploadImg';
import { languages } from '../../Settings';

interface AddEditQuestProps {
  mode: 'create' | 'edit';
}

export default function AddEditQuest(props: AddEditQuestProps) {
  // hooks
  const { brandId, questId } = useParams();
  const {
    findInitiative,
    initiativeTypes,
    initiativeTypesRequest,
    readInitiativeTypes,
    updateQuest,
    updateQuestRequest,
    addQuest,
    addQuestRequest,
    deleteQuest,
    deleteQuestRequest
  } = useInitiatives();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const dispatch = useDispatch();
  const theme = useTheme();

  // Generic states
  const [data, setData] = useState<Quest>();
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage());
  const [fillForEachLanguage, setFillForEachLanguage] = useState(true);
  const [deleteQuestDialogOpened, setDeleteQuestDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);
  const initiativeId = useSelector((state: RootState) => state.local.questForm.initiativeId);
  const edited = useSelector((state: RootState) => state.local.questForm.edited);
  const viewOnlyMode = useSearchParams()[0].get('view_only') === 'true';
  const assignmentModes = [
    {
      slug: TokenAssignmentMode.RANDOM_CONTENT,
      title: t('initiatives:quests:tokenAssignment:randomContent'),
      description: t('initiatives:quests:tokenAssignment:randomContentDescription'),
      icon: <Shuffle />
    },
    {
      slug: TokenAssignmentMode.FIRST_FIXED,
      title: t('initiatives:quests:tokenAssignment:firstFixed'),
      description: t('initiatives:quests:tokenAssignment:firstFixedDescription'),
      icon: <FirstPage />
    },
    {
      slug: TokenAssignmentMode.LAST_FIXED,
      title: t('initiatives:quests:tokenAssignment:lastFixed'),
      description: t('initiatives:quests:tokenAssignment:lastFixedDescription'),
      icon: <LastPage />
    },
    {
      slug: TokenAssignmentMode.FIRST_AND_LAST_FIXED,
      title: t('initiatives:quests:tokenAssignment:firstAndLastFixed'),
      description: t('initiatives:quests:tokenAssignment:firstAndLastFixedDescription'),
      icon: (
        <FlexBox flexDirection="row">
          <FirstPage />
          <LastPage />
        </FlexBox>
      )
    },
    {
      slug: TokenAssignmentMode.TOKEN_FIXED,
      title: t('initiatives:quests:tokenAssignment:tokenFixed'),
      description: t('initiatives:quests:tokenAssignment:tokenFixedDescription'),
      icon: <Room />
    },
    {
      slug: TokenAssignmentMode.ORDERED,
      title: t('initiatives:quests:tokenAssignment:ordered'),
      description: t('initiatives:quests:tokenAssignment:orderedDescription'),
      icon: <Filter1 />
    }
  ];

  // Form states
  const logo = useSelector((state: RootState) => state.local.questForm.inputData.logo);
  const name = useSelector((state: RootState) => state.local.questForm.inputData.name);
  const modeSlug = useSelector((state: RootState) => state.local.questForm.inputData.modeSlug);
  const assetId = useSelector((state: RootState) => state.local.questForm.inputData.assetId);
  const advertising = useSelector(
    (state: RootState) => state.local.questForm.inputData.advertising
  );
  const initiativeTypeId = useSelector(
    (state: RootState) => state.local.questForm.inputData.initiativeTypeId
  );
  const description = useSelector(
    (state: RootState) => state.local.questForm.inputData.description
  );
  const descriptionImage = useSelector(
    (state: RootState) => state.local.questForm.inputData.descriptionImage
  );
  const listDescriptionImage = useSelector(
    (state: RootState) => state.local.questForm.inputData.listDescriptionImage
  );
  const startDate = useSelector((state: RootState) => state.local.questForm.inputData.startDate);
  const endDate = useSelector((state: RootState) => state.local.questForm.inputData.endDate);
  const goalDescription = useSelector(
    (state: RootState) => state.local.questForm.inputData.goalDescription
  );
  const unlockThreshold = useSelector(
    (state: RootState) => state.local.questForm.inputData.unlockThreshold
  );
  const prizeTitle = useSelector((state: RootState) => state.local.questForm.inputData.prizeTitle);
  const prizeDescription = useSelector(
    (state: RootState) => state.local.questForm.inputData.prizeDescription
  );
  const wakaPrize = useSelector((state: RootState) => state.local.questForm.inputData.wakaPrize);
  const wakaTokens = useSelector((state: RootState) => state.local.questForm.inputData.wakaTokens);
  const badgeImage = useSelector(
    (state: RootState) => state.local.questForm.inputData.badge?.image
  );
  const badgeName = useSelector((state: RootState) => state.local.questForm.inputData.badge?.name);
  const badgeDescription = useSelector(
    (state: RootState) => state.local.questForm.inputData.badge?.description
  );
  const tokens = useSelector((state: RootState) => state.local.questForm.inputData.tokens);
  const locations = useSelector((state: RootState) => state.local.questForm.inputData.locations);

  // error states
  const [logoErr, setLogoErr] = useState<string>();
  const [nameErr, setNameErr] = useState<string>();
  const [descriptionErr, setDescriptionErr] = useState<string>();
  const [descriptionImageErr, setDescriptionImageErr] = useState<string>();
  const [listDescriptionImageErr, setListDescriptionImageErr] = useState<string>();
  const [startDateErr, setStartDateErr] = useState<string>();
  const [endDateErr, setEndDateErr] = useState<string>();
  const [goalDescriptionErr, setGoalDescriptionErr] = useState<string>();
  const [unlockThresholdErr, setUnlockThresholdErr] = useState<string>();
  const [prizeTitleErr, setPrizeTitleErr] = useState<string>();
  const [prizeDescriptionErr, setPrizeDescriptionErr] = useState<string>();
  const [wakaPrizeErr, setWakaPrizeErr] = useState<string>();
  const [wakaTokensErr, setWakaTokensErr] = useState<string>();
  const [badgeImageErr, setBadgeImageErr] = useState<string>();
  const [badgeNameErr, setBadgeNameErr] = useState<string>();
  const [badgeDescriptionErr, setBadgeDescriptionErr] = useState<string>();
  const [firstFlyerErr, setFirstFlyerErr] = useState<string>();
  const [lastFlyerErr, setLastFlyerErr] = useState<string>();
  const [flyersErr, setFlyersErr] = useState<string>();
  const [tokensErr, setTokensErr] = useState<string>();

  // Ui utils
  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: 'Nuova quest',
            path: `/home/brand/${brandId}/quests/new-quest`
          }
        : {
            title: `${data?.name[currentLanguage()]}`,
            path: `/home/brand/${brandId}/quests/${data?.id}`
          };
    }, [brandId, data?.id, data?.name, props.mode])
  );
  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  // Data Fetching
  const fetchQuestData = async () => {
    if (questId) {
      const questData = await findInitiative(parseInt(questId));
      if (questData && questData.type === 'quest') setData(questData);
    }
  };

  useEffect(() => {
    const fetchUtilsData = async () => {
      if (!initiativeTypes.length) readInitiativeTypes();
    };
    fetchUtilsData();
    if (props.mode === 'edit') fetchQuestData();
  }, [props.mode]);

  useEffect(() => {
    if (data && data.id !== initiativeId) dispatch(questFormActions.clear());
    if (data && !edited && data.type === 'quest') {
      dispatch(questFormActions.setInitiativeId(data.id));
      dispatch(questFormActions.setBrandId(data.brand.id));
      dispatch(questFormActions.setLogo(PathFormatter.absoluteToRelative(data.logo)));
      dispatch(questFormActions.setName(data.name));
      dispatch(questFormActions.setModeSlug(data.mode.slug as TokenAssignmentMode));
      dispatch(questFormActions.setPublished(data.published));
      dispatch(questFormActions.setDescription(data.description));
      dispatch(
        questFormActions.setDescriptionImage(
          data.descriptionImage ? PathFormatter.absoluteToRelative(data.descriptionImage) : null
        )
      );
      dispatch(
        questFormActions.setListDescriptionImage(
          data.listDescriptionImage
            ? PathFormatter.absoluteToRelative(data.listDescriptionImage)
            : null
        )
      );
      dispatch(questFormActions.setStartDate(data.startDate));
      dispatch(questFormActions.setEndDate(data.endDate));
      dispatch(questFormActions.setGoalDescription(data.goalDescription));
      dispatch(questFormActions.setInitiativeTypeId(data.initiativeType.id));
      dispatch(questFormActions.setUnlockThreshold(data.unlockThreshold));
      dispatch(questFormActions.setPrizeTitle(data.prizeTitle));
      dispatch(questFormActions.setPrizeDescription(data.prizeDescription));
      dispatch(questFormActions.setWakaPrize(data.wakaPrize));
      dispatch(questFormActions.setWakaTokens(data.wakaTokens));
      if (data.badge) {
        dispatch(
          questFormActions.setBadgeImage(PathFormatter.absoluteToRelative(data.badge.image))
        );
        dispatch(questFormActions.setBadgeName(data.badge.name));
        dispatch(questFormActions.setBadgeDescription(data.badge.description));
      }
      dispatch(questFormActions.setAdvertising(data.advertising));
      dispatch(
        questFormActions.setTokens(
          data.tokens
            ? data.tokens.map((t) => {
                return {
                  ...t,
                  flyer: t.flyer ?? undefined,
                  flyerId: t.flyer?.id
                };
              })
            : []
        )
      );
      dispatch(
        questFormActions.setLocations(
          data.locations ?? _.flatten(_.compact(data.tokens?.map((t) => t.locations))) ?? []
        )
      );
      dispatch(questFormActions.setAssetId(data?.asset?.id ?? null));
    }
  }, [data]);

  // Data Validation
  const validation = () => {
    let validated = true;
    const sortedLanguages = Object.keys(name).sort((a) => (a === currentLanguage() ? -1 : 1));
    for (let i = 0; i < sortedLanguages.length && validated; i++) {
      const lang = sortedLanguages[i];
      if (lang) {
        if (!name[lang as Language] && validated) {
          setNameErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!description[lang as Language] && validated) {
          setDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!logo && validated) {
          setLogoErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!descriptionImage && validated) {
          setDescriptionImageErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!listDescriptionImage && validated) {
          setListDescriptionImageErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!startDate && validated) {
          setStartDateErr(t('errors:obligatoryField'));
          validated = false;
        } else if (
          (props.mode === 'create' && startDate && startDate < Date.now() && validated) ||
          (props.mode === 'edit' &&
            startDate &&
            data?.startDate &&
            data.startDate < Date.now() &&
            startDate < data.startDate &&
            validated) ||
          (props.mode === 'edit' &&
            startDate &&
            data?.startDate &&
            data.startDate >= Date.now() &&
            startDate < Date.now() &&
            validated)
        ) {
          setStartDateErr(t('errors:passedStartDate'));
          validated = false;
        }
        if (!endDate && validated) {
          setEndDateErr(t('errors:obligatoryField'));
          validated = false;
        } else if (
          startDate &&
          endDate &&
          startDate < Date.now() &&
          endDate < Date.now() &&
          validated
        ) {
          setEndDateErr(t('errors:passedEndDate'));
          validated = false;
        } else if (endDate && startDate && endDate < startDate) {
          setEndDateErr(t('errors:endDateBeforeStartDate'));
          validated = false;
        }
        if (!goalDescription[lang as Language] && validated) {
          setGoalDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!prizeTitle[lang as Language] && validated) {
          setPrizeTitleErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!prizeDescription[lang as Language] && validated) {
          setPrizeDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!unlockThreshold && validated) {
          setUnlockThresholdErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!wakaTokens && validated) {
          setWakaTokensErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!wakaPrize && validated) {
          setWakaPrizeErr(t('errors:obligatoryField'));
          validated = false;
        }

        // Badge validation
        if (
          (validated &&
            !badgeImage &&
            Object.values(badgeName).every((v) => !v) &&
            Object.values(badgeDescription).every((v) => !v)) ||
          (badgeImage &&
            Object.values(badgeName).every((v) => v) &&
            Object.values(badgeDescription).every((v) => v))
        ) {
          //
        } else {
          if (!badgeImage && validated) {
            setBadgeImageErr(t('errors:obligatoryBadgeField'));
            validated = false;
          }
          if (!badgeName[lang as Language] && validated) {
            setBadgeNameErr(t('errors:obligatoryBadgeField'));
            setSelectedLanguage(lang as Language);
            validated = false;
          }
          if (!badgeDescription[lang as Language] && validated) {
            setBadgeDescriptionErr(t('errors:obligatoryBadgeField'));
            setSelectedLanguage(lang as Language);
            validated = false;
          }
        }
        if (
          validated &&
          data?.published &&
          data?.badge &&
          !badgeImage &&
          Object.values(badgeName).every((v) => !v) &&
          Object.values(badgeDescription).every((v) => !v)
        ) {
          setBadgeImageErr(t('errors:cannotDeleteBadge'));
          setBadgeNameErr(t('errors:cannotDeleteBadge'));
          setBadgeDescriptionErr(t('errors:cannotDeleteBadge'));
          validated = false;
        }
        if (
          validated &&
          data?.published &&
          !data?.badge &&
          badgeImage &&
          Object.values(badgeName).every((v) => v) &&
          Object.values(badgeDescription).every((v) => v)
        ) {
          setBadgeImageErr(t('errors:cannotAddBadge'));
          setBadgeNameErr(t('errors:cannotAddBadge'));
          setBadgeDescriptionErr(t('errors:cannotAddBadge'));
          validated = false;
        }

        // Token assignment errors
        if (
          modeSlug === TokenAssignmentMode.FIRST_FIXED &&
          !tokens.some((t) => t.position === 1) &&
          validated
        ) {
          setFirstFlyerErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (
          modeSlug === TokenAssignmentMode.LAST_FIXED &&
          !tokens.some((t) => t.isFinal && validated)
        ) {
          setLastFlyerErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (modeSlug === TokenAssignmentMode.FIRST_AND_LAST_FIXED && validated) {
          if (!tokens.some((t) => t.isFinal)) {
            setLastFlyerErr(t('errors:obligatoryField'));
            validated = false;
          }
          if (!tokens.some((t) => t.position === 1)) {
            setFirstFlyerErr(t('errors:obligatoryField'));
            validated = false;
          }
        }
        if (!locations.length && validated) {
          setTokensErr(t('errors:obligatoryMarker'));
          validated = false;
        }
        const notAssignedLocation = locations.find(
          (l) =>
            !_.flatten(_.compact(tokens.map((t) => t.locations))).some((tl) => _.isEqual(tl, l))
        );
        if (modeSlug === TokenAssignmentMode.TOKEN_FIXED && notAssignedLocation && validated) {
          setTokensErr(t('errors:everyTokenAssigned'));
          validated = false;
        }
        if (modeSlug === TokenAssignmentMode.ORDERED && validated) {
          if (tokens.some((t) => !t.position)) {
            setFlyersErr(t('errors:obligatoryFlyersPosition'));
            validated = false;
          }
        }
        if (
          modeSlug !== TokenAssignmentMode.TOKEN_FIXED &&
          locations.length > tokens.length &&
          brandId &&
          parseInt(brandId) !== 1 && // not wakapapa brand
          validated
        ) {
          setFlyersErr(t('errors:moreFlyersThenMarkers'));
          setTokensErr(t('errors:moreFlyersThenMarkers'));
          validated = false;
        }
      }
    }

    return validated;
  };

  // Main interactions
  const triggerAddQuest = async () => {
    if (!brandId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (
      dataValidated &&
      name &&
      description &&
      descriptionImage &&
      listDescriptionImage &&
      startDate &&
      endDate &&
      goalDescription &&
      unlockThreshold &&
      wakaPrize &&
      wakaTokens &&
      prizeTitle &&
      prizeDescription
    ) {
      const success = await addQuest({
        brandId: parseInt(brandId),
        logo,
        name,
        description,
        descriptionImage,
        listDescriptionImage,
        modeSlug,
        startDate,
        endDate,
        goalDescription,
        unlockThreshold,
        wakaPrize,
        wakaTokens,
        initiativeTypeId,
        prizeTitle,
        prizeDescription,
        badge:
          badgeName && badgeImage && badgeDescription
            ? { name: badgeName, description: badgeDescription, image: badgeImage }
            : undefined,
        tokens: tokens
          .filter((t) =>
            modeSlug === TokenAssignmentMode.TOKEN_FIXED ? t.locations?.length : true
          )
          .map((t) => _.pick(t, ['id', 'flyerId', 'position', 'isFinal', 'locations'])),
        locations: modeSlug !== TokenAssignmentMode.TOKEN_FIXED ? locations : undefined,
        advertising,
        assetId
      });
      openSnackBar(
        success,
        t('initiatives:quests:snackbar:questAddSuccess'),
        t('initiatives:quests:snackbar:questAddError')
      );
      if (success) {
        dispatch(questFormActions.clear());
        dispatch(questFormActions.setEdited(false));
        navigate(-1);
      }
    }
  };
  const triggerUpdateQuest = async () => {
    if (!questId || !brandId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    let requestTokens: TokenRequest[] = tokens
      .filter((t) => (modeSlug === TokenAssignmentMode.TOKEN_FIXED ? t.locations?.length : true))
      .map((t) => _.pick(t, ['id', 'flyerId', 'position', 'isFinal', 'locations']));
    data?.tokens?.forEach((dt) => {
      if (!requestTokens.some((rt) => rt.id === dt.id)) {
        requestTokens = requestTokens.concat({ id: dt.id, flyerId: dt.flyer?.id, delete: true });
      } else if (modeSlug === TokenAssignmentMode.TOKEN_FIXED) {
        // Set delete flag to assigned locations
        dt.locations?.forEach((dtl) => {
          const requestToken = requestTokens.find((rt) => rt.id === dt.id);
          if (requestToken) {
            if (
              !requestToken.locations?.some((rtl) => rtl.id === dtl.id) &&
              !locations.some((l) => l.id === dtl.id)
            ) {
              requestToken.locations = requestToken.locations?.concat({ ...dtl, delete: true });
            }
          }
        });
      }
    });
    let requestLocations: LocationRequest[] = locations;
    data?.locations?.forEach((dl) => {
      if (!requestLocations.some((rl) => rl.id === dl.id)) {
        requestLocations = requestLocations.concat({
          ...dl,
          delete: true
        });
      }
    });

    if (
      dataValidated &&
      name &&
      description &&
      descriptionImage &&
      listDescriptionImage &&
      startDate &&
      endDate &&
      goalDescription &&
      unlockThreshold &&
      wakaPrize &&
      wakaTokens &&
      prizeTitle &&
      prizeDescription
    ) {
      const success = await updateQuest(parseInt(questId), {
        brandId: parseInt(brandId),
        logo,
        name,
        description,
        descriptionImage,
        listDescriptionImage,
        modeSlug,
        startDate,
        endDate,
        goalDescription,
        unlockThreshold,
        wakaPrize,
        wakaTokens,
        initiativeTypeId,
        prizeTitle,
        prizeDescription,
        badge:
          badgeName && badgeImage && badgeDescription
            ? { name: badgeName, description: badgeDescription, image: badgeImage }
            : undefined,
        tokens: requestTokens,
        locations: modeSlug === TokenAssignmentMode.TOKEN_FIXED ? undefined : requestLocations,
        advertising,
        assetId
      });
      openSnackBar(
        success,
        t('initiatives:quests:snackbar:questEditSuccess'),
        t('initiatives:quests:snackbar:questEditError')
      );
      dispatch(questFormActions.setEdited(false));
      fetchQuestData();
    }
  };
  const triggerDeleteQuest = async () => {
    if (questId) {
      const success = await deleteQuest(parseInt(questId));
      openSnackBar(
        success,
        t('initiatives:quests:snackbar:questDeleteSuccess'),
        t('initiatives:quests:snackbar:questDeleteError')
      );
      setDeleteQuestDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  // Flyer methods
  const manageFlyerSelect = async (flyer: Flyer) => {
    setFlyersErr(undefined);
    if (tokensErr === t('errors:moreFlyersThenMarkers')) setTokensErr(undefined);

    const alreadyCheckedToken = tokens.find((t) => t.flyerId === flyer.id);
    if (!alreadyCheckedToken) {
      dispatch(
        questFormActions.setTokens(
          tokens.concat({
            flyer,
            flyerId: flyer.id,
            position: modeSlug === TokenAssignmentMode.ORDERED ? tokens.length + 1 : undefined
          })
        )
      );
      if (!edited) dispatch(questFormActions.setEdited(true));
    }
  };
  const manageFlyerDeselect = async (flyerId: number) => {
    setFlyersErr(undefined);
    if (tokensErr === t('errors:moreFlyersThenMarkers')) setTokensErr(undefined);

    const alreadyCheckedToken = tokens.find((t) => t.flyerId === flyerId);
    if (alreadyCheckedToken) {
      dispatch(
        questFormActions.setTokens(_.differenceWith(tokens, [alreadyCheckedToken], _.isEqual))
      );
      if (modeSlug === TokenAssignmentMode.ORDERED) {
        const reoderedTokens = [..._.differenceWith(tokens, [alreadyCheckedToken], _.isEqual)];
        for (let i = 0; i < reoderedTokens.length; i++) {
          reoderedTokens[i] = { ...reoderedTokens[i], position: i + 1 };
        }
        dispatch(questFormActions.setTokens(reoderedTokens));
      }
      if (!edited) dispatch(questFormActions.setEdited(true));
    }
  };
  const setFirstFlyer = async (flyerId: number) => {
    if (
      modeSlug !== TokenAssignmentMode.FIRST_FIXED &&
      modeSlug !== TokenAssignmentMode.FIRST_AND_LAST_FIXED
    )
      return;
    setFirstFlyerErr(undefined);

    const newTokens: ({ flyer?: Flyer } & TokenRequest)[] = tokens.map((t) =>
      _.omit(t, ['position'])
    );
    const concernedFlyerIndex = newTokens.findIndex((t) => t.flyerId === flyerId);
    if (concernedFlyerIndex >= 0) {
      newTokens[concernedFlyerIndex] = {
        ..._.omit(newTokens[concernedFlyerIndex], ['isFinal']),
        position: 1
      };
      dispatch(questFormActions.setTokens(newTokens));
      if (!edited) dispatch(questFormActions.setEdited(true));
    }
  };
  const setLastFlyer = async (flyerId: number) => {
    if (
      modeSlug !== TokenAssignmentMode.LAST_FIXED &&
      modeSlug !== TokenAssignmentMode.FIRST_AND_LAST_FIXED
    )
      return;
    setLastFlyerErr(undefined);

    const newTokens: ({ flyer?: Flyer } & TokenRequest)[] = tokens.map((t) =>
      _.omit(t, ['isFinal'])
    );
    const concernedFlyerIndex = newTokens.findIndex((t) => t.flyerId === flyerId);
    if (concernedFlyerIndex >= 0) {
      newTokens[concernedFlyerIndex] = {
        ..._.omit(newTokens[concernedFlyerIndex], ['position']),
        isFinal: true
      };
      dispatch(questFormActions.setTokens(newTokens));
      if (!edited) dispatch(questFormActions.setEdited(true));
    }
  };

  // Usefull Components
  const DictionaryTextField = useCallback(
    (props: {
      label: string;
      dictionaryField: DictionaryString;
      onChange?: (dictionaryText: { en: string; it: string }) => void;
      error: string | undefined;
      errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
      multiline?: boolean;
      disabled?: boolean;
      maxLength?: number;
    }) => {
      return (
        <TextField
          disabled={props.disabled}
          size="small"
          label={`${
            fillForEachLanguage
              ? languages.map((l) => getUnicodeFlagIcon(l.unicode_slug)).join(' ')
              : getUnicodeFlagIcon(
                  languages.find((l) => l.slug === selectedLanguage)?.unicode_slug ?? ''
                )
          } ${props.label}`}
          error={Boolean(props.error)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: props.maxLength }}
          value={props.dictionaryField[selectedLanguage]}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newTextValue = { ...props.dictionaryField, [selectedLanguage]: e.target.value };
            if (fillForEachLanguage)
              _.forEach(newTextValue, (value, key) => {
                newTextValue[key as Language] = e.target.value;
              });
            if (props.onChange) props.onChange(newTextValue);
            if (props.error) props.errorSetter(undefined);
          }}
          helperText={props.error}
          multiline={props.multiline}
          sx={
            props.multiline
              ? {
                  '&>div': {
                    minHeight: '4rem',
                    height: '100%',
                    '&>textarea': {
                      marginBottom: 'auto'
                    }
                  }
                }
              : undefined
          }
        />
      );
    },
    [selectedLanguage, fillForEachLanguage]
  );
  const UploadImgField = (props: {
    label: string;
    field: string | null;
    setter: ActionCreatorWithPayload<string | null>;
    error: string | undefined;
    errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
    disabled?: boolean;
    helperText?: string;
    maxSize?: number;
  }) => {
    return (
      <div>
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            border: `1px solid ${props.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
            position: 'relative'
          }}>
          <label
            style={{
              color: props.disabled
                ? 'rgba(0, 0, 0, 0.38)'
                : props.error
                ? '#d32f2f'
                : 'rgba(0, 0, 0, 0.6)',
              fontSize: '1rem',
              position: 'absolute',
              left: 0,
              top: 0,
              transform: 'translate(8px, -9px) scale(0.75)',
              transformOrigin: 'top left',
              background: 'white',
              paddingInline: 6,
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              letterSpacing: '0.00938em'
            }}>
            {props.label}
          </label>
          <UploadImg
            title={`${props.field ? 'change' : 'upload'} image`}
            remoteFileName={props.field ? PathFormatter.absoluteToRelative(props.field) : undefined}
            onDelete={() => {
              dispatch(props.setter(null));
              if (!edited) dispatch(questFormActions.setEdited(true));
            }}
            onUploaded={(filename) => {
              dispatch(props.setter(filename));
              props.errorSetter(undefined);
              if (!edited) dispatch(questFormActions.setEdited(true));
            }}
            disabled={props.disabled}
            helperText={props.helperText}
            maxSize={props.maxSize}
          />
        </Box>

        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            mt: 0.5,
            mx: '14px',
            display: props.error ? 'block' : 'none'
          }}>
          {props.error}
        </Typography>
      </div>
    );
  };

  // UI
  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between" mb={3}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              <PinDrop sx={{ mr: 1 }} />
              {props.mode === 'create'
                ? t('initiatives:quests:createQuest')
                : data?.name?.it ?? '--'}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('initiatives:quests:texts:createPageDescription')
                : t('initiatives:quests:texts:editPageDescription')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          leftcomponent={
            <LanguageSelection
              fillForEachLanguage={fillForEachLanguage}
              onFillForEachLanguageBtnClick={() => setFillForEachLanguage(!fillForEachLanguage)}
              selectedLanguage={selectedLanguage}
              onLanguageSelected={(lng: Language) => setSelectedLanguage(lng)}
            />
          }
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addQuestRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddQuest}>
                {t('initiatives:quests:createQuest')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data}
                  onClick={() => setDeleteQuestDialogOpen(true)}
                  sx={{ backgroundColor: 'white' }}>
                  {t('initiatives:quests:deleteQuest')}
                </Button>
                {!viewOnlyMode && (
                  <LoadingButton
                    loading={updateQuestRequest.inProgress}
                    color="success"
                    variant="contained"
                    startIcon={<Check />}
                    disabled={!edited}
                    onClick={triggerUpdateQuest}>
                    {t('common:confirmChanges')}
                  </LoadingButton>
                )}
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      {brandId && (props.mode === 'create' || (props.mode === 'edit' && data)) ? (
        <FlexBox flexDirection="column" gap={2} width="88%" m="auto">
          <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} flex={1} gap={2} width="100%">
            <FlexBox flexDirection="column" flex={1} gap={2}>
              <EditableCard
                hideHeaderDivider
                sx={{ flex: 1 }}
                title={t('initiatives:quests:pageSections:generalInfo')}
                icon={<InfoOutlined color="primary" />}>
                <Stack gap={2} p={2} pt={0}>
                  <InfoText>
                    <p>{t('initiatives:formInputHelperTexts:rememberToCompileForEachLanguage')}</p>
                    <p>
                      &bull; &nbsp;
                      {t('initiatives:formInputHelperTexts:insertNameAndDescription')}
                    </p>
                  </InfoText>

                  {/* Name */}
                  <DictionaryTextField
                    label={`${t('initiatives:formInputLabels:name')}*`}
                    dictionaryField={name}
                    onChange={(dictionaryText) => {
                      dispatch(questFormActions.setName(dictionaryText));
                      if (!edited) dispatch(questFormActions.setEdited(true));
                    }}
                    error={nameErr}
                    errorSetter={setNameErr}
                    disabled={viewOnlyMode}
                  />

                  {/* Description */}
                  <DictionaryTextField
                    label={`${t('initiatives:formInputLabels:description')}*`}
                    dictionaryField={description}
                    onChange={(dictionaryText) => {
                      dispatch(questFormActions.setDescription(dictionaryText));
                      if (!edited) dispatch(questFormActions.setEdited(true));
                    }}
                    error={descriptionErr}
                    errorSetter={setDescriptionErr}
                    multiline
                    disabled={viewOnlyMode}
                  />

                  <InfoText>
                    <p>
                      &bull; &nbsp;
                      {t('initiatives:formInputHelperTexts:insertLogo')}
                    </p>
                  </InfoText>
                  {/* Initiative logo */}
                  <UploadImgField
                    label={t('initiatives:formInputLabels:initiativeLogo') + '*'}
                    field={logo}
                    setter={questFormActions.setLogo}
                    error={logoErr}
                    errorSetter={setLogoErr}
                    disabled={viewOnlyMode}
                    helperText={`Ratio: 300x50, Max size: 300kb`}
                    maxSize={300000}
                  />

                  <InfoText>
                    <p>
                      &bull; &nbsp;
                      {t('initiatives:formInputHelperTexts:insertDescriptionImages')}
                    </p>
                  </InfoText>
                  {/* Description Image */}
                  <UploadImgField
                    label={`${t('initiatives:formInputLabels:descriptionImage')} *`}
                    field={descriptionImage}
                    setter={questFormActions.setDescriptionImage}
                    error={descriptionImageErr}
                    errorSetter={setDescriptionImageErr}
                    disabled={viewOnlyMode}
                    helperText={`Ratio: 750x450, Max size: 500kb`}
                    maxSize={500000}
                  />
                  {/* Description Image */}
                  <UploadImgField
                    label={`${t('initiatives:formInputLabels:listDescriptionImage')} *`}
                    field={listDescriptionImage}
                    setter={questFormActions.setListDescriptionImage}
                    error={listDescriptionImageErr}
                    errorSetter={setListDescriptionImageErr}
                    disabled={viewOnlyMode}
                    helperText={`Ratio: 300x300, Max size: 500kb`}
                    maxSize={500000}
                  />

                  <InfoText>
                    <p>
                      &bull; &nbsp;
                      {t('initiatives:formInputHelperTexts:specifyInitiativeType')}
                    </p>
                  </InfoText>
                  {/* Initiative Type */}
                  <FormControl fullWidth size="small">
                    <InputLabel>{t('initiatives:formInputLabels:initiativeType') + '*'}</InputLabel>
                    <Select
                      size="small"
                      disabled={viewOnlyMode || initiativeTypesRequest.inProgress}
                      value={initiativeTypeId}
                      label={t('initiatives:formInputLabels:initiativeType') + '*'}
                      onChange={(e) => {
                        if (!edited) dispatch(questFormActions.setEdited(true));
                        dispatch(
                          questFormActions.setInitiativeTypeId(
                            typeof e.target.value === 'string'
                              ? parseInt(e.target.value)
                              : e.target.value
                          )
                        );
                      }}>
                      {initiativeTypes?.map((inTy) => (
                        <MenuItem key={`initiative-type-${inTy.id}`} value={inTy.id}>
                          {inTy.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </EditableCard>

              <EditableCard
                hideHeaderDivider
                icon={<Tune color="primary" />}
                title={t('initiatives:quests:pageSections:mode')}
                bodySx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Mode */}
                <InfoText mb={1}>{t('initiatives:formInputHelperTexts:setMode')}</InfoText>

                {/* Token Assignment mode */}
                <FormControl fullWidth size="small" disabled={viewOnlyMode || data?.published}>
                  <InputLabel>{t('initiatives:quests:tokenAssignment:mode')}</InputLabel>
                  <Select
                    value={modeSlug}
                    label={t('initiatives:quests:tokenAssignment:mode')}
                    onChange={(e) => {
                      if (!edited) dispatch(questFormActions.setEdited(true));
                      dispatch(questFormActions.setModeSlug(e.target.value as TokenAssignmentMode));
                      // Reset tokens values on mode change
                      dispatch(
                        questFormActions.setTokens(
                          data
                            ? data.tokens
                              ? data.tokens.map((t) => {
                                  return {
                                    id: t.id,
                                    flyerId: t.flyer?.id,
                                    flyer: t.flyer ?? undefined
                                  };
                                })
                              : []
                            : []
                        )
                      );
                      // Reset errors
                      setFirstFlyerErr(undefined);
                      setLastFlyerErr(undefined);
                      setFlyersErr(undefined);
                    }}>
                    {assignmentModes.map((mode) => (
                      <MenuItem key={`item-${mode.slug}`} value={mode.slug}>
                        <FlexBox alignItems="center" gap={1} mt={0.5}>
                          <Box
                            sx={{
                              backgroundColor: (theme) => theme.palette.primary.main,
                              p: '2px',
                              height: '25px',
                              color: 'white',
                              borderRadius: 1
                            }}>
                            {mode.icon}
                          </Box>
                          {mode.title}
                        </FlexBox>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InfoText mt={-1}>
                  {assignmentModes.find((m) => m.slug === modeSlug)?.description}
                </InfoText>

                {/* No Campaign Flyers for Wakapapa */}
                {brandId && parseInt(brandId) === WAKAPAPA_ID && (
                  <>
                    <InfoText pt={2}>
                      &bull; &nbsp;{t('initiatives:formInputHelperTexts:setAdvertising')}
                    </InfoText>
                    <Button
                      disabled={viewOnlyMode || data?.published}
                      onClick={() => {
                        dispatch(questFormActions.setAdvertising(advertising ? undefined : true));
                        if (!edited) dispatch(questFormActions.setEdited(true));
                      }}
                      variant={advertising ? 'contained' : 'outlined'}
                      disableElevation
                      sx={{
                        color: (theme) => (advertising ? 'white' : theme.palette.primary.main)
                      }}
                      startIcon={advertising ? <Check /> : undefined}>
                      {t('initiatives:formInputLabels:advertising')}
                    </Button>
                  </>
                )}
              </EditableCard>
            </FlexBox>

            <FlexBox
              flexDirection="column"
              flex={2}
              maxWidth={!tabletWidth ? '66%' : undefined}
              gap={2}>
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} flex={1} gap={2}>
                <FlexBox flexDirection="column" flex={1} gap={2}>
                  {brandId && (
                    <AssetsCard
                      assetId={assetId}
                      infoText={t('initiatives:formInputHelperTexts:setAsset')}
                      brandId={parseInt(brandId)}
                      onlyEditMode
                      disabled={viewOnlyMode}
                      onChangeAsset={(asset?: { id: number; file: string }) => {
                        dispatch(questFormActions.setAssetId(asset?.id ?? null));
                        if (!edited) dispatch(questFormActions.setEdited(true));
                      }}
                    />
                  )}

                  <EditableCard
                    hideHeaderDivider
                    sx={{ flex: 1 }}
                    title={t('initiatives:quests:pageSections:prizes')}
                    icon={<EmojiEventsOutlined color="primary" />}>
                    <Stack gap={2} p={2} pt={0}>
                      <InfoText>
                        <p>
                          {t('initiatives:formInputHelperTexts:rememberToCompileForEachLanguage')}
                        </p>
                        <p>
                          &bull; &nbsp;
                          {t('initiatives:formInputHelperTexts:insertGoalDescription')}
                        </p>
                      </InfoText>
                      {/* Goal Description */}
                      <DictionaryTextField
                        label={`${t('initiatives:formInputLabels:goalDescription')}*`}
                        dictionaryField={goalDescription}
                        onChange={(dictionaryText) => {
                          dispatch(questFormActions.setGoalDescription(dictionaryText));
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        error={goalDescriptionErr}
                        errorSetter={setGoalDescriptionErr}
                        multiline
                        disabled={viewOnlyMode}
                      />

                      <InfoText>
                        <p>
                          &bull; &nbsp;
                          {t('initiatives:formInputHelperTexts:insertPrizeTitleAndDescription')}
                        </p>
                      </InfoText>
                      {/* Prize Title */}
                      <DictionaryTextField
                        label={`${t('initiatives:formInputLabels:prizeTitle')}*`}
                        dictionaryField={prizeTitle}
                        onChange={(dictionaryText) => {
                          dispatch(questFormActions.setPrizeTitle(dictionaryText));
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        error={prizeTitleErr}
                        errorSetter={setPrizeTitleErr}
                        disabled={viewOnlyMode}
                        maxLength={7}
                      />
                      {/* Prize Description */}
                      <DictionaryTextField
                        label={`${t('initiatives:formInputLabels:prizeDescription')}*`}
                        dictionaryField={prizeDescription}
                        onChange={(dictionaryText) => {
                          dispatch(questFormActions.setPrizeDescription(dictionaryText));
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        error={prizeDescriptionErr}
                        errorSetter={setPrizeDescriptionErr}
                        multiline
                        disabled={viewOnlyMode}
                        maxLength={30}
                      />

                      {/* Unlock Threshold */}
                      <InfoText>
                        <p>
                          &bull; &nbsp;
                          {t('initiatives:formInputHelperTexts:specifyUnlockThreshold')}
                        </p>
                        <p>{t('initiatives:formInputHelperTexts:unlockThresholdBounds')}</p>
                      </InfoText>
                      <FlexBox gap={1}>
                        <NumericFormat
                          disabled={viewOnlyMode || data?.published}
                          sx={{ flex: 1 }}
                          size="small"
                          error={Boolean(unlockThresholdErr)}
                          label={t('initiatives:formInputLabels:unlockThreshold') + '*'}
                          customInput={TextField}
                          helperText={unlockThresholdErr}
                          InputLabelProps={{ shrink: true }}
                          value={unlockThreshold}
                          onChange={(e) => {
                            dispatch(questFormActions.setUnlockThreshold(parseInt(e.target.value)));
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setUnlockThresholdErr(undefined);
                          }}
                        />
                        <IncrementDecrementBtn
                          disabled={viewOnlyMode || data?.published}
                          onIncrement={() => {
                            dispatch(
                              questFormActions.setUnlockThreshold(
                                unlockThreshold ? unlockThreshold + 1 : 1
                              )
                            );
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setUnlockThresholdErr(undefined);
                          }}
                          onDecrement={() => {
                            dispatch(
                              questFormActions.setUnlockThreshold(
                                unlockThreshold ? unlockThreshold - 1 : 0
                              )
                            );
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setUnlockThresholdErr(undefined);
                          }}
                        />
                      </FlexBox>

                      <InfoText>
                        <p>
                          &bull; &nbsp;
                          {t('initiatives:formInputHelperTexts:specifyWakaTokensAndPrize')}
                        </p>
                      </InfoText>
                      {/* Waka Tokens */}
                      <FlexBox gap={1}>
                        <NumericFormat
                          disabled={viewOnlyMode || data?.published}
                          sx={{ flex: 1 }}
                          size="small"
                          error={Boolean(wakaTokensErr)}
                          label={t('initiatives:formInputLabels:wakaTokens') + '*'}
                          customInput={TextField}
                          helperText={wakaTokensErr}
                          InputLabelProps={{ shrink: true }}
                          value={wakaTokens}
                          onChange={(e) => {
                            dispatch(questFormActions.setWakaTokens(parseInt(e.target.value)));
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaTokensErr(undefined);
                          }}
                        />
                        <IncrementDecrementBtn
                          disabled={viewOnlyMode || data?.published}
                          onIncrement={() => {
                            dispatch(
                              questFormActions.setWakaTokens(wakaTokens ? wakaTokens + 1 : 1)
                            );
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaTokensErr(undefined);
                          }}
                          onDecrement={() => {
                            dispatch(
                              questFormActions.setWakaTokens(wakaTokens ? wakaTokens - 1 : 0)
                            );
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaTokensErr(undefined);
                          }}
                        />
                      </FlexBox>
                      {/* Waka Prize */}
                      <FlexBox gap={1}>
                        <NumericFormat
                          disabled={viewOnlyMode || data?.published}
                          sx={{ flex: 1 }}
                          size="small"
                          error={Boolean(wakaPrizeErr)}
                          label={t('initiatives:formInputLabels:wakaPrize') + '*'}
                          customInput={TextField}
                          helperText={wakaPrizeErr}
                          InputLabelProps={{ shrink: true }}
                          value={wakaPrize}
                          onChange={(e) => {
                            dispatch(questFormActions.setWakaPrize(parseInt(e.target.value)));
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaPrizeErr(undefined);
                          }}
                        />
                        <IncrementDecrementBtn
                          disabled={viewOnlyMode || data?.published}
                          onIncrement={() => {
                            dispatch(questFormActions.setWakaPrize(wakaPrize ? wakaPrize + 1 : 1));
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaPrizeErr(undefined);
                          }}
                          onDecrement={() => {
                            dispatch(questFormActions.setWakaPrize(wakaPrize ? wakaPrize - 1 : 0));
                            if (!edited) dispatch(questFormActions.setEdited(true));
                            setWakaPrizeErr(undefined);
                          }}
                        />
                      </FlexBox>
                    </Stack>
                  </EditableCard>
                </FlexBox>

                <FlexBox flexDirection="column" flex={1} gap={2}>
                  <EditableCard
                    hideHeaderDivider
                    title={t('initiatives:quests:pageSections:duration')}
                    icon={<AccessTime color="primary" />}>
                    <Stack gap={2} p={2} pt={0}>
                      <Box sx={{ color: 'text.secondary', fontSize: 13, fontStyle: 'italic' }}>
                        <p>{t('initiatives:formInputHelperTexts:selectDates')}</p>
                        <p>{t('initiatives:formInputHelperTexts:rememberToPublish')}</p>
                      </Box>
                      {/* Start Date */}
                      <DateTimePicker
                        label={t('initiatives:formInputLabels:startDate') + '*'}
                        value={startDate}
                        disabled={
                          viewOnlyMode ||
                          (startDate ? props.mode === 'edit' && data?.published : undefined)
                        }
                        minDateTime={
                          props.mode === 'create'
                            ? Date.now()
                            : data?.startDate && data?.startDate < Date.now() && data?.published
                            ? data?.startDate
                            : Date.now()
                        }
                        onChange={(value: number | null) => {
                          dispatch(questFormActions.setStartDate(value));
                          setStartDateErr(undefined);
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            helperText={startDateErr}
                            FormHelperTextProps={{ error: Boolean(startDateErr), filled: true }}
                            sx={{
                              '&>label': {
                                color: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                              },
                              '&>div>fieldset': {
                                borderColor: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                              }
                            }}
                            {...params}
                          />
                        )}
                      />
                      {/* End Date */}
                      <DateTimePicker
                        label={t('initiatives:formInputLabels:endDate') + '*'}
                        value={endDate}
                        minDateTime={startDate && startDate < Date.now() ? Date.now() : startDate}
                        disabled={viewOnlyMode}
                        onChange={(value: number | null) => {
                          dispatch(questFormActions.setEndDate(value));
                          setEndDateErr(undefined);
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            helperText={endDateErr}
                            FormHelperTextProps={{ error: Boolean(endDateErr) }}
                            sx={{
                              '&>label': {
                                color: viewOnlyMode
                                  ? 'rgba(0, 0, 0, 0.38) !important'
                                  : endDateErr
                                  ? '#d32f2f'
                                  : 'rgba(0, 0, 0, 0.6)'
                              },
                              '&>div>fieldset': {
                                borderColor: endDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                              }
                            }}
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </EditableCard>

                  <EditableCard
                    hideHeaderDivider
                    sx={{ flex: 1 }}
                    bodySx={{
                      minHeight: 150
                    }}
                    title={t('initiatives:quests:pageSections:badge')}
                    icon={<VerifiedOutlined color="primary" />}>
                    <Stack gap={2} p={2} pt={0}>
                      <Box sx={{ color: 'text.secondary', fontSize: 13, fontStyle: 'italic' }}>
                        <p>{t('initiatives:formInputHelperTexts:addBadge')}</p>
                        <p>{t('initiatives:formInputHelperTexts:validBadge')}</p>
                      </Box>

                      {/* Badge Image */}
                      <UploadImgField
                        label={
                          t('initiatives:formInputLabels:badgeImage') +
                          `${
                            Object.values(badgeName).some((v) => v) ||
                            Object.values(badgeDescription).some((v) => v) ||
                            badgeImage
                              ? '*'
                              : ''
                          }`
                        }
                        field={badgeImage}
                        setter={questFormActions.setBadgeImage}
                        error={badgeImageErr}
                        errorSetter={setBadgeImageErr}
                        disabled={viewOnlyMode}
                        helperText={`Ratio: 500x500, Max size: 300kb`}
                        maxSize={300000}
                      />

                      {/* Badge Name */}
                      <DictionaryTextField
                        label={
                          t('initiatives:formInputLabels:badgeName') +
                          `${
                            Object.values(badgeName).some((v) => v) ||
                            Object.values(badgeDescription).some((v) => v) ||
                            badgeImage
                              ? '*'
                              : ''
                          }`
                        }
                        dictionaryField={badgeName}
                        onChange={(dictionaryText) => {
                          setBadgeImageErr(undefined);
                          setBadgeDescriptionErr(undefined);
                          dispatch(questFormActions.setBadgeName(dictionaryText));
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        error={badgeNameErr}
                        errorSetter={setBadgeNameErr}
                        disabled={viewOnlyMode}
                      />

                      {/* Badge Description */}
                      <DictionaryTextField
                        label={
                          t('initiatives:formInputLabels:badgeDescription') +
                          `${
                            Object.values(badgeName).some((v) => v) ||
                            Object.values(badgeDescription).some((v) => v) ||
                            badgeImage
                              ? '*'
                              : ''
                          }`
                        }
                        dictionaryField={badgeDescription}
                        onChange={(dictionaryText) => {
                          setBadgeImageErr(undefined);
                          setBadgeNameErr(undefined);
                          dispatch(questFormActions.setBadgeDescription(dictionaryText));
                          if (!edited) dispatch(questFormActions.setEdited(true));
                        }}
                        error={badgeDescriptionErr}
                        errorSetter={setBadgeDescriptionErr}
                        multiline
                        disabled={viewOnlyMode}
                      />
                    </Stack>
                  </EditableCard>
                </FlexBox>
              </FlexBox>

              <FlyersContainer
                iconColor={theme.palette.primary.main}
                errorMsg={flyersErr}
                firstFlyerErr={firstFlyerErr}
                lastFlyerErr={lastFlyerErr}
                brandId={parseInt(brandId)}
                mode={modeSlug}
                initiativeTokens={tokens}
                onFlyerSelected={manageFlyerSelect}
                onFlyerDeselected={manageFlyerDeselect}
                onFirstFlyerDropped={setFirstFlyer}
                onLastFlyerDropped={setLastFlyer}
                onTokensOrderChanged={(newTokens) => {
                  dispatch(questFormActions.setTokens(newTokens));
                  setFlyersErr(undefined);
                  if (!edited) dispatch(questFormActions.setEdited(true));
                }}
                disableAll={viewOnlyMode}
                disablePit={props.mode === 'edit' && data?.published}
                disabledTokens={
                  props.mode === 'edit' && data?.published ? data?.tokens ?? undefined : undefined
                }
              />
            </FlexBox>
          </FlexBox>

          {brandId && (
            <LocationsCard
              disableLocationsForDelete={
                data?.published
                  ? (
                      data?.locations ?? _.flatten(_.compact(data?.tokens?.map((t) => t.locations)))
                    ).map((location) => location.id)
                  : undefined
              }
              disabled={viewOnlyMode}
              error={tokensErr}
              brandId={parseInt(brandId)}
              locations={locations}
              tokens={tokens}
              onLocationAdd={(location) => {
                dispatch(questFormActions.setLocations([...locations, location]));
                setTokensErr(undefined);
                if (!edited) dispatch(questFormActions.setEdited(true));
              }}
              onLocationDeleted={(locationToDelete) => {
                dispatch(
                  questFormActions.setLocations(
                    _.differenceWith(locations, [locationToDelete], _.isEqual)
                  )
                );

                if (modeSlug === TokenAssignmentMode.TOKEN_FIXED) {
                  const newTokens = [...tokens];
                  tokens.forEach((tkn, i) => {
                    newTokens[i] = {
                      ...newTokens[i],
                      locations: _.differenceWith(tkn.locations, [locationToDelete], _.isEqual)
                    };
                  });
                  dispatch(questFormActions.setTokens(newTokens));
                }
                setTokensErr(undefined);
                if (!edited) dispatch(questFormActions.setEdited(true));
              }}
              onLocationChanged={(newLocation, oldLocation) => {
                const newLocations = [...locations];
                const concernedLocationIndex = newLocations.findIndex((l) =>
                  _.isEqual(l, oldLocation)
                );
                if (concernedLocationIndex >= 0) {
                  newLocations[concernedLocationIndex] = newLocation;
                }
                dispatch(questFormActions.setLocations(newLocations));

                if (modeSlug === TokenAssignmentMode.TOKEN_FIXED) {
                  const newTokens = [...tokens];
                  const concernedTokenIndex = newTokens.findIndex((tkn) =>
                    tkn.locations?.find((l) => _.isEqual(l, oldLocation))
                  );
                  if (concernedTokenIndex >= 0) {
                    const newTokenLocations = newTokens[concernedTokenIndex].locations;
                    const concernedTokenLocationIndex = newTokenLocations?.findIndex((l) =>
                      _.isEqual(l, oldLocation)
                    );
                    if (
                      newTokenLocations &&
                      concernedTokenLocationIndex !== undefined &&
                      concernedTokenLocationIndex >= 0
                    ) {
                      const newLocations = [...newTokenLocations];
                      newLocations[concernedTokenLocationIndex] = newLocation;
                      newTokens[concernedTokenIndex] = {
                        ...newTokens[concernedTokenIndex],
                        locations: newLocations
                      };
                      dispatch(questFormActions.setTokens(newTokens));
                    }
                  }
                }
                if (!edited) dispatch(questFormActions.setEdited(true));
              }}
              onAssignLocationToTkn={(tknIndex, location) => {
                if (modeSlug !== TokenAssignmentMode.TOKEN_FIXED) return;
                const newTokens = [...tokens];

                // Remove old assignment
                const toRemoveLocationTknIndex = tokens.findIndex((tkn) =>
                  tkn.locations?.find((l) => _.isEqual(l, location))
                );
                if (toRemoveLocationTknIndex >= 0) {
                  const toRemoveLocationTokenLocations =
                    newTokens[toRemoveLocationTknIndex].locations;
                  const concernedTokenLocationIndex = toRemoveLocationTokenLocations?.findIndex(
                    (l) => _.isEqual(l, location)
                  );
                  if (
                    toRemoveLocationTokenLocations &&
                    concernedTokenLocationIndex !== undefined &&
                    concernedTokenLocationIndex >= 0
                  ) {
                    const newLocations = [...toRemoveLocationTokenLocations];
                    newLocations.splice(concernedTokenLocationIndex, 1);
                    newTokens[toRemoveLocationTknIndex] = {
                      ...newTokens[toRemoveLocationTknIndex],
                      locations: newLocations.length ? newLocations : undefined
                    };
                  }
                }

                // assign to new token
                newTokens[tknIndex] = {
                  ...newTokens[tknIndex],
                  locations: newTokens[tknIndex].locations
                    ? newTokens[tknIndex].locations?.concat(location)
                    : [location]
                };
                dispatch(questFormActions.setTokens(newTokens));
                setTokensErr(undefined);
                if (!edited) dispatch(questFormActions.setEdited(true));
              }}
            />
          )}
        </FlexBox>
      ) : (
        <ActivityIndicator />
      )}

      {/* Footer */}
      <Toolbar
        mt={4}
        rightcomponent={
          props.mode === 'create' ? (
            <LoadingButton
              loading={addQuestRequest.inProgress}
              color="success"
              variant="contained"
              startIcon={<Check />}
              onClick={triggerAddQuest}>
              {t('initiatives:quests:createQuest')}
            </LoadingButton>
          ) : props.mode === 'edit' ? (
            <>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForever />}
                disabled={!data}
                onClick={() => setDeleteQuestDialogOpen(true)}
                sx={{ backgroundColor: 'white' }}>
                {t('initiatives:quests:deleteQuest')}
              </Button>
              {!viewOnlyMode && (
                <LoadingButton
                  loading={updateQuestRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateQuest}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              )}
            </>
          ) : (
            <></>
          )
        }
      />

      {/* Dialogs */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('errors:compilationErrors')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
      <Dialog open={deleteQuestDialogOpened} onClose={() => setDeleteQuestDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('initiatives:texts:deleteInitiativeInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteQuestDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteQuestRequest.inProgress}
              onClick={triggerDeleteQuest}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
