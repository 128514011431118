import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FilesRepository from '../api/repositories/FilesRepository';
import useAuthentication from './useAuthentication';

type UseFiles = {
  uploadFile: (file: File) => Promise<string | void>;
  deleteFile: (filename: string) => Promise<boolean>;
};

export default function useFiles(): UseFiles {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      FilesRepository.updateAuthToken(accessToken);
      FilesRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const uploadFile: UseFiles['uploadFile'] = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file as Blob, file.name);
      let relativeRemoteFilePath;

      try {
        relativeRemoteFilePath = await FilesRepository.uploadFile(formData);
      } catch (error) {
        console.warn(error);
        return;
      }

      return relativeRemoteFilePath;
    },
    [dispatch]
  );

  const deleteFile: UseFiles['deleteFile'] = useCallback(
    async (filename: string) => {
      try {
        await FilesRepository.deleteFile(filename);
      } catch (error) {
        console.warn(error);
        return false;
      }

      return true;
    },
    [dispatch]
  );

  return {
    uploadFile,
    deleteFile
  };
}
