import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  FilterList,
  FilterListOff,
  FirstPage,
  History,
  LastPage,
  MenuBook,
  Tag
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Slide from '@mui/material/Slide';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { Flyer } from '../../entities/Flyer';
import { Token, TokenAssignmentMode, TokenRequest } from '../../entities/Token';
import useFlyers from '../../hooks/useFlyers';
import DateFormatter from '../../utils/DateFormatter';
import ActivityIndicator from './ActivityIndicator';
import { DatePicker } from './DateTimePicker';
import EditableCard from './EditableCard';
import FlyerCard from './FlyerCard';
import NoDataBanner from './NoDataBanner';
import OrderSelection from './OrderSelection';
import { FlexBox, InfoText, StyledTooltip } from './StyledComponents';

interface FlyersContainerProps {
  brandId: number;
  mode?: TokenAssignmentMode;
  initiativeTokens?: ({ flyer?: Flyer } & TokenRequest)[];
  onFlyerSelected?: (flyer: Flyer) => void;
  onFlyerDeselected?: (flyerId: number) => void;
  onFirstFlyerDropped?: (flyerId: number) => void;
  onLastFlyerDropped?: (flyerId: number) => void;
  onTokensOrderChanged?: (newTokens: ({ flyer?: Flyer } & TokenRequest)[]) => void;
  disableAll?: boolean;
  disabledTokens?: Token[];
  disablePit?: boolean;
  sx?: SxProps<Theme>;
  errorMsg?: string;
  firstFlyerErr?: string;
  lastFlyerErr?: string;
  iconColor?: string;
}
export default function FlyersContainer(props: FlyersContainerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { brandFlyers, readFlyers, readFlyersRequest } = useFlyers();
  const [orderedTokens, setOrderedTokens] = useState<({ flyer?: Flyer } & TokenRequest)[]>();
  const [validationTooltip, setValidationTooltip] = useState<{
    tknIndex: number;
    text: string;
  }>();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  // Filters
  const [sortValue, setSortValue] = React.useState<'default' | 'created_at'>('default');
  const [sortModeAsc, setSortModeAsc] = React.useState<boolean>(true);
  const [notUsed, setNotUsed] = React.useState<boolean>(false);
  const [expiresBefore, setExpiresBefore] = React.useState<number | null>(null);
  const [expiresAfter, setExpiresAfter] = React.useState<number | null>(null);

  const fecthFlyers = useCallback(
    async (page?: number) => {
      const range =
        !expiresAfter && !expiresBefore
          ? undefined
          : DateFormatter.toRange(expiresAfter, expiresBefore);

      await readFlyers(
        props.brandId,
        page,
        sortValue === 'default' ? undefined : `${sortModeAsc ? '' : '-'}${sortValue}`,
        range,
        notUsed || undefined
      );
    },
    [props.brandId, sortValue, notUsed, expiresAfter, expiresBefore, sortModeAsc]
  );

  useEffect(() => {
    fecthFlyers();
  }, [fecthFlyers]);

  useEffect(() => {
    const tmpTkns = props.initiativeTokens
      ? [...props.initiativeTokens].sort((a, b) => {
          if (a.position && b.position) {
            return a.position - b.position;
          } else return 0;
        })
      : undefined;
    if (tmpTkns) setOrderedTokens(tmpTkns);
  }, [props.initiativeTokens]);

  const renderContent = useMemo(() => {
    switch (props.mode) {
      case TokenAssignmentMode.TOKEN_FIXED:
      case TokenAssignmentMode.RANDOM_CONTENT:
        return (
          <>
            <FlexBox
              flexDirection="row"
              gap={1}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              {brandFlyers.flyers.map((f) => (
                <FlyerCard
                  sx={{ minWidth: 110, maxWidth: 'calc(10% - 10px)' }}
                  key={`flyer-${f.id}`}
                  data={f}
                  brandId={props.brandId}
                  selectable={Boolean(props.onFlyerSelected)}
                  selected={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                  onSelect={() => {
                    if (props.onFlyerSelected) props.onFlyerSelected(f);
                  }}
                  onDeselect={() => {
                    if (props.onFlyerDeselected) props.onFlyerDeselected(f.id);
                  }}
                  disabled={
                    props.disableAll ||
                    props.disabledTokens?.some((t) => t.flyer?.id === f.id) ||
                    props.initiativeTokens?.some((t) => t.flyerId === f.id && t.locations?.length)
                  }
                />
              ))}
            </FlexBox>
            <Pagination
              count={brandFlyers.pagination?.totalPages}
              page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
              onChange={(e, page) => fecthFlyers(page - 1)}
              shape="rounded"
              sx={{
                p: 2,
                m: 'auto',
                mb: 0,
                pb: 0
              }}
            />
          </>
        );
      case TokenAssignmentMode.FIRST_FIXED:
        return (
          <FlexBox flexDirection="row" gap={2}>
            <FlyerDropPit
              disabled={props.disablePit}
              brandId={props.brandId}
              flyer={props.initiativeTokens?.find((t) => t.position === 1)?.flyer}
              onDropped={(flyerId: number) => {
                if (props.onFirstFlyerDropped) props.onFirstFlyerDropped(flyerId);
              }}
              label={t('flyer:dragFirstFlyer')}
              err={props.firstFlyerErr}
              sx={{ flex: 1 }}
              topComponent={
                <FlexBox sx={{ mx: 'auto', mb: 1, alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      p: '2px',
                      height: '25px',
                      color: 'white',
                      borderRadius: 1
                    }}>
                    <FirstPage />
                  </Box>
                  <span>{t('common:first')}:</span>
                </FlexBox>
              }
            />
            <Box width={'1px !important'} sx={{ backgroundColor: '#e0e0e0' }} />
            <FlexBox flexDirection="column" flex={5}>
              <FlexBox
                flexDirection="row"
                gap={1}
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                {brandFlyers.flyers.length &&
                  brandFlyers.flyers.map((f) => (
                    <FlyerCard
                      sx={{ minWidth: 110, maxWidth: 'calc(10% - 10px)' }}
                      key={`flyer-${f.id}`}
                      data={f}
                      brandId={props.brandId}
                      selectable={Boolean(props.onFlyerSelected)}
                      selected={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                      onSelect={() => {
                        if (props.onFlyerSelected) props.onFlyerSelected(f);
                      }}
                      onDeselect={() => {
                        if (props.onFlyerDeselected) props.onFlyerDeselected(f.id);
                      }}
                      disabled={
                        props.disableAll || props.disabledTokens?.some((t) => t.flyer?.id === f.id)
                      }
                      draggable={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                    />
                  ))}
              </FlexBox>
              <Pagination
                count={brandFlyers.pagination?.totalPages}
                page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
                onChange={(e, page) => fecthFlyers(page - 1)}
                shape="rounded"
                sx={{
                  p: 2,
                  m: 'auto',
                  mb: 0,
                  pb: 0
                }}
              />
            </FlexBox>
          </FlexBox>
        );
      case TokenAssignmentMode.LAST_FIXED:
        return (
          <FlexBox flexDirection="row" gap={2}>
            <FlyerDropPit
              disabled={props.disablePit}
              brandId={props.brandId}
              flyer={props.initiativeTokens?.find((t) => t.isFinal)?.flyer}
              onDropped={(flyerId: number) => {
                if (props.onLastFlyerDropped) props.onLastFlyerDropped(flyerId);
              }}
              label={t('flyer:dragLastFlyer')}
              err={props.lastFlyerErr}
              sx={{ flex: 1 }}
              topComponent={
                <FlexBox sx={{ mx: 'auto', mb: 1, alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      p: '2px',
                      height: '25px',
                      color: 'white',
                      borderRadius: 1
                    }}>
                    <LastPage />
                  </Box>
                  <span>{t('common:last')}:</span>
                </FlexBox>
              }
            />
            <Box width={'1px !important'} sx={{ backgroundColor: '#e0e0e0', mx: 2 }} />

            <FlexBox flexDirection="column" flex={5}>
              <FlexBox
                flexDirection="row"
                gap={1}
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                {brandFlyers.flyers.length &&
                  brandFlyers.flyers.map((f) => (
                    <FlyerCard
                      sx={{ minWidth: 110, maxWidth: 'calc(10% - 10px)' }}
                      key={`flyer-${f.id}`}
                      data={f}
                      brandId={props.brandId}
                      selectable={Boolean(props.onFlyerSelected)}
                      selected={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                      onSelect={() => {
                        if (props.onFlyerSelected) props.onFlyerSelected(f);
                      }}
                      onDeselect={() => {
                        if (props.onFlyerDeselected) props.onFlyerDeselected(f.id);
                      }}
                      disabled={
                        props.disableAll || props.disabledTokens?.some((t) => t.flyer?.id === f.id)
                      }
                      draggable={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                    />
                  ))}
              </FlexBox>
              <Pagination
                count={brandFlyers.pagination?.totalPages}
                page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
                onChange={(e, page) => fecthFlyers(page - 1)}
                shape="rounded"
                sx={{
                  p: 2,
                  m: 'auto',
                  mb: 0,
                  pb: 0
                }}
              />
            </FlexBox>
          </FlexBox>
        );
      case TokenAssignmentMode.FIRST_AND_LAST_FIXED:
        return (
          <FlexBox flexDirection="row">
            <FlexBox flexDirection="column" flex={1} gap={1}>
              <FlyerDropPit
                disabled={props.disablePit}
                brandId={props.brandId}
                flyer={props.initiativeTokens?.find((t) => t.position === 1)?.flyer}
                onDropped={(flyerId: number) => {
                  if (props.onFirstFlyerDropped) props.onFirstFlyerDropped(flyerId);
                }}
                label={t('flyer:dragFirstFlyer')}
                err={props.firstFlyerErr}
                sx={{ flex: 1 }}
                topComponent={
                  <FlexBox sx={{ mx: 'auto', mb: 1, alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        p: '2px',
                        height: '25px',
                        color: 'white',
                        borderRadius: 1
                      }}>
                      <FirstPage />
                    </Box>
                    <span>{t('common:first')}:</span>
                  </FlexBox>
                }
              />
              <FlyerDropPit
                disabled={props.disablePit}
                brandId={props.brandId}
                flyer={props.initiativeTokens?.find((t) => t.isFinal)?.flyer}
                onDropped={(flyerId: number) => {
                  if (props.onLastFlyerDropped) props.onLastFlyerDropped(flyerId);
                }}
                label={t('flyer:dragLastFlyer')}
                err={props.lastFlyerErr}
                sx={{ flex: 1 }}
                topComponent={
                  <FlexBox sx={{ mx: 'auto', mb: 1, alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        p: '2px',
                        height: '25px',
                        color: 'white',
                        borderRadius: 1
                      }}>
                      <LastPage />
                    </Box>
                    <span>{t('common:last')}:</span>
                  </FlexBox>
                }
              />
            </FlexBox>

            <Box width={'1px !important'} sx={{ backgroundColor: '#e0e0e0', mx: 2 }} />

            <FlexBox flexDirection="column" flex={5}>
              <FlexBox
                flexDirection="row"
                gap={1}
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                {brandFlyers.flyers.length &&
                  brandFlyers.flyers.map((f) => (
                    <FlyerCard
                      sx={{ minWidth: 110, maxWidth: 'calc(10% - 10px)' }}
                      key={`flyer-${f.id}`}
                      data={f}
                      brandId={props.brandId}
                      selectable={Boolean(props.onFlyerSelected)}
                      selected={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                      onSelect={() => {
                        if (props.onFlyerSelected) props.onFlyerSelected(f);
                      }}
                      onDeselect={() => {
                        if (props.onFlyerDeselected) props.onFlyerDeselected(f.id);
                      }}
                      disabled={
                        props.disableAll || props.disabledTokens?.some((t) => t.flyer?.id === f.id)
                      }
                      draggable={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                    />
                  ))}
              </FlexBox>
              <Pagination
                count={brandFlyers.pagination?.totalPages}
                page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
                onChange={(e, page) => fecthFlyers(page - 1)}
                shape="rounded"
                sx={{
                  p: 2,
                  m: 'auto',
                  mb: 0,
                  pb: 0
                }}
              />
            </FlexBox>
          </FlexBox>
        );
      case TokenAssignmentMode.ORDERED:
        return (
          <>
            <FlexBox
              flexDirection="row"
              gap={1}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}>
              {brandFlyers.flyers.length &&
                brandFlyers.flyers.map((f) => (
                  <FlyerCard
                    sx={{ minWidth: 110, maxWidth: 'calc(10% - 10px)' }}
                    key={`flyer-${f.id}`}
                    data={f}
                    brandId={props.brandId}
                    selectable={Boolean(props.onFlyerSelected)}
                    selected={props.initiativeTokens?.some((t) => t.flyerId === f.id)}
                    onSelect={() => {
                      if (props.onFlyerSelected) props.onFlyerSelected(f);
                    }}
                    onDeselect={() => {
                      if (props.onFlyerDeselected) props.onFlyerDeselected(f.id);
                    }}
                    disabled={
                      props.disableAll || props.disabledTokens?.some((t) => t.flyer?.id === f.id)
                    }
                  />
                ))}
            </FlexBox>
            <Pagination
              count={brandFlyers.pagination?.totalPages}
              page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
              onChange={(e, page) => fecthFlyers(page - 1)}
              shape="rounded"
              sx={{
                p: 2,
                m: 'auto',
                mb: 0,
                pb: 0
              }}
            />

            {props.brandId && orderedTokens && Boolean(orderedTokens.length) && (
              <>
                <Typography variant="button">{t('flyer:orderFlyers')}:</Typography>
                <InfoText sx={{ mb: 2 }}>{t('flyer:texts:orderFlyersInfo')}</InfoText>
                <FlexBox
                  flexDirection="row"
                  gap={1}
                  sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    pb: 2
                  }}>
                  {orderedTokens.map(
                    (tkn, index) =>
                      tkn.flyer && (
                        <FlexBox
                          flexDirection="column"
                          sx={{
                            minWidth: 110,
                            maxWidth: 'calc(10% - 10px)'
                          }}>
                          <FlyerCard data={tkn.flyer} brandId={props.brandId} />
                          <FlexBox flexDirection="row" alignItems="center" sx={{ mt: 1 }}>
                            <IconButton
                              size="small"
                              disabled={
                                props.disableAll ||
                                props.disabledTokens?.some((dt) => dt.id === tkn.id) ||
                                !orderedTokens ||
                                !orderedTokens[index] ||
                                !orderedTokens[index].position ||
                                !orderedTokens[index - 1] ||
                                !orderedTokens[index - 1].position
                              }
                              onClick={() => {
                                if (
                                  !props.onTokensOrderChanged ||
                                  !orderedTokens ||
                                  !orderedTokens[index - 1]
                                )
                                  return;
                                const newTokens = [...orderedTokens].sort((a, b) => {
                                  if (a.position && b.position) {
                                    return a.position - b.position;
                                  } else return 0;
                                });
                                const positionA = newTokens[index].position;
                                const positionB = newTokens[index - 1].position;
                                newTokens[index] = {
                                  ...newTokens[index],
                                  position: positionB
                                };
                                newTokens[index - 1] = {
                                  ...newTokens[index - 1],
                                  position: positionA
                                };
                                props.onTokensOrderChanged(newTokens);
                              }}>
                              <ChevronLeft />
                            </IconButton>
                            <StyledTooltip
                              title={validationTooltip?.text}
                              severity="error"
                              arrow
                              placement="bottom"
                              disableHoverListener
                              open={
                                Boolean(validationTooltip) && index === validationTooltip?.tknIndex
                              }>
                              <span>
                                <NumericFormat
                                  disabled={
                                    props.disableAll ||
                                    props.disabledTokens?.some((dt) => dt.id === tkn.id)
                                  }
                                  customInput={TextField}
                                  value={tkn.position}
                                  allowNegative={false}
                                  isAllowed={(values) => {
                                    const { value } = values;
                                    const isValid =
                                      (parseInt(value) > 0 &&
                                        parseInt(value) <= (orderedTokens?.length ?? 999) &&
                                        !orderedTokens?.some(
                                          (it) => it.position && it.position === parseInt(value)
                                        )) ||
                                      value === '';

                                    setValidationTooltip(
                                      !isValid || isNaN(parseInt(value))
                                        ? {
                                            tknIndex: index,
                                            text: isNaN(parseInt(value))
                                              ? t('errors:notValidValue') +
                                                ' ' +
                                                t('errors:insertNumericValue')
                                              : parseInt(value) < 1 ||
                                                parseInt(value) > orderedTokens.length
                                              ? t('errors:orderedFlyersValueBounds')
                                              : t('errors:orderedFlyersAlreadyInsertedValue')
                                          }
                                        : undefined
                                    );
                                    return isValid;
                                  }}
                                  onChange={(e) => {
                                    if (!props.onTokensOrderChanged || !orderedTokens) return;
                                    const newTokens = [...orderedTokens];
                                    newTokens[index] = {
                                      ...newTokens[index],
                                      position: parseInt(e.target.value)
                                    };
                                    props.onTokensOrderChanged(newTokens);
                                  }}
                                  error={!tkn.position}
                                  sx={{ minWidth: 42, '&>div': { height: 32 } }}
                                />
                              </span>
                            </StyledTooltip>
                            <IconButton
                              size="small"
                              disabled={
                                props.disableAll ||
                                props.disabledTokens?.some((dt) => dt.id === tkn.id) ||
                                !orderedTokens ||
                                !orderedTokens[index] ||
                                !orderedTokens[index].position ||
                                !orderedTokens[index + 1] ||
                                !orderedTokens[index + 1].position
                              }
                              onClick={() => {
                                if (
                                  !props.onTokensOrderChanged ||
                                  !orderedTokens ||
                                  !orderedTokens[index + 1]
                                )
                                  return;
                                const newTokens = [...orderedTokens].sort((a, b) => {
                                  if (a.position && b.position) {
                                    return a.position - b.position;
                                  } else return 0;
                                });
                                const positionA = newTokens[index].position;
                                const positionB = newTokens[index + 1].position;
                                newTokens[index] = {
                                  ...newTokens[index],
                                  position: positionB
                                };
                                newTokens[index + 1] = {
                                  ...newTokens[index + 1],
                                  position: positionA
                                };
                                props.onTokensOrderChanged(newTokens);
                              }}>
                              <ChevronRight />
                            </IconButton>
                          </FlexBox>
                        </FlexBox>
                      )
                  )}
                </FlexBox>
              </>
            )}
          </>
        );
      default:
        return (
          <>
            <FlexBox
              flexDirection="row"
              gap={2}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}>
              {brandFlyers.flyers.map((f) => (
                <FlyerCard
                  sx={{ minWidth: 110, maxWidth: 'calc(10% - 17px)' }}
                  key={`flyer-${f.id}`}
                  data={f}
                  brandId={props.brandId}
                  toolbar
                />
              ))}
            </FlexBox>
            <Pagination
              count={brandFlyers.pagination?.totalPages}
              page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
              onChange={(e, page) => fecthFlyers(page - 1)}
              shape="rounded"
              sx={{
                p: 2,
                m: 'auto',
                mb: 0,
                pb: 0
              }}
            />
          </>
        );
    }
  }, [props, orderedTokens, validationTooltip, brandFlyers]);

  return (
    <EditableCard
      hideHeaderDivider
      sx={{
        ...props.sx,
        border: props.errorMsg ? (theme) => `1px solid ${theme.palette.error.main}` : 'none',
        pb: 0
      }}
      bodySx={{ minHeight: 200, pb: 2, pt: 1 }}
      title={t('flyer:flyers')}
      onAdd={() => {
        navigate(`/flyers/${props.brandId}/add_flyer`);
      }}
      icon={<MenuBook htmlColor={props.iconColor || theme.palette.tertiary.main} />}
      additionalHeaderComponent={
        <FlexBox flexDirection="row" gap={1} alignItems="center">
          {/* toggle btn */}
          <StyledTooltip
            arrow
            placement="top"
            title={showFilters ? t('filters:hideFilters') : t('filters:showFilters')}
            onClick={() => setShowFilters(!showFilters)}>
            <IconButton size="small" sx={{ my: 0.5 }} disabled={!brandFlyers.flyers.length}>
              {showFilters ? <FilterListOff /> : <FilterList />}
            </IconButton>
          </StyledTooltip>

          <Slide in={showFilters} direction="left" unmountOnExit>
            <FlexBox flexDirection="row" gap={1} rowGap={1.6} flexWrap="wrap">
              {/* Not used filter */}
              <Button
                onClick={() => setNotUsed(!notUsed)}
                variant="outlined"
                sx={{
                  height: 40,
                  color: 'gray',
                  borderColor: '#c4c4c4',
                  textTransform: 'none',
                  flex: '1 0 180px',
                  justifyContent: 'flex-start',
                  whiteSpace: 'nowrap'
                }}
                startIcon={<Checkbox sx={{ ml: -0.7, mr: -1 }} checked={notUsed} />}>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: 14
                  }}>
                  {t('filters:flyersNotAssigned')}
                </Typography>
              </Button>
              {/* Sort filter */}
              <OrderSelection
                sortValue={sortValue}
                sortItems={[
                  {
                    value: 'default',
                    label: t('filters:default'),
                    icon: ({ sx, fontSize }) => <Tag sx={sx} fontSize={fontSize} />
                  },
                  {
                    value: 'created_at',
                    label: t('filters:creationDate'),
                    icon: ({ sx, fontSize }) => <History sx={sx} fontSize={fontSize} />,
                    doubleOrder: true
                  }
                ]}
                onSortValueChange={(value) => setSortValue(value as 'default' | 'created_at')}
                sortMode={sortModeAsc ? 'asc' : 'desc'}
                onSortModeClick={() => setSortModeAsc(!sortModeAsc)}
                sx={{
                  flex: '1 0 180px',
                  width: 180
                }}
              />
              {/* Date creation Filter */}
              <FlexBox flex={'2 0 360px'}>
                {/* Expires after */}
                <DatePicker
                  label={`${t('filters:expiresAfter')}:`}
                  value={expiresAfter}
                  onChange={(value: number | null) => {
                    setExpiresAfter(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        '& >div': {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
                {/* Expires before */}
                <DatePicker
                  label={`${t('filters:expiresBefore')}:`}
                  value={expiresBefore}
                  minDate={expiresAfter}
                  onChange={(value: number | null) => {
                    setExpiresBefore(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        ml: '-1px',
                        '& >div': {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </FlexBox>
            </FlexBox>
          </Slide>
        </FlexBox>
      }>
      {brandFlyers.brandId === props.brandId && Boolean(brandFlyers.flyers.length) ? (
        <FlexBox flexDirection="column">{renderContent}</FlexBox>
      ) : readFlyersRequest.inProgress ? (
        <ActivityIndicator />
      ) : (
        <NoDataBanner />
      )}

      {props.errorMsg && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            mt: 2,
            mb: -1.3,
            mx: '7px',
            display: props.errorMsg ? 'block' : 'none'
          }}>
          {props.errorMsg}
        </Typography>
      )}
    </EditableCard>
  );
}

interface FlyerDropPitProps {
  brandId: number;
  flyer?: Flyer;
  label?: string;
  onDropped?: (flyerId: number) => void;
  topComponent?: React.ReactNode;
  err?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}
function FlyerDropPit(props: FlyerDropPitProps) {
  const { t } = useTranslation();
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'flyer',
      canDrop: () => true,
      drop(_item, monitor) {
        const item: { flyerId: number } = monitor.getItem();
        if (props.onDropped && item.flyerId && !props.disabled) props.onDropped(item.flyerId);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    }),
    [props.onDropped, props.disabled]
  );

  return (
    <FlexBox
      flexDirection="column"
      sx={{
        minWidth: 120,
        p: 1,
        ...props.sx
      }}>
      {props.topComponent}
      <FlexBox
        ref={drop}
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: 'auto',
          height: props.flyer && !canDrop ? 'auto' : '100%',
          maxHeight: !props.flyer ? 240 : undefined,
          borderRadius: 4,
          border: (theme) =>
            `2px dashed ${
              props.disabled
                ? 'lightgray'
                : props.err
                ? theme.palette.error.main
                : theme.palette.primary.main
            }`,
          mx: 'auto',
          backgroundColor: !props.disabled && canDrop && isOver ? 'rgba(0,0,0,0.05)' : 'white',
          transition: '0.2s'
        }}>
        {props.flyer && !(canDrop && !props.disabled) ? (
          <FlyerCard data={props.flyer} brandId={props.brandId} />
        ) : (
          <Typography
            fontSize={14}
            fontStyle="italic"
            sx={{
              opacity: 0.5,
              mx: 'auto',
              textAlign: 'center',
              p: 2,
              fontSize: 13
            }}>
            {props.label ?? 'Trascina qui un volantino'}
            {props.disabled && <p>{t('common:disabled')}</p>}
          </Typography>
        )}
      </FlexBox>
      {props.err && (
        <Typography
          sx={{
            textAlign: 'center',
            color: '#d32f2f',
            fontSize: '0.75rem',
            my: 0.5,
            display: props.err ? 'block' : 'none'
          }}>
          {props.err}
        </Typography>
      )}
    </FlexBox>
  );
}
