import * as React from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { useTranslation } from 'react-i18next';
import { Language as LanguageEntity } from '../../../entities/Common';
import i18n from '../../../i18n';
import { useBreadcrumbs } from '../../components/Breadcrumbs';
import EditableCard from '../../components/EditableCard';
import { FlexBox, InfoText } from '../../components/StyledComponents';
import Toolbar from '../../components/Toolbar';

type Language = {
  label: string;
  unicode_slug: string;
  slug: LanguageEntity;
};
export const languages = [
  {
    label: 'English',
    unicode_slug: 'GB',
    slug: 'en'
  },
  {
    label: 'Italiano',
    unicode_slug: 'IT',
    slug: 'it'
  }
] as Language[];

export default function Settings() {
  const { t } = useTranslation();
  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:settings'),
        path: '/settings'
      };
    }, [t])
  );

  const [language, setLanguage] = React.useState<Language | null>(
    languages.find((l) => i18n.language === l.slug) ?? null
  );

  React.useEffect(() => {
    if (language && language.slug !== i18n.language) {
      i18n.changeLanguage(language.slug);
    }
  }, [language]);

  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      <Box mb={2}>
        <Typography variant="h6" fontWeight="bold">
          {t('settings:pageTitle')}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
          {t('settings:pageSubtitle')}
        </Typography>

        <Toolbar mt={4} mb={2} />
      </Box>

      {/* Body */}
      <FlexBox flexDirection="row" flex={1} gap={2} width="90%" m="auto" alignItems={'flex-start'}>
        <EditableCard
          icon={<TranslateIcon sx={{ color: (theme) => theme.palette.tertiary.main }} />}
          hideHeaderDivider
          sx={{ width: '40%', mx: 'auto' }}
          bodySx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 4 }}
          title={t('settings:languageOptions')}>
          <InfoText>{t('settings:languageOptionsInfo')}</InfoText>
          <Autocomplete
            size="small"
            fullWidth
            id="country-select-demo"
            options={languages}
            autoHighlight
            getOptionLabel={(option) =>
              `${getUnicodeFlagIcon(option.unicode_slug)} ${option.label} `
            }
            renderOption={(props, option) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}>
                {`${getUnicodeFlagIcon(option.unicode_slug)} ${option.label} `}
              </Box>
            )}
            value={language}
            onChange={(event: any, newValue: Language | null) => {
              setLanguage(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('settings:chooseLanguage')}
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
          />
        </EditableCard>
      </FlexBox>
    </FlexBox>
  );
}
