import React, { useEffect } from 'react';
import useAuthentication from '../../hooks/useAuthentication';
import useUser from '../../hooks/useUser';

export function AuthenticationProvider(props: { children: React.ReactNode }) {
  const { tokenChecked, checkToken, signedIn } = useAuthentication();
  const { readMe } = useUser();

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  useEffect(() => {
    if (signedIn) readMe();
  }, [signedIn]);

  return <>{tokenChecked && props.children}</>;
}
