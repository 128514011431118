import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { BEInteraction } from './be_interaction_store/reducers_combiner';
import { local } from './local_store/reducers_combiner';

export const rootReducer = combineReducers({
  BEInteraction,
  local
});

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer
});
