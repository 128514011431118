import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AccessTime,
  Check,
  DeleteForever,
  DirectionsRun,
  EmojiEventsOutlined,
  InfoOutlined,
  KeyboardArrowLeft,
  Tune,
  VerifiedOutlined
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DictionaryString, Language } from '../../../../../entities/Common';
import { Flyer } from '../../../../../entities/Flyer';
import { Challenge } from '../../../../../entities/Initiatives';
import { TokenAssignmentMode, TokenRequest } from '../../../../../entities/Token';
import useInitiatives from '../../../../../hooks/useInitiatives';
import { currentLanguage } from '../../../../../i18n';
import { challengeFormActions } from '../../../../../redux/local_store/slices/challengeForm';
import { RootState } from '../../../../../redux/store';
import { TUTORIAL_INITIATIVE_ID, WAKAPAPA_ID } from '../../../../../utils/Constants';
import PathFormatter from '../../../../../utils/PathFormatter';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import AssetsCard from '../../../../components/AssetsCard';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import { DateTimePicker } from '../../../../components/DateTimePicker';
import EditableCard from '../../../../components/EditableCard';
import FlyersContainer from '../../../../components/FlyersContainer';
import IncrementDecrementBtn from '../../../../components/IncrementDecrementBtn';
import LanguageSelection from '../../../../components/LanguageSelection';
import { SnackbarContext } from '../../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';
import UploadImg from '../../../../components/UploadImg';
import { languages } from '../../Settings';

interface AddEditChallengeProps {
  mode: 'create' | 'edit';
}
export default function AddEditChallenge(props: AddEditChallengeProps) {
  // hooks
  const { brandId, challengeId } = useParams();
  const {
    findInitiative,
    initiativeTypes,
    initiativeTypesRequest,
    readInitiativeTypes,
    updateChallenge,
    updateChallengeRequest,
    addChallenge,
    addChallengeRequest,
    deleteChallenge,
    deleteChallengeRequest
  } = useInitiatives();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const dispatch = useDispatch();
  const theme = useTheme();

  // Generic states
  const [data, setData] = useState<Challenge>();
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage());
  const [fillForEachLanguage, setFillForEachLanguage] = useState(true);
  const [deleteChallengeDialogOpened, setDeleteChallengeDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);
  const initiativeId = useSelector((state: RootState) => state.local.challengeForm.initiativeId);
  const edited = useSelector((state: RootState) => state.local.challengeForm.edited);
  const viewOnlyMode = useSearchParams()[0].get('view_only') === 'true';

  // Form states
  const logo = useSelector((state: RootState) => state.local.challengeForm.inputData.logo);
  const name = useSelector((state: RootState) => state.local.challengeForm.inputData.name);
  const modeSlug = useSelector((state: RootState) => state.local.challengeForm.inputData.modeSlug);
  const noFlyersMode = useSelector((state: RootState) => state.local.challengeForm.noFlyersMode);
  const advertising = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.advertising
  );
  const assetId = useSelector((state: RootState) => state.local.challengeForm.inputData.assetId);
  const initiativeTypeId = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.initiativeTypeId
  );
  const description = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.description
  );
  const descriptionImage = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.descriptionImage
  );
  const listDescriptionImage = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.listDescriptionImage
  );
  const startDate = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.startDate
  );
  const endDate = useSelector((state: RootState) => state.local.challengeForm.inputData.endDate);
  const goalDescription = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.goalDescription
  );
  const unlockThreshold = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.unlockThreshold
  );
  const prizeTitle = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.prizeTitle
  );
  const prizeDescription = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.prizeDescription
  );
  const wakaPrize = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.wakaPrize
  );
  const wakaTokens = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.wakaTokens
  );
  const badgeImage = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.badge?.image
  );
  const badgeName = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.badge?.name
  );
  const badgeDescription = useSelector(
    (state: RootState) => state.local.challengeForm.inputData.badge?.description
  );
  const tokens = useSelector((state: RootState) => state.local.challengeForm.inputData.tokens);

  // error states
  const [logoErr, setLogoErr] = useState<string>();
  const [nameErr, setNameErr] = useState<string>();
  const [descriptionErr, setDescriptionErr] = useState<string>();
  const [descriptionImageErr, setDescriptionImageErr] = useState<string>();
  const [listDescriptionImageErr, setListDescriptionImageErr] = useState<string>();
  const [startDateErr, setStartDateErr] = useState<string>();
  const [endDateErr, setEndDateErr] = useState<string>();
  const [goalDescriptionErr, setGoalDescriptionErr] = useState<string>();
  const [unlockThresholdErr, setUnlockThresholdErr] = useState<string>();
  const [prizeTitleErr, setPrizeTitleErr] = useState<string>();
  const [prizeDescriptionErr, setPrizeDescriptionErr] = useState<string>();
  const [wakaPrizeErr, setWakaPrizeErr] = useState<string>();
  const [wakaTokensErr, setWakaTokensErr] = useState<string>();
  const [badgeImageErr, setBadgeImageErr] = useState<string>();
  const [badgeNameErr, setBadgeNameErr] = useState<string>();
  const [badgeDescriptionErr, setBadgeDescriptionErr] = useState<string>();

  // Ui utils
  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: 'Nuova challenge',
            path: `/home/brand/${brandId}/challenges/new-challenge`
          }
        : {
            title: `${data?.name[currentLanguage()]}`,
            path: `/home/brand/${brandId}/challenges/${data?.id}`
          };
    }, [brandId, data?.id, data?.name, props.mode])
  );
  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  // Data Fetching
  const fecthChallengeData = async () => {
    if (challengeId) {
      const challengeData = await findInitiative(parseInt(challengeId));
      if (challengeData && challengeData.type === 'challenge') setData(challengeData);
    }
  };

  useEffect(() => {
    const fetchUtilsData = async () => {
      if (!initiativeTypes.length) readInitiativeTypes();
    };
    fetchUtilsData();
    if (props.mode === 'edit') fecthChallengeData();
  }, [props.mode]);

  useEffect(() => {
    if (data && data.id !== initiativeId) dispatch(challengeFormActions.clear());
    if (data && !edited && data.type === 'challenge') {
      dispatch(challengeFormActions.setInitiativeId(data.id));
      dispatch(challengeFormActions.setBrandId(data.brand.id));
      dispatch(challengeFormActions.setLogo(PathFormatter.absoluteToRelative(data.logo)));
      dispatch(challengeFormActions.setName(data.name));
      dispatch(challengeFormActions.setModeSlug(data.mode.slug as 'single' | 'revolving'));
      dispatch(challengeFormActions.setPublished(data.published));
      dispatch(challengeFormActions.setDescription(data.description));
      dispatch(
        challengeFormActions.setDescriptionImage(
          data.descriptionImage ? PathFormatter.absoluteToRelative(data.descriptionImage) : null
        )
      );
      dispatch(
        challengeFormActions.setListDescriptionImage(
          data.listDescriptionImage
            ? PathFormatter.absoluteToRelative(data.listDescriptionImage)
            : null
        )
      );
      dispatch(challengeFormActions.setStartDate(data.startDate));
      dispatch(challengeFormActions.setEndDate(data.endDate));
      dispatch(challengeFormActions.setGoalDescription(data.goalDescription));
      dispatch(challengeFormActions.setInitiativeTypeId(data.initiativeType.id));
      dispatch(challengeFormActions.setUnlockThreshold(data.unlockThreshold));
      dispatch(challengeFormActions.setPrizeTitle(data.prizeTitle));
      dispatch(challengeFormActions.setPrizeDescription(data.prizeDescription));
      dispatch(challengeFormActions.setWakaPrize(data.wakaPrize));
      dispatch(challengeFormActions.setWakaTokens(data.wakaTokens));
      if (data.badge) {
        dispatch(
          challengeFormActions.setBadgeImage(PathFormatter.absoluteToRelative(data.badge.image))
        );
        dispatch(challengeFormActions.setBadgeName(data.badge.name));
        dispatch(challengeFormActions.setBadgeDescription(data.badge.description));
      }
      if (data.tokens) {
        dispatch(
          challengeFormActions.setTokens(
            data.tokens.map((t) => {
              return {
                flyer: t.flyer ?? undefined,
                id: t.id,
                flyerId: t.flyer?.id ?? -1
              };
            })
          )
        );
      }
      if (data.advertising) dispatch(challengeFormActions.setAdvertising(data.advertising));
      dispatch(challengeFormActions.setAssetId(data?.asset?.id ?? null));
      if (data.tokens?.length === 1 && !data.tokens[0].flyer) {
        dispatch(challengeFormActions.setNoFlyersMode(true));
      }
    }
  }, [data]);

  useEffect(() => {
    // No flyers mode setter logic
    if (noFlyersMode) {
      if (data?.tokens) {
        const tokenWithoutFlyer = data?.tokens.find((t) => !t.flyer);
        if (tokenWithoutFlyer)
          dispatch(challengeFormActions.setTokens([{ id: tokenWithoutFlyer.id, flyerId: -1 }]));
        else dispatch(challengeFormActions.setTokens([{ flyerId: -1 }]));
      } else dispatch(challengeFormActions.setTokens([{ flyerId: -1 }]));
    } else {
      dispatch(
        challengeFormActions.setTokens(
          !data?.tokens
            ? []
            : data.tokens
                .filter((t) => t.flyer)
                .map((t) => {
                  return {
                    flyer: t.flyer ?? undefined,
                    id: t.id,
                    flyerId: t.flyer?.id ?? -1
                  };
                })
        )
      );
    }
  }, [noFlyersMode]);

  // Data Validation
  const validation = () => {
    let validated = true;
    const sortedLanguages = Object.keys(name).sort((a) => (a === currentLanguage() ? -1 : 1));
    for (let i = 0; i < sortedLanguages.length && validated; i++) {
      const lang = sortedLanguages[i];
      if (lang) {
        if (!logo) {
          setLogoErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!name[lang as Language] && validated) {
          setNameErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!description[lang as Language] && validated) {
          setDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!descriptionImage && validated) {
          setDescriptionImageErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!listDescriptionImage && validated) {
          setListDescriptionImageErr(t('errors:obligatoryField'));
          validated = false;
        }

        if (!goalDescription[lang as Language] && validated) {
          setGoalDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!prizeTitle[lang as Language] && validated) {
          setPrizeTitleErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!prizeDescription[lang as Language] && validated) {
          setPrizeDescriptionErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (!unlockThreshold && validated) {
          setUnlockThresholdErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!wakaTokens && validated) {
          setWakaTokensErr(t('errors:obligatoryField'));
          validated = false;
        }
        if (!wakaPrize && validated) {
          setWakaPrizeErr(t('errors:obligatoryField'));
          validated = false;
        }

        // Dates validation
        if (!startDate && validated) {
          setStartDateErr(t('errors:obligatoryField'));
          validated = false;
        } else if (
          (props.mode === 'create' && startDate && startDate < Date.now() && validated) ||
          (props.mode === 'edit' &&
            startDate &&
            data?.startDate &&
            data.startDate < Date.now() &&
            startDate < data.startDate &&
            validated) ||
          (props.mode === 'edit' &&
            startDate &&
            data?.startDate &&
            data.startDate >= Date.now() &&
            startDate < Date.now() &&
            validated)
        ) {
          setStartDateErr(t('errors:passedStartDate'));
          validated = false;
        }
        if (!endDate && validated) {
          setEndDateErr(t('errors:obligatoryField'));
          validated = false;
        } else if (
          startDate &&
          endDate &&
          startDate < Date.now() &&
          endDate < Date.now() &&
          validated
        ) {
          setEndDateErr(t('errors:passedEndDate'));
          validated = false;
        } else if (endDate && startDate && endDate < startDate) {
          setEndDateErr(t('errors:endDateBeforeStartDate'));
          validated = false;
        }

        // Badge validation
        if (
          (validated &&
            !badgeImage &&
            Object.values(badgeName).every((v) => !v) &&
            Object.values(badgeDescription).every((v) => !v)) ||
          (badgeImage &&
            Object.values(badgeName).every((v) => v) &&
            Object.values(badgeDescription).every((v) => v))
        ) {
          //
        } else {
          if (!badgeImage && validated) {
            setBadgeImageErr(t('errors:obligatoryBadgeField'));
            validated = false;
          }
          if (!badgeName[lang as Language] && validated) {
            setBadgeNameErr(t('errors:obligatoryBadgeField'));
            setSelectedLanguage(lang as Language);
            validated = false;
          }
          if (!badgeDescription[lang as Language] && validated) {
            setBadgeDescriptionErr(t('errors:obligatoryBadgeField'));
            setSelectedLanguage(lang as Language);
            validated = false;
          }
        }
        if (
          validated &&
          data?.published &&
          data?.badge &&
          !badgeImage &&
          Object.values(badgeName).every((v) => !v) &&
          Object.values(badgeDescription).every((v) => !v)
        ) {
          setBadgeImageErr(t('errors:cannotDeleteBadge'));
          setBadgeNameErr(t('errors:cannotDeleteBadge'));
          setBadgeDescriptionErr(t('errors:cannotDeleteBadge'));
          validated = false;
        }
        if (
          validated &&
          data?.published &&
          !data?.badge &&
          badgeImage &&
          Object.values(badgeName).every((v) => v) &&
          Object.values(badgeDescription).every((v) => v)
        ) {
          setBadgeImageErr(t('errors:cannotAddBadge'));
          setBadgeNameErr(t('errors:cannotAddBadge'));
          setBadgeDescriptionErr(t('errors:cannotAddBadge'));
          validated = false;
        }
      }
    }

    return validated;
  };

  // Main interactions
  const triggerAddChallenge = async () => {
    if (!brandId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }
    if (
      dataValidated &&
      name &&
      description &&
      descriptionImage &&
      listDescriptionImage &&
      startDate &&
      endDate &&
      goalDescription &&
      unlockThreshold &&
      wakaPrize &&
      wakaTokens &&
      prizeTitle &&
      prizeDescription
    ) {
      const success = await addChallenge({
        brandId: parseInt(brandId),
        logo,
        name,
        description,
        descriptionImage,
        listDescriptionImage,
        modeSlug,
        startDate,
        endDate,
        goalDescription,
        unlockThreshold,
        wakaPrize,
        wakaTokens,
        initiativeTypeId,
        prizeTitle,
        prizeDescription,
        badge:
          badgeName && badgeImage && badgeDescription
            ? { name: badgeName, description: badgeDescription, image: badgeImage }
            : undefined,
        tokens: tokens.map((t) => _.pick(t, ['id', 'flyerId'])),
        advertising,
        assetId,
        // mocked so far:
        titleCollected: {
          it: 'libri',
          en: 'books'
        },
        titleTotal: {
          it: 'alberi',
          en: 'trees'
        }
      });
      openSnackBar(
        success,
        t('initiatives:challenges:snackbar:challengeAddSuccess'),
        t('initiatives:challenges:snackbar:challengeAddError')
      );
      if (success) {
        dispatch(challengeFormActions.clear());
        dispatch(challengeFormActions.setEdited(false));
        navigate(-1);
      }
    }
  };
  const triggerUpdateChallenge = async () => {
    if (!challengeId || !brandId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    let requestTokens: TokenRequest[] = tokens.map((t) => _.pick(t, ['id', 'flyerId']));
    data?.tokens?.forEach((dt) => {
      if (!requestTokens.some((rt) => rt.id === dt.id)) {
        requestTokens = requestTokens.concat({
          id: dt.id,
          flyerId: dt.flyer?.id ?? -1,
          delete: true
        });
      }
    });
    if (
      dataValidated &&
      name &&
      description &&
      descriptionImage &&
      listDescriptionImage &&
      startDate &&
      endDate &&
      goalDescription &&
      unlockThreshold &&
      wakaPrize &&
      wakaTokens &&
      prizeTitle &&
      prizeDescription
    ) {
      const success = await updateChallenge(parseInt(challengeId), {
        brandId: parseInt(brandId),
        logo,
        name,
        description,
        descriptionImage,
        listDescriptionImage,
        modeSlug,
        startDate,
        endDate,
        goalDescription,
        unlockThreshold,
        wakaPrize,
        wakaTokens,
        initiativeTypeId,
        prizeTitle,
        prizeDescription,
        badge:
          badgeName && badgeImage && badgeDescription
            ? { name: badgeName, description: badgeDescription, image: badgeImage }
            : undefined,
        tokens: requestTokens,
        advertising,
        assetId,
        // mocked so far:
        titleCollected: {
          it: 'libri',
          en: 'books'
        },
        titleTotal: {
          it: 'alberi',
          en: 'trees'
        }
      });

      openSnackBar(
        success,
        t('initiatives:challenges:snackbar:challengeEditSuccess'),
        t('initiatives:challenges:snackbar:challengeEditError')
      );
      dispatch(challengeFormActions.setEdited(false));
      fecthChallengeData();
    }
  };
  const triggerDeleteChallenge = async () => {
    if (challengeId) {
      const success = await deleteChallenge(parseInt(challengeId));
      openSnackBar(
        success,
        t('initiatives:challenges:snackbar:challengeDeleteSuccess'),
        t('initiatives:challenges:snackbar:challengeDeleteError')
      );
      setDeleteChallengeDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  // Flyer methods
  const manageFlyerSelect = async (flyer: Flyer) => {
    const alreadyCheckedToken = tokens.find((t) => t.flyerId === flyer.id);
    if (!alreadyCheckedToken) {
      dispatch(challengeFormActions.setTokens(tokens.concat({ flyer, flyerId: flyer.id })));
      if (!edited) dispatch(challengeFormActions.setEdited(true));
    }
  };
  const manageFlyerDeselect = async (flyerId: number) => {
    const alreadyCheckedToken = tokens.find((t) => t.flyerId === flyerId);
    if (alreadyCheckedToken) {
      dispatch(
        challengeFormActions.setTokens(_.differenceWith(tokens, [alreadyCheckedToken], _.isEqual))
      );
      if (!edited) dispatch(challengeFormActions.setEdited(true));
    }
  };

  // Usefull Components
  const DictionaryTextField = useCallback(
    (props: {
      label: string;
      dictionaryField: DictionaryString;
      onChange?: (dictionaryText: { en: string; it: string }) => void;
      error: string | undefined;
      errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
      multiline?: boolean;
      disabled?: boolean;
      maxLength?: number;
    }) => {
      return (
        <TextField
          disabled={props.disabled}
          size="small"
          label={`${
            fillForEachLanguage
              ? languages.map((l) => getUnicodeFlagIcon(l.unicode_slug)).join(' ')
              : getUnicodeFlagIcon(
                  languages.find((l) => l.slug === selectedLanguage)?.unicode_slug ?? ''
                )
          } ${props.label}`}
          error={Boolean(props.error)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: props.maxLength }}
          value={props.dictionaryField[selectedLanguage]}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newTextValue = { ...props.dictionaryField, [selectedLanguage]: e.target.value };
            if (fillForEachLanguage)
              _.forEach(newTextValue, (value, key) => {
                newTextValue[key as Language] = e.target.value;
              });
            if (props.onChange) props.onChange(newTextValue);
            if (props.error) props.errorSetter(undefined);
          }}
          helperText={props.error}
          multiline={props.multiline}
          sx={
            props.multiline
              ? {
                  '&>div': {
                    p: 0,
                    '&>textarea': {
                      ml: 2,
                      mt: 1,
                      pr: 2,
                      pb: 1,
                      minHeight: '4.3rem',
                      maxHeight: '18rem',
                      height: '100%',
                      overflowY: 'auto !important',
                      textOverflow: 'auto'
                    }
                  }
                }
              : undefined
          }
        />
      );
    },
    [selectedLanguage, fillForEachLanguage]
  );
  const UploadImgField = (props: {
    label: string;
    field: string | null;
    setter: ActionCreatorWithPayload<string | null>;
    error: string | undefined;
    errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
    disabled?: boolean;
    helperText?: string;
    maxSize?: number;
  }) => {
    return (
      <div>
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            border: `1px solid ${props.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
            position: 'relative'
          }}>
          <label
            style={{
              color: props.disabled
                ? 'rgba(0, 0, 0, 0.38)'
                : props.error
                ? '#d32f2f'
                : 'rgba(0, 0, 0, 0.6)',
              fontSize: '1rem',
              position: 'absolute',
              left: 0,
              top: 0,
              transform: 'translate(8px, -9px) scale(0.75)',
              transformOrigin: 'top left',
              background: 'white',
              paddingInline: 6,
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              letterSpacing: '0.00938em'
            }}>
            {props.label}
          </label>
          <UploadImg
            title={`${props.field ? 'change' : 'upload'} image`}
            remoteFileName={props.field ? PathFormatter.absoluteToRelative(props.field) : undefined}
            onDelete={() => {
              dispatch(props.setter(null));
              if (!edited) dispatch(challengeFormActions.setEdited(true));
            }}
            onUploaded={(filename) => {
              dispatch(props.setter(filename));
              props.errorSetter(undefined);
              if (!edited) dispatch(challengeFormActions.setEdited(true));
            }}
            disabled={props.disabled}
            maxSize={props.maxSize}
            helperText={props.helperText}
          />
        </Box>

        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            mt: 0.5,
            mx: '14px',
            display: props.error ? 'block' : 'none'
          }}>
          {props.error}
        </Typography>
      </div>
    );
  };

  // UI
  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between" mb={3}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              <DirectionsRun sx={{ mr: 1 }} />
              {props.mode === 'create'
                ? t('initiatives:challenges:createChallenge')
                : data?.name?.it ?? '--'}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('initiatives:challenges:texts:editChallenge')
                : t('initiatives:challenges:texts:createChallenge')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          leftcomponent={
            <LanguageSelection
              fillForEachLanguage={fillForEachLanguage}
              onFillForEachLanguageBtnClick={() => setFillForEachLanguage(!fillForEachLanguage)}
              selectedLanguage={selectedLanguage}
              onLanguageSelected={(lng: Language) => setSelectedLanguage(lng)}
            />
          }
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addChallengeRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddChallenge}>
                {t('initiatives:challenges:createChallenge')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data || data.id === TUTORIAL_INITIATIVE_ID}
                  onClick={() => setDeleteChallengeDialogOpen(true)}
                  sx={{ backgroundColor: 'white' }}>
                  {t('initiatives:challenges:deleteChallenge')}
                </Button>
                {!viewOnlyMode && (
                  <LoadingButton
                    loading={updateChallengeRequest.inProgress}
                    color="success"
                    variant="contained"
                    startIcon={<Check />}
                    disabled={!edited}
                    onClick={triggerUpdateChallenge}>
                    {t('common:confirmChanges')}
                  </LoadingButton>
                )}
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      {props.mode === 'create' || (props.mode === 'edit' && data) ? (
        <FlexBox
          flexDirection={tabletWidth ? 'column' : 'row'}
          flex={1}
          gap={2}
          width="88%"
          m="auto">
          <FlexBox flexDirection="column" gap={2} flex={1}>
            <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              title={t('initiatives:challenges:pageSections:generalInfo')}
              icon={<InfoOutlined color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                <InfoText>
                  <p>{t('initiatives:formInputHelperTexts:rememberToCompileForEachLanguage')}</p>
                  <p>
                    &bull; &nbsp;
                    {t('initiatives:formInputHelperTexts:insertNameAndDescription')}
                  </p>
                </InfoText>
                {/* Name */}
                <DictionaryTextField
                  label={`${t('initiatives:formInputLabels:name')}*`}
                  dictionaryField={name}
                  onChange={(dictionaryText) => {
                    dispatch(challengeFormActions.setName(dictionaryText));
                    if (!edited) dispatch(challengeFormActions.setEdited(true));
                  }}
                  error={nameErr}
                  errorSetter={setNameErr}
                  disabled={viewOnlyMode}
                />
                {/* Description */}
                <DictionaryTextField
                  label={`${t('initiatives:formInputLabels:description')}*`}
                  dictionaryField={description}
                  onChange={(dictionaryText) => {
                    dispatch(challengeFormActions.setDescription(dictionaryText));
                    if (!edited) dispatch(challengeFormActions.setEdited(true));
                  }}
                  error={descriptionErr}
                  errorSetter={setDescriptionErr}
                  multiline
                  disabled={viewOnlyMode}
                />

                <InfoText>
                  <p>
                    &bull; &nbsp;
                    {t('initiatives:formInputHelperTexts:insertLogo')}
                  </p>
                </InfoText>
                {/* Initiative logo */}
                <UploadImgField
                  label={t('initiatives:formInputLabels:initiativeLogo') + '*'}
                  field={logo}
                  setter={challengeFormActions.setLogo}
                  error={logoErr}
                  errorSetter={setLogoErr}
                  disabled={viewOnlyMode}
                  helperText={`Ratio: 300x50, Max size: 300kb`}
                  maxSize={300000}
                />

                <InfoText>
                  <p>
                    &bull; &nbsp;
                    {t('initiatives:formInputHelperTexts:insertDescriptionImages')}
                  </p>
                </InfoText>
                {/* Description Image */}
                <UploadImgField
                  label={`${t('initiatives:formInputLabels:descriptionImage')} *`}
                  field={descriptionImage}
                  setter={challengeFormActions.setDescriptionImage}
                  error={descriptionImageErr}
                  errorSetter={setDescriptionImageErr}
                  disabled={viewOnlyMode}
                  helperText={`Ratio: 750x450, Max size: 500kb`}
                  maxSize={500000}
                />
                {/* List description Image */}
                <UploadImgField
                  label={`${t('initiatives:formInputLabels:listDescriptionImage')} *`}
                  field={listDescriptionImage}
                  setter={challengeFormActions.setListDescriptionImage}
                  error={listDescriptionImageErr}
                  errorSetter={setListDescriptionImageErr}
                  disabled={viewOnlyMode}
                  helperText={`Ratio: 300x300, Max size: 500kb`}
                  maxSize={500000}
                />

                <InfoText>
                  <p>
                    &bull; &nbsp;
                    {t('initiatives:formInputHelperTexts:specifyInitiativeType')}
                  </p>
                </InfoText>
                {/* Initiative Type */}
                <FormControl fullWidth size="small">
                  <InputLabel>{t('initiatives:formInputLabels:initiativeType') + '*'}</InputLabel>
                  <Select
                    size="small"
                    disabled={viewOnlyMode || initiativeTypesRequest.inProgress}
                    value={initiativeTypeId}
                    label={t('initiatives:formInputLabels:initiativeType') + '*'}
                    onChange={(e) => {
                      if (!edited) dispatch(challengeFormActions.setEdited(true));
                      dispatch(
                        challengeFormActions.setInitiativeTypeId(
                          typeof e.target.value === 'string'
                            ? parseInt(e.target.value)
                            : e.target.value
                        )
                      );
                    }}>
                    {initiativeTypes?.map((inTy) => (
                      <MenuItem key={`initiative-type-${inTy.id}`} value={inTy.id}>
                        {inTy.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </EditableCard>

            <EditableCard
              hideHeaderDivider
              icon={<Tune color="primary" />}
              title={t('initiatives:quests:pageSections:mode')}
              bodySx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Mode */}
              <InfoText>{t('initiatives:formInputHelperTexts:setMode')}</InfoText>

              {/* Single / Revolving */}
              <InfoText>
                &bull; &nbsp;{t('initiatives:challenges:texts:modeSingleInfo')}
                {'\n'}
                &bull; &nbsp;{t('initiatives:challenges:texts:modeRevolvingInfo')}
              </InfoText>
              <ButtonGroup disabled={viewOnlyMode} fullWidth disableElevation>
                <Button
                  disabled={viewOnlyMode || data?.published}
                  startIcon={modeSlug === 'single' ? <Check /> : undefined}
                  variant={modeSlug === 'single' ? 'contained' : 'outlined'}
                  sx={{ color: modeSlug === 'single' ? 'white' : 'default' }}
                  onClick={() => {
                    if (modeSlug !== 'single') {
                      dispatch(challengeFormActions.setModeSlug('single'));
                      if (!edited) dispatch(challengeFormActions.setEdited(true));
                    }
                  }}>
                  Single
                </Button>
                <Button
                  disabled={viewOnlyMode || data?.published}
                  startIcon={modeSlug === 'revolving' ? <Check /> : undefined}
                  variant={modeSlug === 'revolving' ? 'contained' : 'outlined'}
                  sx={{ color: modeSlug === 'revolving' ? 'white' : 'default' }}
                  onClick={() => {
                    if (modeSlug !== 'revolving') {
                      dispatch(challengeFormActions.setModeSlug('revolving'));
                      if (!edited) dispatch(challengeFormActions.setEdited(true));
                    }
                  }}>
                  Revolving
                </Button>
              </ButtonGroup>

              {/* No Flyer Mode */}
              <InfoText pt={2}>
                &bull; &nbsp;{t('initiatives:challenges:texts:noFlyersMode')}
              </InfoText>
              <Button
                disabled={viewOnlyMode || data?.published}
                onClick={() => {
                  dispatch(challengeFormActions.setNoFlyersMode(!noFlyersMode));
                  if (!edited) dispatch(challengeFormActions.setEdited(true));
                }}
                variant={noFlyersMode ? 'contained' : 'outlined'}
                disableElevation
                sx={{
                  color: (theme) => (noFlyersMode ? 'white' : theme.palette.primary.main)
                }}
                startIcon={noFlyersMode ? <Check /> : undefined}>
                {t('initiatives:formInputLabels:noFlyersMode')}
              </Button>

              {/* No Campaign Flyers for Wakapapa */}
              {brandId && parseInt(brandId) === WAKAPAPA_ID && (
                <>
                  <InfoText pt={2}>
                    &bull; &nbsp;{t('initiatives:formInputHelperTexts:setAdvertising')}
                  </InfoText>
                  <Button
                    disabled={viewOnlyMode || data?.published}
                    onClick={() => {
                      dispatch(challengeFormActions.setAdvertising(advertising ? undefined : true));
                      if (!edited) dispatch(challengeFormActions.setEdited(true));
                    }}
                    variant={advertising ? 'contained' : 'outlined'}
                    disableElevation
                    sx={{
                      color: (theme) => (advertising ? 'white' : theme.palette.primary.main)
                    }}
                    startIcon={advertising ? <Check /> : undefined}>
                    {t('initiatives:formInputLabels:advertising')}
                  </Button>
                </>
              )}
            </EditableCard>
          </FlexBox>

          <FlexBox flexDirection="column" flex={2} gap={2}>
            <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} flex={1} gap={2}>
              <FlexBox flexDirection="column" flex={1} gap={2}>
                {brandId && (
                  <AssetsCard
                    assetId={assetId}
                    infoText={t('initiatives:formInputHelperTexts:setAsset')}
                    brandId={parseInt(brandId)}
                    onlyEditMode
                    disabled={viewOnlyMode}
                    onChangeAsset={(asset?: { id: number; file: string }) => {
                      dispatch(challengeFormActions.setAssetId(asset?.id ?? null));
                      if (!edited) dispatch(challengeFormActions.setEdited(true));
                    }}
                  />
                )}

                <EditableCard
                  hideHeaderDivider
                  sx={{ flex: 1 }}
                  title={t('initiatives:challenges:pageSections:prizes')}
                  icon={<EmojiEventsOutlined color="primary" />}>
                  <Stack gap={2} p={2} pt={0}>
                    <InfoText>
                      <p>
                        {t('initiatives:formInputHelperTexts:rememberToCompileForEachLanguage')}
                      </p>
                      <p>
                        &bull; &nbsp;
                        {t('initiatives:formInputHelperTexts:insertGoalDescription')}
                      </p>
                    </InfoText>
                    {/* Goal Description */}
                    <DictionaryTextField
                      label={`${t('initiatives:formInputLabels:goalDescription')}*`}
                      dictionaryField={goalDescription}
                      onChange={(dictionaryText) => {
                        dispatch(challengeFormActions.setGoalDescription(dictionaryText));
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      error={goalDescriptionErr}
                      errorSetter={setGoalDescriptionErr}
                      multiline
                      disabled={viewOnlyMode}
                    />

                    <InfoText>
                      <p>
                        &bull; &nbsp;
                        {t('initiatives:formInputHelperTexts:insertPrizeTitleAndDescription')}
                      </p>
                    </InfoText>
                    {/* Prize Title */}
                    <DictionaryTextField
                      label={`${t('initiatives:formInputLabels:prizeTitle')}*`}
                      dictionaryField={prizeTitle}
                      onChange={(dictionaryText) => {
                        dispatch(challengeFormActions.setPrizeTitle(dictionaryText));
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      error={prizeTitleErr}
                      errorSetter={setPrizeTitleErr}
                      disabled={viewOnlyMode}
                    />
                    {/* Prize Description */}
                    <DictionaryTextField
                      label={`${t('initiatives:formInputLabels:prizeDescription')}*`}
                      dictionaryField={prizeDescription}
                      onChange={(dictionaryText) => {
                        dispatch(challengeFormActions.setPrizeDescription(dictionaryText));
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      error={prizeDescriptionErr}
                      errorSetter={setPrizeDescriptionErr}
                      multiline
                      disabled={viewOnlyMode}
                    />

                    <InfoText>
                      <p>
                        &bull; &nbsp;
                        {t('initiatives:formInputHelperTexts:specifyUnlockThreshold')}
                      </p>
                    </InfoText>
                    <FlexBox gap={1}>
                      <NumericFormat
                        disabled={viewOnlyMode || data?.published}
                        sx={{ flex: 1 }}
                        size="small"
                        error={Boolean(unlockThresholdErr)}
                        label={t('initiatives:formInputLabels:unlockThreshold') + '*'}
                        customInput={TextField}
                        helperText={unlockThresholdErr}
                        InputLabelProps={{ shrink: true }}
                        value={unlockThreshold}
                        onChange={(e) => {
                          dispatch(
                            challengeFormActions.setUnlockThreshold(parseInt(e.target.value))
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setUnlockThresholdErr(undefined);
                        }}
                      />
                      <IncrementDecrementBtn
                        disabled={viewOnlyMode || data?.published}
                        onIncrement={() => {
                          dispatch(
                            challengeFormActions.setUnlockThreshold(
                              unlockThreshold ? unlockThreshold + 1 : 1
                            )
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setUnlockThresholdErr(undefined);
                        }}
                        onDecrement={() => {
                          dispatch(
                            challengeFormActions.setUnlockThreshold(
                              unlockThreshold ? unlockThreshold - 1 : 0
                            )
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setUnlockThresholdErr(undefined);
                        }}
                      />
                    </FlexBox>

                    <InfoText>
                      <p>
                        &bull; &nbsp;
                        {t('initiatives:formInputHelperTexts:specifyWakaTokensAndPrize')}
                      </p>
                    </InfoText>
                    <FlexBox gap={1}>
                      <NumericFormat
                        disabled={viewOnlyMode || data?.published}
                        sx={{ flex: 1 }}
                        size="small"
                        error={Boolean(wakaTokensErr)}
                        label={t('initiatives:formInputLabels:wakaTokens') + '*'}
                        customInput={TextField}
                        helperText={wakaTokensErr}
                        InputLabelProps={{ shrink: true }}
                        value={wakaTokens}
                        onChange={(e) => {
                          dispatch(challengeFormActions.setWakaTokens(parseInt(e.target.value)));
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaTokensErr(undefined);
                        }}
                      />
                      <IncrementDecrementBtn
                        disabled={viewOnlyMode || data?.published}
                        onIncrement={() => {
                          dispatch(
                            challengeFormActions.setWakaTokens(wakaTokens ? wakaTokens + 1 : 1)
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaTokensErr(undefined);
                        }}
                        onDecrement={() => {
                          dispatch(
                            challengeFormActions.setWakaTokens(wakaTokens ? wakaTokens - 1 : 0)
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaTokensErr(undefined);
                        }}
                      />
                    </FlexBox>
                    <FlexBox gap={1}>
                      <NumericFormat
                        disabled={viewOnlyMode || data?.published}
                        sx={{ flex: 1 }}
                        size="small"
                        error={Boolean(wakaPrizeErr)}
                        label={t('initiatives:formInputLabels:wakaPrize') + '*'}
                        customInput={TextField}
                        helperText={wakaPrizeErr}
                        InputLabelProps={{ shrink: true }}
                        value={wakaPrize}
                        onChange={(e) => {
                          dispatch(challengeFormActions.setWakaPrize(parseInt(e.target.value)));
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaPrizeErr(undefined);
                        }}
                      />
                      <IncrementDecrementBtn
                        disabled={viewOnlyMode || data?.published}
                        onIncrement={() => {
                          dispatch(
                            challengeFormActions.setWakaPrize(wakaPrize ? wakaPrize + 1 : 1)
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaPrizeErr(undefined);
                        }}
                        onDecrement={() => {
                          dispatch(
                            challengeFormActions.setWakaPrize(wakaPrize ? wakaPrize - 1 : 0)
                          );
                          if (!edited) dispatch(challengeFormActions.setEdited(true));
                          setWakaPrizeErr(undefined);
                        }}
                      />
                    </FlexBox>
                  </Stack>
                </EditableCard>
              </FlexBox>

              <FlexBox flexDirection="column" flex={1} gap={2}>
                <EditableCard
                  hideHeaderDivider
                  title={t('initiatives:challenges:pageSections:duration')}
                  icon={<AccessTime color="primary" />}>
                  <Stack gap={2} p={2} pt={0}>
                    <InfoText>
                      <p>{t('initiatives:formInputHelperTexts:selectDates')}</p>
                      <p>{t('initiatives:formInputHelperTexts:rememberToPublish')}</p>
                    </InfoText>

                    {/* Start Date */}
                    <DateTimePicker
                      label={t('initiatives:formInputLabels:startDate') + '*'}
                      value={startDate}
                      disabled={
                        viewOnlyMode ||
                        (startDate ? props.mode === 'edit' && data?.published : undefined)
                      }
                      minDateTime={
                        props.mode === 'create'
                          ? Date.now()
                          : data?.startDate && data?.startDate < Date.now() && data?.published
                          ? data?.startDate
                          : Date.now()
                      }
                      onChange={(value: number | null) => {
                        dispatch(challengeFormActions.setStartDate(value));
                        setStartDateErr(undefined);
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          helperText={startDateErr}
                          FormHelperTextProps={{ error: Boolean(startDateErr), filled: true }}
                          sx={{
                            '&>label': {
                              color: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                            },
                            '&>div>fieldset': {
                              borderColor: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                            }
                          }}
                          {...params}
                        />
                      )}
                    />
                    {/* End Date */}
                    <DateTimePicker
                      label={t('initiatives:formInputLabels:endDate') + '*'}
                      value={endDate}
                      minDateTime={startDate && startDate < Date.now() ? Date.now() : startDate}
                      disabled={viewOnlyMode}
                      onChange={(value: number | null) => {
                        dispatch(challengeFormActions.setEndDate(value));
                        setEndDateErr(undefined);
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          helperText={endDateErr}
                          FormHelperTextProps={{ error: Boolean(endDateErr) }}
                          sx={{
                            '&>label': {
                              color: viewOnlyMode
                                ? 'rgba(0, 0, 0, 0.38) !important'
                                : endDateErr
                                ? '#d32f2f'
                                : 'rgba(0, 0, 0, 0.6)'
                            },
                            '&>div>fieldset': {
                              borderColor: endDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                            }
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </EditableCard>

                <EditableCard
                  hideHeaderDivider
                  sx={{ flex: 1 }}
                  bodySx={{
                    minHeight: 150
                  }}
                  title={t('initiatives:challenges:pageSections:badge')}
                  icon={<VerifiedOutlined color="primary" />}>
                  <Stack gap={2} p={2} pt={0}>
                    <Box sx={{ color: 'text.secondary', fontSize: 13, fontStyle: 'italic' }}>
                      <p>{t('initiatives:formInputHelperTexts:addBadge')}</p>
                      <p>{t('initiatives:formInputHelperTexts:validBadge')}</p>
                    </Box>

                    {/* Badge Image */}
                    <UploadImgField
                      label={
                        t('initiatives:formInputLabels:badgeImage') +
                        `${
                          Object.values(badgeName).some((v) => v) ||
                          Object.values(badgeDescription).some((v) => v) ||
                          badgeImage
                            ? '*'
                            : ''
                        }`
                      }
                      field={badgeImage}
                      setter={challengeFormActions.setBadgeImage}
                      error={badgeImageErr}
                      errorSetter={setBadgeImageErr}
                      disabled={viewOnlyMode}
                      helperText={`Ratio: 500x500, Max size: 300kb`}
                      maxSize={300000}
                    />

                    {/* Badge Name */}
                    <DictionaryTextField
                      label={
                        t('initiatives:formInputLabels:badgeName') +
                        `${
                          Object.values(badgeName).some((v) => v) ||
                          Object.values(badgeDescription).some((v) => v) ||
                          badgeImage
                            ? '*'
                            : ''
                        }`
                      }
                      dictionaryField={badgeName}
                      onChange={(dictionaryText) => {
                        setBadgeImageErr(undefined);
                        setBadgeDescriptionErr(undefined);
                        dispatch(challengeFormActions.setBadgeName(dictionaryText));
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      error={badgeNameErr}
                      errorSetter={setBadgeNameErr}
                      disabled={viewOnlyMode}
                    />

                    {/* Badge Description */}
                    <DictionaryTextField
                      label={
                        t('initiatives:formInputLabels:badgeDescription') +
                        `${
                          Object.values(badgeName).some((v) => v) ||
                          Object.values(badgeDescription).some((v) => v) ||
                          badgeImage
                            ? '*'
                            : ''
                        }`
                      }
                      dictionaryField={badgeDescription}
                      onChange={(dictionaryText) => {
                        setBadgeImageErr(undefined);
                        setBadgeNameErr(undefined);
                        dispatch(challengeFormActions.setBadgeDescription(dictionaryText));
                        if (!edited) dispatch(challengeFormActions.setEdited(true));
                      }}
                      error={badgeDescriptionErr}
                      errorSetter={setBadgeDescriptionErr}
                      multiline
                      disabled={viewOnlyMode}
                    />
                  </Stack>
                </EditableCard>
              </FlexBox>
            </FlexBox>

            {brandId && (
              <FlyersContainer
                iconColor={theme.palette.primary.main}
                brandId={parseInt(brandId)}
                mode={TokenAssignmentMode.RANDOM_CONTENT}
                onFlyerSelected={manageFlyerSelect}
                onFlyerDeselected={manageFlyerDeselect}
                initiativeTokens={tokens}
                disableAll={viewOnlyMode || noFlyersMode}
                disabledTokens={
                  props.mode === 'edit' && data?.published ? data?.tokens ?? undefined : undefined
                }
              />
            )}
          </FlexBox>
        </FlexBox>
      ) : (
        <ActivityIndicator />
      )}

      {/* Footer */}
      <Toolbar
        mt={4}
        rightcomponent={
          props.mode === 'create' ? (
            <LoadingButton
              loading={addChallengeRequest.inProgress}
              color="success"
              variant="contained"
              startIcon={<Check />}
              onClick={triggerAddChallenge}>
              {t('initiatives:challenges:createChallenge')}
            </LoadingButton>
          ) : props.mode === 'edit' ? (
            <>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForever />}
                disabled={!data || data.id === TUTORIAL_INITIATIVE_ID}
                onClick={() => setDeleteChallengeDialogOpen(true)}
                sx={{ backgroundColor: 'white' }}>
                {t('initiatives:challenges:deleteChallenge')}
              </Button>
              {!viewOnlyMode && (
                <LoadingButton
                  loading={updateChallengeRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateChallenge}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              )}
            </>
          ) : (
            <></>
          )
        }
      />

      {/* Dialogs */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('errors:compilationErrors')}.
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
      <Dialog
        open={deleteChallengeDialogOpened}
        onClose={() => setDeleteChallengeDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('initiatives:texts:deleteInitiativeInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteChallengeDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteChallengeRequest.inProgress}
              onClick={triggerDeleteChallenge}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
