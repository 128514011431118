import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Language as LanguageEntity } from '../../../entities/Common';
import i18n from '../../../i18n';
import { useBreadcrumbs } from '../../components/Breadcrumbs';

type Language = {
  label: string;
  unicode_slug: string;
  slug: LanguageEntity;
};
export const languages = [
  {
    label: 'English',
    unicode_slug: 'GB',
    slug: 'en'
  },
  {
    label: 'Italiano',
    unicode_slug: 'IT',
    slug: 'it'
  }
] as Language[];

export default function Stats() {
  const { t } = useTranslation();
  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:Analytics'),
        path: '/stats'
      };
    }, [t])
  );

  const [language, setLanguage] = React.useState<Language | null>(
    languages.find((l) => i18n.language === l.slug) ?? null
  );

  React.useEffect(() => {
    if (language && language.slug !== i18n.language) {
      i18n.changeLanguage(language.slug);
    }
  }, [language]);

  return (
    <iframe
      src="https://lookerstudio.google.com/embed/reporting/bb27efc0-de49-4416-a79a-061712edd083/page/xEZqD"
      style={{
        border: 'none',
        height: '100%',
        width: '95%',
        marginTop: '3%',
        marginLeft: '5%',
        position: 'absolute',
        top: '0',
        left: '0'
      }}
      allowFullScreen
      sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
  );
}
