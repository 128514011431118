import React from 'react';
import { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FlexBox } from './StyledComponents';

interface ActivityIndicatorProps {
  size?: 'small' | 'medium';
  inline?: boolean;
}
export default function ActivityIndicator(props: BoxProps & ActivityIndicatorProps) {
  const { t } = useTranslation();

  return (
    <FlexBox
      flexDirection={props.inline ? 'row' : 'column'}
      gap={1}
      flex={1}
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      margin="auto"
      {...props}>
      <FlexBox mb={0.5}>
        <CircularProgress size={props.size === 'small' ? 18 : 30} />
      </FlexBox>
      <Typography fontSize={13} fontStyle="italic" color="primary" sx={{ opacity: 0.6 }}>
        {t('common:activityIndicatorText')}
      </Typography>
    </FlexBox>
  );
}
