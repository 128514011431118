import { AxiosError } from 'axios';
import { ZodError } from 'zod';

export class RequestError extends Error {
  axiosError: AxiosError;

  constructor(AxiosError: AxiosError) {
    super(AxiosError?.message);
    Object.setPrototypeOf(this, RequestError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }
    this.axiosError = AxiosError;
  }
}

export class RequestParseError<T = any> extends Error {
  requestName: string;
  originalZodError: ZodError;
  toStringArray: () => string[];
  toString: () => string;

  constructor(error: ZodError<T>, requestName: string) {
    super(error.message);

    this.requestName = requestName;
    this.originalZodError = error;

    this.toStringArray = () => {
      return [`On request ${requestName}:`, ...this.originalZodError.message.split('\n')];
    };

    this.toString = () => {
      return `On request ${requestName}: ` + this.originalZodError.message;
    };
  }
}

type RequestState = {
  inProgress: boolean;
  httpError: null | RequestError;
  parseError: null | RequestParseError;
};
export function requestState(): RequestState {
  return {
    inProgress: false,
    httpError: null,
    parseError: null
  };
}

export function mutateRequestState(state: RequestState): void {
  state.inProgress = true;
  state.httpError = null;
  state.parseError = null;
}

export function mutateSuccessState(state: RequestState): void {
  state.inProgress = false;
  state.httpError = null;
  state.parseError = null;
}

export function mutateErrorState(state: RequestState, error: RequestError): void {
  state.inProgress = false;
  state.httpError = error;
}

export function mutateParseErrorState(state: RequestState, parseError: RequestParseError): void {
  state.inProgress = false;
  state.parseError = parseError;
}
