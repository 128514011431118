import React, { useState } from 'react';

export type Snackbar = {
  opened: boolean;
  severity: 'success' | 'warning' | 'error';
  msg: string;
  onClose?: () => void;
};

export type SnackbarContextType = {
  snackbar: Snackbar;
  setSnackbar: (snackbar: Snackbar) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>({
  snackbar: {
    opened: false,
    severity: 'success' as 'success' | 'warning' | 'error',
    msg: ''
  },
  setSnackbar: () => {}
});

type SnackbarProviderProps = {
  children: React.ReactNode;
};

export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState({
    opened: false,
    severity: 'success' as 'success' | 'warning' | 'error',
    msg: ''
  });

  return (
    <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
      {props.children}
    </SnackbarContext.Provider>
  );
};
