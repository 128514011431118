import { AppUserRequest, BusinessUserEdit, BusinessUserAdd } from '../../entities/User';
import { Repository } from '../Repository';

class UserRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api'
    });
  }

  async createUser(type: string, payload: BusinessUserAdd) {
    const { data } = await this.axios.post('/' + type, payload);
    return data;
  }

  async updateUser(type: string, id: number, payload: AppUserRequest | BusinessUserEdit) {
    const { data } = await this.axios.put('/' + type + '/' + id, payload);
    return data;
  }

  async deleteUser(type: string, id: number) {
    const { data } = await this.axios.delete('/' + type + '/' + id);
    return data;
  }

  async findAppUser(id: number) {
    const { data } = await this.axios.get('/users/' + id);
    return data;
  }

  async findBOUser(id: number) {
    const { data } = await this.axios.get('/business/' + id);
    return data;
  }

  async getAllBackOfficeUsers(page?: number, email?: string, roleId?: number, sort?: string) {
    const { data } = await this.axios.get('/business', {
      params: { page, email, role_id: roleId, sort }
    });
    return data;
  }

  async getAllAppUsers(page?: number, username?: string, email?: string, sort?: string) {
    const { data } = await this.axios.get('/users', {
      params: { page, username, email, sort, subscribe: 'true' }
    });
    return data;
  }

  async getMe() {
    const { data } = await this.axios.get('/business/me');
    return data;
  }

  async getUserRoles() {
    const { data } = await this.axios.get('/roles');
    return data;
  }
}

export default new UserRepository();
