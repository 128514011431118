import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthData } from '../../../entities/Authentication';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  signedIn: undefined as boolean | undefined,
  tokenChecked: false as boolean,
  accessToken: undefined as string | undefined,
  expiredAt: undefined as number | undefined,
  refreshToken: undefined as string | undefined,
  refreshExpiredAt: undefined as number | undefined,

  requests: {
    signIn: requestState(),
    checkToken: requestState(),
    refreshToken: requestState(),
    mailVerification: requestState(),
    resetPassword: requestState(),
    resetBusinessPassword: requestState()
  }
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    // Sign In
    signInRequest(state) {
      mutateRequestState(state.requests.signIn);
    },
    signInSuccess(state, action: PayloadAction<AuthData>) {
      state.signedIn = true;
      state.accessToken = action.payload.access_token;
      state.expiredAt = action.payload.expired_at;
      state.refreshToken = action.payload.refresh_token;
      state.refreshExpiredAt = action.payload.refresh_expired_at;
      mutateSuccessState(state.requests.signIn);
    },
    signInError(state, action: PayloadAction<{ error: RequestError }>) {
      state.signedIn = false;
      mutateErrorState(state.requests.signIn, action.payload.error);
    },
    signInParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      state.signedIn = false;
      mutateParseErrorState(state.requests.signIn, action.payload.parseError);
    },

    // Sign Out
    signOut(state) {
      state.signedIn = false;
      state.accessToken = initialState.accessToken;
      state.expiredAt = initialState.expiredAt;
      state.refreshToken = initialState.refreshToken;
      state.refreshExpiredAt = initialState.refreshExpiredAt;
    },

    // Check Token
    checkTokenRequest(state) {
      mutateRequestState(state.requests.checkToken);
    },
    checkTokenSuccess(state, action: PayloadAction<AuthData>) {
      state.accessToken = action.payload.access_token;
      state.expiredAt = action.payload.expired_at;
      state.refreshToken = action.payload.refresh_token;
      state.refreshExpiredAt = action.payload.refresh_expired_at;
      state.signedIn = true;
      state.tokenChecked = true;
      mutateSuccessState(state.requests.checkToken);
    },
    checkTokenError(state, action: PayloadAction<{ error: RequestError }>) {
      state.tokenChecked = true;
      mutateErrorState(state.requests.checkToken, action.payload.error);
    },

    // Refresh Token
    refreshTokenRequest(state) {
      mutateRequestState(state.requests.refreshToken);
    },
    refreshTokenSuccess(state, action: PayloadAction<AuthData>) {
      state.signedIn = true;
      state.accessToken = action.payload.access_token;
      state.expiredAt = action.payload.expired_at;
      state.refreshToken = action.payload.refresh_token;
      state.refreshExpiredAt = action.payload.refresh_expired_at;
      mutateSuccessState(state.requests.refreshToken);
    },
    refreshTokenError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.refreshToken, action.payload.error);
    },
    refreshTokenParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.refreshToken, action.payload.parseError);
    },

    // Confirm Email

    mailVerificationRequest(state) {
      mutateRequestState(state.requests.mailVerification);
    },
    mailVerificationSuccess(state) {
      mutateSuccessState(state.requests.mailVerification);
    },
    mailVerificationError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.mailVerification, action.payload.error);
    },

    // Reset Password

    resetPasswordRequest(state) {
      mutateRequestState(state.requests.resetPassword);
    },
    resetPasswordSuccess(state) {
      mutateSuccessState(state.requests.resetPassword);
    },
    resetPasswordError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.resetPassword, action.payload.error);
    },

    resetBusinessPasswordRequest(state) {
      mutateRequestState(state.requests.resetBusinessPassword);
    },
    resetBusinessPasswordSuccess(state) {
      mutateSuccessState(state.requests.resetBusinessPassword);
    },
    resetBusinessPasswordError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.resetBusinessPassword, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const authenticationActions = authenticationSlice.actions;

export default authenticationSlice.reducer;
