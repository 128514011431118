class PathFormatter {
  relativeToAbsolute(imgRelPath: string) {
    return `${process.env.REACT_APP_CDN_URL}/${imgRelPath}`;
  }

  absoluteToRelative(imgAbsPath: string) {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp('^(https|http)(://)((.)*).(com|it)/');
    if (imgAbsPath.match(regex)) return imgAbsPath.replace(regex, '');
    return imgAbsPath;
  }

  relativeToFilename(imgRelPath: string) {
    return imgRelPath.split('/')[1] ?? imgRelPath;
  }
}

export default new PathFormatter();
