import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import InitiativesRepository from '../api/repositories/InitiativesRepository';
import {
  Campaign,
  CampaignRequest,
  CampaignsAPIResponse,
  CampaignsAPIResponseSchema,
  CampaignSchema,
  Challenge,
  ChallengeRequest,
  InitiativeAPIResponse,
  InitiativesAPIResponseSchema,
  InitiativeSchema,
  InitiativeType,
  InitiativeTypesApiResponse,
  InitiativeTypesAPIResponseSchema,
  Quest,
  QuestRequest
} from '../entities/Initiatives';
import { initiativesActions } from '../redux/be_interaction_store/slices/initiatives';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type InitiativeState = RootState['BEInteraction']['initiatives'];

type UseInitiatives = {
  challenges: InitiativeState['challenges'];
  readChallenges: (
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    startDateRange?: string,
    endDateRange?: string,
    sort?: string
  ) => Promise<void>;
  readChallengesRequest: InitiativeState['requests']['readChallenges'];
  updateChallenge: (
    challengeId: number,
    challengeRequestData: ChallengeRequest
  ) => Promise<boolean>;
  updateChallengeRequest: InitiativeState['requests']['updateChallenge'];
  addChallenge: (challengeRequestData: ChallengeRequest) => Promise<boolean>;
  addChallengeRequest: InitiativeState['requests']['addChallenge'];
  deleteChallenge: (challengeId: number) => Promise<boolean>;
  deleteChallengeRequest: InitiativeState['requests']['deleteChallenge'];

  quests: InitiativeState['quests'];
  readQuests: (
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    startDateRange?: string,
    endDateRange?: string,
    sort?: string
  ) => Promise<void>;
  readQuestsRequest: InitiativeState['requests']['readQuests'];
  updateQuest: (questId: number, questRequestData: QuestRequest) => Promise<boolean>;
  updateQuestRequest: InitiativeState['requests']['updateQuest'];
  addQuest: (questRequestData: QuestRequest) => Promise<boolean>;
  addQuestRequest: InitiativeState['requests']['addQuest'];
  deleteQuest: (questId: number) => Promise<boolean>;
  deleteQuestRequest: InitiativeState['requests']['deleteQuest'];

  initiativeTypes: InitiativeState['initiativeTypes'];
  readInitiativeTypes: () => Promise<void>;
  initiativeTypesRequest: InitiativeState['requests']['readInitiativeTypes'];

  findInitiative: (initiativeId: number) => Promise<Quest | Challenge | void>;
  findInitiativeRequest: InitiativeState['requests']['findInitiative'];

  publishInitiative: (initiativeId: number) => Promise<boolean>;
  publishInitiativeRequest: InitiativeState['requests']['publishInitiative'];

  campaigns: InitiativeState['campaigns'];
  readCampaigns: (
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    sort?: string
  ) => Promise<void>;
  readCampaignsRequest: InitiativeState['requests']['readCampaigns'];
  findCampaign: (campaignId: number) => Promise<Campaign | void>;
  findCampaignRequest: InitiativeState['requests']['findCampaign'];
  addCampaign: (campaignRequestData: CampaignRequest) => Promise<boolean>;
  addCampaignRequest: InitiativeState['requests']['addCampaign'];
  updateCampaign: (campaignId: number, campaignRequestData: CampaignRequest) => Promise<boolean>;
  updateCampaignRequest: InitiativeState['requests']['updateCampaign'];
  deleteCampaign: (campaignId: number) => Promise<boolean>;
  deleteCampaignRequest: InitiativeState['requests']['deleteCampaign'];
};

export default function useInitiatives(): UseInitiatives {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      InitiativesRepository.updateAuthToken(accessToken);
      InitiativesRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const initiativeTypes = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.initiativeTypes
  );
  const campaigns = useSelector((state: RootState) => state.BEInteraction.initiatives.campaigns);
  const challenges = useSelector((state: RootState) => state.BEInteraction.initiatives.challenges);
  const quests = useSelector((state: RootState) => state.BEInteraction.initiatives.quests);

  const initiativeTypesRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.readInitiativeTypes
  );
  const findInitiativeRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.findInitiative
  );
  const publishInitiativeRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.publishInitiative
  );

  const readChallengesRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.readChallenges
  );
  const updateChallengeRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.updateChallenge
  );
  const addChallengeRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.addChallenge
  );
  const deleteChallengeRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.deleteChallenge
  );

  const readQuestsRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.readQuests
  );
  const updateQuestRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.updateQuest
  );
  const addQuestRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.addQuest
  );
  const deleteQuestRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.deleteQuest
  );

  const readCampaignsRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.readCampaigns
  );
  const findCampaignRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.findCampaign
  );
  const updateCampaignRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.updateCampaign
  );
  const addCampaignRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.addCampaign
  );
  const deleteCampaignRequest = useSelector(
    (state: RootState) => state.BEInteraction.initiatives.requests.deleteCampaign
  );

  // Readers
  const readInitiativeTypes: UseInitiatives['readInitiativeTypes'] = useCallback(async () => {
    dispatch(initiativesActions.readInitiativeTypesRequest());
    let response: unknown;

    try {
      response = await InitiativesRepository.getInitiativeTypes();
    } catch (error) {
      dispatch(
        initiativesActions.readInitiativeTypesError({
          error: new RequestError(error as AxiosError)
        })
      );
      return;
    }

    const parseResult = InitiativeTypesAPIResponseSchema.safeParse(response);
    if (!parseResult.success) {
      dispatch(
        initiativesActions.readInitiativeTypesParseError({
          parseError: new RequestParseError(parseResult.error, 'UseInitiatives.readInitiativeTypes')
        })
      );
      return;
    }

    dispatch(
      initiativesActions.readInitiativeTypesSuccess(
        (response as InitiativeTypesApiResponse).content as InitiativeType[]
      )
    );
  }, [dispatch]);

  const findInitiative: UseInitiatives['findInitiative'] = useCallback(
    async (initiativeId: number) => {
      dispatch(initiativesActions.findInitiativeRequest());
      let response: unknown;

      try {
        response = await InitiativesRepository.findInitiative(initiativeId);
      } catch (error) {
        dispatch(
          initiativesActions.findInitiativeError({ error: new RequestError(error as AxiosError) })
        );
        return;
      }

      const parseResult = InitiativeSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          initiativesActions.findInitiativeParseError({
            parseError: new RequestParseError(parseResult.error, 'UseInitiatives.findInitiative')
          })
        );
        return;
      }

      dispatch(initiativesActions.findInitiativeSuccess());
      return response as Quest | Challenge;
    },
    [dispatch]
  );

  const findCampaign: UseInitiatives['findCampaign'] = useCallback(
    async (campaignId: number) => {
      dispatch(initiativesActions.findCampaignRequest());
      let response: unknown;

      try {
        response = await InitiativesRepository.findCampaign(campaignId);
      } catch (error) {
        dispatch(
          initiativesActions.findCampaignError({ error: new RequestError(error as AxiosError) })
        );
        return;
      }

      const parseResult = CampaignSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          initiativesActions.findCampaignParseError({
            parseError: new RequestParseError(parseResult.error, 'UseInitiatives.findCampaign')
          })
        );
        return;
      }

      dispatch(initiativesActions.findCampaignSuccess());
      return response as Campaign;
    },
    [dispatch]
  );

  const readCampaigns: UseInitiatives['readCampaigns'] = useCallback(
    async (brandId: number, page?: number, name?: string, published?: boolean, sort?: string) => {
      dispatch(initiativesActions.readCampaignsRequest());
      let response: unknown;

      try {
        response = await InitiativesRepository.getCampaigns(brandId, page, name, published, sort);
      } catch (error) {
        dispatch(
          initiativesActions.readCampaignsError({ error: new RequestError(error as AxiosError) })
        );
      }

      const parseResult = CampaignsAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          initiativesActions.readCampaignsParseError({
            parseError: new RequestParseError(parseResult.error, 'UseInitiatives.readCampaigns')
          })
        );
        return;
      }

      dispatch(initiativesActions.readCampaignsSuccess(response as CampaignsAPIResponse));
    },
    [dispatch]
  );

  const readChallenges: UseInitiatives['readChallenges'] = useCallback(
    async (
      brandId: number,
      page?: number,
      name?: string,
      published?: boolean,
      startDateRange?: string,
      endDateRange?: string,
      sort?: string
    ) => {
      dispatch(initiativesActions.readChallengesRequest());
      let response: unknown;

      try {
        response = await InitiativesRepository.getChallenges(
          brandId,
          page,
          name,
          published,
          startDateRange,
          endDateRange,
          sort
        );
      } catch (error) {
        dispatch(
          initiativesActions.readChallengesError({ error: new RequestError(error as AxiosError) })
        );
        return;
      }

      const parseResult = InitiativesAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          initiativesActions.readChallengesParseError({
            parseError: new RequestParseError(parseResult.error, 'UseInitiatives.readChallenges')
          })
        );
        return;
      }

      dispatch(initiativesActions.readChallengesSuccess(response as InitiativeAPIResponse));
    },
    [dispatch]
  );

  const readQuests: UseInitiatives['readQuests'] = useCallback(
    async (
      brandId: number,
      page?: number,
      name?: string,
      published?: boolean,
      startDateRange?: string,
      endDateRange?: string,
      sort?: string
    ) => {
      dispatch(initiativesActions.readQuestsRequest());
      let response: unknown;

      try {
        response = await InitiativesRepository.getQuests(
          brandId,
          page,
          name,
          published,
          startDateRange,
          endDateRange,
          sort
        );
      } catch (error) {
        dispatch(
          initiativesActions.readQuestsError({ error: new RequestError(error as AxiosError) })
        );
        return;
      }

      const parseResult = InitiativesAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          initiativesActions.readQuestsParseError({
            parseError: new RequestParseError(parseResult.error, 'UseInitiatives.readQuests')
          })
        );
        return;
      }

      dispatch(initiativesActions.readQuestsSuccess(response as InitiativeAPIResponse));
    },
    [dispatch]
  );

  // Updaters
  const publishInitiative: UseInitiatives['publishInitiative'] = useCallback(
    async (initiativeId: number) => {
      dispatch(initiativesActions.publishInitiativeRequest());

      try {
        await InitiativesRepository.publish(initiativeId);
      } catch (error) {
        dispatch(
          initiativesActions.publishInitiativeError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(initiativesActions.publishInitiativeSuccess());
      return true;
    },
    [dispatch]
  );

  const updateChallenge: UseInitiatives['updateChallenge'] = useCallback(
    async (challengeId: number, challengeRequestData: ChallengeRequest) => {
      dispatch(initiativesActions.updateChallengeRequest());

      try {
        await InitiativesRepository.updateChallenge(challengeId, challengeRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.updateChallengeError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(initiativesActions.updateChallengeSuccess());
      return true;
    },
    [dispatch]
  );

  const updateQuest: UseInitiatives['updateQuest'] = useCallback(
    async (questId: number, questRequestData: QuestRequest) => {
      dispatch(initiativesActions.updateQuestRequest());

      try {
        await InitiativesRepository.updateQuest(questId, questRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.updateQuestError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.updateQuestSuccess());
      return true;
    },
    [dispatch]
  );

  const updateCampaign: UseInitiatives['updateCampaign'] = useCallback(
    async (campaignId: number, campaignRequestData: CampaignRequest) => {
      dispatch(initiativesActions.updateCampaignRequest());

      try {
        await InitiativesRepository.updateCampaign(campaignId, campaignRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.updateCampaignError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.updateCampaignSuccess());
      return true;
    },
    [dispatch]
  );

  // Adders
  const addChallenge: UseInitiatives['addChallenge'] = useCallback(
    async (challengeRequestData: ChallengeRequest) => {
      dispatch(initiativesActions.addChallengeRequest());

      try {
        await InitiativesRepository.addChallenge(challengeRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.addChallengeError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.addChallengeSuccess());
      return true;
    },
    [dispatch]
  );

  const addQuest: UseInitiatives['addQuest'] = useCallback(
    async (questRequestData: QuestRequest) => {
      dispatch(initiativesActions.addQuestRequest());

      try {
        await InitiativesRepository.addQuest(questRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.addQuestError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.addQuestSuccess());
      return true;
    },
    [dispatch]
  );

  const addCampaign: UseInitiatives['addCampaign'] = useCallback(
    async (campaignRequestData: CampaignRequest) => {
      dispatch(initiativesActions.addCampaignRequest());

      try {
        await InitiativesRepository.addCampaign(campaignRequestData);
      } catch (error) {
        dispatch(
          initiativesActions.addCampaignError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.addCampaignSuccess());
      return true;
    },
    [dispatch]
  );

  // Deleters
  const deleteChallenge: UseInitiatives['deleteChallenge'] = useCallback(
    async (challengeId: number) => {
      dispatch(initiativesActions.deleteChallengeRequest());

      try {
        await InitiativesRepository.deleteInitiative(challengeId);
      } catch (error) {
        dispatch(
          initiativesActions.deleteChallengeError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.deleteChallengeSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteQuest: UseInitiatives['deleteQuest'] = useCallback(
    async (questId: number) => {
      dispatch(initiativesActions.deleteQuestRequest());

      try {
        await InitiativesRepository.deleteInitiative(questId);
      } catch (error) {
        dispatch(
          initiativesActions.deleteQuestError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.deleteQuestSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteCampaign: UseInitiatives['deleteCampaign'] = useCallback(
    async (campaignId: number) => {
      dispatch(initiativesActions.deleteCampaignRequest());

      try {
        await InitiativesRepository.deleteCampaign(campaignId);
      } catch (error) {
        dispatch(
          initiativesActions.deleteCampaignError({ error: new RequestError(error as AxiosError) })
        );
        return false;
      }

      dispatch(initiativesActions.deleteCampaignSuccess());
      return true;
    },
    [dispatch]
  );

  return {
    initiativeTypes,
    readInitiativeTypes,
    initiativeTypesRequest,

    findInitiative,
    findInitiativeRequest,

    publishInitiative,
    publishInitiativeRequest,

    challenges,
    readChallenges,
    readChallengesRequest,
    updateChallenge,
    updateChallengeRequest,
    addChallenge,
    addChallengeRequest,
    deleteChallenge,
    deleteChallengeRequest,

    quests,
    readQuests,
    readQuestsRequest,
    updateQuest,
    updateQuestRequest,
    addQuest,
    addQuestRequest,
    deleteQuest,
    deleteQuestRequest,

    campaigns,
    readCampaigns,
    readCampaignsRequest,
    findCampaign,
    findCampaignRequest,
    updateCampaign,
    updateCampaignRequest,
    addCampaign,
    addCampaignRequest,
    deleteCampaign,
    deleteCampaignRequest
  };
}
