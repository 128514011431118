import * as z from 'zod';

export const LocationSchema = z.object({
  id: z.number(),
  lat: z.number(),
  lng: z.number(),
  name: z.string()
});
export type Location = z.infer<typeof LocationSchema>;

export const LocationRequestSchema = z.object({
  id: z.number().optional(),
  lat: z.number(),
  lng: z.number(),
  name: z.string().optional(),
  delete: z.boolean().optional()
});
export type LocationRequest = z.infer<typeof LocationRequestSchema>;
