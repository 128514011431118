import { Repository } from '../Repository';

class BrandRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api/brands'
    });
  }

  async get(brandId: number) {
    const { data } = await this.axios.get(`/${brandId}`);
    return data;
  }

  async getMine() {
    const { data } = await this.axios.get(`/mine`);
    return data;
  }

  async getAll(page?: number, name?: string, sort?: string) {
    const { data } = await this.axios.get('', { params: { page, name, sort } });
    return data;
  }

  async storeNewBrand(name: string, logo: string, link?: string) {
    const { data } = await this.axios.post('', {
      name,
      link,
      logo
    });
    return data;
  }

  async updateBrand(brandId: string, name: string, logo: string, link?: string | null) {
    const { data } = await this.axios.put(`/${brandId}`, {
      name,
      link,
      logo
    });
    return data;
  }

  async updateMine(name: string, logo: string, link?: string | null) {
    const { data } = await this.axios.put(``, {
      name,
      link,
      logo
    });
    return data;
  }

  async deleteBrand(brandId: string) {
    const { data } = await this.axios.delete(`/${brandId}`);
    return data;
  }
}

export default new BrandRepository();
