import React, { useContext, useMemo } from 'react';
import MuiBreadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { BreadcrumbsContext } from './BreadcrumbsProvider';

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  const location = useLocation();
  const navigate = useNavigate();

  const currentBreadcrumbs = useMemo(
    () =>
      Object.keys(breadcrumbs)
        .filter((keyPath) => {
          const pathCrumbsArr = keyPath.split('/').filter((v) => Boolean(v));
          const locationCrumbsArr = location.pathname.split('/').filter((v) => Boolean(v));
          return pathCrumbsArr.every(
            (k, index) => k === _.take(locationCrumbsArr, pathCrumbsArr.length)[index]
          );
        }) // takes paths that matches location pathname in initial (array of crumbs) strings
        .sort((a, b) => a.length - b.length) // orders the paths from the shortest to logest
        .map((path) => {
          const breadcrumbObj = breadcrumbs[path];
          if (breadcrumbObj) {
            return breadcrumbObj;
          }
          return null;
        }) // builds an ordered array with the current crumb objects
        .filter((b) => b !== null), // removes possible errors
    [breadcrumbs, location.pathname]
  );

  return (
    <MuiBreadcrumbs {...props}>
      {currentBreadcrumbs.map((crumb, index) => {
        return (
          crumb &&
          (index === currentBreadcrumbs.length - 1 ? (
            <Typography key={index} fontWeight="bold">
              {crumb.title}
            </Typography>
          ) : (
            <Link
              key={index}
              underline="hover"
              color="inherit"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate(crumb.path)}>
              {crumb.title}
            </Link>
          ))
        );
      })}
    </MuiBreadcrumbs>
  );
}
