import * as z from 'zod';
import { AssetSchema } from './Asset';
import { BadgeShema } from './Badge';
import { EmbeddedBrandSchema } from './Brand';
import { DictionaryStringSchema, PageblePayloadSchema } from './Common';
import { FlyerSchema } from './Flyer';
import { LocationRequestSchema, LocationSchema } from './Location';
import { TokenAssignmentMode, TokenRequestSchema, TokenSchema } from './Token';

// Types
const InitiativeTypeSchema = z.object({
  id: z.number(),
  name: z.string()
});
export type InitiativeType = z.infer<typeof InitiativeTypeSchema>;
export const InitiativeTypesAPIResponseSchema = PageblePayloadSchema.extend({
  content: z.array(InitiativeTypeSchema)
});
export type InitiativeTypesApiResponse = z.infer<typeof InitiativeTypesAPIResponseSchema>;

// Campaigns
export const CampaignSchema = z.object({
  id: z.number(),
  name: z.string(),
  startDate: z.number(),
  endDate: z.number(),
  creator: z.object({
    email: z.string()
  }),
  brand: EmbeddedBrandSchema,
  published: z.boolean(),
  flyers: z.array(FlyerSchema).nullable().optional(),
  countActiveUsers: z.number().nullable().optional()
});
export type Campaign = z.infer<typeof CampaignSchema>;
export const CampaignsAPIResponseSchema = PageblePayloadSchema.extend({
  content: z.array(CampaignSchema)
});
export type CampaignsAPIResponse = z.infer<typeof CampaignsAPIResponseSchema>;

const CampaignRequestSchema = z.object({
  brandId: z.number(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
  name: z.string().optional(),
  published: z.boolean().optional(),
  flyers: z.array(z.number()).nullable().optional()
});
export type CampaignRequest = z.infer<typeof CampaignRequestSchema>;

// Initiatives
export const InitiativeSchema = z.object({
  id: z.number(),
  type: z.union([z.literal('challenge'), z.literal('quest')]),
  name: DictionaryStringSchema,
  description: DictionaryStringSchema,
  logo: z.string(),
  prizeDescription: DictionaryStringSchema,
  prizeTitle: DictionaryStringSchema,
  startDate: z.number(),
  endDate: z.number(),
  unlockThreshold: z.number(),
  descriptionImage: z.string().nullable(),
  listDescriptionImage: z.string().nullable(),
  wakaPrize: z.number(),
  wakaTokens: z.number(),
  goalDescription: DictionaryStringSchema,
  published: z.boolean(),
  initiativeType: z.object({
    id: z.number(),
    name: z.string()
  }),
  mode: z.object({
    id: z.number(),
    name: z.string(),
    slug: z.union([z.literal('single'), z.literal('revolving'), z.nativeEnum(TokenAssignmentMode)])
  }),
  brand: EmbeddedBrandSchema,
  titleCollected: DictionaryStringSchema.nullable(),
  badge: BadgeShema.nullable().optional(),
  titleTotal: DictionaryStringSchema.nullable(),
  tokens: z.array(TokenSchema).nullable().optional(),
  locations: z.array(LocationSchema).nullable().optional(),
  advertising: z.boolean().optional(),
  asset: AssetSchema.nullable().optional()
});

const EmbeddedInitiativeSchema = z.object({
  id: z.number(),
  logo: z.string().nullable(),
  name: DictionaryStringSchema,
  description: DictionaryStringSchema,
  descriptionImage: z.string().nullable(),
  endDate: z.number(),
  startDate: z.number(),
  published: z.boolean(),
  mode: z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string()
  }),
  brand: EmbeddedBrandSchema,
  type: z.union([z.literal('challenge'), z.literal('quest')]),
  unlockThreshold: z.number(),
  initiativeType: z.object({
    id: z.number(),
    name: z.string()
  }),
  countActiveUsers: z.number().nullable().optional()
});
export type EmbeddedInitiative = z.infer<typeof EmbeddedInitiativeSchema>;

export const InitiativesAPIResponseSchema = PageblePayloadSchema.extend({
  content: z.array(EmbeddedInitiativeSchema)
});
export type InitiativeAPIResponse = z.infer<typeof InitiativesAPIResponseSchema>;

// Challenges
const ChallengeRequestSchema = z.object({
  brandId: z.number(),
  name: DictionaryStringSchema,
  description: DictionaryStringSchema,
  logo: z.string().nullable().optional(),
  prizeDescription: DictionaryStringSchema,
  prizeTitle: DictionaryStringSchema,
  startDate: z.number(),
  endDate: z.number(),
  unlockThreshold: z.number(),
  descriptionImage: z.string(),
  listDescriptionImage: z.string(),
  wakaPrize: z.number(),
  wakaTokens: z.number(),
  goalDescription: DictionaryStringSchema,
  initiativeTypeId: z.number(),
  modeSlug: z.union([z.literal('single'), z.literal('revolving')]),
  titleCollected: DictionaryStringSchema.nullable(),
  titleTotal: DictionaryStringSchema.nullable(),
  badge: BadgeShema.nullable().optional(),
  tokens: z.array(TokenRequestSchema).nullable().optional(),
  advertising: z.boolean().optional(),
  assetId: z.number().nullable().optional()
});
export type ChallengeRequest = z.infer<typeof ChallengeRequestSchema>;

export const ChallengeSchema = InitiativeSchema.extend({
  type: z.literal('challenge')
});
export type Challenge = z.infer<typeof ChallengeSchema>;

// Quests
const QuestRequestSchema = z.object({
  brandId: z.number(),
  name: DictionaryStringSchema,
  description: DictionaryStringSchema,
  logo: z.string().nullable().optional(),
  prizeDescription: DictionaryStringSchema,
  prizeTitle: DictionaryStringSchema,
  startDate: z.number(),
  endDate: z.number(),
  unlockThreshold: z.number(),
  descriptionImage: z.string(),
  listDescriptionImage: z.string(),
  wakaPrize: z.number(),
  wakaTokens: z.number(),
  goalDescription: DictionaryStringSchema,
  initiativeTypeId: z.number(),
  modeSlug: z.nativeEnum(TokenAssignmentMode),
  badge: BadgeShema.nullable().optional(),
  tokens: z.array(TokenRequestSchema).nullable().optional(),
  locations: z.array(LocationRequestSchema).nullable().optional(),
  advertising: z.boolean().optional(),
  assetId: z.number().nullable().optional()
});
export type QuestRequest = z.infer<typeof QuestRequestSchema>;

export const QuestSchema = InitiativeSchema.extend({
  type: z.literal('quest')
});

export type Quest = z.infer<typeof QuestSchema>;
