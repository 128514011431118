/* eslint-disable prefer-regex-literals */
import React, { useContext, useEffect, useState } from 'react';
import {
  Check,
  DeleteForever,
  InfoOutlined,
  Key,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BusinessUserAdd, BusinessUser, RoleIds } from '../../../../entities/User';
import useBrand from '../../../../hooks/useBrand';
import useUser from '../../../../hooks/useUser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../components/Breadcrumbs';
import EditableCard from '../../../components/EditableCard';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';

interface UserProps {
  mode: 'create' | 'edit';
}

export default function AddEditBackofficeUser(props: UserProps) {
  const {
    addUser,
    addUserRequest,
    updateUser,
    updateUserRequest,
    deleteUser,
    deleteUserRequest,
    readUserRoles,
    findBackOfficeUser
    // userRoles,
    // readUserRolesRequest,
  } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { setSnackbar } = useContext(SnackbarContext);
  const { brands, readBrands, readBrandsRequest } = useBrand();

  const [data, setData] = useState<BusinessUser>();
  const [edited, setEdited] = useState(false);
  const [deleteUserDialogOpened, setDeleteUserDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);
  const [pswVisible, setPswVisible] = useState(false);

  const [email, setEmail] = useState<string | null | undefined>();
  // const [image, setImage] = useState<string | undefined | null>();
  const [password, setPassword] = useState<string | undefined | null>('');
  const [confirmationPassword, setConfirmationPassword] = useState<string | undefined | null>('');
  const [roleId, setRoleId] = useState<number>(props.mode === 'create' ? 1 : 0);
  const [brandId, setBrandId] = React.useState<number>();

  const [brandName, setBrandName] = React.useState<string>();
  const [selectedBrand, setSelectedBrand] = React.useState<{
    label: string | undefined;
    logo: string | undefined;
    id: number | undefined;
  } | null>();

  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmationPasswordErr, setConfirmationPasswordErr] = useState<string>();
  const [emailErr, setEmailErr] = useState<string>();
  const [brandError, setBrandError] = useState<string>();

  React.useEffect(() => {
    readBrands(undefined, brandName || undefined, 'name');
  }, [brandName]);

  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('users:newUser'),
            path: `/users/bo/new_user`
          }
        : {
            title: t('users:editUser'),
            path: `/users/bo/${userId}/edit`
          };
    }, [userId, props.mode])
  );

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const fecthUserData = async () => {
    if (userId) {
      const userData = await findBackOfficeUser(parseInt(userId));
      if (userData) setData(userData);
    }
  };

  useEffect(() => {
    if (props.mode === 'edit') {
      fecthUserData();
    }
  }, [userId]);

  useEffect(() => {
    if (data) {
      setRoleId(data.role?.id ?? 0);
      setEmail(data.email);
      setBrandId(data.brand?.id);
      setBrandName(data.brand?.name);
      setSelectedBrand({
        id: data.brand?.id,
        label: data.brand?.name,
        logo: data.brand?.logo
      });
    }
  }, [data]);

  useEffect(() => {
    readUserRoles();
  }, [readUserRoles]);

  const validation = () => {
    let validated = true;

    // email validation
    if (!email) {
      setEmailErr(t('errors:obligatoryField'));
      validated = false;
    }
    const emailRe = new RegExp('^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}');
    if (email && !email.match(emailRe)) {
      setEmailErr(t('errors:invalidEmail'));
      validated = false;
    }

    // psw validation
    if (!password && validated) {
      setPasswordErr(t('errors:obligatoryField'));
      validated = false;
    }
    const pswRe = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    if (password && !password.match(pswRe) && validated) {
      setPasswordErr(t('errors:notValidPsw'));
      validated = false;
    }
    if (!confirmationPassword && validated) {
      setConfirmationPasswordErr(t('errors:obligatoryField'));
      validated = false;
    }
    if ((!password || !confirmationPassword || password !== confirmationPassword) && validated) {
      setPasswordErr(t('errors:doubleCheckPsw'));
      setConfirmationPasswordErr(t('errors:doubleCheckPsw'));
      validated = false;
    }

    // Role validation
    if (roleId === RoleIds.BRAND_USER_ID && !brandId && validated) {
      setBrandError(t('errors:obligatoryField'));
      validated = false;
    }
    return validated;
  };

  const triggerAddUser = async () => {
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && password) {
      let payload: BusinessUserAdd;
      if (email) {
        payload = {
          email,
          // image,
          roleId,
          password,
          brandId
        };
      } else return;

      const success = await addUser('business', payload);
      openSnackBar(success, t('users:snackbar:addUserSuccess'), t('users:snackbar:addUserError'));
      if (success) navigate(-1);
    }
  };

  const triggerUpdateUser = async () => {
    if (!userId) return;
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && password) {
      const payload = {
        password
      };
      const success = await updateUser('business', parseInt(userId), payload);
      openSnackBar(success, t('users:snackbar:editUserSuccess'), t('users:snackbar:editUserError'));
    }
  };

  const triggerDeleteUser = async () => {
    if (userId) {
      const success = await deleteUser('business', parseInt(userId));
      openSnackBar(
        success,
        t('users:snackbar:deleteUserSuccess'),
        t('users:snackbar:deleteUserError')
      );
      setDeleteUserDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  //   Usefull UI Components
  // const UploadImgField = (props: {
  //   label: string;
  //   field: string | null;
  //   setter: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  //   error?: string | undefined;
  //   errorSetter?: React.Dispatch<React.SetStateAction<string | undefined>>;
  //   disabled?: boolean;
  //   helperText?: string;
  //   maxSize?: number;
  // }) => {
  //   return (
  //     <div>
  //       <Box
  //         sx={{
  //           p: 2,
  //           borderRadius: 1,
  //           border: `1px solid ${props.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
  //           position: 'relative'
  //         }}>
  //         <label
  //           style={{
  //             color: props.disabled
  //               ? 'rgba(0, 0, 0, 0.38)'
  //               : props.error
  //               ? '#d32f2f'
  //               : 'rgba(0, 0, 0, 0.6)',
  //             fontSize: '1rem',
  //             position: 'absolute',
  //             left: 0,
  //             top: 0,
  //             transform: 'translate(8px, -9px) scale(0.75)',
  //             transformOrigin: 'top left',
  //             background: 'white',
  //             paddingInline: 6,
  //             fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  //             letterSpacing: '0.00938em'
  //           }}>
  //           {props.label}
  //         </label>
  //         <UploadImg
  //           title={`${props.field ? 'change' : 'upload'} image`}
  //           remoteFileName={props.field ? PathFormatter.absoluteToRelative(props.field) : undefined}
  //           onDelete={() => {
  //             props.setter(null);
  //             setEdited(true);
  //           }}
  //           onUploaded={(filename) => {
  //             props.setter(filename);
  //             if (props.errorSetter) props.errorSetter(undefined);
  //             setEdited(true);
  //           }}
  //           disabled={props.disabled}
  //           helperText={props.helperText}
  //           maxSize={props.maxSize}
  //         />
  //       </Box>

  //       <Typography
  //         sx={{
  //           color: '#d32f2f',
  //           fontSize: '0.75rem',
  //           mt: 0.5,
  //           mx: '14px',
  //           display: props.error ? 'block' : 'none'
  //         }}>
  //         {props.error}
  //       </Typography>
  //     </div>
  //   );
  // };

  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between">
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {props.mode === 'create' ? t('users:newUser') : t('users:editUser')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('users:texts:createPageDescription')
                : t('users:texts:editBusinessPageDescription')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addUserRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddUser}>
                {t('users:addUser')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={'row'} alignSelf="center" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data}
                  onClick={() => setDeleteUserDialogOpen(true)}>
                  {t('users:deleteUser')}
                </Button>
                <LoadingButton
                  loading={updateUserRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateUser}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      <FlexBox flexDirection={'row'} gap={2} flex={1} alignItems="flex-start" width="90%" mx="auto">
        {props.mode === 'create' || (props.mode === 'edit' && data) ? (
          <>
            <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              bodySx={{ gap: 2, p: 2, pt: 0, display: 'flex', flexDirection: 'column' }}
              title={t('users:formPageSections:info')}
              icon={<InfoOutlined color="primary" />}>
              {/* Profile Pic (not supported by BE) */}
              {/* <InfoText>
                <p>{t('users:formInputHelperTexts:setImage')}</p>
              </InfoText>
              <UploadImgField
                field={image ?? null}
                setter={setImage}
                label={t('users:formInputLabels:image')}
                disabled={props.mode === 'edit'}
              /> */}

              {/* email */}
              <InfoText>
                <p>{t('users:formInputHelperTexts:insertEmail')}</p>
              </InfoText>
              <TextField
                size="small"
                disabled={props.mode === 'edit'}
                label={t('users:formInputLabels:email') + '*'}
                error={Boolean(emailErr)}
                helperText={emailErr}
                InputLabelProps={{ shrink: true }}
                value={email}
                autoComplete="new-email"
                onChange={(e) => {
                  const newTextValue = e.target.value;
                  setEmail(newTextValue);
                  setEmailErr(undefined);
                  setEdited(true);
                }}
              />
            </EditableCard>

            <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              bodySx={{ gap: 2, p: 2, pt: 0, display: 'flex', flexDirection: 'column' }}
              title={t('users:formPageSections:setPassword')}
              icon={<Key color="primary" />}>
              {/* Password */}
              <InfoText>
                <p>{t('users:formInputHelperTexts:setPassword')}</p>
                <p>{t('users:formInputHelperTexts:passwordCostraints')}</p>
              </InfoText>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <FlexBox flexDirection="row" position="relative" width="100%">
                  <TextField
                    size="small"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordErr(undefined);
                      setEdited(true);
                    }}
                    type={pswVisible ? 'text' : 'password'}
                    label={t('authentication:password') + '*'}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(passwordErr)}
                    helperText={passwordErr}
                    fullWidth
                    autoComplete="new-password"
                  />
                  <IconButton
                    onClick={() => setPswVisible(!pswVisible)}
                    color="primary"
                    title={pswVisible ? 'Hide password' : 'Show password'}
                    sx={{
                      position: 'absolute',
                      right: 0
                    }}>
                    {pswVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FlexBox>
              </Box>

              {/* Confirm Password */}
              <InfoText>
                <p>{t('users:formInputHelperTexts:confirmPassword')}</p>
              </InfoText>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <FlexBox flexDirection="row" position="relative" width="100%">
                  <TextField
                    size="small"
                    value={confirmationPassword}
                    onChange={(e) => {
                      setConfirmationPassword(e.target.value);
                      setConfirmationPasswordErr(undefined);
                      setEdited(true);
                    }}
                    type={pswVisible ? 'text' : 'password'}
                    label={t('users:formInputLabels:confirmPassword') + '*'}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(confirmationPasswordErr)}
                    helperText={confirmationPasswordErr}
                    fullWidth
                    autoComplete="new-confirmationPassword"
                  />
                  <IconButton
                    onClick={() => setPswVisible(!pswVisible)}
                    color="primary"
                    title={pswVisible ? 'Hide password' : 'Show password'}
                    sx={{
                      position: 'absolute',
                      right: 0
                    }}>
                    {pswVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FlexBox>
              </Box>
            </EditableCard>

            {/* TODO: uncomment in future releases to enable Brand role selection */}
            {/* <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              bodySx={{ gap: 2, p: 2, pt: 0, display: 'flex', flexDirection: 'column' }}
              title={t('users:formPageSections:privileges')}
              icon={<MilitaryTech color="primary" />}>
              <InfoText>
                <p>{t('users:formInputHelperTexts:setRole')}</p>
              </InfoText>
              <FormControl fullWidth size="small" disabled={props.mode === 'edit'}>
                <InputLabel>{t('users:formInputLabels:role') + '*'}</InputLabel>
                <Select
                  size="small"
                  disabled={props.mode === 'edit' || readUserRolesRequest.inProgress}
                  value={roleId}
                  label={t('users:formInputLabels:role') + '*'}
                  onChange={(e) => {
                    setRoleId(e.target.value as any);
                    setBrandId(undefined);
                    setBrandName(undefined);
                    setSelectedBrand(null);
                    setBrandError(undefined);
                    setEdited(true);
                  }}>
                  {userRoles.map((role: any) => (
                    <MenuItem key={`role-${role.id}`} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Slide
                in={roleId === RoleIds.BRAND_USER_ID}
                enter={!data?.brand?.id}
                unmountOnExit
                direction="right">
                <div>
                  <InfoText mb={3}>
                    <p>{t('users:formInputHelperTexts:chooseBrand')}</p>
                  </InfoText>
                  <Autocomplete
                    disabled={props.mode === 'edit'}
                    value={selectedBrand}
                    options={brands.data
                      .filter((b) => b.id !== WAKAPAPA_ID)
                      .map((b) => ({ label: b.name, logo: b.logo, id: b.id }))}
                    onChange={(e, value) => {
                      if (value?.id) {
                        setBrandId(value.id);
                        setSelectedBrand(value);
                        setBrandError(undefined);
                      }
                    }}
                    onInput={_.debounce((e) => setBrandName(e.target.value), 800)}
                    loading={readBrandsRequest.inProgress}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label={t('brand:selectBrand') + '*'}
                        size="small"
                        sx={{ backgroundColor: 'white' }}
                        error={Boolean(brandError)}
                        helperText={brandError}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <>
                          <li {...props}>
                            {option.logo && (
                              <img src={option.logo} style={{ maxWidth: 40, marginRight: 12 }} />
                            )}
                            <div>{option.label}</div>
                          </li>
                          {option.id === brands.data.at(brands.data.length - 1)?.id &&
                            !brands.pagination?.last && <div style={{ marginLeft: 16 }}>...</div>}
                        </>
                      );
                    }}
                  />
                </div>
              </Slide>
            </EditableCard> */}
          </>
        ) : (
          <ActivityIndicator />
        )}
      </FlexBox>

      {/* Form Errors Dialog */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:formsErrorInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog open={deleteUserDialogOpened} onClose={() => setDeleteUserDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:deleteUserInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteUserDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteUserRequest.inProgress}
              onClick={triggerDeleteUser}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
