import React, { ReactElement, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import EditableCardHeader from './EditableCardHeader';

interface EditableCardProps {
  title: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  mode?: 'read' | 'edit' | 'loading';
  onAdd?: () => void;
  onEditMode?: () => void;
  onEditModeDiscard?: () => void;
  onEditModeConfirm?: () => void;
  confirmDisabled?: boolean;
  sx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  additionalHeaderComponent?: React.ReactNode;
  hideHeaderDivider?: boolean;
  editButtonComponent?: (props: {
    onClick?: (e: React.MouseEvent) => void;
    children?: React.ReactNode;
  }) => ReactElement;
}

export default function EditableCard(props: EditableCardProps) {
  const [cardZIndex, setCardZIndex] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    if (props.mode === 'edit' || props.mode === 'loading') {
      setCardZIndex(2);
    } else {
      setTimeout(() => {
        setCardZIndex(0);
      }, theme.transitions.duration.short);
    }
  }, [props.mode]);

  return (
    <>
      <Fade in={true}>
        <Card
          variant="outlined"
          sx={{
            zIndex: cardZIndex,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            borderRadius: 4,
            border: 'none',
            ...props.sx
          }}>
          <EditableCardHeader
            title={props.title}
            mode={props.mode}
            icon={props.icon}
            onAdd={props.onAdd}
            additionalHeaderComponent={props.additionalHeaderComponent}
            editButtonComponent={props.editButtonComponent}
            onEditMode={
              props.onEditMode
                ? () => {
                    if (props.onEditMode) props.onEditMode();
                  }
                : undefined
            }
            onEditModeDiscard={() => {
              if (props.onEditModeDiscard) props.onEditModeDiscard();
            }}
            onEditModeConfirm={() => {
              if (props.onEditModeConfirm) props.onEditModeConfirm();
            }}
            confirmDisabled={props.confirmDisabled}
            sx={props.headerSx}
          />

          {!props.hideHeaderDivider && <Divider />}

          <Box flex={1} sx={props.bodySx}>
            {props.children}
          </Box>
        </Card>
      </Fade>

      <Backdrop
        sx={{ zIndex: 1 }}
        open={props.mode !== 'read' && props.mode !== undefined}
        onClick={() => {
          if (props.onEditModeDiscard) props.onEditModeDiscard();
        }}
      />
    </>
  );
}
