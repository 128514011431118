import * as z from 'zod';
import { PageblePayloadSchema } from './Common';

export const AssetSchema = z.object({
  id: z.number(),
  file: z.string(),
  fileAndroid: z.string().nullable(),
  brandId: z.number(),
  isDefault: z.boolean().optional()
});
export type Asset = z.infer<typeof AssetSchema>;

export const AssetsAPIResponseSchema = PageblePayloadSchema.extend({
  content: z.array(AssetSchema)
});
export type AssetsApiResponse = z.infer<typeof AssetsAPIResponseSchema>;

const AssetRequestSchema = z.object({
  file: z.string(),
  fileAndroid: z.string().nullable(),
  brandId: z.number(),
  isDefault: z.boolean().optional()
});
export type AssetRequest = z.infer<typeof AssetRequestSchema>;
