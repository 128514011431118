import React, { useContext } from 'react';
import { DeleteForever } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoleIds } from '../../../../../entities/User';
import useAssets from '../../../../../hooks/useAssets';
import useBrand from '../../../../../hooks/useBrand';
import useUser from '../../../../../hooks/useUser';
import PathFormatter from '../../../../../utils/PathFormatter';
import AssetsCard from '../../../../components/AssetsCard';
import BrandInfoCard from '../../../../components/BrandInfoCard';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
// import PartnershipInfoCard from '../../../../components/Cards/PartnershipInfoCard';
import EditableCard from '../../../../components/EditableCard';
import FlyersContainer from '../../../../components/FlyersContainer';
import { SnackbarContext } from '../../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../../components/StyledComponents';

export default function BrandHome() {
  const { t } = useTranslation();
  const { me } = useUser();
  const { setSnackbar } = useContext(SnackbarContext);
  const { currentBrand, deleteBrand, deleteBrandRequest, readBrand, readMyBrand } = useBrand();
  const { updateAsset, defaultAsset } = useAssets();
  const navigate = useNavigate();
  const theme = useTheme();
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const [deleteBrandDialogOpen, setDeleteBrandDialogOpen] = React.useState(false);

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: `${currentBrand?.name.charAt(0).toUpperCase()}${currentBrand?.name
          .toLowerCase()
          .slice(1)}`,
        path: `/home/brand/${currentBrand?.id}`
      };
    }, [currentBrand])
  );

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const openDeleteBrandDialog = () => {
    setDeleteBrandDialogOpen(true);
  };
  const closeDeleteBrandDialog = () => {
    setDeleteBrandDialogOpen(false);
  };
  const confirmDeleteBrandDialog = async () => {
    if (!currentBrand) return;
    const success = await deleteBrand(currentBrand.id.toString());
    openSnackBar(
      success,
      t('brand:snackbar:deleteBrandSuccess'),
      t('brand:snackbar:deleteBrandError')
    );
    navigate('/home');
  };

  return currentBrand ? (
    <FlexBox flex={1} flexDirection="column" gap={2} px="6%" pt={1} pb={2}>
      <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} gap={2}>
        <FlexBox flexDirection="column" gap={2} flex={2}>
          <BrandInfoCard data={currentBrand} />
          {/* <PartnershipInfoCard /> */}
        </FlexBox>

        <FlexBox flexDirection="column" gap={2} flex={1}>
          <AssetsCard
            assetId={defaultAsset === null ? null : defaultAsset?.id}
            brandId={currentBrand.id}
            sx={{ flex: '1 1 auto' }}
            infoText={`${t('asset:texts:assetInfo')}

             ${
               defaultAsset === null
                 ? t('asset:texts:mandatoryToPublishInitiatives')
                 : defaultAsset !== undefined && t('asset:texts:clickToDownload')
             }`}
            iconColor={theme.palette.tertiary.main}
            onChangeAsset={async (asset?: {
              id: number;
              file: string;
              fileAndroid: string | null;
            }) => {
              if (asset) {
                const success = await updateAsset(asset.id, {
                  file: PathFormatter.absoluteToRelative(asset.file),
                  fileAndroid: asset.fileAndroid
                    ? PathFormatter.absoluteToRelative(asset.fileAndroid)
                    : null,
                  brandId: currentBrand.id,
                  isDefault: true
                });
                me?.role?.id === RoleIds.BRAND_USER_ID
                  ? readMyBrand()
                  : await readBrand(currentBrand.id);
                openSnackBar(
                  success,
                  t('brand:snackbar:editBrandSuccess'),
                  t('brand:snackbar:editBrandError')
                );
              }
            }}
          />

          {me?.role?.id === RoleIds.ADMIN_USER_ID && (
            <EditableCard
              icon={<DeleteForever sx={{ color: (theme) => theme.palette.tertiary.main }} />}
              hideHeaderDivider
              title={t('brand:deleteBrand')}
              bodySx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'space-between'
              }}
              sx={{ flex: '1 1 auto' }}>
              <InfoText>{t('brand:texts:deleteBrandInfo')}</InfoText>

              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForever />}
                disabled={deleteBrandRequest.inProgress}
                onClick={openDeleteBrandDialog}>
                {t('brand:deleteBrand')}
              </Button>
            </EditableCard>
          )}
        </FlexBox>
      </FlexBox>

      <FlyersContainer brandId={currentBrand.id} />

      {/* Confirm Dialog */}
      <Dialog
        open={deleteBrandDialogOpen}
        onClose={!deleteBrandRequest.inProgress ? closeDeleteBrandDialog : undefined}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }} id="alert-dialog-title">
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('brand:texts:deleteBrand')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={closeDeleteBrandDialog} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteBrandRequest.inProgress}
              onClick={confirmDeleteBrandDialog}
              autoFocus
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  ) : (
    <></>
  );
}
