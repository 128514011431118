import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  campaignId: undefined as number | undefined,
  edited: false as boolean,
  inputData: {
    brandId: null as number | null,
    name: null as string | null,
    startDate: null as number | null,
    endDate: null as number | null,
    flyers: [] as number[]
  }
};

export const campaignFormSlice = createSlice({
  name: 'campaignForm',
  initialState,
  reducers: {
    setCampaignId(state, action: PayloadAction<number | undefined>) {
      state.campaignId = action.payload;
    },
    setEdited(state, action: PayloadAction<boolean>) {
      state.edited = action.payload;
    },

    // input Data
    clear(state) {
      state.inputData = initialState.inputData;
    },

    // setter
    setBrandId(state, action: PayloadAction<number>) {
      state.inputData.brandId = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.inputData.name = action.payload;
    },
    setStartDate(state, action: PayloadAction<number | null>) {
      state.inputData.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<number | null>) {
      state.inputData.endDate = action.payload;
    },
    setFlyers(state, action: PayloadAction<number[]>) {
      state.inputData.flyers = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const campaignFormActions = campaignFormSlice.actions;

export default campaignFormSlice.reducer;
