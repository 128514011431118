import { CommunityAddEdit } from '../../entities/Communities';
import { Repository } from '../Repository';

class CommunitiesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api/communities'
    });
  }

  async createCommunity(payload: CommunityAddEdit) {
    const { data } = await this.axios.post('', payload);
    return data;
  }

  async updateCommunity(id: number, payload: CommunityAddEdit) {
    const { data } = await this.axios.put('/' + id, payload);
    return data;
  }

  async deleteCommunity(id: number) {
    const { data } = await this.axios.delete('/' + id);
    return data;
  }

  async findCommunity(id: number) {
    const { data } = await this.axios.get('/' + id);
    return data;
  }

  async getAllCommunities(page?: number, name?: string, activationCode?: string, sort?: string) {
    const { data } = await this.axios.get('', {
      params: { page, name, code: activationCode, sort }
    });
    return data;
  }
}

export default new CommunitiesRepository();
