import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import UserRepository from '../api/repositories/UserRepository';
import { Role, RolesAPIResponseSchema } from '../entities/Role';
import {
  AppUser,
  AppUserSchema,
  AppUserRequest,
  AppUsersApiResponse,
  AppUsersApiResponseSchema,
  BusinessUserAdd,
  BusinessUserEdit,
  BusinessUsersApiResponse,
  BusinessUsersApiResponseSchema,
  BusinessUser,
  BusinessUserSchema
} from '../entities/User';
import { userActions } from '../redux/be_interaction_store/slices/user';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type UserState = RootState['BEInteraction']['user'];

type UseUser = {
  appUsers: UserState['appUsers'];
  readAppUsers: (page?: number, username?: string, email?: string, sort?: string) => Promise<void>;
  readAppUsersRequest: UserState['requests']['readAppUsers'];
  findAppUser: (userId: number) => Promise<AppUser | void>;
  findAppUserRequest: UserState['requests']['findAppUser'];

  backOfficeUsers: UserState['backOfficeUsers'];
  readBackOfficeUsers: (
    page?: number,
    email?: string,
    roleId?: number,
    sort?: string
  ) => Promise<void>;
  readBackOfficeUsersRequest: UserState['requests']['readBackOfficeUsers'];
  findBackOfficeUser: (userId: number) => Promise<BusinessUser | void>;
  findBackOfficeUserRequest: UserState['requests']['findBackOfficeUser'];

  addUser: (
    userType: string,
    userRequestData: BusinessUserAdd | AppUserRequest
  ) => Promise<boolean>;
  addUserRequest: UserState['requests']['addUser'];

  updateUser: (
    userType: string,
    userId: number,
    userRequestData: AppUserRequest | BusinessUserEdit
  ) => Promise<boolean>;
  updateUserRequest: UserState['requests']['updateUser'];

  deleteUser: (userType: string, userId: number) => Promise<boolean>;
  deleteUserRequest: UserState['requests']['deleteUser'];

  userRoles: UserState['userRoles'];
  readUserRoles: () => Promise<void>;
  readUserRolesRequest: UserState['requests']['readUserRoles'];

  me: UserState['me'];
  readMe: () => Promise<BusinessUser | void>;
  readMeRequest: UserState['requests']['readMe'];
};

export default function useUser(): UseUser {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      UserRepository.updateAuthToken(accessToken);
      UserRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const appUsers = useSelector((state: RootState) => state.BEInteraction.user.appUsers);
  const backOfficeUsers = useSelector(
    (state: RootState) => state.BEInteraction.user.backOfficeUsers
  );

  const readAppUsersRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.readAppUsers
  );
  const readBackOfficeUsersRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.readBackOfficeUsers
  );
  const findAppUserRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.findAppUser
  );
  const findBackOfficeUserRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.findBackOfficeUser
  );

  const readAppUsers: UseUser['readAppUsers'] = useCallback(
    async (page?: number, username?: string, email?: string, sort?: string) => {
      dispatch(userActions.readAppUsersRequest());
      let response: unknown;

      try {
        response = await UserRepository.getAllAppUsers(page, username, email, sort);
      } catch (error) {
        console.warn(error);
        dispatch(userActions.readAppUsersError({ error: new RequestError(error as AxiosError) }));
      }

      const parseResult = AppUsersApiResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          userActions.readAppUsersParseError({
            parseError: new RequestParseError(parseResult.error, 'UseUser.readAppUsers')
          })
        );
        return;
      }

      dispatch(userActions.readAppUsersSuccess(response as AppUsersApiResponse));
    },
    [dispatch]
  );
  const findAppUser: UseUser['findAppUser'] = useCallback(
    async (userId: number) => {
      dispatch(userActions.findAppUserRequest());
      let response: unknown;

      try {
        response = await UserRepository.findAppUser(userId);
      } catch (error) {
        dispatch(
          userActions.findAppUserError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }
      const parseResult = AppUserSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          userActions.findAppUserParseError({
            parseError: new RequestParseError(parseResult.error, 'UseUser.findAppUser')
          })
        );
        return;
      }
      dispatch(userActions.findAppUserSuccess());
      return response as AppUser;
    },
    [dispatch]
  );
  const readBackOfficeUsers: UseUser['readBackOfficeUsers'] = useCallback(
    async (page?: number, email?: string, roleId?: number, sort?: string) => {
      dispatch(userActions.readBackOfficeUsersRequest());
      let response: unknown;

      try {
        response = await UserRepository.getAllBackOfficeUsers(page, email, roleId, sort);
      } catch (error) {
        console.warn(error);
        dispatch(
          userActions.readBackOfficeUsersError({ error: new RequestError(error as AxiosError) })
        );
      }

      const parseResult = BusinessUsersApiResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          userActions.readBackOfficeUsersParseError({
            parseError: new RequestParseError(parseResult.error, 'UseUser.readBackOfficeUsers')
          })
        );
        return;
      }

      dispatch(userActions.readBackOfficeUsersSuccess(response as BusinessUsersApiResponse));
    },
    [dispatch]
  );
  const findBackOfficeUser: UseUser['findBackOfficeUser'] = useCallback(
    async (userId: number) => {
      dispatch(userActions.findBackOfficeUserRequest());
      let response: unknown;

      try {
        response = await UserRepository.findBOUser(userId);
      } catch (error) {
        dispatch(
          userActions.findBackOfficeUserError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }
      const parseResult = BusinessUserSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          userActions.findBackOfficeUserParseError({
            parseError: new RequestParseError(parseResult.error, 'UseUser.findBackOfficeUser')
          })
        );
        return;
      }
      dispatch(userActions.findBackOfficeUserSuccess());
      return response as BusinessUser;
    },
    [dispatch]
  );

  const addUserRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.addUser
  );

  const addUser: UseUser['addUser'] = useCallback(
    async (userType: string, userRequestData: BusinessUserAdd | AppUserRequest) => {
      dispatch(userActions.addUserRequest());

      try {
        await UserRepository.createUser(userType, userRequestData);
      } catch (error) {
        dispatch(
          userActions.addUserError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(userActions.addUserSuccess());
      return true;
    },
    [dispatch]
  );

  const updateUserRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.updateUser
  );

  const updateUser: UseUser['updateUser'] = useCallback(
    async (
      userType: string,
      userId: number,
      userRequestData: AppUserRequest | BusinessUserEdit
    ) => {
      dispatch(userActions.updateUserRequest());

      try {
        await UserRepository.updateUser(userType, userId, userRequestData);
      } catch (error) {
        dispatch(
          userActions.updateUserError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(userActions.updateUserSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteUserRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.deleteUser
  );

  const deleteUser: UseUser['deleteUser'] = useCallback(
    async (userType: string, userId: number) => {
      dispatch(userActions.deleteUserRequest());

      try {
        await UserRepository.deleteUser(userType, userId);
      } catch (error) {
        dispatch(
          userActions.deleteUserError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(userActions.deleteUserSuccess());
      return true;
    },
    [dispatch]
  );

  const userRoles = useSelector((state: RootState) => state.BEInteraction.user.userRoles);
  const readUserRolesRequest = useSelector(
    (state: RootState) => state.BEInteraction.user.requests.readUserRoles
  );
  const readUserRoles: UseUser['readUserRoles'] = useCallback(async () => {
    dispatch(userActions.readUserRolesRequest());
    let response: unknown;

    try {
      response = await UserRepository.getUserRoles();
    } catch (error) {
      console.warn(error);
      dispatch(userActions.readUserRolesError({ error: new RequestError(error as AxiosError) }));
    }

    const parseResult = RolesAPIResponseSchema.safeParse(response);
    if (!parseResult.success) {
      dispatch(
        userActions.readUserRolesParseError({
          parseError: new RequestParseError(parseResult.error, 'UseUser.readUserRoles')
        })
      );
      return;
    }

    dispatch(userActions.readUserRolesSuccess(response as Role[]));
  }, [dispatch]);

  const readMeRequest = useSelector((state: RootState) => state.BEInteraction.user.requests.readMe);
  const me = useSelector((state: RootState) => state.BEInteraction.user.me);
  const readMe: UseUser['readMe'] = useCallback(async () => {
    dispatch(userActions.readMeRequest());
    let response: unknown;

    try {
      response = await UserRepository.getMe();
    } catch (error) {
      dispatch(
        userActions.readMeError({
          error: new RequestError(error as AxiosError)
        })
      );
      return;
    }
    const parseResult = BusinessUserSchema.safeParse(response);
    if (!parseResult.success) {
      dispatch(
        userActions.readMeParseError({
          parseError: new RequestParseError(parseResult.error, 'UseUser.readMe')
        })
      );
      return;
    }
    dispatch(userActions.readMeSuccess(response as BusinessUser));
    return response as BusinessUser;
  }, [dispatch]);

  return {
    appUsers,
    readAppUsers,
    readAppUsersRequest,
    findAppUser,
    findAppUserRequest,

    backOfficeUsers,
    readBackOfficeUsers,
    readBackOfficeUsersRequest,
    findBackOfficeUser,
    findBackOfficeUserRequest,

    addUser,
    addUserRequest,

    updateUser,
    updateUserRequest,

    deleteUser,
    deleteUserRequest,

    userRoles,
    readUserRoles,
    readUserRolesRequest,

    me,
    readMe,
    readMeRequest
  };
}
