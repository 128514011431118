class CountFormatter {
  formatThousand(count: number) {
    if (count >= 1000 && count < 1000000) {
      return Math.floor(count / 1000) + 'k';
    } else return count;
  }

  formatMilions(count: number) {
    if (count >= 1000000 && count < 1000000000) {
      return Math.floor(count / 1000000) + 'm';
    } else return count;
  }

  formatBillions(count: number) {
    if (count >= 1000000000 && count < 1000000000000) {
      return Math.floor(count / 1000000000) + 'b';
    } else return count;
  }

  format(count: number) {
    if (count >= 1000 && count < 1000000) {
      return this.formatThousand(count);
    } else if (count >= 1000000 && count < 1000000000) {
      return this.formatMilions(count);
    } else if (count >= 1000000000 && count < 1000000000000) {
      return this.formatBillions(count);
    } else return count;
  }
}

export default new CountFormatter();
