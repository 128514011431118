import * as React from 'react';
import { useState } from 'react';
import {
  AccountCircle,
  ChevronLeft,
  Email,
  Key,
  Send,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import FormGroup from '@mui/material/FormGroup';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../assets/Actifyre_logo.svg';
import { ReactComponent as TextLogo } from '../../../assets/Actifyre_logo_text.svg';
import useAuthentication from '../../hooks/useAuthentication';
import { DarkBox, FlexBox } from '../components/StyledComponents';

export default function Login() {
  const { t } = useTranslation();
  const { signIn, signInRequest, resetBusinessPassword, resetBusinessPasswordRequest } =
    useAuthentication();
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);

  const [pswVisible, setPswVisible] = useState(false);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [usernameErr, setUsernameErr] = useState<string>();
  const [passwordErr, setPasswordErr] = useState<string>();

  const [pswReecoveryMode, setPswReecoveryMode] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState<string>();

  const login = React.useCallback(async () => {
    setUsernameErr(undefined);
    setPasswordErr(undefined);
    if (!username) {
      setUsernameErr('Required field');
      return;
    }
    if (!password) {
      setPasswordErr('Required field');
      return;
    }
    await signIn(username, password);
  }, [username, password, signIn]);

  const sendPswReecoveryEmail = async () => {
    if (recoveryEmail) {
      const success = await resetBusinessPassword(recoveryEmail);
      if (success) setSuccessDialogVisible(true);
    }
  };

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#43c4ec',
            light: '#90DDF4',
            dark: '#16B0DF'
          },
          secondary: {
            main: '#43ebb5',
            light: '#91F3D4',
            dark: '#17DE9F'
          },
          tertiary: {
            main: '#002a4d',
            light: '#0065B8',
            dark: '#001629'
          },
          background: {
            default: '#001b32',
            paper: '#043c6b'
          },
          mode: 'dark'
        }
      })}>
      <DarkBox
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <FlexBox
          flexDirection="column"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '25%',
            minWidth: 400,
            mb: '20vh'
          }}>
          {/* Logo */}
          {/* <Logo style={{ color: 'red' }} /> */}
          <TextLogo style={{ marginTop: 18 }} />

          {/* Credentials input banner */}
          <Card
            variant="outlined"
            sx={{
              padding: 6,
              marginTop: 4,
              width: '100%'
            }}>
            {!pswReecoveryMode ? (
              <FormGroup
                onKeyUp={(e) => {
                  if (e.key === 'Enter') login();
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    color: 'white'
                  }}>
                  <Grow
                    in={Boolean(signInRequest.httpError) || Boolean(signInRequest.parseError)}
                    mountOnEnter
                    unmountOnExit>
                    <Alert severity="error" variant="outlined">
                      {(
                        signInRequest.httpError?.axiosError.response?.data as {
                          error: { message: string };
                        }
                      )?.error.message ?? 'Parsing error occurred'}
                    </Alert>
                  </Grow>

                  <FlexBox alignItems="baseline">
                    <AccountCircle sx={{ color: 'white', mr: 3, transform: 'translateY(6px)' }} />
                    <TextField
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      label={t('authentication:username')}
                      variant="standard"
                      fullWidth
                      error={Boolean(usernameErr)}
                      helperText={usernameErr}
                      sx={{
                        '& input': {
                          color: 'white'
                        },
                        '& input:-webkit-autofill': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        },
                        '& input:-webkit-autofill:focus': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        }
                      }}
                    />
                  </FlexBox>

                  <FlexBox flexDirection="column">
                    <Box display="flex" flexDirection="row" alignItems="baseline">
                      <Key sx={{ color: 'white', mr: 3, transform: 'translateY(6px)' }} />
                      <FlexBox flexDirection="row" position="relative" width="100%">
                        <TextField
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={pswVisible ? 'text' : 'password'}
                          label={t('authentication:password')}
                          variant="standard"
                          fullWidth
                          error={Boolean(passwordErr)}
                          helperText={passwordErr}
                          sx={{
                            '& input': {
                              color: 'white'
                            },
                            '& input:-webkit-autofill': {
                              '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                              '-webkit-text-fill-color': `white !important`,
                              color: `white !important`
                            },
                            '& input:-webkit-autofill:focus': {
                              '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                              '-webkit-text-fill-color': `white !important`,
                              color: `white !important`
                            }
                          }}
                        />
                        <IconButton
                          onClick={() => setPswVisible(!pswVisible)}
                          color="primary"
                          title={pswVisible ? 'Hide password' : 'Show password'}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: 12,
                            p: 0.8
                          }}>
                          {pswVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </FlexBox>
                    </Box>
                    <Button
                      variant="text"
                      disabled={signInRequest.inProgress}
                      onClick={() => setPswReecoveryMode(true)}
                      sx={{ alignSelf: 'flex-end', textTransform: 'none' }}>
                      {t('authentication:forgotPassword')}
                    </Button>
                  </FlexBox>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={signInRequest.inProgress}
                    onClick={login}>
                    {t('authentication:login')}
                  </LoadingButton>
                </Box>
              </FormGroup>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  color: 'white'
                }}>
                <Box>
                  <Typography textAlign="center" fontWeight="600" fontSize={28} letterSpacing={1.2}>
                    {t('authentication:recoverAccess')}
                  </Typography>

                  <Typography textAlign="center" fontSize={13}>
                    {t('authentication:recoverAccessInfo')}
                  </Typography>
                </Box>

                <Grow
                  in={
                    Boolean(resetBusinessPasswordRequest.httpError) ||
                    Boolean(resetBusinessPasswordRequest.parseError)
                  }
                  mountOnEnter
                  unmountOnExit>
                  <Alert severity="error" variant="outlined">
                    {(
                      resetBusinessPasswordRequest.httpError?.axiosError.response?.data as {
                        error: { message: string };
                      }
                    )?.error.message ?? 'An error occurred'}
                  </Alert>
                </Grow>

                <FlexBox alignItems="flex-end">
                  <Email sx={{ color: 'white', mr: 3, my: 0.5 }} />
                  <TextField
                    value={recoveryEmail}
                    onChange={(e) => setRecoveryEmail(e.target.value)}
                    label={t('common:email')}
                    size="small"
                    variant="standard"
                    fullWidth
                    placeholder={t('common:emailPlaceholder')}
                  />
                </FlexBox>

                <FlexBox justifyContent="space-between">
                  <Button
                    startIcon={<ChevronLeft />}
                    variant="text"
                    onClick={() => setPswReecoveryMode(false)}>
                    {t('authentication:returnToLogin')}
                  </Button>
                  <LoadingButton
                    loading={resetBusinessPasswordRequest.inProgress}
                    endIcon={<Send />}
                    variant="contained"
                    onClick={() => sendPswReecoveryEmail()}
                    loadingIndicator={<CircularProgress size={20} />}>
                    {t('common:send')}
                  </LoadingButton>
                </FlexBox>

                {/* Success Dialog */}
                <Dialog
                  open={successDialogVisible}
                  onClose={() => {
                    setSuccessDialogVisible(false);
                    setPswReecoveryMode(false);
                  }}>
                  <Alert sx={{ p: 3 }} severity="success" variant="outlined">
                    <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {t('common:congrats')}
                    </AlertTitle>
                    <DialogContentText>
                      {t('authentication:resetPasswordRequestSuccessInfo')}
                    </DialogContentText>
                    <DialogActions sx={{ pb: 0 }}>
                      <Button
                        onClick={() => {
                          setSuccessDialogVisible(false);
                          setPswReecoveryMode(false);
                        }}
                        color="inherit">
                        Ok
                      </Button>
                    </DialogActions>
                  </Alert>
                </Dialog>
              </Box>
            )}
          </Card>
        </FlexBox>

        {/* Version Banner */}
        <Typography
          color="white"
          margin={2}
          fontWeight={600}
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            opacity: 0.6
          }}>
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </DarkBox>
    </ThemeProvider>
  );
}
