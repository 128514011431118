/* eslint-disable prefer-regex-literals */
import React, { useContext, useEffect, useState } from 'react';
import {
  Check,
  DeleteForever,
  InfoOutlined,
  Key,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppUser, AppUserRequest } from '../../../../entities/User';
import useUser from '../../../../hooks/useUser';
// import PathFormatter from '../../../../utils/PathFormatter';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../components/Breadcrumbs';
import EditableCard from '../../../components/EditableCard';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
// import UploadImg from '../../../components/UploadImg';

interface UserProps {
  mode: 'create' | 'edit';
}

export default function AddEditAppUser(props: UserProps) {
  const {
    findAppUser,
    addUser,
    addUserRequest,
    updateUser,
    updateUserRequest,
    deleteUser,
    deleteUserRequest
  } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');

  const [data, setData] = useState<AppUser>();
  const [edited, setEdited] = useState(false);
  const [deleteUserDialogOpened, setDeleteUserDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);
  const [pswVisible, setPswVisible] = useState(false);

  const [email, setEmail] = useState(data?.email);
  const [username, setUsername] = useState<string | undefined | null>('');
  const [password, setPassword] = useState<string | undefined | null>('');
  const [confirmationPassword, setConfirmationPassword] = useState<string | undefined | null>('');
  // const [image, setImage] = useState<string | undefined | null>(data?.image ?? null);

  const [emailErr, setEmailErr] = useState<string>();
  const [usernameErr, setUsernameErr] = useState<string>();
  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmationPasswordErr, setConfirmationPasswordErr] = useState<string>();

  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('users:addUser'),
            path: `/users/app/new_user`
          }
        : {
            title: t('users:editUser'),
            path: `/users/app/${userId}/edit`
          };
    }, [userId, props.mode])
  );

  const fecthUserData = async () => {
    if (userId) {
      const userData = await findAppUser(parseInt(userId));
      if (userData) setData(userData);
    }
  };

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  useEffect(() => {
    if (props.mode === 'edit') {
      fecthUserData();
    }
  }, [userId]);

  useEffect(() => {
    if (data) {
      setEmail(data.email);
      setUsername(data.username);
      // setImage(data.image);
    }
  }, [data]);

  const validation = () => {
    let validated = true;
    if (!username) {
      setUsernameErr(t('errors:obligatoryField'));
      validated = false;
    }
    if (!email && validated) {
      setEmailErr(t('errors:obligatoryField'));
      validated = false;
    }
    const emailRe = new RegExp('^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}');
    if (email && !email.match(emailRe) && validated) {
      setEmailErr(t('errors:invalidEmail'));
      validated = false;
    }
    if (!password && validated) {
      setPasswordErr(t('errors:obligatoryField'));
      validated = false;
    }
    const pswRe = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    if (password && !password.match(pswRe) && validated) {
      setPasswordErr(t('errors:notValidPsw'));
      validated = false;
    }
    if (!confirmationPassword && validated) {
      setConfirmationPasswordErr(t('errors:obligatoryField'));
      validated = false;
    }
    if ((!password || !confirmationPassword || password !== confirmationPassword) && validated) {
      setPasswordErr(t('errors:doubleCheckPsw'));
      setConfirmationPasswordErr(t('errors:doubleCheckPsw'));
      validated = false;
    }
    return validated;
  };

  const triggerAddUser = async () => {
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && password) {
      let payload: AppUserRequest;
      if (email && username) {
        payload = {
          email,
          // image,
          password,
          username
        };
      } else return;

      const success = await addUser('users', payload);
      openSnackBar(success, t('users:snackbar:addUserSuccess'), t('users:snackbar:addUserError'));
      if (success) navigate(-1);
    }
  };

  const triggerUpdateUser = async () => {
    if (!userId) return;
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && password) {
      let payload: AppUserRequest;
      if (username && email) {
        payload = {
          username,
          password,
          // image,
          email
        };
      } else return;
      const success = await updateUser('users', parseInt(userId), payload);
      openSnackBar(success, t('users:snackbar:editUserSuccess'), t('users:snackbar:editUserError'));
    }
  };

  const triggerDeleteUser = async () => {
    if (userId) {
      const success = await deleteUser('users', parseInt(userId));
      openSnackBar(
        success,
        t('users:snackbar:deleteUserSuccess'),
        t('users:snackbar:deleteUserError')
      );
      setDeleteUserDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between">
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {props.mode === 'create' ? t('users:addUser') : t('users:editUser')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('users:texts:createPageDescription')
                : t('users:texts:editUsersPageDescription')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addUserRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddUser}>
                {t('users:addUser')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data}
                  onClick={() => setDeleteUserDialogOpen(true)}>
                  {t('users:deleteUser')}
                </Button>
                <LoadingButton
                  loading={updateUserRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateUser}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      <FlexBox
        flexDirection={'row'}
        gap={2}
        flex={1}
        alignItems="flex-start"
        width="90%"
        margin="auto">
        {props.mode === 'create' || (props.mode === 'edit' && data) ? (
          <>
            <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              title={t('users:formPageSections:info')}
              icon={<InfoOutlined color="primary" />}
              headerSx={{
                pr: 0
              }}>
              <Stack gap={2} p={2} pt={0}>
                {/* Image (Not supported by BE) */}
                {/* <InfoText>
                  <p>{t('users:formInputHelperTexts:setImage')}</p>
                </InfoText>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    border: `1px solid rgba(0, 0, 0, 0.23)`,
                    position: 'relative'
                  }}>
                  <label
                    style={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '1rem',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      transform: 'translate(8px, -9px) scale(0.75)',
                      transformOrigin: 'top left',
                      background: 'white',
                      paddingInline: 6,
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      letterSpacing: '0.00938em'
                    }}>
                    {t('users:formInputLabels:image')}
                  </label>
                  <UploadImg
                    title={`${image ? 'change' : 'upload'} image`}
                    remoteFileName={
                      data?.image ? PathFormatter.absoluteToRelative(data?.image) : undefined
                    }
                    onDelete={() => setImage(data?.image)}
                    onUploaded={(filename) => {
                      setImage(filename);
                      setEdited(filename !== data?.image);
                    }}
                  />
                </Box> */}

                {/* Username */}
                <InfoText>
                  <p>{t('users:formInputHelperTexts:setUsername')}</p>
                </InfoText>
                <TextField
                  size="small"
                  label={t('users:username')}
                  error={Boolean(usernameErr)}
                  helperText={usernameErr}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={username}
                  onChange={(e) => {
                    const newTextValue = e.target.value;
                    setUsername(newTextValue);
                    setUsernameErr(undefined);
                    setEdited(newTextValue !== data?.username);
                  }}
                />

                {/* email */}
                <InfoText>
                  <p>{t('users:formInputHelperTexts:insertEmail')}</p>
                </InfoText>
                <TextField
                  size="small"
                  label={t('users:formInputLabels:email')}
                  error={Boolean(emailErr)}
                  helperText={emailErr}
                  InputLabelProps={{ shrink: true }}
                  value={email}
                  autoComplete="new-email"
                  onChange={(e) => {
                    const newTextValue = e.target.value;
                    setEmail(newTextValue);
                    setEmailErr(undefined);
                    setEdited(true);
                  }}
                />
              </Stack>
            </EditableCard>

            <EditableCard
              hideHeaderDivider
              sx={{ flex: 1 }}
              bodySx={{ gap: 2, p: 2, pt: 0, display: 'flex', flexDirection: 'column' }}
              title={t('users:formPageSections:setPassword')}
              icon={<Key color="primary" />}>
              {/* Password */}
              <InfoText>
                <p>{t('users:formInputHelperTexts:setPassword')}</p>
                <p>{t('users:formInputHelperTexts:passwordCostraints')}</p>
              </InfoText>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <FlexBox flexDirection="row" position="relative" width="100%">
                  <TextField
                    size="small"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordErr(undefined);
                      setConfirmationPasswordErr(undefined);
                      setEdited(true);
                    }}
                    type={pswVisible ? 'text' : 'password'}
                    label={t('authentication:password')}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(passwordErr)}
                    helperText={passwordErr}
                    fullWidth
                    autoComplete="new-password"
                  />
                  <IconButton
                    onClick={() => setPswVisible(!pswVisible)}
                    color="primary"
                    title={pswVisible ? 'Hide password' : 'Show password'}
                    sx={{
                      position: 'absolute',
                      right: 0
                    }}>
                    {pswVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FlexBox>
              </Box>

              {/* Confirm Password */}
              <InfoText>
                <p>{t('users:formInputHelperTexts:confirmPassword')}</p>
              </InfoText>
              <Box display="flex" flexDirection="row" alignItems="baseline">
                <FlexBox flexDirection="row" position="relative" width="100%">
                  <TextField
                    size="small"
                    value={confirmationPassword}
                    error={Boolean(confirmationPasswordErr)}
                    onChange={(e) => {
                      setConfirmationPassword(e.target.value);
                      setConfirmationPasswordErr(undefined);
                      setEdited(true);
                    }}
                    type={pswVisible ? 'text' : 'password'}
                    label={t('users:formInputLabels:confirmPassword')}
                    InputLabelProps={{ shrink: true }}
                    helperText={confirmationPasswordErr}
                    fullWidth
                    autoComplete="new-confirmationPassword"
                  />
                  <IconButton
                    onClick={() => setPswVisible(!pswVisible)}
                    color="primary"
                    title={pswVisible ? 'Hide password' : 'Show password'}
                    sx={{
                      position: 'absolute',
                      right: 0
                    }}>
                    {pswVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FlexBox>
              </Box>
            </EditableCard>
          </>
        ) : (
          <ActivityIndicator />
        )}
      </FlexBox>

      {/* Form Errors Dialog */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:formsErrorInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog open={deleteUserDialogOpened} onClose={() => setDeleteUserDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:deleteUserInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteUserDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteUserRequest.inProgress}
              onClick={triggerDeleteUser}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
