import * as React from 'react';
import { Add, History, Tag } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RoleIds } from '../../../../entities/User';
import useBrand from '../../../../hooks/useBrand';
import useFlyers from '../../../../hooks/useFlyers';
import useUser from '../../../../hooks/useUser';
import { WAKAPAPA_ID } from '../../../../utils/Constants';
import DateFormatter from '../../../../utils/DateFormatter';
import ActivityIndicator from '../../../components/ActivityIndicator';
import useBreadcrumbs from '../../../components/Breadcrumbs/useBreadcrumbs';
import { DatePicker } from '../../../components/DateTimePicker';
import FlyerCard from '../../../components/FlyerCard';
import NoDataBanner from '../../../components/NoDataBanner';
import OrderSelection from '../../../components/OrderSelection';
import { FlexBox } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import AddEditFlyer from './AddEditFlyer';

export default function FlyersRouteGuard() {
  return (
    <Routes>
      <Route path="/" element={<Flyers />} />
      <Route path="/:brandId/add_flyer" element={<AddEditFlyer mode="create" />} />
      <Route path="/:brandId/:flyerId/edit" element={<AddEditFlyer mode="edit" />} />
    </Routes>
  );
}

function Flyers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brandFlyers, readFlyers, readFlyersRequest } = useFlyers();
  const { brands, readBrands, readBrandsRequest, currentBrand } = useBrand();
  const { me } = useUser();
  const [brandName, setBrandName] = React.useState<string>();

  // Filters
  const [brandId, setBrandId] = React.useState<number>(WAKAPAPA_ID);
  const [selectedBrand, setSelectedBrand] = React.useState<{
    label: string | undefined;
    logo: string | undefined;
    id: number | undefined;
  } | null>();
  const [sortValue, setSortValue] = React.useState<'default' | 'created_at'>('default');
  const [sortModeAsc, setSortModeAsc] = React.useState<boolean>(true);
  const [notUsed, setNotUsed] = React.useState<boolean>(false);
  const [expiresBefore, setExpiresBefore] = React.useState<number | null>(null);
  const [expiresAfter, setExpiresAfter] = React.useState<number | null>(null);

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:flyers'),
        path: '/flyers'
      };
    }, [t])
  );

  React.useEffect(() => {
    const fecthWakapapaBrand = async () => {
      const brands = await readBrands(undefined, 'wakapapa');
      if (brands) {
        setSelectedBrand(
          brands.find((b) => b.id === WAKAPAPA_ID)
            ? {
                label: brands.find((b) => b.id === WAKAPAPA_ID)?.name,
                logo: brands.find((b) => b.id === WAKAPAPA_ID)?.logo,
                id: brands.find((b) => b.id === WAKAPAPA_ID)?.id
              }
            : null
        );
        setBrandId(WAKAPAPA_ID);
      }
    };
    fecthWakapapaBrand();
  }, []);

  React.useEffect(() => {
    readBrands(undefined, brandName || undefined, 'name');
  }, [brandName]);

  const fecthFlyers = React.useCallback(
    async (page?: number) => {
      const range =
        !expiresAfter && !expiresBefore
          ? undefined
          : DateFormatter.toRange(expiresAfter, expiresBefore);

      await readFlyers(
        brandId,
        page,
        sortValue === 'default' ? undefined : `${sortModeAsc ? '' : '-'}${sortValue}`,
        range,
        notUsed || undefined
      );
    },
    [brandId, sortValue, notUsed, expiresAfter, expiresBefore, sortModeAsc]
  );

  React.useEffect(() => {
    fecthFlyers();
  }, [fecthFlyers]);

  return (
    <FlexBox flexDirection="column" p={3} pb={0} flex={1}>
      <FlexBox alignItems="center" justifyContent="space-between">
        <Box flex={1}>
          <Typography variant="h6" fontWeight="bold">
            {t('flyer:flyers')}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
            {me?.role?.id === RoleIds.ADMIN_USER_ID
              ? t('flyer:texts:flyersPageDescription')
              : t('flyer:texts:flyersPageDescriptionAsBrand')}
          </Typography>
        </Box>
      </FlexBox>

      <Toolbar
        my={4}
        leftcomponent={
          <FlexBox gap={1} rowGap={1.6} flexDirection="row" flexWrap="wrap" flex={'1 1 auto'}>
            {/* brand selection */}
            {me && me.role?.id !== RoleIds.BRAND_USER_ID && selectedBrand !== undefined && (
              <Autocomplete
                sx={{
                  flex: '1 0 180px'
                }}
                ListboxProps={{
                  style: {
                    maxHeight: '50vh'
                  }
                }}
                value={selectedBrand}
                options={brands.data.map((b) => ({ label: b.name, logo: b.logo, id: b.id }))}
                onChange={(e, value) => {
                  if (value?.id) {
                    setBrandId(value.id);
                    setSelectedBrand(value);
                  }
                }}
                onInput={_.debounce((e) => setBrandName(e.target.value), 800)}
                loading={readBrandsRequest.inProgress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('brand:brands')}
                    size="small"
                    sx={{ backgroundColor: 'white' }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <>
                      <li {...props}>
                        {option.logo && (
                          <img src={option.logo} style={{ maxWidth: 40, marginRight: 12 }} />
                        )}
                        <div>{option.label}</div>
                      </li>
                      {option.id === brands.data.at(brands.data.length - 1)?.id &&
                        !brands.pagination?.last && <div style={{ marginLeft: 16 }}>...</div>}
                    </>
                  );
                }}
              />
            )}

            {/* Not used filter */}
            <Button
              onClick={() => setNotUsed(!notUsed)}
              variant="outlined"
              sx={{
                backgroundColor: 'white',
                height: 40,
                color: 'gray',
                borderColor: '#c4c4c4',
                textTransform: 'none',
                flex: '1 0 180px',
                justifyContent: 'flex-start'
              }}
              startIcon={<Checkbox sx={{ ml: -0.7, mr: -1 }} checked={notUsed} />}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 14
                }}>
                {t('filters:flyersNotAssigned')}
              </Typography>
            </Button>

            {/* Sort filter */}
            <OrderSelection
              sortValue={sortValue}
              sortItems={[
                {
                  value: 'default',
                  label: t('filters:default'),
                  icon: ({ sx, fontSize }) => <Tag sx={sx} fontSize={fontSize} />
                },
                {
                  value: 'created_at',
                  label: t('filters:creationDate'),
                  icon: ({ sx, fontSize }) => <History sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                }
              ]}
              onSortValueChange={(value) => setSortValue(value as 'default' | 'created_at')}
              sortMode={sortModeAsc ? 'asc' : 'desc'}
              onSortModeClick={() => setSortModeAsc(!sortModeAsc)}
              sx={{
                flex: '1 0 220px',
                width: 220
              }}
            />

            <FlexBox flex={'2 0 360px'}>
              {/* Expires after */}
              <DatePicker
                label={`${t('filters:expiresAfter')}:`}
                value={expiresAfter}
                onChange={(value: number | null) => {
                  setExpiresAfter(value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    size="small"
                    sx={{
                      flex: '1 0 180px',
                      '& >div': {
                        backgroundColor: 'white',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
              {/* Expires before */}
              <DatePicker
                label={`${t('filters:expiresBefore')}:`}
                value={expiresBefore}
                minDate={expiresAfter}
                onChange={(value: number | null) => {
                  setExpiresBefore(value);
                }}
                renderInput={(params: any) => (
                  <TextField
                    size="small"
                    sx={{
                      flex: '1 0 180px',
                      ml: '-1px',
                      '& >div': {
                        backgroundColor: 'white',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </FlexBox>
          </FlexBox>
        }
        rightcomponent={
          <Button
            variant="contained"
            startIcon={<Add />}
            sx={{ color: 'white' }}
            onClick={() => navigate(`/flyers/${brandId}/add_flyer`)}>
            {`${t('flyer:addFlyer')} ${
              me && me.role?.id !== RoleIds.BRAND_USER_ID
                ? selectedBrand?.label
                : currentBrand?.name
            }`}
          </Button>
        }
      />

      {readFlyersRequest.inProgress ? (
        <ActivityIndicator />
      ) : brandFlyers.brandId === brandId && Boolean(brandFlyers.flyers.length) ? (
        <FlexBox
          width="88%"
          m="auto"
          flexDirection="row"
          flex={'1 1 0'}
          minHeight={0}
          gap={1}
          pb={9}
          flexWrap="wrap"
          alignItems={'flex-start'}>
          {brandFlyers.flyers.map((f) => (
            <Box key={`flyer-${f.id}`} sx={{ flex: '0 0 calc(20% - 6.4px)', height: '49%' }}>
              <FlyerCard
                data={f}
                brandId={brandId}
                toolbar
                sx={{ maxHeight: '100%', minWidth: 160, mx: 'auto' }}
              />
            </Box>
          ))}
          <Pagination
            count={brandFlyers.pagination?.totalPages}
            page={brandFlyers.pagination?.number ? brandFlyers.pagination?.number + 1 : 1}
            onChange={(e, page) => fecthFlyers(page - 1)}
            shape="rounded"
            sx={{
              position: 'fixed',
              zIndex: 2,
              bottom: 0,
              right: '50%',
              transform: 'translateX(calc(50% + 35px))',
              p: 1.5
            }}
          />
        </FlexBox>
      ) : (
        <NoDataBanner />
      )}
    </FlexBox>
  );
}
