import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import FlyerRepository from '../api/repositories/FlyerRepository';
import {
  Flyer,
  FlyerRequest,
  FlyersApiResponse,
  FlyersAPIResponseSchema,
  FlyerSchema
} from '../entities/Flyer';
import { flyersActions } from '../redux/be_interaction_store/slices/flyer';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type FlyerState = RootState['BEInteraction']['flyer'];

type UseFlyers = {
  flyers: FlyerState['allFlyers'];
  brandFlyers: FlyerState['brandFlyers'];
  readFlyers: (
    brandId?: number,
    page?: number,
    sort?: string,
    expiredAt?: string,
    notUsed?: boolean
  ) => Promise<void>;
  readFlyersRequest: FlyerState['requests']['readFlyers'];

  findFlyer: (flyerId: number) => Promise<Flyer | void>;
  findFlyerRequest: FlyerState['requests']['findFlyer'];

  addFlyer: (flyerRequestData: FlyerRequest) => Promise<boolean>;
  addFlyerRequest: FlyerState['requests']['addFlyer'];

  updateFlyer: (flyerId: number, flyerRequestData: FlyerRequest) => Promise<boolean>;
  updateFlyerRequest: FlyerState['requests']['updateFlyer'];

  deleteFlyer: (flyerId: number) => Promise<boolean>;
  deleteFlyerRequest: FlyerState['requests']['deleteFlyer'];
};

export default function useFlyers(): UseFlyers {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      FlyerRepository.updateAuthToken(accessToken);
      FlyerRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const flyers = useSelector((state: RootState) => state.BEInteraction.flyer.allFlyers);
  const brandFlyers = useSelector((state: RootState) => state.BEInteraction.flyer.brandFlyers);

  const readFlyersRequest = useSelector(
    (state: RootState) => state.BEInteraction.flyer.requests.readFlyers
  );
  const findFlyerRequest = useSelector(
    (state: RootState) => state.BEInteraction.flyer.requests.findFlyer
  );
  const addFlyerRequest = useSelector(
    (state: RootState) => state.BEInteraction.flyer.requests.addFlyer
  );
  const updateFlyerRequest = useSelector(
    (state: RootState) => state.BEInteraction.flyer.requests.updateFlyer
  );
  const deleteFlyerRequest = useSelector(
    (state: RootState) => state.BEInteraction.flyer.requests.deleteFlyer
  );

  const readFlyers: UseFlyers['readFlyers'] = useCallback(
    async (
      brandId?: number,
      page?: number,
      sort?: string,
      expiredAt?: string,
      notUsed?: boolean
    ) => {
      dispatch(flyersActions.readFlyersRequest());
      let response: unknown;

      try {
        if (brandId) {
          response = await FlyerRepository.getByBrand(brandId, page, sort, expiredAt, notUsed);
        } else {
          response = await FlyerRepository.getAll();
        }
      } catch (error) {
        dispatch(
          flyersActions.readFlyersError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }

      const parseResult = FlyersAPIResponseSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          flyersActions.readFlyersParseError({
            parseError: new RequestParseError(parseResult.error, 'UseFlyers.readFlyers')
          })
        );
        return;
      }
      if (brandId)
        dispatch(
          flyersActions.readBrandFlyersSuccess({
            brandId,
            data: response as FlyersApiResponse
          })
        );
      else
        dispatch(
          flyersActions.readFlyersSuccess((response as FlyersApiResponse).content as Flyer[])
        );
    },
    [dispatch]
  );

  const findFlyer: UseFlyers['findFlyer'] = useCallback(
    async (flyerId: number) => {
      dispatch(flyersActions.findFlyerRequest());
      let response: unknown;

      try {
        response = await FlyerRepository.find(flyerId);
      } catch (error) {
        dispatch(
          flyersActions.findFlyerError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }
      const parseResult = FlyerSchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          flyersActions.findFlyerParseError({
            parseError: new RequestParseError(parseResult.error, 'UseFlyers.findFlyer')
          })
        );
        return;
      }
      dispatch(flyersActions.findFlyerSuccess());
      return response as Flyer;
    },
    [dispatch]
  );

  const addFlyer: UseFlyers['addFlyer'] = useCallback(
    async (flyerRequestData: FlyerRequest) => {
      dispatch(flyersActions.addFlyerRequest());

      try {
        await FlyerRepository.add(flyerRequestData);
      } catch (error) {
        dispatch(
          flyersActions.addFlyerError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(flyersActions.addFlyerSuccess());
      return true;
    },
    [dispatch]
  );

  const updateFlyer: UseFlyers['updateFlyer'] = useCallback(
    async (flyerId: number, flyerRequestData: FlyerRequest) => {
      dispatch(flyersActions.updateFlyerRequest());

      try {
        await FlyerRepository.update(flyerId, flyerRequestData);
      } catch (error) {
        dispatch(
          flyersActions.updateFlyerError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(flyersActions.updateFlyerSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteFlyer: UseFlyers['deleteFlyer'] = useCallback(
    async (flyerId: number) => {
      dispatch(flyersActions.deleteFlyerRequest());

      try {
        await FlyerRepository.delete(flyerId);
      } catch (error) {
        dispatch(
          flyersActions.deleteFlyerError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(flyersActions.deleteFlyerSuccess());
      return true;
    },
    [dispatch]
  );

  return {
    flyers,
    brandFlyers,
    readFlyers,
    readFlyersRequest,

    findFlyer,
    findFlyerRequest,

    addFlyer,
    addFlyerRequest,

    updateFlyer,
    updateFlyerRequest,

    deleteFlyer,
    deleteFlyerRequest
  };
}
