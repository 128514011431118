import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { theme } from '../../theme';

export const DarkBox = styled(Box)`
  background: ${theme.palette.background.default};
  color: ${theme.palette.text.primary};
`;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const InfoText = (props: { children: React.ReactNode } & BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        color: 'text.secondary',
        fontSize: 13,
        fontStyle: 'italic',
        whiteSpace: 'pre-line',
        ...props.sx
      }}>
      {props.children}
    </Box>
  );
};

export const StyledTooltip = styled(
  ({ severity, className, ...props }: TooltipProps & { severity?: 'info' | 'error' }) => (
    <Tooltip
      classes={{ popper: className }}
      componentsProps={{
        tooltip: {
          sx: {
            p: 1.5,
            fontWeight: 'bold',
            fontFamily: 'revert-layer',
            borderRadius: 2,
            whiteSpace: 'pre-line'
          }
        },
        arrow: {
          sx: {
            color: (theme) =>
              !severity || severity === 'info'
                ? theme.palette.tertiary.main + 'e0'
                : theme.palette.error.main + 'e0'
          }
        }
      }}
      {...props}
    />
  )
)(({ theme, severity }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      !severity || severity === 'info'
        ? theme.palette.tertiary.main + 'e0'
        : theme.palette.error.main + 'e0',
    color: '#fff',
    boxShadow: theme.shadows[3],
    fontSize: 12
  }
}));
