import * as React from 'react';
import { DeleteForever, GroupAdd } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid, GridApi, GridCellValue } from '@mui/x-data-grid';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useCommunities from '../../../../hooks/useCommunities';
import useBreadcrumbs from '../../../components/Breadcrumbs/useBreadcrumbs';
import { SearchBar } from '../../../components/SearchBar';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, StyledTooltip } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import AddEditCommunity from './addEditCommunity';

export default function CommunitiesRouteGuard() {
  return (
    <Routes>
      <Route path="/" element={<Communities />} />
      <Route path="/add_community" element={<AddEditCommunity mode="create" />} />
      <Route path="/:communityId/edit" element={<AddEditCommunity mode="edit" />} />
    </Routes>
  );
}

function Communities() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    communities,
    readCommunities,
    readCommunitiesRequest,
    deleteCommunity,
    deleteCommunityRequest
  } = useCommunities();
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const { setSnackbar } = React.useContext(SnackbarContext);

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:communities'),
        path: '/communities'
      };
    }, [t])
  );

  const [deleteCommunityDialog, setDeleteCommunityDialog] = React.useState({
    open: false,
    communityId: ''
  });

  // Filters
  const [nameKey, setNameKey] = React.useState<string>('');
  const [activationCodeKey, setActivationCodeKey] = React.useState<string>('');
  const [communitiesOrder, setCommunitiesOrder] = React.useState<string>('default');

  const fetchCommunities = React.useCallback(
    async (page?: number) => {
      await readCommunities(
        page,
        nameKey || undefined,
        activationCodeKey || undefined,
        communitiesOrder === 'default' ? undefined : communitiesOrder
      );
    },
    [activationCodeKey, nameKey, communitiesOrder]
  );

  React.useEffect(() => {
    fetchCommunities();
  }, [fetchCommunities]);

  const communitiesData = {
    rows: communities.data,
    pagination: communities.pagination,
    columns: [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'name',
        headerName: t('communities:formInputLabels:name'),
        flex: 1,
        headerClassName: 'pl-2em',
        cellClassName: 'pl-2em'
      },
      {
        field: 'code',
        headerName: t('communities:formInputLabels:code'),
        flex: 1
      },
      {
        field: 'numberOfUsers',
        headerName: t('communities:numberOfUsers'),
        flex: 1,
        sortable: false
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        width: 150,
        sortable: false,
        renderCell: (params: any) => {
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          const onEditClick = (e: any) => {
            e.stopPropagation();
            return navigate(`/communities/${thisRow.id}/edit`);
          };

          const onDeleteClick = (e: any) => {
            e.stopPropagation();
            return setDeleteCommunityDialog({
              open: true,
              communityId: thisRow.id as string
            });
          };

          return (
            <Stack direction="row" spacing={1}>
              <StyledTooltip title={t('common:edit')} arrow placement="left">
                <IconButton aria-label="edit" onClick={onEditClick}>
                  <EditIcon />
                </IconButton>
              </StyledTooltip>
              {!thisRow.numberOfUsers && (
                <StyledTooltip title={t('common:delete')} arrow placement="right" severity="error">
                  <IconButton aria-label="delete" color="error" onClick={onDeleteClick}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTooltip>
              )}
            </Stack>
          );
        }
      }
    ],
    canAdd: false,
    request: readCommunitiesRequest,
    searchParam: 'name'
  };

  const triggerDeleteCommunity = async (communityId: string) => {
    if (communityId) {
      const success = await deleteCommunity(parseInt(communityId));
      openSnackBar(
        success,
        t('communities:snackbar:communityDeleteSuccess'),
        t('communities:snackbar:communityDeleteError')
      );
      fetchCommunities(communities.pagination?.number);
    }
  };

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {t('communities:communitiesList')}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
          {t('communities:texts:communitiesListInfo')}
        </Typography>
      </Box>

      <Toolbar
        mt={4}
        mb={2}
        leftcomponent={
          <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
            <SearchBar
              backgroundColor="white"
              value={nameKey}
              loading={readCommunitiesRequest.inProgress}
              onChange={_.debounce((text) => setNameKey(text), 800)}
              placeholder={t('filters:searchByName')}
              sx={{ height: 19, border: '1px solid lightgray', width: 260 }}
            />
            <SearchBar
              backgroundColor="white"
              value={activationCodeKey}
              loading={readCommunitiesRequest.inProgress}
              onChange={_.debounce((text) => setActivationCodeKey(text), 800)}
              placeholder={t('filters:searchByActivationCode')}
              sx={{ height: 19, border: '1px solid lightgray', width: 260 }}
            />
          </FlexBox>
        }
        rightcomponent={
          <Button
            startIcon={<GroupAdd sx={{ mr: 1 }} />}
            variant="contained"
            sx={{
              color: 'white'
            }}
            onClick={() => {
              navigate(`/communities/add_community`);
            }}>
            {t('communities:addCommunity')}
          </Button>
        }
      />

      <Card
        sx={{
          flex: 1,
          mt: -0.2,
          typography: 'body1',
          mx: '6%',
          px: 2,
          pt: 1,
          borderRadius: 4,
          border: 'none'
        }}
        variant="outlined">
        {/* communities table */}
        <DataGrid
          rows={communitiesData.rows}
          pagination
          paginationMode="server"
          columns={communitiesData.columns}
          pageSize={communitiesData.pagination?.size}
          rowsPerPageOptions={[communitiesData.pagination?.size ?? 10]}
          page={communitiesData.pagination?.number}
          rowCount={communitiesData.pagination?.totalElements}
          onPageChange={(page) => fetchCommunities(page)}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: true }}
          sx={{ border: 'none' }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sortData = sortModel[0];
            if (sortData) {
              setCommunitiesOrder(
                `${sortData.sort === 'desc' ? '-' : ''}${
                  sortData.field === 'code' ? 'activation_code' : sortData.field
                }`
              );
            } else setCommunitiesOrder('default');
          }}
          loading={readCommunitiesRequest.inProgress}
        />
      </Card>

      {/* Delete Community Dialog */}
      <Dialog
        open={deleteCommunityDialog.open}
        onClose={() => setDeleteCommunityDialog({ open: false, communityId: '' })}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('communities:texts:deleteCommunityInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button
              onClick={() => setDeleteCommunityDialog({ open: false, communityId: '' })}
              color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteCommunityRequest.inProgress}
              onClick={() => {
                if (deleteCommunityDialog.communityId) {
                  triggerDeleteCommunity(deleteCommunityDialog.communityId);
                }
                setDeleteCommunityDialog({ open: false, communityId: '' });
              }}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
