const it = {
  common: {
    languageName: 'Italiano',
    close: 'Chiudi',
    send: 'Invia',
    verify: 'Verifica ora',
    email: 'Email',
    emailPlaceholder: 'mariorossi@wakapapa.com',
    sort: 'Ordina',
    confirm: 'Conferma',
    cancel: 'Annulla',
    delete: 'Elimina',
    remove: 'Rimuovi',
    clear: 'Pulisci',
    create: 'Crea',
    restore: 'Ripristina',
    name: 'Nome',
    logo: 'Logo',
    no: 'No',
    yes: 'Si',
    attention: 'Attenzione',
    info: 'Info',
    informations: 'Informazioni',
    add: 'Aggiungi',
    edit: 'Modifica',
    publish: 'Pubblica',
    withdraw: 'Nascondi al pubblico',
    duplicate: 'Duplica',
    noData: 'Nessun dato',
    all: 'Tutti',
    confirmChanges: 'Conferma modifiche',
    copy: 'Copia',
    filter: 'Filtro',
    view: 'Visualizza',
    expired: 'Scaduta',
    congrats: 'Congratulazioni!',
    go: 'Vai',
    ascending: 'Ascendente',
    descending: 'Discendente',
    first: 'Primo',
    last: 'Ultimo',
    disabled: 'Disabilitato',
    activityIndicatorText: 'Un momento...',
    actions: 'Comandi'
  },
  errors: {
    obligatoryField: 'Questo campo è obbligatorio',
    compilationErrors: 'Sono presenti degli errori nella compilazione',
    obligatoryMarker: 'Per essere creata una Quest ha bisogno di avere almeno un segnaposto',
    everyTokenAssigned:
      'In questa modalità ogni segnaposto deve essere assegnato ad un token/volantino',
    moreFlyersThenMarkers: `Il numero di token/volantini selezionati per l'iniziativa deve essere uguale o superiore al numero di segnaposti`,
    obligatoryFlyersPosition: `Ogni volantino deve avere una posizione specifica assegnata`,
    passedEndDate: 'La data di fine non può essere passata',
    passedStartDate: 'La data di inizio non può essere passata',
    endDateBeforeStartDate: 'La data di fine non può essere antecedente a quella di inizio',
    obligatoryBadgeField: 'Obbligatorio se gli altri campi badge sono compilati',
    cannotDeleteBadge: `Non è possibile eliminare il badge se l'iniziativa è pubblica.`,
    cannotAddBadge: `Non è possibile aggiungere un nuovo badge se l'iniziativa è pubblica.`,
    fileSizeOvershoot: 'Le dimensioni del file superano quelle ammesse.',
    invalidEmail: 'Non sembra essere una mail valida.',
    doubleCheckPsw: 'Le password non corrispondono.',
    notValidPsw: 'La password inserita non è valida.',
    atLeast1Flyer: 'Deve essere selezionato almeno un volantino.',
    insertNumericValue: 'Inserire un carattere numerico.',
    notValidValue: 'Il valore inserito non è valido.',
    orderedFlyersValueBounds:
      'Il valore inserito deve essere compreso tra 1 e il numero totale di volantini selezionati.',
    orderedFlyersAlreadyInsertedValue:
      'Il valore inserito è già stato assegnato ad un altro volantino.'
  },
  filters: {
    showFilters: 'Mostra filtri',
    hideFilters: 'Nascondi filtri',
    sortBy: 'Ordinamento',
    alphabetic: 'Alfabetico',
    flyersNotAssigned: 'Solo non assegnati',
    default: 'Default',
    creationDate: 'Data creazione',
    startsAfter: 'Comincia dopo il',
    startsBefore: 'Comincia prima del',
    endsAfter: 'Scade dopo il',
    endsBefore: 'Scade prima del',
    expiresAfter: 'Scade dopo il',
    expiresBefore: 'Scade prima del',
    activeUsers: 'Utenti attivi',
    publishedInitiatives: 'Solo pubblicate',
    userRole: 'Ruolo',
    searchByName: 'Cerca per nome',
    searchByUsername: 'Cerca per username',
    searchByMail: 'Cerca per email',
    searchByActivationCode: 'Cerca per codice di attivazione'
  },
  mailVerification: {
    title: 'Verifica la tua Mail',
    info: ' Clicca il bottone in basso per verificare la tua e-mail e completare la procedura di registrazione.',
    success: `L'indirizzo email è stato verificato con successo.`,
    failed: `Non è stato possibile verificare l'indirizzo email.`
  },
  authentication: {
    login: 'Entra',
    logout: 'Disconnettiti',
    username: 'Nome utente',
    password: 'Password',
    forgotPassword: 'Password dimenticata?',
    recoverAccess: 'Recupero Password',
    recoverAccessInfo: `Inserisci l'email associata al tuo account di back office wakapapa. Ti manderemo al più presto una mail contente un link per recuperare la password o per impostarne una nuova.`,
    returnToLogin: 'Ritorna al login',
    newPassword: 'Nuova Password',
    confirmNewPassword: 'Conferma Nuova Password',
    resetPasswordSuccess: 'Password resettata con successo',
    resetPasswordFailure: "L'operazione di reset non è andata a buon fine",
    changePassword: 'Cambia Password',
    resetRequestSuccess: 'Richiesta di reset password inviata con successo',
    resetRequestFailure: 'La richiesta di reset password non è andata a buon fine',
    resetPasswordRequestSuccessInfo: `La richiesta di recupero password è stata inviata correttamente. Controlla la casella email per completare la procedura.`,
    resetPasswordSuccessInfo: `La tua password è stata reimpostata correttamente, torna al Login per effettuare l'accesso`,
    deleteAccount: 'Cancella account'
  },
  navigation: {
    home: 'Home',
    communities: 'Comunità',
    flyers: 'Volantini',
    account: 'Account',
    settings: 'Impostazioni',
    users: 'Utenti',
    boUsers: 'Utenti Back Office',
    appUsers: 'Utenti App',
    trackingStatistics: 'Statistiche',
    externalUrl: 'Link esterno',
    externalUrlInfo:
      'Stai cercando di aprire il seguente link esterno. Se continui verrai direzionato ad un dominio differente in una nuova scheda. Continuare?'
  },
  settings: {
    chooseLanguage: 'Scegli lingua',
    pageTitle: 'Impostazioni Generali',
    pageSubtitle: `In questa pagina puoi modificare le impostazioini generali dell'interfaccia Back Office.`,
    languageOptions: 'Opzioni Lingua',
    languageOptionsInfo: `Cambia la lingua di visualizzazione dell'interfaccia. La scelta non andrà ad influenzare la procedura di compilazione di iniziative o volantini, in cui è possibile scegliere separatamente una lingua di inserimento specifica.`
  },
  brand: {
    brand: 'Brand',
    brands: 'Brand',
    allBrands: 'Tutti i brand',
    selectBrand: 'Seleziona un Brand',
    searchBrand: 'Cerca Brand',
    addBrand: 'Aggiungi Brand',
    addNewBrand: 'Aggiungi nuovo Brand',
    websiteLink: 'Link sito web',
    brandInfo: 'Informazioni brand',
    deleteBrand: 'Elimina brand',
    partnershipInfo: 'Informazioni partnership',
    texts: {
      brandSelectInfo:
        'Clicca su una carta della seguente lista per andare alla pagina di gestione del Brand.',
      brandNotFounfInfo: 'Non trovi il tuo Brand?',
      addBrandInfo:
        'Compila tutti i campi necessari per aggiungere un nuovo Brand al Back-office di Wakapapa.',
      deleteBrand: 'Vuoi veramente eliminare questo Brand? Questa operazione è irreversibile.',
      deleteBrandInfo: `Puoi decidere di rimuovere definitivamente questo brand dal Back Office di Wakapapa. Potrai sempre aggiungerlo nuovamente in seguito.`
    },
    formInputHelperTexts: {
      setBrandName: `Specifica il nome del Brand.`,
      setWebsiteLink: `Inserisci un link di riferimento al sito web del Brand (opzionale).`,
      setLogo: `Carica un'immagine (nera su sfondo bianco) di logo del Brand.`
    },
    snackbar: {
      addNewBrandFailure:
        'Si è verificato un errore nella procedura di aggiunta del Brand. Riprovare.',
      addNewBrandSuccess: 'Brand caricato con successo!',
      deleteBrandSuccess: 'Brand eliminato correttamente.',
      deleteBrandError: 'Si è verificato un problema. Riprovare.',
      editBrandSuccess: 'Modifica avvenuta con successo!',
      editBrandError: 'Si è verificato un problema. Riprovare.'
    }
  },
  asset: {
    asset: 'Asset',
    setDefaultAsset: 'Imposta default asset',
    noAsset: 'Nessun asset',
    assetList: 'Lista asset',
    showAllAssets: 'Mostra tutti gli assets',
    addAsset: 'Crea nuovo asset',
    editAsset: 'Modifica asset',
    deleteAsset: 'Elimina asset',
    texts: {
      selectAsset: 'Seleziona asset (o ricerca il nome del file)',
      mandatoryToPublishInitiatives:
        'Devi inserire obbligatoriamente un asset di default per il Brand se vuoi creare delle iniziative.',
      uploadAsset: 'Carica un asset',
      assetInfo: `Il modello 3D che viene renderizzato nella Realtà Aumentata in corrispondenza del token di cattura.`,
      clickToDownload: `Clicca sul link per scaricare il file asset di tipo USDZ.`,
      editAssetTitle: `Modifica asset`,
      createAssetTitle: `Nuovo asset`,
      editAssetDescription: `In questa pagina puoi modificare il modello di un asset già esistente. La modifica avrà ripercusssione su tutte le iniziative a cui è stato associato questo asset, che siano pubbliche oppure no.
         Una volta caricato il nuovo asset clicca sul bottone in alto a destra per confermare le modifiche.`,
      createAssetDescription:
        'In questa pagina puoi creare un nuovo asset associato al tuo brand. Una volta caricato il modello clicca sul bottone in alto a destra per confermare la creazione.',
      cannotCreateInitiatives: `Non puoi creare iniziative finchè non imposti un asset di default per questo brand.`,
      deleteAssetInfo: `Sei sicuro di voler cancellare questo Asset? Ogni iniziativa associata a questo asset tornerà a mostrare l'asset di default del Brand.`
    },
    snackbar: {
      addAssetSuccess: 'Asset aggiunto con successo!',
      addAssetError: `Si è verificato un errore e l'asset non è stato aggiunto, riprovare.`,
      editAssetSuccess: 'Asset aggiornato con successo!',
      editAssetError: `Si è verificato un errore e l'asset non è stato aggiornato, riprovare.`,
      deleteAssetSuccess: 'Asset eliminato con successo!',
      deleteAssetError: `Si è verificato un errore e l'asset non è stato eliminato, riprovare.`
    }
  },
  flyer: {
    flyer: 'Volantino',
    flyers: 'Volantini',
    selectFlyer: 'Seleziona volantino',
    searchFlyer: 'Cerca volantino',
    addFlyer: 'Aggiungi Volantino',
    editFlyer: 'Modifica Volantino',
    createFlyer: 'Crea nuovo Volantino',
    deleteFlyer: 'Elimina Volantino',
    dragFlyer: 'Trascina qui il volantino',
    dragFirstFlyer: 'Trascina qui il primo volantino',
    dragLastFlyer: 'Trascina qui il volantino finale',
    orderFlyers: 'Ordina i volantini selezionati',
    pageSections: {
      generalInfo: 'Informazioni generali',
      codes: 'Codici',
      duration: 'Durata',
      cost: 'Costo'
    },
    texts: {
      flyersPageDescription: `In questa pagina puoi visualizzare e gestire con facilità i volantini di qualsiasi Brand. Seleziona un Brand nella sezione filtri.`,
      flyersPageDescriptionAsBrand: `In questa pagina puoi visualizzare e gestire con facilità i volantini del tuo Brand.`,
      createPageDescription:
        'Crea un nuovo Volantino inserendo con cura i dati prestando attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.',
      editPageDescription:
        'In questa pagina puoi visualizzare e modificare i dati del Volantino: inserisci con cura i dati e presta attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per confermare le modifiche.',
      deleteFlyerInfo:
        'Vuoi veramente eliminare questo volantino? Questa operazione è irreversibile.',
      consistentBackErr: `Se il costo in waka per il volantino è maggiore di 0 deve necessariamente essere prensente anche un codice per il retro del volantino.`,
      notDeletable:
        'Il volantino non è eliminabile perchè associato a delle iniziative o campagne in corso.',
      orderFlyersInfo: `A ogni volantino deve essere assegnato un numero compreso tra 1 ed il totale dei volantini selezionati. Non è possibile inserire due volte lo stesso numero. Al completamento della compilazione ogni volantino dovrà avere una posizione adiacente a quella di quelli accanto a lui, quindi ogni volantino (fatta eccezione per gli estremi) dovrà esplicitare un numero intero compreso tra il suo immediato successore e il suo immediato predecessore (Es: 1-2-3 è valido, 1-3-4 invece no).`,
      flyerShare: `Complimenti, un utente ha condiviso un volantino con te! Per scoprire come utilizzarlo scarica ora l'app di Wakapapa.`
    },
    formInputLabels: {
      front: 'Fronte',
      back: 'Retro',
      code: 'Codice',
      qrCode: 'QrCode',
      wakaCost: 'Costo in waka',
      link: `Link dell'offerta`,
      expirationDate: 'Data di scadenza'
    },
    formInputHelperTexts: {
      rememberToCompileImageForEachLanguage: `Ricorda che questo campo (immagine) devono essere obbligatoriamente compilato per ogni lingua disponibile. Assicurati di caricare l'immagine corrispondente alla lingua corretta.`,
      setFronImage: `Inserisci l'immagine che verrà mostrata in realtà aumentata al momento della cattura del Token.`,
      setCode: `Imposta un codice promozionale per il del volantino (opzionale se il costo è 0).`,
      setQrCode: `Inserisci un link di riferimento che verrà successivamente codificato e visualizzato in app come immagine QR (opzionale).`,
      setOfferLink: `Inserisci un link di riferimento alla pagine dell'offerta (opzionale).`,
      specifyExpirationDate: `Specifica una data di scadenza (opzionale), dopo la quale il volantino non risulterà più valido nella realtà aumentata.`,
      setCost: `Imposta il costo in waka che l'utente dovrà spendere per salvare il volantino nel proprio portafoglio.`
    },
    snackbar: {
      flyerUpdateSuccess: 'Volantino modificato correttamente!',
      flyerUpdateError:
        'Si è verificato un problema e il Volantino non è stato aggiornato. Riprovare.',
      flyerAddSuccess: 'Volantino creato con successo!',
      flyerAddError: 'Si è verificato un problema e il Volantino non è stato creato. Riprovare.',
      flyerDeleteSuccess: 'Volantino eliminato!',
      flyerDeleteError:
        'Si è verificato un problema e il Volantino non è stato eliminato. Riprovare.'
    }
  },
  communities: {
    community: 'Community',
    communities: 'Community',
    communitiesList: 'Lista Community',
    numberOfUsers: 'Utenti Registrati',
    searchCommunity: 'Cerca Community',
    addCommunity: 'Aggiungi Community',
    editCommunity: 'Modifica Community',
    deleteCommunity: 'Cancella Community',
    info: 'Informazioni Community',
    texts: {
      createPageDescription:
        'Crea una nuova Community inserendo con cura i dati prestando attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.',
      editPageDescription:
        'In questa pagina puoi visualizzare e modificare i dati della Community: inserisci con cura i dati e presta attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per confermare le modifiche.',
      deleteCommunityInfo:
        'Vuoi veramente eliminare questa Community? Questa operazione è irreversibile.',
      communitiesListInfo:
        'In questa pagina puoi consultare la lista delle Community, aggiungerne di nuove o modificare quelle esistenti.'
    },
    formInputLabels: {
      name: 'Nome',
      code: 'Codice di Attivazione'
    },
    formInputHelperTexts: {
      insertName: 'Inserisci un nome di riferimento della community.',
      specifyCode:
        'Specifica un codice identificativo che accomuna tutti gli utenti della community. Preferibilmente deve essere corto e facilmente memorizzabile.'
    },
    snackbar: {
      communityUpdateSuccess: 'Community modificata correttamente!',
      communityUpdateError:
        'Si è verificato un problema e la Community non è stata aggiornata. Riprovare.',
      communityAddSuccess: 'Community creata con successo!',
      communityAddError:
        'Si è verificato un problema e la Community non è stata creata. Riprovare.',
      communityDeleteSuccess: 'Community eliminata!',
      communityDeleteError:
        'Si è verificato un problema e la Community non è stata eliminata. Riprovare.'
    }
  },
  account: {
    pageTitle: 'Impostazioni Account',
    pageSubtitle: `In questa pagina puoi visualizzare i dati del tuo account Back Office e modificare la tua password di accesso.`,
    email: 'Email',
    role: 'Ruolo',
    createdAt: 'Data di creazione',
    pageSections: {
      profileDetails: 'Dettagli Profilo',
      changePassword: 'Cambia Password',
      closeAccount: 'Cancella Account',
      changePasswordDescription:
        'Clicca sul pulsante per avviare la procedura di recupero della tua password.',
      closeAccountDescription:
        'Puoi decidere di rimuovere definitivamente il tuo account di Back Office Wakapapa.Clicca sul pulsante seguente per avviare la procedura.'
    }
  },
  initiatives: {
    initiatives: 'Iniziative',
    registeredUsers: 'Utenti registrati',
    changeLanguage: 'Scegli lingua di compilazione',
    fillForEachLanguage: 'Compila per ogni lingua',
    texts: {
      deleteInitiativeInfo:
        'Vuoi veramente eliminare questa iniziativa? Questa operazione è irreversibile.',
      changeLanguageInfo:
        'Cambia la lingua per compilare i campi testuali per ogni lingua disponibile in app.',
      fillForEachLanguageInfo:
        'Se la casella è spuntata, ogni campo testuale verrà compilato automaticamente per ogni lingua disponibile in app con il valore inserito nella lingua selezionata, sovrascrivendo quello che era già stato scritto.'
    },
    initiativeCardTooltipTexts: {
      publishInitiative: `Rendi pubblica questa iniziativa. Non sarà comunque visibile al pubblico fino al sopraggiungere della data di inizio impostata.`,
      cantPublishInitiative: `Non è possibile pubblicare un'iniziativa con data di inizio nel passato. Modificare prima la data di inizio dell'iniziativa.`,
      unpublishInitiative: `Annulla la pubblicazione di questa iniziativa prima che venga resa visibile al pubblico al momento della data di inizio.`,
      expiredInitiative: `L'iniziativa è scaduta. Puoi ancora duplicarla o consultare le sue specifiche.`,
      onGoingInitiative: `L'iniziativa è in corso.`,
      activeUsers: `Qui viene riportato il numero di utenti che finora si sono registrati all'iniziaitiva.`
    },
    formInputLabels: {
      name: 'Nome',
      description: 'Descrizione',
      descriptionImage: 'Immagine di descrizione',
      listDescriptionImage: 'Immagine di descrizione in lista',
      initiativeLogo: 'Logo iniziativa',
      goalDescription: 'Descrizione obiettivo',
      initiativeType: 'Tipo di iniziativa',
      startDate: 'Data inizio',
      endDate: 'Data fine',
      prizeTitle: 'Titolo premio (max 7 caratteri)',
      prizeDescription: 'Descrizione premio (max 30 caratteri)',
      unlockThreshold: 'Soglia di sblocco (n. token)',
      wakaPrize: 'Premio in Waka',
      wakaTokens: 'Waka per token',
      badgeName: 'Nome badge',
      badgeDescription: 'Descrizione badge',
      badgeImage: 'Immagine badge',
      noFlyersMode: 'Modalità senza volantini',
      advertising: 'Iniziativa pubblicitaria'
    },
    formInputHelperTexts: {
      rememberToCompileForEachLanguage: `Ricorda che questi campi (testuali) devono essere obbligatoriamente compilati per ogni lingua disponibile.`,
      insertNameAndDescription: `Per cominciare, inserisci il nome dell'iniziativa e una breve descrizione.`,
      insertName: `Per cominciare, inserisci un nome.`,
      insertLogo: `Inserisci un'immagine (nera su sfondo bianco) del logo del brand che promuove l'iniziativa.`,
      insertDescriptionImages: `Inserisci un'immagine che rappresenti al meglio l'iniziativa, e una sua versiona con proporzioni specifiche per la sua visualizzazione dentro la lista nell'app.`,
      specifyInitiativeType: `Specifica il tipo di iniziativa. Questo flag è puramente informativo e non produce alcun cambiamento effetivo.`,
      insertGoalDescription: `Inserisci una breve descrizione dell'obiettivo che si deve raggiungere.`,
      insertPrizeTitleAndDescription: `Specifica un titolo e una descrizione del premio che si ottiene una volta completata l'iniziativa.`,
      specifyUnlockThreshold: `Specifica il numero minimo di token che è necessario catturare per completare l'iniziativa, sbloccare il premio e il badge.`,
      unlockThresholdBounds: `Se il valore inserito è inferiore al numero di segnaposti, l'utente sblocca i premi una volta raggiunta la soglia indicata. Se invece, il valore inserito è maggiore del numero di segnaposti, allora la soglia verrà aggiornata con il numero di segnaposti inserito.`,
      specifyWakaTokensAndPrize: `Specifica le ricompense (in waka): sia per quando l'utente cattura un token, sia per il premio finale erogato quando completa l'iniziativa.`,
      selectDates: `Seleziona una data di inizio e di fine per l'iniziativa.`,
      rememberToPublish: `Ricorda che per rendere visibile l'iniziativa agli utenti è comunque necessario pubblicarla cliccando sul pulsante dedicato nella card di riepilogo.`,
      addBadge: `Aggiungi un badge (opzionale) di ricompensa che verrà mostrato una volta che l'utente avrà completato l'iniziativa. Ma attenzione: se pubblichi l'iniziativa non potrai più eliminare il badge inserito o aggiungerne uno nuovo.`,
      validBadge: `Un badge valido deve contenere obbligatoriamente un'immagine, un nome e una descrizione (quest'ultimi due compilati per ogni lingua disponibile).`,
      setMode: `Imposta la modalità dell'iniziativa.`,
      setAdvertising: `Specifica se questa iniziativa può essere popolata o meno anche dai volantini pubblicitari delle campagne di altri Brand.`,
      setAsset: `Seleziona un modello specifico di asset per questa iniziativa (opzionale). 
      
      Se nessun modello viene specificato, in corrispondenza dei token di cattura verrà mostrato il modello di asset generico del Brand.`
    },
    orders: {
      alphabetical: 'Alfabetico',
      startDate: 'Data di inizio',
      endDate: 'Data di fine',
      published: 'Pubblicazione'
    },
    snackbar: {
      initiativeUpdateSuccess: 'Iniziativa aggiornata con successo!',
      initiativeUpdateError: `Si è verificato un errore e l'iniziativa non è stata aggiornata, riprovare.`,
      initiativePublishSuccess: `L'iniziativa è stata pubblicata! Sarà visibile agli utenti al sopraggiungere della data di inizio.`,
      initiativePublishError: `Si è verificato un errore e l'iniziativa non è stata pubblicata.`
    },
    challenges: {
      challenges: 'Challenge',
      searchChallenge: 'Cerca Challenge',
      deleteChallenge: 'Elimina Challenge',
      createChallenge: 'Crea nuova Challenge',
      texts: {
        editChallenge:
          'In questa pagina puoi visualizzare e modificare i dati della Challenge: inserisci con cura i dati e presta attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per confermare le modifiche.',
        createChallenge:
          'Crea una nuova Challenge inserendo con cura i dati prestando attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.',
        modeSingleInfo: `Seleziona la modalità SINGLE se vuoi che una volta raggiunta la soglia di sblocco, l'utente raccolga il premio e la challenge si chiuda.`,
        modeRevolvingInfo: `Seleziona la modalità REVOLVING se vuoi che una volta raggiunta la soglia di sblocco, il contatore dei token si azzeri e l'utente possa partecipare nuovamente alla challenge per ottenere di nuovo il premio.`,
        noFlyersMode: `Clicca la casalla qui sotto per creare una Challenge senza volantini. I token potranno essere comunque catturati ma nessun volantino verrà mostrato all'utente.`
      },
      pageSections: {
        generalInfo: 'Informazioni generali',
        duration: 'Durata',
        prizes: 'Premi',
        brand: 'Brand',
        flyers: 'Volantini',
        badge: 'Badge',
        mode: 'Modalità'
      },
      snackbar: {
        challengeEditSuccess: 'Challenge modificata correttamente!',
        challengeEditError:
          'Si è verificato un problema e la Challenge non è stata aggiornata. Riprovare.',
        challengeAddSuccess: 'Challenge creata con successo!',
        challengeAddError:
          'Si è verificato un problema e la Challenge non è stata creata. Riprovare.',
        challengeDeleteSuccess: 'Challenge eliminata!',
        challengeDeleteError:
          'Si è verificato un problema e la Challenge non è stata eliminata. Riprovare.'
      }
    },
    quests: {
      quests: 'Quest',
      searchQuest: 'Cerca Quest',
      deleteQuest: 'Elimina Quest',
      createQuest: 'Crea nuova Quest',
      markerName: 'Nome segnaposto',
      addMarker: 'Aggiungi segnaposto',
      placeMarker: 'Posiziona segnaposto',
      moveMarker: 'Sposta segnaposto',
      texts: {
        editPageDescription:
          'In questa pagina puoi visualizzare e modificare i dati della Quest: inserisci con cura i dati e presta attenzione alla loro consistenza. Puoi posizionare i token sulla mappa e assegnargli i volantini. Una volta inseriti i dati clicca sul bottone in alto a destra per confermare le modifiche.',
        createPageDescription:
          'Crea una nuova Quest inserendo con cura i dati prestando attenzione alla loro consistenza. Puoi posizionare i token sulla mappa e assegnargli i volantini. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.',
        insertLocation: `Inserire le coordinate geografiche in formato numerico (es: 42.123456, 12.25324357)`
      },
      pageSections: {
        generalInfo: 'Informazioni generali',
        duration: 'Durata',
        prizes: 'Premi',
        brand: 'Brand',
        flyers: 'Volantini',
        locations: 'Luoghi',
        badge: 'Badge',
        mode: 'Modalità'
      },
      snackbar: {
        questEditSuccess: 'Quest modificata correttamente!',
        questEditError: 'Si è verificato un problema e la Quest non è stata aggiornata. Riprovare.',
        questAddSuccess: 'Quest creata con successo!',
        questAddError: 'Si è verificato un problema e la Quest non è stata creata. Riprovare.',
        questDeleteSuccess: 'Quest eliminata!',
        questDeleteError: 'Si è verificato un problema e la Quest non è stata eliminata. Riprovare.'
      },
      tokenAssignment: {
        mode: 'Modalità di assegnazione token',
        randomContent: 'Contenuto casuale',
        randomContentDescription: `Ogni volta che si cattura un token verrà mostrato un volantino pescato casualmente da quelli selezionati.`,
        firstFixed: 'Primo fissato',
        firstFixedDescription: `Si potrà decidere quale volantino mostrare alla cattura del primo token.`,
        lastFixed: 'Ultimo fissato',
        lastFixedDescription: `Si potrà decidere quale volantino mostrare alla cattura dell'ultimo token.`,
        firstAndLastFixed: 'Primo e ultimo fissati',
        firstAndLastFixedDescription: `Si potrà decidere quali volantini mostrare alla cattura del primo e ultimo token.`,
        tokenFixed: 'Fissato al segnaposto',
        tokenFixedDescription: `Ad ogni segnaposto (sulla mappa) deve essere assegnato un volantino specifico. Se selezioni più volantini di quelli che effettivamente assegni, questi non verranno inseriti nell'iniziativa.`,
        ordered: 'Ordinato',
        orderedDescription: `Si specifica un ordinamento per i volantini, i quali verranno mostrati in sequenza via via che i token vengono catturati.`
      }
    },
    campaigns: {
      campaigns: 'Campagne',
      newCampaign: 'Nuova campagna',
      searchCampaign: 'Cerca Campagna',
      createCampaign: 'Crea nuova Campagna',
      deleteCampaign: 'Elimina Campagna',
      pageSections: {
        generalInfo: 'Informazioni generali',
        duration: 'Durata'
      },
      snackbar: {
        campaignEditSuccess: 'Campagna modificata correttamente!',
        campaignEditError:
          'Si è verificato un problema e la Campagna non è stata aggiornata. Riprovare.',
        campaignAddSuccess: 'Campagna creata con successo!',
        campaignAddError:
          'Si è verificato un problema e la Campagna non è stata creata. Riprovare.',
        campaignDeleteSuccess: 'Campagna eliminata!',
        campaignDeleteError:
          'Si è verificato un problema e la Campagna non è stata eliminata. Riprovare.'
      },
      texts: {
        editCampaign:
          'In questa pagina puoi visualizzare e modificare i dati della Campagna Una volta modificati i dati clicca sul bottone in alto a destra per confermare le modifiche.',
        createCampaign: `In questa pagina puoi creare una Campagna: un particolare tipo di iniziativa che va ad inserire volantini all'interno delle iniziative di Wakapapa. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.`
      }
    }
  },
  contents: {
    contents: 'Contenuti'
  },
  users: {
    users: 'Utenti',
    appUsers: 'Utenti App Wakapapa',
    newUser: 'Nuovo utente',
    backOfficeUsers: 'Utenti Back Office',
    username: 'Nome Utente',
    community: 'Community',
    createdAt: 'Data Creazione',
    updatedAt: 'Data Ultima Modifica',
    usersLists: 'Liste Utenti',
    searchUser: 'Cerca Utente',
    addUser: 'Aggiungi Utente',
    earnedWaka: 'Waka Guadagnati',
    editUser: 'Modifica Utente',
    deleteUser: 'Elimina Utente',
    texts: {
      usersListsInfo:
        'Seleziona la tab per consultare la lista Utenti App, oppure la lista Utenti Back Office.',
      deleteUserInfo: 'Vuoi veramente eliminare questo Utente? Questa operazione è irreversibile.',
      createPageDescription:
        'Crea un nuovo Utente inserendo con cura i dati prestando attenzione alla loro consistenza. Una volta inseriti i dati clicca sul bottone in alto a destra per procedere alla creazione.',
      editUsersPageDescription: `In questa pagina è possibile visualizzare e modificare i dati dell'utente: inserisci i dati con attenzione e presta attenzione alla loro coerenza. Una volta compilato il tutto clicca sul pulsante in alto a destra per confermare le modifiche.`,
      editBusinessPageDescription: `In questa pagina è possibile modificare la password dell'utente. Una volta impostata la nuova password cliccare sul pulsante in alto a destra per confermare le modifiche.`,
      formsErrorInfo:
        'Alcuni campi presentano degli errori di compilazione oppure sono vuoti. Verifica di aver compilato ogni campo testuale e che i valori siano corretti.'
    },
    formPageSections: {
      info: 'Informazioni Utente',
      setPassword: 'Imposta password',
      privileges: 'Privilegi'
    },
    formInputLabels: {
      image: 'Immagine',
      email: 'Email',
      role: 'Ruolo',
      confirmPassword: 'Conferma Password'
    },
    formInputHelperTexts: {
      setImage: `Carica un'immagine che permette di identificare visiamente l'utente.`,
      setUsername: `Imposta il nome utente.`,
      insertEmail: `Imposta la mail con la quale l'utente esegue l'accesso all'applicativo.`,
      setPassword: `Imposta una password con la quale l'utente esegue l'accesso all'applicativo.`,
      passwordCostraints: `Una password valida deve essere:
      - Lunga almeno 8 caratteri;
      - Avere sia lettere maiuscole che minuscole;
      - Contenere almeno un numero;
      - Avere almeno un carattere speciale (!@#$%^&*...);
      `,
      confirmPassword: `Digita nuovamente la password per confermarla.`,
      setRole: `Imposta i privilegi di accesso per l'utente: si può asseggnare il ruolo di admin (Wakpapa) o di Brand.

       Il ruolo di admin ha in generale più poteri, permette di effettuare determinate modifiche alle iniziative e in generale possiede dei privilegi che un utente Brand invece non possiede.`,
      chooseBrand: `Per gli utenti Brand è obbligatorio selezionare un Brand di riferimento al quale l'utenza verrà associata.`
    },
    snackbar: {
      addUserSuccess: 'Utente aggiunto correttamente!',
      addUserError: `Si è verificato un problema e l'utente non è stato aggiunto. Riprovare.`,
      editUserSuccess: 'Utente aggiornato correttamente!',
      editUserError: `Si è verificato un problema e l'utente non è stato aggiornato. Riprovare.`,
      deleteUserSuccess: 'Utente eliminato correttamente!',
      deleteUserError: `Si è verificato un problema e l'utente non è stato eliminato. Riprovare.`
    }
  }
};

export default it;
