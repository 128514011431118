import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DictionaryString } from '../../../entities/Common';
import { Flyer } from '../../../entities/Flyer';
import { TokenRequest } from '../../../entities/Token';
import { EmptyDictionaryString } from '../../../i18n';

const initialState = {
  initiativeId: undefined as number | undefined,
  edited: false as boolean,
  noFlyersMode: false as boolean,
  inputData: {
    brandId: null as number | null,
    name: EmptyDictionaryString as DictionaryString,
    description: EmptyDictionaryString as DictionaryString,
    logo: null as string | null,
    prizeDescription: EmptyDictionaryString as DictionaryString,
    prizeTitle: EmptyDictionaryString as DictionaryString,
    startDate: null as number | null,
    endDate: null as number | null,
    unlockThreshold: null as number | null,
    descriptionImage: null as string | null,
    listDescriptionImage: null as string | null,
    wakaPrize: null as number | null,
    wakaTokens: null as number | null,
    goalDescription: EmptyDictionaryString as DictionaryString,
    published: false,
    initiativeTypeId: 1 as number,
    modeSlug: 'single' as 'single' | 'revolving',
    titleCollected: EmptyDictionaryString as DictionaryString,
    titleTotal: EmptyDictionaryString as DictionaryString,
    badge: {
      name: EmptyDictionaryString as DictionaryString,
      description: EmptyDictionaryString as DictionaryString,
      image: null as string | null
    },
    tokens: [] as ({ flyer?: Flyer } & TokenRequest)[],
    advertising: undefined as boolean | undefined,
    assetId: null as number | null | undefined
  }
};

export const challengeFormSlice = createSlice({
  name: 'challengeForm',
  initialState,
  reducers: {
    setInitiativeId(state, action: PayloadAction<number | undefined>) {
      state.initiativeId = action.payload;
    },
    setEdited(state, action: PayloadAction<boolean>) {
      state.edited = action.payload;
    },

    // input Data
    clear(state) {
      state.inputData = initialState.inputData;
    },

    // setter
    setBrandId(state, action: PayloadAction<number>) {
      state.inputData.brandId = action.payload;
    },
    setName(state, action: PayloadAction<DictionaryString>) {
      state.inputData.name = action.payload;
    },
    setDescription(state, action: PayloadAction<DictionaryString>) {
      state.inputData.description = action.payload;
    },
    setLogo(state, action: PayloadAction<string | null>) {
      state.inputData.logo = action.payload;
    },
    setPrizeDescription(state, action: PayloadAction<DictionaryString>) {
      state.inputData.prizeDescription = action.payload;
    },
    setPrizeTitle(state, action: PayloadAction<DictionaryString>) {
      state.inputData.prizeTitle = action.payload;
    },
    setStartDate(state, action: PayloadAction<number | null>) {
      state.inputData.startDate = action.payload;
    },
    setEndDate(state, action: PayloadAction<number | null>) {
      state.inputData.endDate = action.payload;
    },
    setUnlockThreshold(state, action: PayloadAction<number>) {
      state.inputData.unlockThreshold = action.payload;
    },
    setListDescriptionImage(state, action: PayloadAction<string | null>) {
      state.inputData.listDescriptionImage = action.payload;
    },
    setDescriptionImage(state, action: PayloadAction<string | null>) {
      state.inputData.descriptionImage = action.payload;
    },
    setWakaPrize(state, action: PayloadAction<number>) {
      state.inputData.wakaPrize = action.payload;
    },
    setWakaTokens(state, action: PayloadAction<number>) {
      state.inputData.wakaTokens = action.payload;
    },
    setGoalDescription(state, action: PayloadAction<DictionaryString>) {
      state.inputData.goalDescription = action.payload;
    },
    setPublished(state, action: PayloadAction<boolean>) {
      state.inputData.published = action.payload;
    },
    setInitiativeTypeId(state, action: PayloadAction<number>) {
      state.inputData.initiativeTypeId = action.payload;
    },
    setModeSlug(state, action: PayloadAction<'single' | 'revolving'>) {
      state.inputData.modeSlug = action.payload;
    },
    setNoFlyersMode(state, action: PayloadAction<boolean>) {
      state.noFlyersMode = action.payload;
    },
    setTitleCollected(state, action: PayloadAction<DictionaryString>) {
      state.inputData.titleCollected = action.payload;
    },
    setTitleTotal(state, action: PayloadAction<DictionaryString>) {
      state.inputData.titleTotal = action.payload;
    },
    setBadgeName(state, action: PayloadAction<DictionaryString>) {
      state.inputData.badge = {
        name: action.payload,
        description: state.inputData.badge?.description ?? EmptyDictionaryString,
        image: state.inputData.badge?.image ?? null
      };
    },
    setBadgeDescription(state, action: PayloadAction<DictionaryString>) {
      state.inputData.badge = {
        name: state.inputData.badge?.name ?? EmptyDictionaryString,
        description: action.payload,
        image: state.inputData.badge?.image ?? null
      };
    },
    setBadgeImage(state, action: PayloadAction<string | null>) {
      state.inputData.badge = {
        name: state.inputData.badge?.name ?? EmptyDictionaryString,
        description: state.inputData.badge?.description ?? EmptyDictionaryString,
        image: action.payload
      };
    },
    setTokens(state, action: PayloadAction<({ flyer?: Flyer } & TokenRequest)[]>) {
      state.inputData.tokens = action.payload;
    },
    setAdvertising(state, action: PayloadAction<boolean | undefined>) {
      state.inputData.advertising = action.payload;
    },
    setAssetId(state, action: PayloadAction<number | null | undefined>) {
      state.inputData.assetId = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const challengeFormActions = challengeFormSlice.actions;

export default challengeFormSlice.reducer;
