import React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DownloadOnAppStore } from '../../../assets/download_on_app_store_white.svg';
import { ReactComponent as TextLogo } from '../../../assets/wakapapa_text_logo.svg';
import { DarkBox, FlexBox, InfoText } from '../components/StyledComponents';

export default function FlyerShare() {
  const { t } = useTranslation();
  const imgUrl = useSearchParams()[0].get('imgUrl');

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#43c4ec',
            light: '#90DDF4',
            dark: '#16B0DF'
          },
          secondary: {
            main: '#43ebb5',
            light: '#91F3D4',
            dark: '#17DE9F'
          },
          tertiary: {
            main: '#002a4d',
            light: '#0065B8',
            dark: '#001629'
          },
          info: {
            main: '#ffffff'
          },
          background: {
            default: '#001b32',
            paper: '#043c6b'
          },
          mode: 'dark'
        }
      })}>
      <Helmet>
        <title>Wakapapa flyer sharing</title>
        {/* To be displayed on whatsapp the size of the image to display must be less than 300KB */}
        <meta property="og:site_name" content="Wakapapa" />
        <meta property="og:title" content="Wakapapa - Share a flyer" />
        <meta property="og:description" content="Go check this beautiful flyer!" />
        <meta property="og:image" itemProp="image" content={imgUrl ?? ''} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <DarkBox
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <FlexBox
          flexDirection="column"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '25%',
            minWidth: 400,
            gap: 4
          }}>
          <TextLogo style={{ color: 'red' }} />

          <FlexBox flexDirection="column" alignItems="center">
            <InfoText textAlign="center" px={3} pb={2}>
              {t('flyer:texts:flyerShare')}
            </InfoText>
            <Box
              sx={{
                borderRadius: 4,
                maxHeight: '50vh',
                minHeight: '30vh',
                maxWidth: '70vw',
                minWdith: '30vw',
                background: 'white',
                objectFit: imgUrl ? 'cover' : 'scale-down',
                boxShadow: (theme) => theme.shadows[4]
              }}
              component="img"
              src={imgUrl ?? require('../../../assets/no-image.png')}
            />
          </FlexBox>

          <Box>
            <InfoText textAlign="center" pb={1}>
              Download Wakapapa:
            </InfoText>
            <Box
              onClick={() => window.open('https://apps.apple.com/us/app/wakapapa/id1644651587')}
              sx={{
                transition: '.3s',
                '&:hover': {
                  filter: 'drop-shadow(1px 1px 8px white) brightness(1.3)',
                  cursor: 'pointer'
                }
              }}>
              <DownloadOnAppStore
                height={70}
                width={200}
                title="Download Wakapapa app on the App Store"
              />
            </Box>
          </Box>
        </FlexBox>
      </DarkBox>
    </ThemeProvider>
  );
}
