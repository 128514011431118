import * as z from 'zod';

export const PageblePayloadSchema = z.object({
  pageable: z.object({
    sort: z.object({
      empty: z.boolean(),
      sorted: z.boolean(),
      unsorted: z.boolean()
    }),
    offset: z.number(),
    pageNumber: z.number(),
    pageSize: z.number(),
    paged: z.boolean(),
    unpaged: z.boolean()
  }),
  totalPages: z.number(),
  totalElements: z.number(),
  last: z.boolean(),
  size: z.number(),
  number: z.number(),
  sort: z.object({
    empty: z.boolean(),
    sorted: z.boolean(),
    unsorted: z.boolean()
  }),
  numberOfElements: z.number(),
  first: z.boolean(),
  empty: z.boolean()
});
export type PageblePayload = z.infer<typeof PageblePayloadSchema>;

export const DictionaryStringSchema = z.object({
  en: z.string(),
  it: z.string()
});
export type DictionaryString = z.infer<typeof DictionaryStringSchema>;

export enum Language {
  IT = 'it',
  EN = 'en'
}
