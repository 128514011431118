import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CheckCircle,
  Close,
  Delete,
  DeleteForever,
  Edit,
  List,
  ViewInArTwoTone
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../entities/Asset';
import useAssets from '../../hooks/useAssets';
import PathFormatter from '../../utils/PathFormatter';
import ActivityIndicator from './ActivityIndicator';
import EditableCard from './EditableCard';
import EditableCardHeader from './EditableCardHeader';
import { SnackbarContext } from './Snackbar/SnackbarProvider';
import { FlexBox, InfoText, StyledTooltip } from './StyledComponents';

// import '@google/model-viewer';

interface AssetCardProps {
  brandId: number;
  onlyEditMode?: boolean;
  sx?: SxProps<Theme>;
  onChangeAsset?: (
    asset: { id: number; file: string; fileAndroid: string | null } | undefined
  ) => void | Promise<void>;
  assetId?: number | null;
  infoText?: string;
  iconColor?: string;
  disabled?: boolean;
}
export default function AssetsCard(props: AssetCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setSnackbar } = useContext(SnackbarContext);
  const {
    assets,
    findAsset,
    getAssets,
    getAssetsRequest,
    deleteAsset,
    deleteAssetRequest,
    defaultAsset,
    findDefaultAsset
  } = useAssets();
  const [assetsListModalOpen, setAssetsListModalOpen] = React.useState(false);
  const [deleteAssetId, setDeleteAssetId] = React.useState(undefined);
  const [mode, setMode] = useState<'read' | 'edit' | 'loading'>(
    props.onlyEditMode ? 'edit' : 'read'
  );

  const [selectedAsset, setSelectedAsset] = useState<{
    label: string;
    id: number;
    file: string;
    fileAndroid: string | null;
  } | null>();
  const [assetNameFilter, setAssetNameFilter] = useState<string>('');

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  useEffect(() => {
    if (defaultAsset === undefined) findDefaultAsset(props.brandId);
  }, [defaultAsset, props.brandId]);

  const fetchAssets = useCallback(async () => {
    await getAssets(props.brandId, assetNameFilter || undefined);
  }, [props.brandId, assetNameFilter]);
  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  const setInitialAsset = useCallback(async () => {
    if (!props.assetId) {
      if (props.assetId === null) setSelectedAsset(null);
      return;
    }
    const asset: Asset | void =
      assets?.data.find((a) => a.id === props.assetId) || (await findAsset(props.assetId));
    if (asset) {
      setSelectedAsset({
        id: asset.id,
        file: asset.file,
        fileAndroid: asset.fileAndroid,
        label: PathFormatter.relativeToFilename(PathFormatter.absoluteToRelative(asset.file))
      });
    }
  }, [props.assetId, assets]);
  useEffect(() => {
    setInitialAsset();
  }, [props.assetId]);

  const discardChanges = useCallback(() => {
    setInitialAsset();
    setMode('read');
  }, [setInitialAsset]);

  const confirmChanges = useCallback(async () => {
    setMode('loading');
    if (props.onChangeAsset && selectedAsset) {
      await props.onChangeAsset({
        id: selectedAsset.id,
        file: selectedAsset.file,
        fileAndroid: selectedAsset.fileAndroid
      });
      findDefaultAsset(props.brandId);
    }
    setMode('read');
  }, [selectedAsset, props.brandId]);

  return (
    <EditableCard
      sx={props.sx}
      hideHeaderDivider
      mode={props.onlyEditMode ? undefined : mode}
      bodySx={{
        p: 1,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      title={t('asset:asset')}
      confirmDisabled={!selectedAsset}
      onEditMode={props.onlyEditMode || !defaultAsset ? undefined : () => setMode('edit')}
      onEditModeConfirm={confirmChanges}
      onEditModeDiscard={discardChanges}
      icon={<ViewInArTwoTone htmlColor={props.iconColor ?? theme.palette.primary.main} />}
      onAdd={() => {
        navigate(`/home/brand/${props.brandId}/new-asset`);
      }}
      additionalHeaderComponent={
        <StyledTooltip arrow placement="top" title={t('asset:showAllAssets')}>
          <IconButton size="small" onClick={() => setAssetsListModalOpen(true)}>
            <List />
          </IconButton>
        </StyledTooltip>
      }>
      <InfoText mb={1}>{props.infoText}</InfoText>
      {mode === 'read' ? (
        //  @ts-ignore
        // <model-viewer
        //   style={{ width: '100%', height: '28vh' }}
        //   loading="eager"
        //   src={require('../../../../assets/car.glb')}
        //   ios-src={require('../../../../assets/earth.usdz')}
        //   camera-controls
        //   touch-action="pan-y"
        //   auto-rotate
        // />
        selectedAsset ? (
          <a rel="ar" href={selectedAsset?.file}>
            <Link sx={{ maxHeight: '30vh', width: 'auto', maxWidth: '100%' }}>
              {selectedAsset?.label}
            </Link>
          </a>
        ) : selectedAsset === undefined ? (
          <ActivityIndicator inline size="small" />
        ) : (
          <Button startIcon={<Edit />} variant="outlined" onClick={() => setMode('edit')}>
            {t('asset:setDefaultAsset')}
          </Button>
        )
      ) : selectedAsset !== undefined ? (
        <Autocomplete
          disabled={props.disabled}
          loading={getAssetsRequest.inProgress}
          value={selectedAsset}
          options={
            assets?.data.map((a) => ({
              label: PathFormatter.relativeToFilename(PathFormatter.absoluteToRelative(a.file)),
              file: a.file,
              fileAndroid: a.fileAndroid,
              id: a.id
            })) ?? []
          }
          onChange={(e, value) => {
            if (props.onlyEditMode) {
              if (props.onChangeAsset) {
                props.onChangeAsset(
                  value
                    ? { id: value.id, file: value.file, fileAndroid: value.fileAndroid }
                    : undefined
                );
              }
            } else {
              setSelectedAsset(value ?? null);
            }
          }}
          onInput={_.debounce((e) => setAssetNameFilter(e.target.value), 800)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('asset:texts:selectAsset')}
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <ActivityIndicator inline size="small" />
      )}

      {/* Assets list modal */}
      <Modal open={assetsListModalOpen} onClose={() => setAssetsListModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 4,
            boxShadow: 24,
            px: 3,
            pt: 3
          }}>
          <FlexBox>
            <EditableCardHeader
              sx={{ flex: 1, p: 0 }}
              title={t('asset:assetList')}
              icon={<ViewInArTwoTone />}
              onAdd={() => {
                navigate(`/home/brand/${props.brandId}/new-asset`);
              }}
              additionalHeaderComponent={
                <StyledTooltip title={t('common:close')} arrow placement="top">
                  <IconButton size="small" onClick={() => setAssetsListModalOpen(false)}>
                    <Close />
                  </IconButton>
                </StyledTooltip>
              }
            />
          </FlexBox>
          <FlexBox
            flexDirection="row"
            position="relative"
            height={500}
            minWidth={300}
            width={'40vw'}
            maxWidth={1000}>
            {getAssetsRequest.inProgress && (
              <ActivityIndicator
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  right: 0,
                  background: '#ffffffb0',
                  zIndex: 1
                }}
              />
            )}
            {assets && (
              <DataGrid
                sx={{ border: 'none' }}
                rows={assets.data}
                disableSelectionOnClick={true}
                columns={[
                  {
                    field: 'file',
                    headerName: 'File',
                    sortable: false,
                    flex: 1,
                    renderCell: (params: GridRenderCellParams<string>) => (
                      <a rel="ar" href={params.row.file}>
                        <Link>
                          {PathFormatter.relativeToFilename(
                            PathFormatter.absoluteToRelative(params.row.file)
                          )}
                        </Link>
                      </a>
                    )
                  },
                  {
                    field: 'isDefault',
                    headerName: 'Default',
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params: GridRenderCellParams<string>) =>
                      params.row.id === defaultAsset?.id && (
                        <CheckCircle sx={{ m: 'auto' }} color="success" />
                      )
                  },
                  {
                    field: 'actions',
                    headerName: t('common:actions'),
                    sortable: false,
                    disableColumnMenu: true,
                    renderCell: (params: GridRenderCellParams<string>) => (
                      <FlexBox>
                        <StyledTooltip title={t('common:edit')} arrow placement="left">
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/home/brand/${props.brandId}/assets/${params.row.id}${
                                  params.row.isDefault ? '?default=true' : ''
                                }`
                              )
                            }>
                            <Edit />
                          </IconButton>
                        </StyledTooltip>

                        {params.row.id !== defaultAsset?.id && (
                          <StyledTooltip
                            severity="error"
                            title={t('common:delete')}
                            arrow
                            placement="right">
                            <IconButton
                              color="error"
                              onClick={() => setDeleteAssetId(params.row.id)}>
                              <Delete />
                            </IconButton>
                          </StyledTooltip>
                        )}
                      </FlexBox>
                    )
                  }
                ]}
                paginationMode="server"
                sortingMode="server"
                rowCount={assets?.pagination?.totalElements || 10}
                rowsPerPageOptions={[assets?.pagination?.size ?? 10]}
              />
            )}
          </FlexBox>
        </Box>
      </Modal>

      {/* Delete Asset Dialog */}
      <Dialog open={Boolean(deleteAssetId)} onClose={() => setDeleteAssetId(undefined)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('asset:texts:deleteAssetInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteAssetId(undefined)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteAssetRequest.inProgress}
              onClick={async () => {
                if (!deleteAssetId) return;
                const success = await deleteAsset(deleteAssetId);
                openSnackBar(
                  success,
                  t('asset:snackbar:deleteAssetSuccess'),
                  t('asset:snackbar:deleteAssetError')
                );
                if (deleteAssetId === props.assetId) {
                  setSelectedAsset(null);
                  setAssetNameFilter('');
                  if (props.onChangeAsset) {
                    props.onChangeAsset(undefined);
                  }
                }
                setDeleteAssetId(undefined);
                await fetchAssets();
              }}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </EditableCard>
  );
}
