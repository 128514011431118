import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Close,
  CloudOff,
  CloudUpload,
  ControlPointDuplicate,
  DeleteForever,
  Edit,
  Person,
  Timer,
  TimerOff,
  Visibility
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import { purple } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DictionaryString, Language } from '../../entities/Common';
import { Campaign, Challenge, EmbeddedInitiative, Quest } from '../../entities/Initiatives';
import { TokenAssignmentMode } from '../../entities/Token';
import { RoleIds } from '../../entities/User';
import useInitiatives from '../../hooks/useInitiatives';
import useUser from '../../hooks/useUser';
import { currentLanguage } from '../../i18n';
import { initiativesActions } from '../../redux/be_interaction_store/slices/initiatives';
import { campaignFormActions } from '../../redux/local_store/slices/campaignForm';
import { challengeFormActions } from '../../redux/local_store/slices/challengeForm';
import { questFormActions } from '../../redux/local_store/slices/questForm';
import { TUTORIAL_INITIATIVE_ID } from '../../utils/Constants';
import CountFormatter from '../../utils/CountFormatter';
import PathFormatter from '../../utils/PathFormatter';
import { SnackbarContext } from './Snackbar/SnackbarProvider';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface InitiativeCardProps {
  brandId: number;
  type: 'quest' | 'challenge' | 'campaign';
  challengeData?: EmbeddedInitiative;
  questData?: EmbeddedInitiative;
  campaignData?: Campaign;
}
export default function InitiativeCard(props: InitiativeCardProps) {
  const data =
    props.type === 'quest'
      ? props.questData
      : props.type === 'challenge'
      ? props.challengeData
      : props.campaignData;

  if (!data) return <></>;

  const theme = useTheme();
  const { me } = useUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    challenges,
    readChallenges,
    addChallenge,
    deleteChallenge,
    deleteChallengeRequest,
    quests,
    readQuests,
    addQuest,
    deleteQuest,
    deleteQuestRequest,
    campaigns,
    readCampaigns,
    updateCampaign,
    findCampaign,
    addCampaign,
    deleteCampaign,
    deleteCampaignRequest,
    findInitiative,
    publishInitiative,
    publishInitiativeRequest
  } = useInitiatives();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const [loading, setLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [deleteChallengeDialogOpened, setDeleteChallengeDialogOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  useEffect(() => {
    if (publishInitiativeRequest.httpError) {
      setSnackbar({
        opened: true,
        severity: 'error',
        msg: `${t('initiatives:snackbar:initiativePublishError')} \n
        ${
          (publishInitiativeRequest.httpError?.axiosError?.response?.data as any)?.error?.message ??
          'unknown'
        }`,
        onClose: () => dispatch(initiativesActions.publishInitiativeSuccess())
      });
    }
  }, [publishInitiativeRequest.httpError]);

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const togglePublished = async () => {
    setLoading(true);

    let success = false;
    if (props.type === 'challenge') {
      success = await publishInitiative(data.id);
      await readChallenges(
        props.brandId,
        challenges.pagination?.number ? challenges.pagination?.number - 1 : undefined
      );
    } else if (props.type === 'quest') {
      success = await publishInitiative(data.id);
      await readQuests(
        props.brandId,
        quests.pagination?.number ? quests.pagination?.number - 1 : undefined
      );
    } else if (props.type === 'campaign') {
      const campaignData = await findCampaign(data.id);
      if (!campaignData) return;
      success = await updateCampaign(data.id, {
        brandId: props.brandId,
        ..._.omit(campaignData, ['brand']),
        flyers: campaignData.flyers?.map((flyer) => flyer.id),
        published: !data.published
      });
      await readCampaigns(
        props.brandId,
        campaigns.pagination?.number ? campaigns.pagination?.number - 1 : undefined
      );
    }
    if (success) {
      setSnackbar({
        opened: true,
        severity: 'success',
        msg: t('initiatives:snackbar:initiativePublishSuccess')
      });
    }
    setLoading(false);
  };

  const duplicateInitiative = async () => {
    setDuplicating(true);
    let success = false;

    if (props.type === 'challenge') {
      const challengeData = await findInitiative(data.id);
      if (!challengeData || !challengeData.descriptionImage || !challengeData.listDescriptionImage)
        return;
      success = await addChallenge({
        brandId: props.brandId,
        name: Object.keys(challengeData.name).reduce((accumulator, key) => {
          return {
            ...accumulator,
            [key]: challengeData.name[key as Language] + ' (copy)'
          };
        }, {}) as DictionaryString,
        logo: PathFormatter.absoluteToRelative(challengeData.logo),
        prizeDescription: challengeData.prizeDescription,
        prizeTitle: challengeData.prizeTitle,
        description: challengeData.description,
        descriptionImage: PathFormatter.absoluteToRelative(challengeData.descriptionImage),
        listDescriptionImage: PathFormatter.absoluteToRelative(challengeData.listDescriptionImage),
        endDate: challengeData.endDate,
        startDate: challengeData.startDate,
        unlockThreshold: challengeData.unlockThreshold,
        wakaPrize: challengeData.wakaPrize,
        wakaTokens: challengeData.wakaTokens,
        goalDescription: challengeData.goalDescription,
        initiativeTypeId: challengeData.initiativeType.id,
        modeSlug: challengeData.mode.slug as 'single' | 'revolving',
        titleCollected: challengeData.titleCollected,
        titleTotal: challengeData.titleTotal,
        tokens: challengeData.tokens?.map((t) => {
          return { ..._.omit(t, ['flyer', 'id']), flyerId: t.flyer?.id ?? -1 };
        }),
        advertising: challengeData.advertising,
        badge: challengeData.badge
          ? {
              ...challengeData.badge,
              image: PathFormatter.absoluteToRelative(challengeData.badge.image)
            }
          : undefined,
        assetId: challengeData.asset?.id
      });
      openSnackBar(
        success,
        t('initiatives:challenges:snackbar:challengeAddSuccess'),
        t('initiatives:challenges:snackbar:challengeAddError')
      );
      if (success) readChallenges(props.brandId);
    } else if (props.type === 'quest') {
      const questData = await findInitiative(data.id);
      if (!questData || !questData.descriptionImage || !questData.listDescriptionImage) return;
      success = await addQuest({
        brandId: props.brandId,
        name: Object.keys(questData.name).reduce((accumulator, key) => {
          return {
            ...accumulator,
            [key]: questData.name[key as Language] + ' (copy)'
          };
        }, {}) as DictionaryString,
        logo: PathFormatter.absoluteToRelative(questData.logo),
        prizeDescription: questData.prizeDescription,
        prizeTitle: questData.prizeTitle,
        description: questData.description,
        descriptionImage: PathFormatter.absoluteToRelative(questData.descriptionImage),
        listDescriptionImage: PathFormatter.absoluteToRelative(questData.listDescriptionImage),
        endDate: questData.endDate,
        startDate: questData.startDate,
        unlockThreshold: questData.unlockThreshold,
        wakaPrize: questData.wakaPrize,
        wakaTokens: questData.wakaTokens,
        goalDescription: questData.goalDescription,
        initiativeTypeId: questData.initiativeType.id,
        modeSlug: questData.mode.slug as TokenAssignmentMode,
        tokens: questData.tokens?.map((t) => {
          return {
            ..._.omit(t, ['flyer']),
            flyerId: t.flyer?.id,
            locations: t.locations?.map((tl) => _.omit(tl, ['id']))
          };
        }),
        locations: questData.locations?.map((l) => _.omit(l, ['id'])),
        advertising: questData.advertising,
        badge: questData.badge
          ? {
              ...questData.badge,
              image: PathFormatter.absoluteToRelative(questData.badge.image)
            }
          : undefined,
        assetId: questData.asset?.id
      });
      openSnackBar(
        success,
        t('initiatives:quests:snackbar:questAddSuccess'),
        t('initiatives:quests:snackbar:questAddError')
      );
      if (success) readQuests(props.brandId);
    } else if (props.type === 'campaign') {
      const campaignData = await findCampaign(data.id);
      if (!campaignData) return;
      success = await addCampaign({
        brandId: props.brandId,
        name: campaignData.name + ' (copy)',
        startDate: campaignData.startDate,
        endDate: campaignData.endDate,
        flyers: campaignData.flyers?.map((flyer) => flyer.id),
        published: false
      });
      openSnackBar(
        success,
        t('initiatives:campaigns:snackbar:campaignAddSuccess'),
        t('initiatives:campaigns:snackbar:campaignAddError')
      );
      if (success) readCampaigns(props.brandId);
    }

    setDuplicating(false);
  };

  const deleteInitiative = async () => {
    if (data.id) {
      if (props.type === 'challenge') {
        const success = await deleteChallenge(data.id);
        openSnackBar(
          success,
          t('initiatives:challenges:snackbar:challengeDeleteSuccess'),
          t('initiatives:challenges:snackbar:challengeDeleteError')
        );
        setDeleteChallengeDialogOpen(false);
        if (success) readChallenges(props.brandId);
      } else if (props.type === 'quest') {
        const success = await deleteQuest(data.id);
        openSnackBar(
          success,
          t('initiatives:quests:snackbar:questDeleteSuccess'),
          t('initiatives:quests:snackbar:questDeleteError')
        );
        setDeleteChallengeDialogOpen(false);
        if (success) readQuests(props.brandId);
      } else if (props.type === 'campaign') {
        const success = await deleteCampaign(data.id);
        openSnackBar(
          success,
          t('initiatives:campaigns:snackbar:campaignDeleteSuccess'),
          t('initiatives:campaigns:snackbar:campaignDeleteError')
        );
        setDeleteChallengeDialogOpen(false);
        if (success) readCampaigns(props.brandId);
      }
    }
  };

  const timelineLengthPercentage = useMemo(() => {
    const elapsedTime = new Date().getTime() - data.startDate;
    const timeWindow = data.endDate - data.startDate;
    const returnValue = (elapsedTime * 100) / timeWindow;
    if (returnValue > 100) return 100;
    if (returnValue < 0) return 0;
    return (elapsedTime * 100) / timeWindow;
  }, [data]);

  return (
    <>
      <Fade in={true}>
        <Card
          variant="outlined"
          sx={{
            p: 1,
            border: 'none',
            borderRadius: 4,
            position: 'relative',
            background: (theme) => (data.published ? theme.palette.tertiary.light : `white`),
            color: data.published ? 'white' : 'initial',
            flex: `1 0 calc(${tabletWidth ? 50 : 33.3}% - 27px)`,
            maxWidth: `calc(${tabletWidth ? 50 : 33.3}% - 27px)`,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'visible'
          }}>
          {/* Logo */}
          {(data as EmbeddedInitiative).logo && (
            <FlexBox
              sx={{
                position: 'absolute',
                top: 16,
                left: -4,
                bgcolor: 'white',
                borderRadius: 1,
                p: 1.5,
                width: 110,
                zIndex: 1,
                boxShadow: (theme) => theme.shadows[5],
                borderLeft: (theme) =>
                  `8px solid ${
                    data.id === TUTORIAL_INITIATIVE_ID
                      ? purple[300]
                      : theme.palette[
                          data.endDate < Date.now()
                            ? 'error'
                            : data.published
                            ? 'secondary'
                            : 'primary'
                        ].main
                  }`
              }}>
              <img src={(data as EmbeddedInitiative).logo ?? ''} style={{ maxWidth: '100%' }} />
            </FlexBox>
          )}
          {/* Description Image */}
          {props.type !== 'campaign' && (
            <Box
              sx={{
                position: 'relative',
                height: 'auto',
                maxHeight: 200,
                width: '100%'
              }}>
              <CardMedia
                sx={{
                  borderRadius: 2,
                  transition: '0.2s',
                  objectFit: (data as EmbeddedInitiative).descriptionImage ? 'cover' : 'scale-down',
                  background: (theme) => theme.palette.background.default,
                  ':hover': (data as EmbeddedInitiative).descriptionImage
                    ? {
                        filter: 'brightness(1.4)',
                        opacity: 0.8,
                        cursor: 'pointer'
                      }
                    : undefined
                }}
                onClick={
                  (data as EmbeddedInitiative).descriptionImage
                    ? () => setModalOpen(true)
                    : undefined
                }
                component="img"
                height="194"
                image={
                  (data as EmbeddedInitiative).descriptionImage ??
                  require('../../../assets/no-image.png')
                }
              />
            </Box>
          )}

          {/* Publish / Unpublished / active Users Circle  + progress bar */}
          <FlexBox flexDirection="row" alignItems="center" gap={2} sx={{ py: 1.5, px: 1 }}>
            <Box
              sx={{
                width: 'fit-content',
                m: 'auto',
                background: (theme) => (data.published ? theme.palette.tertiary.light : `white`),
                borderBottomLeftRadius: 16,
                zIndex: 1
              }}>
              <StyledTooltip
                arrow
                placement="top"
                title={
                  data.published && data.endDate < Date.now()
                    ? t('initiatives:initiativeCardTooltipTexts:expiredInitiative') +
                      `\n\n ${t('initiatives:registeredUsers')}: ${data.countActiveUsers ?? 0}`
                    : data.published && data.startDate < Date.now()
                    ? t('initiatives:initiativeCardTooltipTexts:onGoingInitiative') +
                      ` ${
                        props.type !== 'campaign'
                          ? t('initiatives:initiativeCardTooltipTexts:activeUsers') +
                            `\n\n ${t('initiatives:registeredUsers')}: ${
                              data.countActiveUsers ?? 0
                            }`
                          : ''
                      }`
                    : data.published && data.startDate >= Date.now()
                    ? t('initiatives:initiativeCardTooltipTexts:unpublishInitiative')
                    : !data.published && data.startDate >= Date.now()
                    ? t('initiatives:initiativeCardTooltipTexts:publishInitiative')
                    : t('initiatives:initiativeCardTooltipTexts:cantPublishInitiative')
                }>
                <span style={{ display: 'block' }}>
                  <Fab
                    color={data.published ? 'secondary' : 'primary'}
                    disabled={loading || data.startDate < Date.now()}
                    onClick={() => togglePublished()}
                    sx={{ color: 'white' }}>
                    {!data.published ? (
                      <CloudUpload />
                    ) : data.startDate < Date.now() && props.type !== 'campaign' ? (
                      <FlexBox
                        sx={{
                          color: 'white',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: 14,
                          fontWeight: 'bold',
                          flexWrap: 'wrap',
                          overflow: 'hidden',
                          p: 1
                        }}>
                        <Box position="relative" sx={{ height: 20 }}>
                          <Person fontSize="small" />
                          <Box
                            width={6}
                            height={6}
                            sx={{
                              background: (theme) =>
                                data.id === TUTORIAL_INITIATIVE_ID
                                  ? purple[200]
                                  : data.endDate < Date.now()
                                  ? theme.palette.error.light
                                  : theme.palette.secondary.light,
                              position: 'absolute',
                              bottom: 0,
                              right: 0,
                              borderRadius: 2,
                              border: (theme) =>
                                `2px solid ${
                                  data.published ? theme.palette.tertiary.light : `white`
                                }`
                            }}
                          />
                        </Box>
                        <span style={{ overflow: 'hidden', textTransform: 'none' }}>
                          {CountFormatter.format(data.countActiveUsers ?? 0)}
                        </span>
                      </FlexBox>
                    ) : (
                      <CloudOff />
                    )}
                  </Fab>
                </span>
              </StyledTooltip>
            </Box>

            {/* Progress bar */}
            <Box
              borderTop={`1px dashed ${data.published ? '#edfbff' : 'gray'}`}
              flex={1}
              mx={4}
              mb={1}
              sx={{ position: 'relative', mx: 6 }}>
              <Box
                bgcolor={
                  data.id === TUTORIAL_INITIATIVE_ID
                    ? purple[300]
                    : data.endDate < Date.now()
                    ? theme.palette.error.main
                    : data.published
                    ? theme.palette.secondary.light
                    : theme.palette.primary.main
                }
                height={5}
                width={`${timelineLengthPercentage}%`}
                sx={{
                  top: -3,
                  left: 0,
                  position: 'absolute',
                  borderRadius: 2
                }}
              />

              <FlexBox
                flexDirection="column"
                alignItems="center"
                sx={{ position: 'absolute', left: -46, top: -14 }}>
                <Timer
                  sx={{
                    px: 0.3
                  }}
                />
                <Typography variant="subtitle2" fontSize={12}>
                  {data.id === TUTORIAL_INITIATIVE_ID ? (
                    <>&infin;</>
                  ) : (
                    new Date(data.startDate).toLocaleDateString()
                  )}
                </Typography>
              </FlexBox>

              <FlexBox
                flexDirection="column"
                alignItems="center"
                sx={{ position: 'absolute', right: -46, top: -14 }}>
                <TimerOff
                  sx={{
                    px: 0.3
                  }}
                />
                <Typography variant="subtitle2" fontSize={12}>
                  {data.id === TUTORIAL_INITIATIVE_ID ? (
                    <>&infin;</>
                  ) : (
                    new Date(data.endDate).toLocaleDateString()
                  )}
                </Typography>
              </FlexBox>
            </Box>
          </FlexBox>

          {/* Initiative Name */}
          <FlexBox alignItems="flex-end" px={1}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
              {props.type !== 'campaign'
                ? (data as Challenge | Quest).name[currentLanguage()]
                : (data as Campaign).name}
            </Typography>
          </FlexBox>

          {/* Initiative Description */}
          {props.type !== 'campaign' && (
            <Typography
              variant="body2"
              sx={{
                color: data.published ? '#edfbff' : 'text.secondary',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                px: 1
              }}>
              {(data as Challenge | Quest).description[currentLanguage()]}
            </Typography>
          )}

          <CardActions sx={{ flex: 1, p: 0, pt: 2, alignItems: 'flex-end' }}>
            <StyledTooltip title={t('common:delete')} arrow severity="error">
              <IconButton
                aria-label={t('common:delete')}
                disabled={loading || duplicating || data.id === TUTORIAL_INITIATIVE_ID}
                onClick={() => setDeleteChallengeDialogOpen(true)}>
                <DeleteForever
                  htmlColor={
                    data.published
                      ? data.id === TUTORIAL_INITIATIVE_ID
                        ? 'default'
                        : theme.palette.error.light
                      : theme.palette.error.main
                  }
                />
              </IconButton>
            </StyledTooltip>

            <StyledTooltip title={t('common:duplicate')} arrow>
              <IconButton
                aria-label={t('common:duplicate')}
                color="primary"
                onClick={duplicateInitiative}
                disabled={loading || data.id === TUTORIAL_INITIATIVE_ID}>
                {duplicating ? (
                  <CircularProgress
                    size={22}
                    sx={{ color: data.published ? 'white' : 'primary' }}
                  />
                ) : (
                  <ControlPointDuplicate
                    htmlColor={
                      data.published
                        ? data.id === TUTORIAL_INITIATIVE_ID
                          ? 'default'
                          : 'white'
                        : 'primary'
                    }
                  />
                )}
              </IconButton>
            </StyledTooltip>

            <FlexBox sx={{ flex: 1, justifyContent: 'flex-end' }}>
              {(data.endDate < Date.now() || me?.role?.id === RoleIds.BRAND_USER_ID) &&
              data.published ? (
                <StyledTooltip
                  title={t('common:view')}
                  arrow
                  onClick={() => {
                    dispatch(challengeFormActions.setEdited(false));
                    dispatch(questFormActions.setEdited(false));
                    navigate(
                      `/home/brand/${props.brandId}/${props.type}s/${data.id}?view_only=true`
                    );
                  }}>
                  <IconButton aria-label={t('common:view')} disabled={loading}>
                    <Visibility htmlColor={data.published ? 'white' : 'default'} />
                  </IconButton>
                </StyledTooltip>
              ) : (
                <StyledTooltip
                  title={t('common:edit')}
                  arrow
                  onClick={() => {
                    dispatch(challengeFormActions.setEdited(false));
                    dispatch(questFormActions.setEdited(false));
                    dispatch(campaignFormActions.setEdited(false));
                    navigate(`/home/brand/${props.brandId}/${props.type}s/${data.id}`);
                  }}>
                  <IconButton
                    aria-label={t('common:edit')}
                    disabled={
                      loading || (me?.role?.id === RoleIds.BRAND_USER_ID && data.published)
                    }>
                    <Edit htmlColor={data.published ? 'white' : 'default'} />
                  </IconButton>
                </StyledTooltip>
              )}
            </FlexBox>
          </CardActions>
        </Card>
      </Fade>

      {/* Img Modal */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 1,
            boxShadow: 24
          }}>
          <FlexBox p={1} justifyContent="flex-end">
            <IconButton size="small" onClick={() => setModalOpen(false)}>
              <Close />
            </IconButton>
          </FlexBox>
          <FlexBox flexDirection="column" position="relative" p={4} pt={0}>
            <img
              src={(data as Challenge | Quest).descriptionImage ?? undefined}
              style={{
                borderRadius: 3,
                height: 'auto',
                maxHeight: '80vh',
                width: 'auto',
                maxWidth: '85vw',
                margin: 'auto'
              }}
            />
          </FlexBox>
        </Box>
      </Modal>

      {/* Delete Initiative Dialog */}
      <Dialog
        open={deleteChallengeDialogOpened}
        onClose={() => setDeleteChallengeDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('initiatives:texts:deleteInitiativeInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteChallengeDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={
                deleteChallengeRequest.inProgress ||
                deleteQuestRequest.inProgress ||
                deleteCampaignRequest.inProgress
              }
              onClick={deleteInitiative}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
}
