import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageblePayload } from '../../../entities/Common';
import { Role } from '../../../entities/Role';
import {
  AppUser,
  BusinessUser,
  AppUsersApiResponse,
  BusinessUsersApiResponse
} from '../../../entities/User';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  appUsers: {
    data: [] as AppUser[],
    pagination: undefined as PageblePayload | undefined
  },
  backOfficeUsers: {
    data: [] as BusinessUser[],
    pagination: undefined as PageblePayload | undefined
  },
  userRoles: [] as Role[],
  me: undefined as BusinessUser | undefined,

  requests: {
    readAppUsers: requestState(),
    readBackOfficeUsers: requestState(),
    findAppUser: requestState(),
    findBackOfficeUser: requestState(),
    addUser: requestState(),
    updateUser: requestState(),
    deleteUser: requestState(),
    readUserRoles: requestState(),
    readMe: requestState()
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // App Users List
    readAppUsersRequest(state) {
      mutateRequestState(state.requests.readAppUsers);
    },
    readAppUsersSuccess(state, action: PayloadAction<AppUsersApiResponse>) {
      state.appUsers.data = action.payload.content;
      delete (action.payload as any).content;
      state.appUsers.pagination = action.payload;
      mutateSuccessState(state.requests.readAppUsers);
    },
    readAppUsersError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readAppUsers, action.payload.error);
    },
    readAppUsersParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readAppUsers, action.payload.parseError);
    },

    // find app user
    findAppUserRequest(state) {
      mutateRequestState(state.requests.findAppUser);
    },
    findAppUserSuccess(state) {
      mutateSuccessState(state.requests.findAppUser);
    },
    findAppUserError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findAppUser, action.payload.error);
    },
    findAppUserParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findAppUser, action.payload.parseError);
    },

    // Back Office Users List
    readBackOfficeUsersRequest(state) {
      mutateRequestState(state.requests.readBackOfficeUsers);
    },
    readBackOfficeUsersSuccess(state, action: PayloadAction<BusinessUsersApiResponse>) {
      state.backOfficeUsers.data = action.payload.content;
      delete (action.payload as any).content;
      state.backOfficeUsers.pagination = action.payload;
      mutateSuccessState(state.requests.readBackOfficeUsers);
    },
    readBackOfficeUsersError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readBackOfficeUsers, action.payload.error);
    },
    readBackOfficeUsersParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readBackOfficeUsers, action.payload.parseError);
    },

    // find BackOffice user
    findBackOfficeUserRequest(state) {
      mutateRequestState(state.requests.findBackOfficeUser);
    },
    findBackOfficeUserSuccess(state) {
      mutateSuccessState(state.requests.findBackOfficeUser);
    },
    findBackOfficeUserError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findBackOfficeUser, action.payload.error);
    },
    findBackOfficeUserParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findBackOfficeUser, action.payload.parseError);
    },

    // add User
    addUserRequest(state) {
      mutateRequestState(state.requests.addUser);
    },
    addUserSuccess(state) {
      mutateSuccessState(state.requests.addUser);
    },
    addUserError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addUser, action.payload.error);
    },

    // update User
    updateUserRequest(state) {
      mutateRequestState(state.requests.updateUser);
    },
    updateUserSuccess(state) {
      mutateSuccessState(state.requests.updateUser);
    },
    updateUserError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateUser, action.payload.error);
    },

    // delete User
    deleteUserRequest(state) {
      mutateRequestState(state.requests.deleteUser);
    },
    deleteUserSuccess(state) {
      mutateSuccessState(state.requests.deleteUser);
    },
    deleteUserError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteUser, action.payload.error);
    },

    // User Roles
    readUserRolesRequest(state) {
      mutateRequestState(state.requests.readUserRoles);
    },
    readUserRolesSuccess(state, action: PayloadAction<Role[]>) {
      state.userRoles = action.payload;
      mutateSuccessState(state.requests.readUserRoles);
    },
    readUserRolesError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readUserRoles, action.payload.error);
    },
    readUserRolesParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readUserRoles, action.payload.parseError);
    },

    // Get Me
    readMeRequest(state) {
      mutateRequestState(state.requests.readMe);
    },
    readMeSuccess(state, action: PayloadAction<BusinessUser>) {
      state.me = action.payload;
      mutateSuccessState(state.requests.readMe);
    },
    readMeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readMe, action.payload.error);
    },
    readMeParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readMe, action.payload.parseError);
    },
    clearMe(state) {
      state.me = initialState.me;
    }
  }
});

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions;

export default userSlice.reducer;
