import React, { ReactElement } from 'react';
import { Add, Check, Close, Edit } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface EditableCardHeaderProps {
  title: string;
  mode?: 'read' | 'edit' | 'loading';
  icon?: React.ReactNode;
  onAdd?: () => void;
  onEditMode?: () => void;
  onEditModeDiscard?: () => void;
  onEditModeConfirm?: () => void;
  confirmDisabled?: boolean;
  additionalHeaderComponent?: React.ReactNode;
  editButtonComponent?: (props: {
    onClick?: (e: React.MouseEvent) => void;
    children?: React.ReactNode;
  }) => ReactElement;
  sx?: SxProps<Theme>;
}
export default function EditableCardHeader(props: EditableCardHeaderProps) {
  const { t } = useTranslation();
  return (
    <FlexBox
      p={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        mt: -1.2,
        ...props.sx
      }}>
      {props.icon && <Box mr={1.5}>{props.icon}</Box>}
      <Typography flex={1} fontWeight="bold" mr={8}>
        {props.title}
      </Typography>
      <FlexBox sx={{ justifyContent: 'flex-end', alignItems: 'center' }} gap={2}>
        {props.onAdd && (props.mode === 'read' || !props.mode) && (
          <StyledTooltip arrow title={t('common:add')} placement="top">
            <IconButton
              size="small"
              onClick={() => {
                if (props.onAdd) props.onAdd();
              }}>
              <Add />
            </IconButton>
          </StyledTooltip>
        )}

        {Boolean(props.mode !== 'edit' && props.mode !== 'loading') &&
          props.additionalHeaderComponent}

        {props.mode === 'read' && props.onEditMode ? (
          props.editButtonComponent ? (
            <props.editButtonComponent
              onClick={() => {
                if (props.onEditMode) props.onEditMode();
              }}
            />
          ) : (
            <StyledTooltip arrow title={t('common:edit')} placement="top">
              <IconButton
                size="small"
                onClick={() => {
                  if (props.onEditMode) props.onEditMode();
                }}>
                <Edit />
              </IconButton>
            </StyledTooltip>
          )
        ) : (
          <></>
        )}

        {props.mode === 'edit' && (
          <Box>
            <StyledTooltip arrow title={t('common:cancel')} placement="top" severity="error">
              <IconButton
                size="small"
                color="error"
                sx={{ mr: 1 }}
                onClick={() => {
                  if (props.onEditModeDiscard) props.onEditModeDiscard();
                }}>
                <Close />
              </IconButton>
            </StyledTooltip>
            <StyledTooltip arrow title={t('common:confirm')} placement="top" severity="info">
              <IconButton
                disabled={props.confirmDisabled}
                size="small"
                color="success"
                onClick={() => {
                  if (props.onEditModeConfirm) props.onEditModeConfirm();
                }}>
                <Check />
              </IconButton>
            </StyledTooltip>
          </Box>
        )}

        {props.mode === 'loading' && (
          <FlexBox alignItems="center" mr={1}>
            <CircularProgress size={20} sx={{ mr: 1.5, my: 0.85 }} />
            <Typography fontSize={14} fontStyle="italic" color={'primary'}>
              Saving...
            </Typography>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}
