import React, { useCallback, useEffect, useState } from 'react';
import { Add, CloudUpload, SortByAlpha, Tag, Timer, TimerOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useInitiatives from '../../../../../hooks/useInitiatives';
import { campaignFormActions } from '../../../../../redux/local_store/slices/campaignForm';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import InitiativeCard from '../../../../components/InitiativeCard';
import NoDataBanner from '../../../../components/NoDataBanner';
import OrderSelection from '../../../../components/OrderSelection';
import { SearchBar } from '../../../../components/SearchBar';
import { FlexBox } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';

interface BrandCampaignsProps {
  brandId: number;
}
export default function BrandCampaigns(props: BrandCampaignsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { readCampaigns, readCampaignsRequest, campaigns } = useInitiatives();

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('initiatives:campaigns:campaigns'),
        path: `/home/brand/${props.brandId}/campaigns`
      };
    }, [props.brandId])
  );

  // Filters
  const [keyWord, setKeyWord] = useState('');
  const [publishedOnly, setPublishedOnly] = useState(false);
  const [order, setOrder] = useState<string>('default');
  const [sortModeAsc, setSortModeAsc] = React.useState<boolean>(true);

  const ownedCampaigns = campaigns.data.filter((campaign) => campaign.brand.id === props.brandId);

  const fetchCampaigns = useCallback(
    async (page?: number) => {
      await readCampaigns(
        props.brandId,
        page,
        keyWord || undefined,
        publishedOnly || undefined,
        order === 'default' ? undefined : `${sortModeAsc ? '' : '-'}${order}`
      );
    },
    [props.brandId, keyWord, sortModeAsc, order, publishedOnly, order]
  );

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  return (
    <>
      <Toolbar
        mt={1}
        mb={4}
        leftcomponent={
          <FlexBox gap={1} rowGap={1.6} flexDirection="row" flexWrap="wrap" flex={'1 1 auto'}>
            <SearchBar
              value={keyWord}
              onChange={_.debounce((text) => setKeyWord(text), 800)}
              placeholder={t('initiatives:challenges:searchChallenge')}
              backgroundColor="white"
              sx={{ flex: '1 0 180px', height: 38, py: 0 }}
            />

            <Button
              onClick={() => setPublishedOnly(!publishedOnly)}
              variant="outlined"
              sx={{
                height: 40,
                backgroundColor: 'white',
                color: 'gray',
                borderColor: '#c4c4c4',
                textTransform: 'none',
                flex: '1 0 180px',
                justifyContent: 'flex-start'
              }}
              startIcon={<Checkbox sx={{ ml: -0.7, mr: -1 }} checked={publishedOnly} />}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 14
                }}>
                {t('filters:publishedInitiatives')}
              </Typography>
            </Button>

            <OrderSelection
              sortValue={order}
              sortItems={[
                {
                  value: 'default',
                  label: t('filters:default'),
                  icon: ({ sx, fontSize }) => <Tag sx={sx} fontSize={fontSize} />
                },
                {
                  value: 'name',
                  label: t('filters:alphabetic'),
                  icon: ({ sx, fontSize }) => <SortByAlpha sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'start_date',
                  label: t('initiatives:orders:startDate'),
                  icon: ({ sx, fontSize }) => <Timer sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'end_date',
                  label: t('initiatives:orders:endDate'),
                  icon: ({ sx, fontSize }) => <TimerOff sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'published',
                  label: t('initiatives:orders:published'),
                  icon: ({ sx, fontSize }) => <CloudUpload sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                }
              ]}
              onSortValueChange={(value) => setOrder(value)}
              sortMode={sortModeAsc ? 'asc' : 'desc'}
              onSortModeClick={() => setSortModeAsc(!sortModeAsc)}
              sx={{
                flex: '1 0 220px',
                width: 220
              }}
            />
          </FlexBox>
        }
        rightcomponent={
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(campaignFormActions.clear());
              navigate(`/home/brand/${props.brandId}/campaigns/new-campaign`);
            }}
            sx={{ ml: 'auto', color: 'white', minWidth: 240 }}>
            <Add sx={{ mr: 1 }} />
            {t('initiatives:campaigns:createCampaign')}
          </Button>
        }
      />

      {readCampaignsRequest.inProgress && !ownedCampaigns.length ? (
        <ActivityIndicator />
      ) : ownedCampaigns.length ? (
        <FlexBox
          flexDirection="row"
          gap={2}
          px="6%"
          sx={{
            mb: 'auto',
            flexWrap: 'wrap'
          }}>
          {ownedCampaigns.map((campaign) => (
            <InitiativeCard
              key={`campaign-${campaign.id}`}
              type="campaign"
              campaignData={campaign}
              brandId={props.brandId}
            />
          ))}
        </FlexBox>
      ) : (
        <NoDataBanner />
      )}

      {Boolean(ownedCampaigns.length) && (
        <Pagination
          count={campaigns.pagination?.totalPages}
          page={campaigns.pagination?.number ? campaigns.pagination.number + 1 : 1}
          onChange={(e, page) => fetchCampaigns(page - 1)}
          shape="rounded"
          sx={{
            alignSelf: 'center',
            p: 2,
            pt: 4
          }}
        />
      )}
    </>
  );
}
