import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageblePayload } from '../../../entities/Common';
import { Flyer, FlyersApiResponse } from '../../../entities/Flyer';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  allFlyers: [] as Flyer[],
  brandFlyers: {
    brandId: 0 as number,
    flyers: [] as Flyer[],
    pagination: undefined as PageblePayload | undefined
  },

  requests: {
    readFlyers: requestState(),
    findFlyer: requestState(),
    addFlyer: requestState(),
    updateFlyer: requestState(),
    deleteFlyer: requestState()
  }
};

export const flyerSlice = createSlice({
  name: 'flyer',
  initialState,
  reducers: {
    // read flyers
    readFlyersRequest(state) {
      mutateRequestState(state.requests.readFlyers);
    },
    readFlyersSuccess(state, action: PayloadAction<Flyer[]>) {
      state.allFlyers = action.payload;
      mutateSuccessState(state.requests.readFlyers);
    },
    readBrandFlyersSuccess(
      state,
      action: PayloadAction<{ brandId: number; data: FlyersApiResponse }>
    ) {
      state.brandFlyers.brandId = action.payload.brandId;
      state.brandFlyers.flyers = action.payload.data.content;
      delete (action.payload.data as any).content;
      state.brandFlyers.pagination = action.payload.data;
      mutateSuccessState(state.requests.readFlyers);
    },
    readFlyersError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readFlyers, action.payload.error);
    },
    readFlyersParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readFlyers, action.payload.parseError);
    },

    // find flyer
    findFlyerRequest(state) {
      mutateRequestState(state.requests.findFlyer);
    },
    findFlyerSuccess(state) {
      mutateSuccessState(state.requests.findFlyer);
    },
    findFlyerError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findFlyer, action.payload.error);
    },
    findFlyerParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findFlyer, action.payload.parseError);
    },

    // find flyer
    addFlyerRequest(state) {
      mutateRequestState(state.requests.addFlyer);
    },
    addFlyerSuccess(state) {
      mutateSuccessState(state.requests.addFlyer);
    },
    addFlyerError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addFlyer, action.payload.error);
    },

    // find flyer
    updateFlyerRequest(state) {
      mutateRequestState(state.requests.updateFlyer);
    },
    updateFlyerSuccess(state) {
      mutateSuccessState(state.requests.updateFlyer);
    },
    updateFlyerError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateFlyer, action.payload.error);
    },

    // find flyer
    deleteFlyerRequest(state) {
      mutateRequestState(state.requests.deleteFlyer);
    },
    deleteFlyerSuccess(state) {
      mutateSuccessState(state.requests.deleteFlyer);
    },
    deleteFlyerError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteFlyer, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const flyersActions = flyerSlice.actions;

export default flyerSlice.reducer;
