import React, { useEffect, useState } from 'react';
import { Close, ImageOutlined, Upload } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useFiles from '../../hooks/useFiles';
import PathFormatter from '../../utils/PathFormatter';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface UploadImgProps {
  title: string;
  remoteFileName?: string;
  onUploaded?: (filename: string) => void;
  onDelete?: () => void;
  disabled?: boolean;
  maxSize?: number;
  helperText?: string;
}
export default function UploadImg(props: UploadImgProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { uploadFile } = useFiles();
  const [uploadingFiles, setUploadingFiles] = useState<FileList | null>();
  const [localUrl, setLocalUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  useEffect(() => {
    if (uploadingFiles) setLocalUrl(URL.createObjectURL(uploadingFiles[0]));
    else setLocalUrl('');
  }, [uploadingFiles]);

  const uploadImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUploading(true);

      if (props.maxSize && e.target.files[0].size > props.maxSize) {
        alert(t('errors:fileSizeOvershoot'));
        setUploading(false);
        return;
      }
      setUploadingFiles(e.target.files);

      const filename = await uploadFile(e.target.files[0]);

      if (props.onUploaded && filename) props.onUploaded(filename);
      setUploading(false);
    }
  };

  return (
    <Box>
      <FlexBox alignItems="flex-end" sx={{ mb: 1.5, mt: 0.5 }} gap={1}>
        <IconButton
          size="large"
          onClick={() => setModalOpen(true)}
          disabled={!uploadingFiles && !props.remoteFileName}>
          <ImageOutlined
            sx={{ m: -2, height: 40, width: 40 }}
            htmlColor={
              uploadingFiles || props.remoteFileName ? theme.palette.tertiary.main : 'lightgray'
            }
          />
        </IconButton>
        <Box flex={1}>
          <FlexBox alignItems="center">
            <Typography
              fontWeight={uploadingFiles || props.remoteFileName ? '600' : 'initial'}
              fontStyle={'italic'}
              fontSize={12}
              onClick={() => {
                if (uploadingFiles || props.remoteFileName) setModalOpen(true);
              }}
              sx={{
                color: uploadingFiles || props.remoteFileName ? 'initial' : 'lightgray',
                mb: -0.1,
                mr: 2,
                wordBreak: 'break-all',
                ':hover': {
                  textDecoration: uploadingFiles || props.remoteFileName ? 'underline' : 'initial',
                  cursor: uploadingFiles || props.remoteFileName ? 'pointer' : 'auto'
                }
              }}>
              {uploadingFiles && uploadingFiles[0]
                ? uploadingFiles[0].name
                : props.remoteFileName ?? 'Nessuna foto'}
            </Typography>
            {(uploadingFiles || props.remoteFileName) && (
              <StyledTooltip arrow title="Rimuovi">
                <IconButton
                  disabled={props.disabled}
                  size="small"
                  sx={{ p: 0, ml: 'auto' }}
                  onClick={() => {
                    setUploadingFiles(null);
                    if (props.onDelete) props.onDelete();
                  }}>
                  <Close fontSize="small" />
                </IconButton>
              </StyledTooltip>
            )}
          </FlexBox>
          {uploading && (
            <Grow in={uploading}>
              <LinearProgress sx={{ mt: 1 }} />
            </Grow>
          )}
        </Box>
      </FlexBox>
      {/* @ts-ignore */}
      <Button
        disabled={props.disabled}
        variant="outlined"
        component="label"
        fullWidth
        sx={{
          p: 1.5,
          flexDirection: 'column',
          border: '2px dashed !important'
        }}>
        <Upload />
        <div style={{ fontSize: 14 }}>{props.title}</div>
        <div style={{ textTransform: 'none', color: 'gray', fontSize: 11 }}>
          Only JPG, JPEG, PNG formats.
        </div>
        {props.helperText && (
          <div
            style={{
              textTransform: 'none',
              color: 'gray',
              fontSize: 11,
              maxWidth: '100%',
              textAlign: 'center',
              lineBreak: 'auto'
            }}>
            {props.helperText}
          </div>
        )}
        <input
          hidden
          accept=".jpg, .jpeg, .png"
          type="file"
          onClick={(e) => ((e.target as HTMLInputElement).value = '')}
          onChange={uploadImg}
        />
      </Button>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 1,
            boxShadow: 24
          }}>
          <Box position="relative" p={8}>
            <IconButton
              size="small"
              onClick={() => setModalOpen(false)}
              sx={{ position: 'absolute', top: 8, right: 8 }}>
              <Close />
            </IconButton>
            <img
              style={{
                maxWidth: '60vw',
                maxHeight: '60vh'
              }}
              src={
                localUrl ||
                (props.remoteFileName ? PathFormatter.relativeToAbsolute(props.remoteFileName) : '')
              }
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
