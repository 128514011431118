import * as z from 'zod';
import { PageblePayloadSchema } from './Common';

export const EmbeddedBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string().nullable(),
  logo: z.string(),
  createdAt: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional()
});
export type EmbeddedBrand = z.infer<typeof EmbeddedBrandSchema>;

export const BrandsApiResponseSchema = PageblePayloadSchema.extend({
  content: z.array(EmbeddedBrandSchema)
});
export type BrandsApiResponse = z.infer<typeof BrandsApiResponseSchema>;
