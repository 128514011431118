import React, { useCallback, useEffect, useState } from 'react';
import { Add, CloudUpload, SortByAlpha, Tag, Timer, TimerOff } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAssets from '../../../../../hooks/useAssets';
import useInitiatives from '../../../../../hooks/useInitiatives';
import { challengeFormActions } from '../../../../../redux/local_store/slices/challengeForm';
import DateFormatter from '../../../../../utils/DateFormatter';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import { DatePicker } from '../../../../components/DateTimePicker';
import InitiativeCard from '../../../../components/InitiativeCard';
import NoDataBanner from '../../../../components/NoDataBanner';
import OrderSelection from '../../../../components/OrderSelection';
import { SearchBar } from '../../../../components/SearchBar';
import { FlexBox, StyledTooltip } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';

interface BrandChallengesProps {
  brandId: number;
}
export default function BrandChallenges(props: BrandChallengesProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { defaultAsset } = useAssets();
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const { readChallenges, readChallengesRequest, challenges } = useInitiatives();

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('initiatives:challenges:challenges'),
        path: `/home/brand/${props.brandId}/challenges`
      };
    }, [props.brandId])
  );

  // Filters
  const [keyWord, setKeyWord] = useState('');
  const [publishedOnly, setPublishedOnly] = useState(false);

  const [startDateBefore, setStartDateBefore] = React.useState<number | null>(null);
  const [startDateAfter, setStartDateAfter] = React.useState<number | null>(null);

  const [endDateBefore, setEndDateBefore] = React.useState<number | null>(null);
  const [endDateAfter, setEndDateAfter] = React.useState<number | null>(null);

  const [order, setOrder] = useState<string>('default');
  const [sortModeAsc, setSortModeAsc] = React.useState<boolean>(true);

  const ownedChallenges = challenges.data.filter(
    (challenge) => challenge.brand.id === props.brandId
  );

  const fetchChallenges = useCallback(
    async (page?: number) => {
      const startDateRange =
        !startDateAfter && !startDateBefore
          ? undefined
          : DateFormatter.toRange(startDateAfter, startDateBefore);
      const endDateRange =
        !endDateAfter && !endDateBefore
          ? undefined
          : DateFormatter.toRange(endDateAfter, endDateBefore);

      await readChallenges(
        props.brandId,
        page,
        keyWord || undefined,
        publishedOnly || undefined,
        startDateRange || undefined,
        endDateRange || undefined,
        order === 'default' ? undefined : `${sortModeAsc ? '' : '-'}${order}`
      );
    },
    [
      props.brandId,
      keyWord,
      order,
      sortModeAsc,
      publishedOnly,
      startDateAfter,
      startDateBefore,
      endDateAfter,
      endDateBefore,
      order
    ]
  );

  useEffect(() => {
    fetchChallenges();
  }, [fetchChallenges]);

  return (
    <>
      <Toolbar
        mt={1}
        mb={4}
        leftcomponent={
          <FlexBox gap={1} rowGap={1.6} flexDirection="row" flexWrap="wrap" flex={'1 1 60%'}>
            <SearchBar
              value={keyWord}
              onChange={_.debounce((text) => setKeyWord(text), 800)}
              placeholder={t('initiatives:challenges:searchChallenge')}
              backgroundColor="white"
              sx={{ flex: '1 0 180px', height: 38, py: 0 }}
            />

            <Button
              onClick={() => setPublishedOnly(!publishedOnly)}
              variant="outlined"
              sx={{
                height: 40,
                backgroundColor: 'white',
                color: 'gray',
                borderColor: '#c4c4c4',
                textTransform: 'none',
                flex: '1 0 180px',
                justifyContent: 'flex-start'
              }}
              startIcon={<Checkbox sx={{ ml: -0.7, mr: -1 }} checked={publishedOnly} />}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 14
                }}>
                {t('filters:publishedInitiatives')}
              </Typography>
            </Button>

            <OrderSelection
              sortValue={order}
              sortItems={[
                {
                  value: 'default',
                  label: t('filters:default'),
                  icon: ({ sx, fontSize }) => <Tag sx={sx} fontSize={fontSize} />
                },
                {
                  value: 'name',
                  label: t('filters:alphabetic'),
                  icon: ({ sx, fontSize }) => <SortByAlpha sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'start_date',
                  label: t('initiatives:orders:startDate'),
                  icon: ({ sx, fontSize }) => <Timer sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'end_date',
                  label: t('initiatives:orders:endDate'),
                  icon: ({ sx, fontSize }) => <TimerOff sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'published',
                  label: t('initiatives:orders:published'),
                  icon: ({ sx, fontSize }) => <CloudUpload sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                }
              ]}
              onSortValueChange={(value) => setOrder(value)}
              sortMode={sortModeAsc ? 'asc' : 'desc'}
              onSortModeClick={() => setSortModeAsc(!sortModeAsc)}
              sx={{
                flex: '1 0 220px',
                width: 220
              }}
            />

            <FlexBox
              flex={`4 0 ${tabletWidth ? 386 : 728}px`}
              gap={1}
              rowGap={1.6}
              sx={{ flexWrap: 'wrap' }}>
              <FlexBox flex={'1 0 360px'}>
                {/* startDateAfter */}
                <DatePicker
                  label={`${t('filters:startsAfter')}:`}
                  value={startDateAfter}
                  onChange={(value: number | null) => {
                    setStartDateAfter(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        '& >div': {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          backgroundColor: 'white'
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
                {/* startDateBefore */}
                <DatePicker
                  label={`${t('filters:startsBefore')}:`}
                  value={startDateBefore}
                  minDate={startDateAfter}
                  onChange={(value: number | null) => {
                    setStartDateBefore(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        ml: '-1px',
                        '& >div': {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          backgroundColor: 'white'
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </FlexBox>

              <FlexBox flex={'1 0 360px'}>
                {/* endDateAfter */}
                <DatePicker
                  label={`${t('filters:endsAfter')}:`}
                  value={endDateAfter}
                  onChange={(value: number | null) => {
                    setEndDateAfter(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        '& >div': {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          backgroundColor: 'white'
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
                {/* endDateBefore */}
                <DatePicker
                  label={`${t('filters:endsBefore')}:`}
                  value={endDateBefore}
                  minDate={endDateAfter}
                  onChange={(value: number | null) => {
                    setEndDateBefore(value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      sx={{
                        flex: '1 0 180px',
                        ml: '-1px',
                        '& >div': {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          backgroundColor: 'white'
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...params}
                    />
                  )}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        }
        rightcomponent={
          <StyledTooltip title={defaultAsset ? '' : t('asset:texts:cannotCreateInitiatives')} arrow>
            <span>
              <Button
                size="large"
                variant="contained"
                color="primary"
                disabled={!defaultAsset}
                onClick={() => {
                  dispatch(challengeFormActions.clear());
                  navigate(`/home/brand/${props.brandId}/challenges/new-challenge`);
                }}
                sx={{ ml: 'auto', color: 'white', minWidth: 240 }}>
                <Add sx={{ mr: 1 }} />
                {t('initiatives:challenges:createChallenge')}
              </Button>
            </span>
          </StyledTooltip>
        }
      />

      {readChallengesRequest.inProgress && !ownedChallenges.length ? (
        <ActivityIndicator />
      ) : ownedChallenges.length ? (
        <FlexBox
          flexDirection="row"
          gap={2}
          px="6%"
          sx={{
            mb: 'auto',
            flexWrap: 'wrap'
          }}>
          {ownedChallenges.map((challenge) => (
            <InitiativeCard
              key={`challenge-${challenge.id}`}
              type="challenge"
              challengeData={challenge}
              brandId={props.brandId}
            />
          ))}
        </FlexBox>
      ) : (
        <NoDataBanner />
      )}

      {Boolean(ownedChallenges.length) && (
        <Pagination
          count={challenges.pagination?.totalPages}
          page={challenges.pagination?.number ? challenges.pagination.number + 1 : 1}
          onChange={(e, page) => fetchChallenges(page - 1)}
          shape="rounded"
          sx={{
            alignSelf: 'center',
            p: 2,
            pt: 4
          }}
        />
      )}
    </>
  );
}
