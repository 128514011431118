import * as z from 'zod';

export enum RoleSlugs {
  ADMIN = 'admin',
  BRAND = 'brand'
}

export const RoleSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.nativeEnum(RoleSlugs)
});
export type Role = z.infer<typeof RoleSchema>;

export const RolesAPIResponseSchema = z.array(RoleSchema);
