import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset, AssetsApiResponse } from '../../../entities/Asset';
import { PageblePayload } from '../../../entities/Common';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  defaultAsset: undefined as Asset | null | undefined,
  assets: {
    data: [] as Asset[],
    pagination: undefined as PageblePayload | undefined
  },

  requests: {
    getAssets: requestState(),
    findAsset: requestState(),
    findDefaultAsset: requestState(),
    addAsset: requestState(),
    updateAsset: requestState(),
    deleteAsset: requestState()
  }
};

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // reset
    reset(state) {
      state.defaultAsset = initialState.defaultAsset;
      state.assets = initialState.assets;
      state.requests = initialState.requests;
    },

    // read flyers
    getAssetsRequest(state) {
      mutateRequestState(state.requests.getAssets);
    },
    getAssetsSuccess(state, action: PayloadAction<AssetsApiResponse>) {
      state.assets.data = action.payload.content;
      delete (action.payload as any).content;
      state.assets.pagination = action.payload;
      mutateSuccessState(state.requests.getAssets);
    },
    getAssetsError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.getAssets, action.payload.error);
    },
    getAssetsParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.getAssets, action.payload.parseError);
    },

    // find flyer
    findAssetRequest(state) {
      mutateRequestState(state.requests.findAsset);
    },
    findAssetSuccess(state) {
      mutateSuccessState(state.requests.findAsset);
    },
    findAssetError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findAsset, action.payload.error);
    },
    findAssetParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findAsset, action.payload.parseError);
    },

    // find default flyer
    findDefaultAssetRequest(state) {
      mutateRequestState(state.requests.findDefaultAsset);
    },
    findDefaultAssetSuccess(state, action: PayloadAction<Asset | null>) {
      state.defaultAsset = action.payload;
      mutateSuccessState(state.requests.findDefaultAsset);
    },
    findDefaultAssetError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findDefaultAsset, action.payload.error);
    },
    findDefaultAssetParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findDefaultAsset, action.payload.parseError);
    },

    // add Asset
    addAssetRequest(state) {
      mutateRequestState(state.requests.addAsset);
    },
    addAssetSuccess(state) {
      mutateSuccessState(state.requests.addAsset);
    },
    addAssetError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addAsset, action.payload.error);
    },

    // update asset
    updateAssetRequest(state) {
      mutateRequestState(state.requests.updateAsset);
    },
    updateAssetSuccess(state) {
      mutateSuccessState(state.requests.updateAsset);
    },
    updateAssetError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateAsset, action.payload.error);
    },

    // delete asset
    deleteAssetRequest(state) {
      mutateRequestState(state.requests.deleteAsset);
    },
    deleteAssetSuccess(state) {
      mutateSuccessState(state.requests.deleteAsset);
    },
    deleteAssetError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteAsset, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const assetActions = assetSlice.actions;

export default assetSlice.reducer;
