import React from 'react';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useTranslation } from 'react-i18next';
import { Language } from '../../entities/Common';
import { languages } from '../screens/Dashboard/Settings';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface LanguageSelectionProps {
  fillForEachLanguage: boolean;
  onFillForEachLanguageBtnClick?: () => void;
  selectedLanguage?: 'it' | 'en';
  onLanguageSelected?: (lng: Language) => void;
}

export default function LanguageSelection(props: LanguageSelectionProps) {
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const { t } = useTranslation();

  return (
    <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
      <StyledTooltip
        arrow
        placement="top"
        title={t('initiatives:texts:fillForEachLanguageInfo')}
        enterDelay={1200}
        enterNextDelay={1200}>
        <Button
          onClick={() => {
            if (props.onFillForEachLanguageBtnClick) props.onFillForEachLanguageBtnClick();
          }}
          variant={props.fillForEachLanguage ? 'contained' : 'outlined'}
          disableElevation
          sx={{
            height: 40,
            color: (theme) => (props.fillForEachLanguage ? 'white' : theme.palette.primary.main),
            backgroundColor: (theme) =>
              !props.fillForEachLanguage ? 'white' : theme.palette.primary.main
          }}
          startIcon={
            props.fillForEachLanguage ? (
              <CheckCircle sx={{ mx: 0.5 }} />
            ) : (
              <CircleOutlined sx={{ mx: 0.5 }} color="disabled" />
            )
          }>
          {t('initiatives:fillForEachLanguage')}
        </Button>
      </StyledTooltip>

      <Grow in={!props.fillForEachLanguage} unmountOnExit>
        <div>
          <StyledTooltip
            arrow
            placement="right"
            title={t('initiatives:texts:changeLanguageInfo')}
            enterDelay={1200}
            enterNextDelay={1200}>
            <FormControl
              sx={{
                backgroundColor: 'white',
                minWidth: 250
              }}>
              <InputLabel>{t('initiatives:changeLanguage')}</InputLabel>
              <Select
                size="small"
                label={t('initiatives:changeLanguage')}
                value={props.selectedLanguage}
                onChange={(e) => {
                  if (props.onLanguageSelected)
                    props.onLanguageSelected(e.target.value as Language);
                }}>
                {languages.map((l) => (
                  <MenuItem key={l.slug} value={l.slug}>
                    {getUnicodeFlagIcon(l.unicode_slug)} {l.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledTooltip>
        </div>
      </Grow>
    </FlexBox>
  );
}
