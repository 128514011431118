import React, { useContext, useEffect, useState } from 'react';
import {
  AccessTime,
  Check,
  Code,
  DeleteForever,
  InfoOutlined,
  KeyboardArrowLeft,
  Toll
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import { DictionaryString, Language } from '../../../../entities/Common';
import { Flyer } from '../../../../entities/Flyer';
import useFlyers from '../../../../hooks/useFlyers';
import { currentLanguage } from '../../../../i18n';
import PathFormatter from '../../../../utils/PathFormatter';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../components/Breadcrumbs';
import { DateTimePicker } from '../../../components/DateTimePicker';
import EditableCard from '../../../components/EditableCard';
import IncrementDecrementBtn from '../../../components/IncrementDecrementBtn';
import LanguageSelection from '../../../components/LanguageSelection';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import UploadImg from '../../../components/UploadImg';
import { languages } from '../Settings';

const EmptyDictionaryString = {
  it: '',
  en: ''
};
interface FlyerProps {
  mode: 'create' | 'edit';
}

export default function AddEditFlyer(props: FlyerProps) {
  const {
    findFlyer,
    addFlyer,
    addFlyerRequest,
    updateFlyer,
    updateFlyerRequest,
    deleteFlyer,
    deleteFlyerRequest
  } = useFlyers();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { flyerId, brandId } = useParams();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');

  // Generic states
  const [data, setData] = useState<Flyer>();
  const [edited, setEdited] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<undefined | 'it' | 'en'>(
    currentLanguage()
  );
  const [fillForEachLanguage, setFillForEachLanguage] = useState(true);
  const [deleteFlyerDialogOpened, setDeleteFlyerDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);

  // Form states
  const [frontImage, setFrontImage] = useState(data?.front ?? EmptyDictionaryString);
  const [code, setCode] = useState(data?.back ?? null);
  const [qrCode, setQrCode] = useState(data?.qrCode ?? null);
  const [link, setLink] = useState(data?.link ?? null);
  const [expiredAt, setExpiredAt] = useState(data?.expiredAt ?? null);
  const [wakaCost, setWakaCost] = useState(data?.wakaCost ?? 0);

  // Errors
  const [frontImageErr, setFrontImageErr] = useState<string>();
  const [codeErr, setCodeErr] = useState<string>();
  const [linkErr, setLinkErr] = useState<string>();
  const [expiredAtErr, setExpiredAtErr] = useState<string>();
  const [wakaCostErr, setWakaCostErr] = useState<string>();

  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('flyer:addFlyer'),
            path: `/flyers/${brandId}/add_flyer`
          }
        : {
            title: t('flyer:editFlyer'),
            path: `/flyers/${brandId}/${flyerId}/edit`
          };
    }, [flyerId, props.mode])
  );

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const fecthFlyerData = async () => {
    if (flyerId) {
      const flyer = await findFlyer(parseInt(flyerId));
      if (flyer) setData(flyer as Flyer);
    }
  };

  useEffect(() => {
    fecthFlyerData();
  }, [flyerId]);

  useEffect(() => {
    if (data) {
      setFrontImage(
        Object.keys(data.front).reduce((accumulator, key) => {
          return {
            ...accumulator,
            [key]: PathFormatter.absoluteToRelative(data.front[key as Language])
          };
        }, {}) as DictionaryString
      );
      setCode(data.back);
      setQrCode(data.qrCode ?? null);
      setLink(data.link);
      setExpiredAt(data.expiredAt);
      setWakaCost(data.wakaCost);
    }
  }, [data]);

  const validation = () => {
    let validated = true;
    const sortedLanguages = Object.keys({ it: '', en: '' }).sort((a) =>
      a === currentLanguage() ? -1 : 1
    );
    for (let i = 0; i < sortedLanguages.length && validated; i++) {
      const lang = sortedLanguages[i];
      if (lang) {
        if (!frontImage[lang as Language] && validated) {
          setFrontImageErr(t('errors:obligatoryField'));
          setSelectedLanguage(lang as Language);
          validated = false;
        }
        if (expiredAt && expiredAt < Date.now()) {
          setExpiredAtErr(t('errors:passedEndDate'));
          validated = false;
        }
        if (wakaCost && !code) {
          setCodeErr(t('flyer:texts:consistentBackErr'));
          validated = false;
        }
      }
    }
    return validated;
  };

  const triggerUpdateFlyer = async () => {
    if (!flyerId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && frontImage && brandId) {
      const payload = {
        brandId: parseInt(brandId),
        front: frontImage,
        back: code,
        qrCode,
        link,
        expiredAt,
        wakaCost: wakaCost > 0 ? wakaCost : 0
      };
      const success = await updateFlyer(parseInt(flyerId), payload);
      openSnackBar(
        success,
        t('flyer:snackbar:flyerUpdateSuccess'),
        t('flyer:snackbar:flyerUpdateError')
      );
    }
  };
  const triggerAddFlyer = async () => {
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && frontImage && brandId) {
      const payload = {
        brandId: parseInt(brandId),
        front: frontImage,
        back: code,
        qrCode,
        link,
        expiredAt,
        wakaCost: wakaCost > 0 ? wakaCost : 0
      };
      const success = await addFlyer(payload);
      openSnackBar(success, t('flyer:snackbar:flyerAddSuccess'), t('flyer:snackbar:flyerAddError'));
      if (success) navigate(-1);
    }
  };
  const triggerDeleteFlyer = async () => {
    if (flyerId) {
      const success = await deleteFlyer(parseInt(flyerId));
      openSnackBar(
        success,
        t('flyer:snackbar:flyerDeleteSuccess'),
        t('flyer:snackbar:flyerDeleteError')
      );
      setDeleteFlyerDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  const DictionaryUploadImgField = (props: {
    label: string;
    dictionaryField: DictionaryString;
    setter: React.Dispatch<
      React.SetStateAction<{
        en: string;
        it: string;
      }>
    >;
    error: string | undefined;
    errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>;
    disabled?: boolean;
  }) => {
    return (
      <div>
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            border: `1px solid ${props.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
            position: 'relative'
          }}>
          <label
            style={{
              color: props.disabled
                ? 'rgba(0, 0, 0, 0.38)'
                : props.error
                ? '#d32f2f'
                : 'rgba(0, 0, 0, 0.6)',
              fontSize: '1rem',
              position: 'absolute',
              left: 0,
              top: 0,
              transform: 'translate(8px, -9px) scale(0.75)',
              transformOrigin: 'top left',
              background: 'white',
              paddingInline: 6,
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              letterSpacing: '0.00938em'
            }}>
            {`${
              fillForEachLanguage
                ? languages.map((l) => getUnicodeFlagIcon(l.unicode_slug)).join(' ')
                : getUnicodeFlagIcon(
                    languages.find((l) => l.slug === selectedLanguage)?.unicode_slug ?? ''
                  )
            } ${props.label}`}
          </label>
          {selectedLanguage && (
            <UploadImg
              title={`${props.dictionaryField[selectedLanguage] ? 'change' : 'upload'} image`}
              remoteFileName={
                props.dictionaryField[selectedLanguage]
                  ? PathFormatter.absoluteToRelative(props.dictionaryField[selectedLanguage])
                  : undefined
              }
              onDelete={() => {
                props.setter({ ...props.dictionaryField, [selectedLanguage]: '' });
              }}
              onUploaded={(filename) => {
                const newData = { ...props.dictionaryField, [selectedLanguage]: filename };
                if (fillForEachLanguage)
                  _.forEach(newData, (value, key) => {
                    newData[key as Language] = filename;
                  });
                props.setter(newData);
                props.errorSetter(undefined);
                setEdited(true);
              }}
            />
          )}
        </Box>

        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            mt: 0.5,
            mx: '14px',
            display: props.error ? 'block' : 'none'
          }}>
          {props.error}
        </Typography>
      </div>
    );
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between" mb={3}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {props.mode === 'create' ? t('flyer:addFlyer') : t('flyer:editFlyer')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('flyer:texts:createPageDescription')
                : t('flyer:texts:editPageDescription')}
            </Typography>
          </Box>
        </FlexBox>
        <Toolbar
          my={4}
          leftcomponent={
            <LanguageSelection
              fillForEachLanguage={fillForEachLanguage}
              onFillForEachLanguageBtnClick={() => setFillForEachLanguage(!fillForEachLanguage)}
              selectedLanguage={selectedLanguage}
              onLanguageSelected={(lng: Language) => setSelectedLanguage(lng)}
            />
          }
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addFlyerRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddFlyer}>
                {t('flyer:createFlyer')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data}
                  onClick={() => setDeleteFlyerDialogOpen(true)}
                  sx={{ background: 'white' }}>
                  {t('flyer:deleteFlyer')}
                </Button>
                <LoadingButton
                  loading={updateFlyerRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateFlyer}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>
      {/* Body */}
      {props.mode === 'create' || (props.mode === 'edit' && data) ? (
        <FlexBox
          flexDirection={tabletWidth ? 'column' : 'row'}
          gap={2}
          flex={1}
          alignItems={tabletWidth ? 'center' : 'flex-start'}
          width="90%"
          m="auto">
          <FlexBox flexDirection="column" flex={1} gap={2} sx={{ width: '100%' }}>
            <EditableCard
              hideHeaderDivider
              title={t('flyer:pageSections:generalInfo')}
              icon={<InfoOutlined color="primary" />}
              headerSx={{
                pr: 0
              }}>
              <Stack gap={2} p={2} pt={0}>
                {/* Front Image */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:setFronImage')}</p>
                  <p>{t('flyer:formInputHelperTexts:rememberToCompileImageForEachLanguage')}</p>
                </InfoText>
                <DictionaryUploadImgField
                  label={t('flyer:formInputLabels:front') + '*'}
                  dictionaryField={frontImage}
                  setter={setFrontImage}
                  error={frontImageErr}
                  errorSetter={setFrontImageErr}
                />

                {/* Link */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:setOfferLink')}</p>
                </InfoText>
                <TextField
                  size="small"
                  label={t('flyer:formInputLabels:link')}
                  error={Boolean(linkErr)}
                  helperText={linkErr}
                  InputLabelProps={{ shrink: true }}
                  value={link}
                  onChange={(e) => {
                    const newTextValue = e.target.value;
                    setLink(newTextValue);
                    setLinkErr(undefined);
                    setEdited(true);
                  }}
                />
              </Stack>
            </EditableCard>
          </FlexBox>

          <FlexBox flexDirection="column" flex={1} gap={2} sx={{ width: '100%' }}>
            <EditableCard
              hideHeaderDivider
              title={t('flyer:pageSections:codes')}
              icon={<Code color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                {/* Code */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:setCode')}</p>
                </InfoText>
                <TextField
                  size="small"
                  label={t('flyer:formInputLabels:code')}
                  error={Boolean(codeErr)}
                  helperText={codeErr}
                  InputLabelProps={{ shrink: true }}
                  value={code}
                  onChange={(e) => {
                    const newTextValue = e.target.value;
                    setCode(newTextValue);
                    setCodeErr(undefined);
                    setEdited(true);
                  }}
                />

                {/* QrCode */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:setQrCode')}</p>
                </InfoText>
                <TextField
                  size="small"
                  label={t('flyer:formInputLabels:qrCode')}
                  InputLabelProps={{ shrink: true }}
                  value={qrCode}
                  onChange={(e) => {
                    const newTextValue = e.target.value;
                    setQrCode(newTextValue);
                    setEdited(true);
                  }}
                />
              </Stack>
            </EditableCard>

            <EditableCard
              hideHeaderDivider
              title={t('flyer:pageSections:duration')}
              icon={<AccessTime color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                {/* Expiration Date */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:specifyExpirationDate')}</p>
                </InfoText>
                <DateTimePicker
                  label={t('flyer:formInputLabels:expirationDate')}
                  value={expiredAt}
                  disablePast
                  onChange={(value: number | null) => {
                    setExpiredAt(value);
                    setExpiredAtErr(undefined);
                    setEdited(true);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      error={Boolean(expiredAtErr)}
                      helperText={expiredAtErr}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '&>label': {
                          color: expiredAtErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                        },
                        '&>div>fieldset': {
                          borderColor: expiredAtErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </EditableCard>
          </FlexBox>

          <FlexBox flexDirection="column" flex={1} gap={2} sx={{ width: '100%' }}>
            <EditableCard
              hideHeaderDivider
              title={t('flyer:pageSections:cost')}
              icon={<Toll color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                {/* Waka Cost */}
                <InfoText>
                  <p>{t('flyer:formInputHelperTexts:setCost')}</p>
                </InfoText>
                <FlexBox gap={1}>
                  <NumericFormat
                    sx={{ flex: 1 }}
                    size="small"
                    error={Boolean(wakaCostErr)}
                    helperText={wakaCostErr}
                    label={t('flyer:formInputLabels:wakaCost') + '*'}
                    customInput={TextField}
                    InputLabelProps={{ shrink: true }}
                    value={wakaCost}
                    onChange={(e) => {
                      setWakaCost(parseInt(e.target.value));
                      setEdited(true);
                      setWakaCostErr(undefined);
                    }}
                  />
                  <IncrementDecrementBtn
                    onIncrement={() => {
                      setWakaCost((wakaCost) => {
                        if (wakaCost) return wakaCost + 1;
                        else return 1;
                      });
                      setEdited(true);
                      setWakaCostErr(undefined);
                    }}
                    onDecrement={() => {
                      setWakaCost((wakaCost) => {
                        if (wakaCost) return wakaCost - 1;
                        else return 0;
                      });
                      setEdited(true);
                      setWakaCostErr(undefined);
                    }}
                  />
                </FlexBox>
              </Stack>
            </EditableCard>
          </FlexBox>
        </FlexBox>
      ) : (
        <ActivityIndicator />
      )}

      {/* Form Errors Dialog */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('errors:compilationErrors')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Delete Challenge Dialog */}
      <Dialog open={deleteFlyerDialogOpened} onClose={() => setDeleteFlyerDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('flyer:texts:deleteFlyerInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteFlyerDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteFlyerRequest.inProgress}
              onClick={triggerDeleteFlyer}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
