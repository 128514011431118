import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Palette {
    tertiary: Palette['primary'];
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#43ECB5',
      light: '#44ecb6'
    },
    secondary: {
      main: '#001B32'
    },
    tertiary: {
      main: '#002a4d',
      light: '#0065B8',
      dark: '#001629'
    },
    error: {
      main: '#d32f2f',
      light: '#d49494'
    },
    background: {
      default: '#001b32',
      paper: '#002a4d'
    },
    text: {
      primary: '#000000',
      secondary: '#44ecb6'
    }
  },
  typography: {
    fontSize: 14
  }
});
