import { CampaignRequest, ChallengeRequest, QuestRequest } from '../../entities/Initiatives';
import { Repository } from '../Repository';

class InitiativesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api'
    });
  }

  // Getters
  async getInitiativeTypes() {
    const { data } = await this.axios.get(`/initiative_types`);
    return data;
  }

  async getChallenges(
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    startDateRange?: string,
    endDateRange?: string,
    sort?: string
  ) {
    const { data } = await this.axios.get(`/initiatives`, {
      params: {
        brand_id: brandId,
        type: 'challenge',
        page,
        name,
        published,
        start_date: startDateRange,
        end_date: endDateRange,
        sort
      }
    });
    return data;
  }

  async getQuests(
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    startDateRange?: string,
    endDateRange?: string,
    sort?: string
  ) {
    const { data } = await this.axios.get(`/initiatives`, {
      params: {
        brand_id: brandId,
        type: 'quest',
        page,
        name,
        published,
        start_date: startDateRange,
        end_date: endDateRange,
        sort
      }
    });
    return data;
  }

  async findInitiative(initiativeId: number) {
    const { data } = await this.axios.get(`/initiatives/${initiativeId}`);
    return data;
  }

  async getCampaigns(
    brandId: number,
    page?: number,
    name?: string,
    published?: boolean,
    sort?: string
  ) {
    const { data } = await this.axios.get(`/campaigns`, {
      params: { brand_id: brandId, page, name, published, sort }
    });
    return data;
  }

  async findCampaign(campaignId: number) {
    const { data } = await this.axios.get(`/campaigns/${campaignId}`);
    return data;
  }

  // Updaters
  async updateChallenge(challengeId: number, challengeRequestData: ChallengeRequest) {
    const { data } = await this.axios.put(
      `/initiatives/challenge/${challengeId}`,
      challengeRequestData
    );
    return data;
  }

  async updateQuest(questId: number, questRequestData: QuestRequest) {
    const { data } = await this.axios.put(`/initiatives/quest/${questId}`, questRequestData);
    return data;
  }

  async updateCampaign(campaignId: number, campaignRequestData: CampaignRequest) {
    const { data } = await this.axios.put(`/campaigns/${campaignId}`, campaignRequestData);
    return data;
  }

  async publish(initiativeId: number) {
    const { data } = await this.axios.patch(`initiatives/${initiativeId}`);
    return data;
  }

  // Adders
  async addChallenge(challengeRequestData: ChallengeRequest) {
    const { data } = await this.axios.post('/initiatives/challenge', challengeRequestData);
    return data;
  }

  async addQuest(questRequestData: QuestRequest) {
    const { data } = await this.axios.post('/initiatives/quest', questRequestData);
    return data;
  }

  async addCampaign(campaignData: CampaignRequest) {
    const { data } = await this.axios.post('/campaigns', campaignData);
    return data;
  }

  // Deleters
  async deleteInitiative(initiativeId: number) {
    const { data } = await this.axios.delete(`/initiatives/${initiativeId}`);
    return data;
  }

  async deleteCampaign(campaignId: number) {
    const { data } = await this.axios.delete(`/campaigns/${campaignId}`);
    return data;
  }
}

export default new InitiativesRepository();
