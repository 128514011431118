import React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { RoleIds } from '../../../entities/User';
import useUser from '../../../hooks/useUser';
import { BreadcrumbsProvider } from '../../components/Breadcrumbs/BreadcrumbsProvider';
import Drawer, { drawerHeight } from '../../components/Drawer';
import Snackbar, { SnackbarProvider } from '../../components/Snackbar';
import Account from './Account';
import Stats from './Stats';
import Communities from './Communities';
import Flyers from './Flyers';
import Home from './Home';
import Settings from './Settings';
import Users from './Users';

export default function Dashboard() {
  const { me } = useUser();

  return (
    <BreadcrumbsProvider>
      <Drawer />
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: '#001b32',
              light: '#90DDF4',
              dark: '#08487d'
            },
            secondary: {
              main: '#43ebb5',
              light: '#91F3D4',
              dark: '#17DE9F'
            },
            tertiary: {
              main: '#002a4d',
              light: '#0065B8',
              dark: '#001629'
            },
            info: {
              main: '#ffffff'
            },
            error: {
              main: '#d32f2f',
              light: '#ff8585'
            },
            background: {
              default: '#eff3f6',
              paper: '#ffffff'
            },
            mode: 'light'
          }
        })}>
        <Box
          sx={{
            marginLeft: '65px',
            minHeight: `calc(100vh - ${drawerHeight}px)`,
            maxHeight: `calc(100vh - ${drawerHeight}px)`,
            overflow: 'auto',
            display: 'flex',
            backgroundColor: (theme) => theme.palette.background.default
          }}>
          <SnackbarProvider>
            <Snackbar>
              <Routes>
                <Route path="*" element={<Home />} />
                <Route path="/flyers/*" element={<Flyers />} />
                {me?.role?.id === RoleIds.ADMIN_USER_ID && (
                  <Route path="/communities/*" element={<Communities />} />
                )}
                {me?.role?.id === RoleIds.ADMIN_USER_ID && (
                  <Route path="/users/*" element={<Users />} />
                )}
                <Route path="/account" element={<Account />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/stats" element={<Stats />} />
              </Routes>
            </Snackbar>
          </SnackbarProvider>
        </Box>
      </ThemeProvider>
    </BreadcrumbsProvider>
  );
}
