import React from 'react';
import { DatePickerProps, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import itLocale from 'date-fns/locale/it';

export function DateTimePicker(props: DateTimePickerProps<any, number | null>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
      <MuiDateTimePicker {...props} />
    </LocalizationProvider>
  );
}

export function DatePicker(props: DatePickerProps<any, number | null>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
      <MuiDatePicker {...props} />
    </LocalizationProvider>
  );
}
