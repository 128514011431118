import React, { useContext } from 'react';
import Alert from '@mui/material/Alert';
import MuiSnackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { Outlet } from 'react-router-dom';
import { SnackbarContext } from './SnackbarProvider';

export default function Snackbar(props: SnackbarProps) {
  const { snackbar, setSnackbar } = useContext(SnackbarContext);

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    if (snackbar.onClose) snackbar.onClose();
    setSnackbar({
      ...snackbar,
      opened: false
    });
  };

  return (
    <>
      {props.children}
      <MuiSnackbar
        open={snackbar.opened}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ mt: -2.4 }}>
        <Alert
          variant="standard"
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            boxShadow: (theme) => theme.shadows[24],
            borderRadius: 1,
            whiteSpace: 'pre-line'
          }}>
          {snackbar.msg}
        </Alert>
      </MuiSnackbar>
      <Outlet />
    </>
  );
}
