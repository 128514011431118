class DateFormatter {
  toRange(dateA?: number | string | null, dateB?: number | string | null) {
    const expiresAfterDate = new Date(dateA ?? 0);
    const from =
      expiresAfterDate.getFullYear() +
      '-' +
      ('0' + (expiresAfterDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + expiresAfterDate.getDate()).slice(-2);

    const expiresBeforeDate = new Date(dateB ?? 4102444800000);
    const to =
      expiresBeforeDate.getFullYear() +
      '-' +
      ('0' + (expiresBeforeDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + expiresBeforeDate.getDate()).slice(-2);
    return `${from},${to}`;
  }
}

export default new DateFormatter();
