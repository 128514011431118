import * as z from 'zod';
import { FlyerSchema } from './Flyer';
import { LocationRequestSchema, LocationSchema } from './Location';

export enum TokenAssignmentMode {
  RANDOM_CONTENT = 'random_content',
  FIRST_FIXED = 'first_fixed',
  LAST_FIXED = 'last_fixed',
  FIRST_AND_LAST_FIXED = 'first_and_last_fixed',
  TOKEN_FIXED = 'token_fixed',
  ORDERED = 'ordered'
}

export const TokenSchema = z.object({
  id: z.number(),
  flyer: FlyerSchema.nullable(),
  position: z.number().nullable().optional(),
  isFinal: z.boolean().optional(),
  locations: z.array(LocationSchema).optional()
});
export type Token = z.infer<typeof TokenSchema>;

export const TokenRequestSchema = z.object({
  id: z.number().optional(),
  flyerId: z.number().optional(),
  position: z.number().nullable().optional(),
  isFinal: z.boolean().optional(),
  locations: z.array(LocationRequestSchema).optional(),
  delete: z.boolean().optional()
});
export type TokenRequest = z.infer<typeof TokenRequestSchema>;
