import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import CommunitiesRepository from '../api/repositories/CommunitiesRepository';
import {
  Community,
  CommunitiesApiResponse,
  CommunitiesApiResponseSchema,
  CommunitySchema,
  CommunityAddEdit
} from '../entities/Communities';
import { communitiesActions } from '../redux/be_interaction_store/slices/communities';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type CommunitiesState = RootState['BEInteraction']['communities'];

type UseCommunities = {
  communities: CommunitiesState['communities'];

  readCommunities: (
    page?: number,
    name?: string,
    activationCode?: string,
    sort?: string
  ) => Promise<void>;
  readCommunitiesRequest: CommunitiesState['requests']['readCommunities'];

  findCommunity: (communityId: number) => Promise<Community | void>;
  findCommunityRequest: CommunitiesState['requests']['findCommunity'];

  addCommunity: (communityRequestData: CommunityAddEdit) => Promise<boolean>;
  addCommunityRequest: CommunitiesState['requests']['addCommunity'];

  updateCommunity: (
    communityId: number,
    communityRequestData: CommunityAddEdit
  ) => Promise<boolean>;
  updateCommunityRequest: CommunitiesState['requests']['updateCommunity'];

  deleteCommunity: (communityId: number) => Promise<boolean>;
  deleteCommunityRequest: CommunitiesState['requests']['deleteCommunity'];
};

export default function useCommunities(): UseCommunities {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      CommunitiesRepository.updateAuthToken(accessToken);
      CommunitiesRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const communities = useSelector(
    (state: RootState) => state.BEInteraction.communities.communities
  );
  const readCommunitiesRequest = useSelector(
    (state: RootState) => state.BEInteraction.communities.requests.readCommunities
  );

  const readCommunities: UseCommunities['readCommunities'] = useCallback(
    async (page?: number, name?: string, activationCode?: string, sort?: string) => {
      dispatch(communitiesActions.readCommunitiesRequest());
      let response: unknown;

      try {
        response = await CommunitiesRepository.getAllCommunities(page, name, activationCode, sort);
      } catch (error) {
        console.warn(error);
        dispatch(
          communitiesActions.readCommunitiesError({ error: new RequestError(error as AxiosError) })
        );
      }

      const parseResult = CommunitiesApiResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          communitiesActions.readCommunitiesParseError({
            parseError: new RequestParseError(parseResult.error, 'UseCommunities.readCommunities')
          })
        );
        return;
      }

      dispatch(communitiesActions.readCommunitiesSuccess(response as CommunitiesApiResponse));
    },
    [dispatch]
  );

  const findCommunityRequest = useSelector(
    (state: RootState) => state.BEInteraction.communities.requests.findCommunity
  );

  const findCommunity: UseCommunities['findCommunity'] = useCallback(
    async (communityId: number) => {
      dispatch(communitiesActions.findCommunityRequest());
      let response: unknown;

      try {
        response = await CommunitiesRepository.findCommunity(communityId);
      } catch (error) {
        dispatch(
          communitiesActions.findCommunityError({
            error: new RequestError(error as AxiosError)
          })
        );
        return;
      }
      const parseResult = CommunitySchema.safeParse(response);
      if (!parseResult.success) {
        dispatch(
          communitiesActions.findCommunityParseError({
            parseError: new RequestParseError(parseResult.error, 'UseCommunities.findCommunity')
          })
        );
        return;
      }
      dispatch(communitiesActions.findCommunitySuccess());
      return response as Community;
    },
    [dispatch]
  );

  const addCommunityRequest = useSelector(
    (state: RootState) => state.BEInteraction.communities.requests.addCommunity
  );

  const addCommunity: UseCommunities['addCommunity'] = useCallback(
    async (communitiesRequestData: CommunityAddEdit) => {
      dispatch(communitiesActions.addCommunityRequest());

      try {
        await CommunitiesRepository.createCommunity(communitiesRequestData);
      } catch (error) {
        dispatch(
          communitiesActions.addCommunityError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(communitiesActions.addCommunitySuccess());
      return true;
    },
    [dispatch]
  );

  const updateCommunityRequest = useSelector(
    (state: RootState) => state.BEInteraction.communities.requests.updateCommunity
  );

  const updateCommunity: UseCommunities['updateCommunity'] = useCallback(
    async (communityId: number, communityRequestData: CommunityAddEdit) => {
      dispatch(communitiesActions.updateCommunityRequest());

      try {
        await CommunitiesRepository.updateCommunity(communityId, communityRequestData);
      } catch (error) {
        dispatch(
          communitiesActions.updateCommunityError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(communitiesActions.updateCommunitySuccess());
      return true;
    },
    [dispatch]
  );

  const deleteCommunityRequest = useSelector(
    (state: RootState) => state.BEInteraction.communities.requests.deleteCommunity
  );

  const deleteCommunity: UseCommunities['deleteCommunity'] = useCallback(
    async (communityId: number) => {
      dispatch(communitiesActions.deleteCommunityRequest());

      try {
        await CommunitiesRepository.deleteCommunity(communityId);
      } catch (error) {
        dispatch(
          communitiesActions.deleteCommunityError({
            error: new RequestError(error as AxiosError)
          })
        );
        return false;
      }

      dispatch(communitiesActions.deleteCommunitySuccess());
      return true;
    },
    [dispatch]
  );

  return {
    communities,
    readCommunities,
    readCommunitiesRequest,

    findCommunity,
    findCommunityRequest,

    addCommunity,
    addCommunityRequest,

    updateCommunity,
    updateCommunityRequest,

    deleteCommunity,
    deleteCommunityRequest
  };
}
