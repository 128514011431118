import { AssetRequest } from '../../entities/Asset';
import { Repository } from '../Repository';

class AssetRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api/assets'
    });
  }

  async get(brandId?: number, name?: string, isDefault?: boolean) {
    const { data } = await this.axios.get(``, {
      params: {
        brand_id: brandId,
        file: name,
        default: isDefault
      }
    });
    return data;
  }

  async find(assetId: number) {
    const { data } = await this.axios.get(`/${assetId}`);
    return data;
  }

  async add(requestData: AssetRequest) {
    const { data } = await this.axios.post('', requestData);
    return data;
  }

  async update(assetId: number, requestData: AssetRequest) {
    const { data } = await this.axios.put(`/${assetId}`, requestData);
    return data;
  }

  async delete(assetId: number) {
    const { data } = await this.axios.delete(`/${assetId}`);
    return data;
  }
}

export default new AssetRepository();
