import { combineReducers } from '@reduxjs/toolkit';
import asset from './slices/asset';
import authentication from './slices/authentication';
import brand from './slices/brand';
import communities from './slices/communities';
import flyer from './slices/flyer';
import initiatives from './slices/initiatives';
import user from './slices/user';

export const BEInteraction = combineReducers({
  authentication,
  brand,
  flyer,
  initiatives,
  user,
  communities,
  asset
});
