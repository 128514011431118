import React, { useMemo } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface OrderSelectionProps {
  sortItems: {
    value: string;
    label: string;
    icon: (props: {
      sx?: SxProps<Theme>;
      fontSize?: 'small' | 'inherit' | 'large' | 'medium';
    }) => any;
    doubleOrder?: boolean;
  }[];
  sortValue: string;
  onSortValueChange?: (value: string) => void;
  sortMode?: 'asc' | 'desc';
  onSortModeClick?: () => void;
  sx?: SxProps<Theme>;
}

export default function OrderSelection(props: OrderSelectionProps) {
  const { t } = useTranslation();
  const doubleOrderItem = useMemo(() => {
    return props.sortItems.find((item) => item.value === props.sortValue)?.doubleOrder;
  }, [props.sortItems, props.sortValue]);

  return (
    <FlexBox flexDirection={'column'} sx={{ position: 'relative', ...props.sx }}>
      <StyledTooltip
        arrow
        title={
          props.sortMode === 'asc'
            ? t('common:ascending')
            : props.sortMode === 'desc'
            ? t('common:descending')
            : undefined
        }>
        <Fade in={doubleOrderItem}>
          <Button
            sx={{
              p: 0,
              borderRadius: 1,
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              minWidth: 32,
              height: '100%',
              border: '1px solid #c4c4c4',
              borderRight: 'none',
              backgroundColor: 'white',
              position: 'absolute',
              left: 0
            }}
            onClick={props.onSortModeClick}>
            {props.sortMode === 'asc' ? (
              <ArrowUpward fontSize="small" />
            ) : (
              <ArrowDownward fontSize="small" />
            )}
          </Button>
        </Fade>
      </StyledTooltip>

      <FormControl
        size="small"
        sx={{
          alignSelf: 'flex-end',
          transition: 'width .2s',
          transitionTimingFunction: 'ease',
          width: doubleOrderItem ? 'calc(100% - 32px)' : '100%'
        }}>
        <InputLabel size="small">{t('filters:sortBy')}</InputLabel>
        <Select
          sx={{
            borderRadius: 1,
            borderTopLeftRadius: doubleOrderItem ? 0 : undefined,
            borderBottomLeftRadius: doubleOrderItem ? 0 : undefined,
            backgroundColor: 'white'
          }}
          value={props.sortValue}
          label={t('filters:sortBy')}
          onChange={(e) => {
            if (props.onSortValueChange) props.onSortValueChange(e.target.value);
          }}>
          {props.sortItems.map((item) => (
            <MenuItem key={`sort-value-${item.value}`} value={item.value}>
              <FlexBox alignItems="center">
                <item.icon sx={{ mr: 1 }} fontSize="small" />
                <Typography fontSize={15}>{item.label}</Typography>
              </FlexBox>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FlexBox>
  );
}
