import { useContext, useEffect, useState } from 'react';
import { BreadcrumbsContext, Breadcrumb } from './BreadcrumbsProvider';

export default function useBreadcrumbs(crumb: Breadcrumb) {
  const { breadcrumbs, add } = useContext(BreadcrumbsContext);
  const [memoizedBreadcrumb, setMemoizedBreadcrumb] = useState(crumb);

  useEffect(() => {
    setMemoizedBreadcrumb(crumb);
  }, [crumb, memoizedBreadcrumb.path]);

  useEffect(() => {
    if (memoizedBreadcrumb) add(crumb);
  }, [add, memoizedBreadcrumb]);

  return breadcrumbs;
}
