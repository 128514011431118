import * as React from 'react';
import { useState } from 'react';
import { Campaign, DirectionsRun, PinDrop, Store } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoleIds } from '../../../../../entities/User';
import useBrand from '../../../../../hooks/useBrand';
import useUser from '../../../../../hooks/useUser';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { FlexBox } from '../../../../components/StyledComponents';
import BrandCampaigns from './BrandCampaigns';
import BrandChallenges from './BrandChallenges';
import BrandHome from './BrandHome';
import BrandQuests from './BrandQuests';

const tabHeaderHeight = 54;

export default function Brand() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentBrand, readBrand } = useBrand();
  const { id } = useParams();
  const { me } = useUser();

  React.useEffect(() => {
    if (!id || me?.role?.id === RoleIds.BRAND_USER_ID) return;
    readBrand(parseInt(id));
  }, [me, id]);

  const [tabIndex, setTabIndex] = useState<number>(
    location.pathname.includes('challenges')
      ? 1
      : location.pathname.includes('quests')
      ? 2
      : location.pathname.includes('campaigns')
      ? 3
      : 0
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);

    if (newValue === 0) navigate(`/home/brand/${currentBrand?.id}`);
    if (newValue === 1) navigate(`/home/brand/${currentBrand?.id}/challenges`);
    if (newValue === 2) navigate(`/home/brand/${currentBrand?.id}/quests`);
    if (newValue === 3) navigate(`/home/brand/${currentBrand?.id}/campaigns`);
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  return (
    <FlexBox flexDirection="column" width="100%">
      <Box
        flex={1}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          background: (theme) => theme.palette.background.paper,
          maxHeight: tabHeaderHeight
        }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          sx={{
            maxHeight: tabHeaderHeight,
            ' & div': {
              height: tabHeaderHeight
            },
            '& button': {
              minHeight: tabHeaderHeight
            }
          }}>
          <Tab label={currentBrand?.name} iconPosition="start" icon={<Store />} {...a11yProps(0)} />
          <Tab
            label={t('initiatives:challenges:challenges')}
            iconPosition="start"
            icon={<DirectionsRun />}
            {...a11yProps(1)}
          />
          <Tab
            label={t('initiatives:quests:quests')}
            iconPosition="start"
            icon={<PinDrop />}
            {...a11yProps(2)}
          />
          <Tab
            label={t('initiatives:campaigns:campaigns')}
            iconPosition="start"
            icon={<Campaign />}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>

      <FlexBox
        flexDirection="column"
        sx={{
          flex: 1,
          p: 1,
          pb: 0,
          overflowY: 'auto'
        }}>
        {currentBrand && currentBrand.id === parseInt(id || '') ? (
          <Routes>
            <Route path="/" element={<BrandHome />} />
            <Route path="/challenges" element={<BrandChallenges brandId={currentBrand.id} />} />
            <Route path="/quests" element={<BrandQuests brandId={currentBrand.id} />} />
            <Route path="/campaigns" element={<BrandCampaigns brandId={currentBrand.id} />} />
          </Routes>
        ) : (
          <ActivityIndicator />
        )}
      </FlexBox>
    </FlexBox>
  );
}
