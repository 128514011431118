import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { FlexBox } from './StyledComponents';

interface ToolbarProps {
  leftcomponent?: React.ReactNode;
  rightcomponent?: React.ReactNode;
  children?: React.ReactNode;
}

export default function Toolbar(props: ToolbarProps & BoxProps) {
  return (
    <FlexBox flexDirection="row" alignItems="center" gap={1} {...props}>
      {props.leftcomponent && (
        <>
          <Box borderTop="1px solid lightgray" height={0} width="calc(6% - 8px)" borderRadius={5} />
          {props.leftcomponent}
        </>
      )}
      <Box borderTop="1px solid lightgray" height={0} flex={'20 1 100px'} borderRadius={5} />
      {props.rightcomponent && (
        <>
          {props.rightcomponent}
          <Box borderTop="1px solid lightgray" height={0} width="calc(6% - 8px)" borderRadius={5} />
        </>
      )}
      {props.children}
    </FlexBox>
  );
}
