import React, { useContext, useEffect, useState } from 'react';
import {
  AccessTime,
  Campaign as CampaignIcon,
  Check,
  DeleteForever,
  InfoOutlined,
  KeyboardArrowLeft
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Flyer } from '../../../../../entities/Flyer';
import { Campaign } from '../../../../../entities/Initiatives';
import { TokenAssignmentMode } from '../../../../../entities/Token';
import useBrand from '../../../../../hooks/useBrand';
import useInitiatives from '../../../../../hooks/useInitiatives';
import { campaignFormActions } from '../../../../../redux/local_store/slices/campaignForm';
import { RootState } from '../../../../../redux/store';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import { DateTimePicker } from '../../../../components/DateTimePicker';
import EditableCard from '../../../../components/EditableCard';
import FlyersContainer from '../../../../components/FlyersContainer';
import { SnackbarContext } from '../../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';

interface AddEditCampaignProps {
  mode: 'create' | 'edit';
}
export default function AddEditCampaign(props: AddEditCampaignProps) {
  // hooks
  const { brandId, campaignId } = useParams();
  const {
    findCampaign,
    updateCampaign,
    updateCampaignRequest,
    addCampaign,
    addCampaignRequest,
    deleteCampaign,
    deleteCampaignRequest
  } = useInitiatives();
  const { brands, readBrands } = useBrand();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const dispatch = useDispatch();
  const theme = useTheme();

  // Generic states
  const [data, setData] = useState<Campaign>();
  const [deleteCampaignDialogOpen, setDeleteCampaignDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);
  const campaignFormId = useSelector((state: RootState) => state.local.campaignForm.campaignId);
  const edited = useSelector((state: RootState) => state.local.campaignForm.edited);
  const viewOnlyMode = useSearchParams()[0].get('view_only') === 'true';

  // Form states
  const name = useSelector((state: RootState) => state.local.campaignForm.inputData.name);
  const startDate = useSelector((state: RootState) => state.local.campaignForm.inputData.startDate);
  const endDate = useSelector((state: RootState) => state.local.campaignForm.inputData.endDate);
  const flyers = useSelector((state: RootState) => state.local.campaignForm.inputData.flyers);

  // error states
  const [nameErr, setNameErr] = useState<string>();
  const [startDateErr, setStartDateErr] = useState<string>();
  const [endDateErr, setEndDateErr] = useState<string>();
  const [flyersErr, setFlyersErr] = useState<string>();

  // Ui utils
  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('initiatives:campaigns:newCampaign'),
            path: `/home/brand/${brandId}/campaigns/new-campaign`
          }
        : {
            title: `${data?.name}`,
            path: `/home/brand/${brandId}/campaigns/${data?.id}`
          };
    }, [brandId, data?.id, data?.name, props.mode])
  );
  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  // Data Fetching
  const fetchCampaignData = async () => {
    if (campaignId) {
      const campaignData = await findCampaign(parseInt(campaignId));
      if (campaignData) setData(campaignData);
    }
  };

  useEffect(() => {
    const fetchUtilsData = async () => {
      if (!brands.data.length) readBrands();
    };
    fetchUtilsData();
    if (props.mode === 'edit') fetchCampaignData();
  }, [props.mode]);

  useEffect(() => {
    if (data && data.id !== campaignFormId) dispatch(campaignFormActions.clear());
    if (data && !edited) {
      dispatch(campaignFormActions.setCampaignId(data.id));
      dispatch(campaignFormActions.setBrandId(data.brand.id));
      dispatch(campaignFormActions.setName(data.name));
      dispatch(campaignFormActions.setStartDate(data.startDate));
      dispatch(campaignFormActions.setEndDate(data.endDate));
      dispatch(
        campaignFormActions.setFlyers(data.flyers ? data.flyers.map((flyer) => flyer.id) : [])
      );
    }
  }, [data]);

  // Data Validation
  const validation = () => {
    let validated = true;
    if (!name) {
      setNameErr(t('errors:obligatoryField'));
      validated = false;
    }
    if (!startDate && validated) {
      setStartDateErr(t('errors:obligatoryField'));
      validated = false;
    } else if (
      (props.mode === 'create' && startDate && startDate < Date.now() && validated) ||
      (props.mode === 'edit' &&
        startDate &&
        data?.startDate &&
        data.startDate < Date.now() &&
        startDate < data.startDate &&
        validated) ||
      (props.mode === 'edit' &&
        startDate &&
        data?.startDate &&
        data.startDate >= Date.now() &&
        startDate < Date.now() &&
        validated)
    ) {
      setStartDateErr(t('errors:passedStartDate'));
      validated = false;
    }
    if (!endDate && validated) {
      setEndDateErr(t('errors:obligatoryField'));
      validated = false;
    } else if (
      startDate &&
      endDate &&
      startDate < Date.now() &&
      endDate < Date.now() &&
      validated
    ) {
      setEndDateErr(t('errors:passedEndDate'));
      validated = false;
    } else if (endDate && startDate && endDate < startDate) {
      setEndDateErr(t('errors:endDateBeforeStartDate'));
      validated = false;
    }
    if (!flyers.length && validated) {
      setFlyersErr(t('errors:atLeast1Flyer'));
      validated = false;
    }
    return validated;
  };

  // Main interactions
  const triggerAddCampaign = async () => {
    if (!brandId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }
    if (dataValidated && name && startDate && endDate) {
      const success = await addCampaign({
        brandId: parseInt(brandId),
        name,
        startDate,
        endDate,
        flyers,
        published: false
      });
      openSnackBar(
        success,
        t('initiatives:campaigns:snackbar:campaignAddSuccess'),
        t('initiatives:campaigns:snackbar:campaignAddError')
      );
      if (success) {
        dispatch(campaignFormActions.clear());
        dispatch(campaignFormActions.setEdited(false));
        navigate(-1);
      }
    }
  };
  const triggerUpdateCampaign = async () => {
    if (!campaignId || !brandId) return;
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }
    if (dataValidated && name && startDate && endDate) {
      const success = await updateCampaign(parseInt(campaignId), {
        brandId: parseInt(brandId),
        name,
        startDate,
        endDate,
        flyers,
        published: data?.published ?? false
      });
      openSnackBar(
        success,
        t('initiatives:campaigns:snackbar:campaignEditSuccess'),
        t('initiatives:campaigns:snackbar:campaignEditError')
      );
      dispatch(campaignFormActions.setEdited(false));
      fetchCampaignData();
    }
  };
  const triggerDeleteCampaign = async () => {
    if (campaignId) {
      const success = await deleteCampaign(parseInt(campaignId));
      openSnackBar(
        success,
        t('initiatives:campaigns:snackbar:campaignDeleteSuccess'),
        t('initiatives:campaigns:snackbar:campaignDeleteError')
      );
      setDeleteCampaignDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  // Flyer methods
  const manageFlyerSelect = async (flyer: Flyer) => {
    if (!flyersErr) setFlyersErr(undefined);
    const alreadyCheckedToken = flyers.find((fId) => fId === flyer.id);
    if (!alreadyCheckedToken) {
      dispatch(campaignFormActions.setFlyers(flyers.concat(flyer.id)));
      if (!edited) dispatch(campaignFormActions.setEdited(true));
    }
  };

  const manageFlyerDeselect = async (flyerId: number) => {
    const alreadyCheckedToken = flyers.find((fId) => fId === flyerId);
    if (alreadyCheckedToken) {
      dispatch(campaignFormActions.setFlyers(_.difference(flyers, [flyerId])));
      if (!edited) dispatch(campaignFormActions.setEdited(true));
    }
  };

  // UI
  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between" mb={3}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              <CampaignIcon sx={{ mr: 1 }} />
              {props.mode === 'create'
                ? t('initiatives:campaigns:createCampaign')
                : data?.name ?? '--'}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('initiatives:campaigns:texts:createCampaign')
                : t('initiatives:campaigns:texts:editCampaign')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addCampaignRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddCampaign}>
                {t('initiatives:campaigns:createCampaign')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteForever />}
                  disabled={!data}
                  onClick={() => setDeleteCampaignDialogOpen(true)}
                  sx={{ backgroundColor: 'white' }}>
                  {t('initiatives:campaigns:deleteCampaign')}
                </Button>
                <LoadingButton
                  loading={updateCampaignRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateCampaign}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              </>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      {props.mode === 'create' || (props.mode === 'edit' && data) ? (
        <FlexBox
          flexDirection={tabletWidth ? 'column' : 'row'}
          gap={2}
          width="88%"
          mx="auto"
          pb={4}>
          <FlexBox flexDirection="column" flex={1} gap={2}>
            <EditableCard
              hideHeaderDivider
              title={t('initiatives:campaigns:pageSections:generalInfo')}
              icon={<InfoOutlined color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                <InfoText>
                  <p>{t('initiatives:formInputHelperTexts:insertName')}</p>
                </InfoText>
                {/* Name */}
                <TextField
                  label={`${t('initiatives:formInputLabels:name')}*`}
                  value={name}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e) => {
                    dispatch(campaignFormActions.setName(e.target.value));
                    if (!edited) dispatch(campaignFormActions.setEdited(true));
                    setNameErr(undefined);
                  }}
                  error={Boolean(nameErr)}
                  helperText={nameErr}
                  disabled={viewOnlyMode}
                />
              </Stack>
            </EditableCard>
            <EditableCard
              sx={{ flex: 1 }}
              hideHeaderDivider
              title={t('initiatives:campaigns:pageSections:duration')}
              icon={<AccessTime color="primary" />}>
              <Stack gap={2} p={2} pt={0}>
                <InfoText>
                  <p>{t('initiatives:formInputHelperTexts:selectDates')}</p>
                  <p>{t('initiatives:formInputHelperTexts:rememberToPublish')}</p>
                </InfoText>
                {/* Start Date */}
                <DateTimePicker
                  label={t('initiatives:formInputLabels:startDate') + '*'}
                  value={startDate}
                  disabled={
                    viewOnlyMode ||
                    (startDate ? props.mode === 'edit' && data?.published : undefined)
                  }
                  minDateTime={
                    props.mode === 'create'
                      ? Date.now()
                      : data?.startDate && data?.startDate < Date.now() && data?.published
                      ? data?.startDate
                      : Date.now()
                  }
                  onChange={(value: number | null) => {
                    dispatch(campaignFormActions.setStartDate(value));
                    setStartDateErr(undefined);
                    if (!edited) dispatch(campaignFormActions.setEdited(true));
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      helperText={startDateErr}
                      FormHelperTextProps={{ error: Boolean(startDateErr), filled: true }}
                      sx={{
                        '&>label': {
                          color: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)'
                        },
                        '&>div>fieldset': {
                          borderColor: startDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
                {/* End Date */}
                <DateTimePicker
                  label={t('initiatives:formInputLabels:endDate') + '*'}
                  value={endDate}
                  minDateTime={startDate && startDate < Date.now() ? Date.now() : startDate}
                  disabled={viewOnlyMode}
                  onChange={(value: number | null) => {
                    dispatch(campaignFormActions.setEndDate(value));
                    setEndDateErr(undefined);
                    if (!edited) dispatch(campaignFormActions.setEdited(true));
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      helperText={endDateErr}
                      FormHelperTextProps={{ error: Boolean(endDateErr) }}
                      sx={{
                        '&>label': {
                          color: viewOnlyMode
                            ? 'rgba(0, 0, 0, 0.38) !important'
                            : endDateErr
                            ? '#d32f2f'
                            : 'rgba(0, 0, 0, 0.6)'
                        },
                        '&>div>fieldset': {
                          borderColor: endDateErr ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </EditableCard>
          </FlexBox>
          <FlexBox flexDirection="column" flex={2} gap={2}>
            {brandId && (
              <FlyersContainer
                iconColor={theme.palette.primary.main}
                brandId={parseInt(brandId)}
                mode={TokenAssignmentMode.RANDOM_CONTENT}
                onFlyerSelected={manageFlyerSelect}
                onFlyerDeselected={manageFlyerDeselect}
                initiativeTokens={flyers.map((fId) => {
                  return { flyerId: fId };
                })}
                disableAll={viewOnlyMode}
                errorMsg={flyersErr}
              />
            )}
          </FlexBox>
        </FlexBox>
      ) : (
        <ActivityIndicator />
      )}

      {/* Dialogs */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('errors:compilationErrors')}.
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
      <Dialog open={deleteCampaignDialogOpen} onClose={() => setDeleteCampaignDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('initiatives:texts:deleteInitiativeInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteCampaignDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteCampaignRequest.inProgress}
              onClick={triggerDeleteCampaign}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
