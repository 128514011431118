import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RootState } from './redux/store';
import { theme } from './theme';
import { AuthenticationProvider } from './ui/components/AuthenticationProvider';
import Dashboard from './ui/screens/Dashboard';
import Login from './ui/screens/Login';

function App() {
  const customTheme = createTheme(theme);
  const navigate = useNavigate();
  const signedIn = useSelector((state: RootState) => state.BEInteraction.authentication.signedIn);

  useEffect(() => {
    if (signedIn === false) {
      navigate('/login', { replace: true });
    }
  }, [signedIn]);

  return (
    <ThemeProvider theme={customTheme}>
      <DndProvider backend={HTML5Backend}>
        <AuthenticationProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </AuthenticationProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
