import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageblePayload } from '../../../entities/Common';
import {
  Campaign,
  CampaignsAPIResponse,
  Challenge,
  EmbeddedInitiative,
  InitiativeAPIResponse,
  InitiativeType,
  Quest
} from '../../../entities/Initiatives';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  initiativeTypes: [] as InitiativeType[],
  challenges: {
    data: [] as EmbeddedInitiative[],
    pagination: undefined as PageblePayload | undefined
  },
  quests: {
    data: [] as EmbeddedInitiative[],
    pagination: undefined as PageblePayload | undefined
  },
  campaigns: {
    data: [] as Campaign[],
    pagination: undefined as PageblePayload | undefined
  },

  requests: {
    readChallenges: requestState(),
    updateChallenge: requestState(),
    addChallenge: requestState(),
    deleteChallenge: requestState(),

    readQuests: requestState(),
    updateQuest: requestState(),
    addQuest: requestState(),
    deleteQuest: requestState(),

    readCampaigns: requestState(),
    findCampaign: requestState(),
    updateCampaign: requestState(),
    addCampaign: requestState(),
    deleteCampaign: requestState(),

    findInitiative: requestState(),
    readInitiativeTypes: requestState(),

    publishInitiative: requestState(),

    addFlyerToInitiative: requestState(),
    updateFlyerInInitiative: requestState(),
    removeFlyerFromInitiative: requestState(),
    massiveUpdateFlyersInInitiative: requestState()
  }
};

export const initiativesSlice = createSlice({
  name: 'initiatives',
  initialState,
  reducers: {
    // Initiative
    // Find
    findInitiativeRequest(state) {
      mutateRequestState(state.requests.findInitiative);
    },
    findInitiativeSuccess(state) {
      mutateSuccessState(state.requests.findInitiative);
    },
    findInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findInitiative, action.payload.error);
    },
    findInitiativeParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findInitiative, action.payload.parseError);
    },
    // Types
    readInitiativeTypesRequest(state) {
      mutateRequestState(state.requests.readInitiativeTypes);
    },
    readInitiativeTypesSuccess(state, action: PayloadAction<InitiativeType[]>) {
      state.initiativeTypes = action.payload;
      mutateSuccessState(state.requests.readInitiativeTypes);
    },
    readInitiativeTypesError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readInitiativeTypes, action.payload.error);
    },
    readInitiativeTypesParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readInitiativeTypes, action.payload.parseError);
    },
    // publishInitiative
    publishInitiativeRequest(state) {
      mutateRequestState(state.requests.publishInitiative);
    },
    publishInitiativeSuccess(state) {
      mutateSuccessState(state.requests.publishInitiative);
    },
    publishInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.publishInitiative, action.payload.error);
    },

    // Challenges
    // Read Challenges
    readChallengesRequest(state) {
      mutateRequestState(state.requests.readChallenges);
    },
    readChallengesSuccess(state, action: PayloadAction<InitiativeAPIResponse>) {
      state.challenges.data = action.payload.content as Challenge[];
      delete (action.payload as any).content;
      state.challenges.pagination = action.payload;
      mutateSuccessState(state.requests.readChallenges);
    },
    readChallengesError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readChallenges, action.payload.error);
    },
    readChallengesParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readChallenges, action.payload.parseError);
    },
    // Update Challenge
    updateChallengeRequest(state) {
      mutateRequestState(state.requests.updateChallenge);
    },
    updateChallengeSuccess(state) {
      mutateSuccessState(state.requests.updateChallenge);
    },
    updateChallengeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateChallenge, action.payload.error);
    },
    // Add Challenge
    addChallengeRequest(state) {
      mutateRequestState(state.requests.addChallenge);
    },
    addChallengeSuccess(state) {
      mutateSuccessState(state.requests.addChallenge);
    },
    addChallengeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addChallenge, action.payload.error);
    },
    // Delete Challenge
    deleteChallengeRequest(state) {
      mutateRequestState(state.requests.deleteChallenge);
    },
    deleteChallengeSuccess(state) {
      mutateSuccessState(state.requests.deleteChallenge);
    },
    deleteChallengeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteChallenge, action.payload.error);
    },
    // Quests
    // Read Quests
    readQuestsRequest(state) {
      mutateRequestState(state.requests.readQuests);
    },
    readQuestsSuccess(state, action: PayloadAction<InitiativeAPIResponse>) {
      state.quests.data = action.payload.content as Quest[];
      delete (action.payload as any).content;
      state.quests.pagination = action.payload;
      mutateSuccessState(state.requests.readQuests);
    },
    readQuestsError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readQuests, action.payload.error);
    },
    readQuestsParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readQuests, action.payload.parseError);
    },
    // Update Quest
    updateQuestRequest(state) {
      mutateRequestState(state.requests.updateQuest);
    },
    updateQuestSuccess(state) {
      mutateSuccessState(state.requests.updateQuest);
    },
    updateQuestError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateQuest, action.payload.error);
    },
    // Add Quest
    addQuestRequest(state) {
      mutateRequestState(state.requests.addQuest);
    },
    addQuestSuccess(state) {
      mutateSuccessState(state.requests.addQuest);
    },
    addQuestError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addQuest, action.payload.error);
    },
    // Delete Quest
    deleteQuestRequest(state) {
      mutateRequestState(state.requests.deleteQuest);
    },
    deleteQuestSuccess(state) {
      mutateSuccessState(state.requests.deleteQuest);
    },
    deleteQuestError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteQuest, action.payload.error);
    },

    // Campaigns
    // Find Campaign
    findCampaignRequest(state) {
      mutateRequestState(state.requests.findCampaign);
    },
    findCampaignSuccess(state) {
      mutateSuccessState(state.requests.findCampaign);
    },
    findCampaignError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findCampaign, action.payload.error);
    },
    findCampaignParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findCampaign, action.payload.parseError);
    },
    // Read Campaigns
    readCampaignsRequest(state) {
      mutateRequestState(state.requests.readCampaigns);
    },
    readCampaignsSuccess(state, action: PayloadAction<CampaignsAPIResponse>) {
      state.campaigns.data = action.payload.content as Campaign[];
      delete (action.payload as any).content;
      state.campaigns.pagination = action.payload;
      mutateSuccessState(state.requests.readCampaigns);
    },
    readCampaignsError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readCampaigns, action.payload.error);
    },
    readCampaignsParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readCampaigns, action.payload.parseError);
    },
    // Update Campaign
    updateCampaignRequest(state) {
      mutateRequestState(state.requests.updateCampaign);
    },
    updateCampaignSuccess(state) {
      mutateSuccessState(state.requests.updateCampaign);
    },
    updateCampaignError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateCampaign, action.payload.error);
    },
    // Add Campaign
    addCampaignRequest(state) {
      mutateRequestState(state.requests.addCampaign);
    },
    addCampaignSuccess(state) {
      mutateSuccessState(state.requests.addCampaign);
    },
    addCampaignError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addCampaign, action.payload.error);
    },
    // Delete Campaign
    deleteCampaignRequest(state) {
      mutateRequestState(state.requests.deleteCampaign);
    },
    deleteCampaignSuccess(state) {
      mutateSuccessState(state.requests.deleteCampaign);
    },
    deleteCampaignError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteCampaign, action.payload.error);
    },

    // Flyer-initiative
    // add
    addFlyerToInitiativeRequest(state) {
      mutateRequestState(state.requests.addFlyerToInitiative);
    },
    addFlyerToInitiativeSuccess(state) {
      mutateSuccessState(state.requests.addFlyerToInitiative);
    },
    addFlyerToInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addFlyerToInitiative, action.payload.error);
    },
    // update
    updateFlyerInInitiativeRequest(state) {
      mutateRequestState(state.requests.updateFlyerInInitiative);
    },
    updateFlyerInInitiativeSuccess(state) {
      mutateSuccessState(state.requests.updateFlyerInInitiative);
    },
    updateFlyerInInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateFlyerInInitiative, action.payload.error);
    },
    // remove
    removeFlyerFromInitiativeRequest(state) {
      mutateRequestState(state.requests.removeFlyerFromInitiative);
    },
    removeFlyerFromInitiativeSuccess(state) {
      mutateSuccessState(state.requests.removeFlyerFromInitiative);
    },
    removeFlyerFromInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.removeFlyerFromInitiative, action.payload.error);
    },
    // massive update
    massiveUpdateFlyersInInitiativeRequest(state) {
      mutateRequestState(state.requests.massiveUpdateFlyersInInitiative);
    },
    massiveUpdateFlyersInInitiativeSuccess(state) {
      mutateSuccessState(state.requests.massiveUpdateFlyersInInitiative);
    },
    massiveUpdateFlyersInInitiativeError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.massiveUpdateFlyersInInitiative, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const initiativesActions = initiativesSlice.actions;

export default initiativesSlice.reducer;
