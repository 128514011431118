import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageblePayload } from '../../../entities/Common';
import { Community, CommunitiesApiResponse } from '../../../entities/Communities';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  communities: {
    data: [] as Community[],
    pagination: undefined as PageblePayload | undefined
  },

  requests: {
    readCommunities: requestState(),
    findCommunity: requestState(),
    addCommunity: requestState(),
    updateCommunity: requestState(),
    deleteCommunity: requestState()
  }
};

export const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {
    // Communities List
    readCommunitiesRequest(state) {
      mutateRequestState(state.requests.readCommunities);
    },
    readCommunitiesSuccess(state, action: PayloadAction<CommunitiesApiResponse>) {
      state.communities.data = action.payload.content;
      delete (action.payload as any).content;
      state.communities.pagination = action.payload;
      mutateSuccessState(state.requests.readCommunities);
    },
    readCommunitiesError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readCommunities, action.payload.error);
    },
    readCommunitiesParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readCommunities, action.payload.parseError);
    },

    // find community
    findCommunityRequest(state) {
      mutateRequestState(state.requests.findCommunity);
    },
    findCommunitySuccess(state) {
      mutateSuccessState(state.requests.findCommunity);
    },
    findCommunityError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.findCommunity, action.payload.error);
    },
    findCommunityParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.findCommunity, action.payload.parseError);
    },

    // add community
    addCommunityRequest(state) {
      mutateRequestState(state.requests.addCommunity);
    },
    addCommunitySuccess(state) {
      mutateSuccessState(state.requests.addCommunity);
    },
    addCommunityError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addCommunity, action.payload.error);
    },

    // update community
    updateCommunityRequest(state) {
      mutateRequestState(state.requests.updateCommunity);
    },
    updateCommunitySuccess(state) {
      mutateSuccessState(state.requests.updateCommunity);
    },
    updateCommunityError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateCommunity, action.payload.error);
    },

    // delete community
    deleteCommunityRequest(state) {
      mutateRequestState(state.requests.deleteCommunity);
    },
    deleteCommunitySuccess(state) {
      mutateSuccessState(state.requests.deleteCommunity);
    },
    deleteCommunityError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteCommunity, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const communitiesActions = communitiesSlice.actions;

export default communitiesSlice.reducer;
