import * as z from 'zod';
import { PageblePayloadSchema } from './Common';

export const CommunitySchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  numberOfUsers: z.number()
});
export type Community = z.infer<typeof CommunitySchema>;

export const CommunitiesApiResponseSchema = PageblePayloadSchema.extend({
  content: z.array(CommunitySchema)
});
export type CommunitiesApiResponse = z.infer<typeof CommunitiesApiResponseSchema>;

export const CommunityAddEditSchema = z.object({
  name: z.string(),
  code: z.string()
});
export type CommunityAddEdit = z.infer<typeof CommunityAddEditSchema>;
