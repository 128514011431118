import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export type RepositoryConfiguration = {
  resourceURL: string;
  apiURL: string;
  axiosConfig?: AxiosRequestConfig;
};

const MAX_REFRESH_ATTEMPTS = 1;

export class Repository {
  protected axios: AxiosInstance;
  protected refreshAttempts = 0;
  public onRefreshToken?: () => Promise<string | void>;

  constructor(configuration: RepositoryConfiguration) {
    this.axios = Axios.create({
      baseURL: `${configuration.apiURL}${configuration.resourceURL}`,
      headers: {
        'Content-Type': 'application/json',
        ...configuration.axiosConfig?.headers
      },
      ...configuration.axiosConfig
    });

    this.axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        const { status } = error.response;
        if (status === 401 && this.onRefreshToken && this.refreshAttempts < MAX_REFRESH_ATTEMPTS) {
          this.refreshAttempts++;
          try {
            // Get new tokens
            const token = await this.onRefreshToken();

            if (token) {
              this.updateAuthToken(token);

              // Re-run the request with the new token
              const res = this.axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: `Bearer ${token}`
                }
              });
              // Pretend nothing happened
              return Promise.resolve(res);
            } else return Promise.reject(error);
          } catch (_refreshError) {
            return Promise.reject(error);
          }
        } else {
          this.refreshAttempts = 0;
          return Promise.reject(error);
        }
      }
    );
  }

  updateAuthToken(token: string): void {
    this.axios.defaults.headers.common.Authorization = `Bearer ` + token;
  }
}
