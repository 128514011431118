import React from 'react';
import { DataObject } from '@mui/icons-material';
import { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FlexBox } from './StyledComponents';

interface NoDataBannerProps {
  text?: string;
  inline?: boolean;
}
export default function NoDataBanner(props: BoxProps & NoDataBannerProps) {
  const { t } = useTranslation();

  return (
    <FlexBox
      flexDirection={props.inline ? 'row' : 'column'}
      columnGap={1}
      flex={1}
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      margin="auto"
      height="100%"
      {...props}
      sx={{ opacity: 0.12, ...props.sx }}>
      <DataObject />
      <Typography fontStyle="italic" fontWeight="bold" textAlign="center" fontSize={12}>
        {props.text ?? t('common:noData')}
      </Typography>
    </FlexBox>
  );
}
