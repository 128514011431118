import * as React from 'react';
import { useEffect, useState } from 'react';
import { Key } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Grow from '@mui/material/Grow';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/wakapapa_monogram.svg';
import { ReactComponent as TextLogo } from '../../../assets/wakapapa_text_logo.svg';
import useAuthentication from '../../hooks/useAuthentication';
import { DarkBox, FlexBox, InfoText } from '../components/StyledComponents';

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState<string | undefined>();

  const { resetPassword, resetPasswordRequest } = useAuthentication();
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);

  const [password, setPassword] = useState<string>('');
  const [confirmationPassword, setConfirmationPassword] = useState<string>('');

  const [passwordErr, setPasswordErr] = useState<string>();
  const [confirmationPasswordErr, setConfirmationPasswordErr] = useState<string>();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) setToken(token);
  }, [searchParams]);

  const validation = () => {
    let validated = true;
    // eslint-disable-next-line prefer-regex-literals
    const pswRe = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    if (password && !password.match(pswRe)) {
      setPasswordErr(t('errors:notValidPsw'));
      validated = false;
    }
    if (!confirmationPassword && validated) {
      setConfirmationPasswordErr(t('errors:obligatoryField'));
      validated = false;
    }
    if ((!password || !confirmationPassword || password !== confirmationPassword) && validated) {
      setConfirmationPasswordErr(t('errors:doubleCheckPsw'));
      validated = false;
    }
    return validated;
  };

  const triggerResetPassword = async () => {
    const dataValidated = validation();
    if (!dataValidated) {
      return;
    }

    if (dataValidated && token) {
      const success = await resetPassword(token, password);
      if (success) {
        setSuccessDialogVisible(true);
      } else setErrorDialogVisible(true);
    }
  };

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#43c4ec',
            light: '#90DDF4',
            dark: '#16B0DF'
          },
          secondary: {
            main: '#43ebb5',
            light: '#91F3D4',
            dark: '#17DE9F'
          },
          tertiary: {
            main: '#002a4d',
            light: '#0065B8',
            dark: '#001629'
          },
          background: {
            default: '#001b32',
            paper: '#043c6b'
          },
          mode: 'dark'
        }
      })}>
      <DarkBox
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <FlexBox
          flexDirection="column"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '25%',
            minWidth: 400,
            mb: '10vh'
          }}>
          {/* Logo */}
          <Logo style={{ color: 'red' }} />
          <TextLogo style={{ marginTop: 18 }} />

          {/* Credentials input banner */}
          <Card
            variant="outlined"
            sx={{
              padding: 6,
              pt: 4,
              marginTop: 4,
              width: '100%'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                color: 'white'
              }}>
              <Grow
                in={
                  Boolean(resetPasswordRequest.httpError) ||
                  Boolean(resetPasswordRequest.parseError)
                }
                mountOnEnter
                unmountOnExit>
                <Alert severity="error" variant="outlined">
                  {(
                    resetPasswordRequest.httpError?.axiosError.response?.data as {
                      error: { message: string };
                    }
                  )?.error.message ?? 'An error occurred'}
                </Alert>
              </Grow>
              <FlexBox flexDirection="column" gap={2}>
                <Typography textAlign="center" fontWeight="600" fontSize={28} letterSpacing={1.2}>
                  {t('authentication:changePassword')}
                </Typography>
                <InfoText>{t('users:formInputHelperTexts:passwordCostraints')}</InfoText>
                <Box display="flex" flexDirection="row" alignItems="baseline">
                  <Key sx={{ color: 'white', mr: 3, transform: 'translateY(6px)' }} />
                  <FlexBox flexDirection="row" position="relative" width="100%">
                    <TextField
                      disabled={!token}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordErr(undefined);
                        setConfirmationPasswordErr(undefined);
                      }}
                      label={t('authentication:newPassword')}
                      variant="standard"
                      fullWidth
                      autoComplete="new-password"
                      error={Boolean(passwordErr)}
                      helperText={passwordErr}
                      sx={{
                        '& input': {
                          color: 'white'
                        },
                        '& input:-webkit-autofill': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        },
                        '& input:-webkit-autofill:focus': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        }
                      }}
                    />
                  </FlexBox>
                </Box>
              </FlexBox>
              <FlexBox flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="baseline">
                  <Key sx={{ color: 'white', mr: 3, transform: 'translateY(6px)' }} />
                  <FlexBox flexDirection="row" position="relative" width="100%">
                    <TextField
                      disabled={!token}
                      value={confirmationPassword}
                      onChange={(e) => {
                        setConfirmationPassword(e.target.value);
                        setConfirmationPasswordErr(undefined);
                      }}
                      label={t('authentication:confirmNewPassword')}
                      variant="standard"
                      fullWidth
                      autoComplete="new-confirmationPassword"
                      error={Boolean(confirmationPasswordErr)}
                      helperText={confirmationPasswordErr}
                      sx={{
                        '& input': {
                          color: 'white'
                        },
                        '& input:-webkit-autofill': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        },
                        '& input:-webkit-autofill:focus': {
                          '-webkit-box-shadow': `0 0 0 50px #043c6b inset`,
                          '-webkit-text-fill-color': `white !important`,
                          color: `white !important`
                        }
                      }}
                    />
                  </FlexBox>
                </Box>
              </FlexBox>

              <LoadingButton
                disabled={!token}
                loading={resetPasswordRequest.inProgress}
                type="submit"
                variant="contained"
                onClick={triggerResetPassword}>
                {t('authentication:changePassword')}
              </LoadingButton>
            </Box>
          </Card>
        </FlexBox>

        {/* Success Dialog */}
        <Dialog open={successDialogVisible} onClose={() => navigate('/login', { replace: true })}>
          <Alert sx={{ p: 3 }} severity="success" variant="outlined">
            <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
              {t('common:congrats')}
            </AlertTitle>
            <DialogContentText>{t('authentication:resetPasswordSuccessInfo')}</DialogContentText>
            <DialogActions sx={{ pb: 0 }}>
              <Button onClick={() => navigate('/login', { replace: true })} color="inherit">
                Ok
              </Button>
            </DialogActions>
          </Alert>
        </Dialog>

        {/* Error Dialog */}
        <Dialog open={errorDialogVisible} onClose={() => setErrorDialogVisible(false)}>
          <Alert sx={{ p: 3 }} severity="error" variant="outlined">
            <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
              {t('common:attention')}
            </AlertTitle>
            <DialogContentText>{t('authentication:resetPasswordErrorInfo')}</DialogContentText>
            <DialogActions sx={{ pb: 0 }}>
              <Button onClick={() => setErrorDialogVisible(false)} color="inherit">
                Ok
              </Button>
            </DialogActions>
          </Alert>
        </Dialog>

        {/* Version Banner */}
        <Typography
          color="white"
          margin={2}
          fontWeight={600}
          sx={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            opacity: 0.6
          }}>
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </DarkBox>
    </ThemeProvider>
  );
}
