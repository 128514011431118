const en = {
  common: {
    languageName: 'English',
    close: 'Close',
    send: 'Send',
    verify: 'Verify now',
    email: 'Email',
    emailPlaceholder: 'johndoe@wakapapa.com',
    sort: 'Sort',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    clear: 'Clear all',
    create: 'Create',
    restore: 'Restore',
    name: 'Name',
    logo: 'Logo',
    no: 'No',
    yes: 'Yes',
    attention: 'Attention',
    info: 'Info',
    informations: 'Informations',
    add: 'Add',
    edit: 'Edit',
    publish: 'Publish',
    withdraw: 'Widthdraw',
    duplicate: 'Duplicate',
    noData: 'No data',
    all: 'All',
    confirmChanges: 'Confirm changes',
    copy: 'Copy',
    filter: 'Filter',
    view: 'View',
    expired: 'Expired',
    congrats: 'Congratulations!',
    go: 'Go',
    ascending: 'Ascending',
    descending: 'Descending',
    first: 'First',
    last: 'Last',
    disabled: 'Disabled',
    activityIndicatorText: 'Please wait...',
    actions: 'Actions'
  },
  errors: {
    obligatoryField: 'This field is required',
    compilationErrors: 'There are errors in the compilation',
    obligatoryMarker: 'To be created a Quest needs to have at least one token',
    everyTokenAssigned: 'In this mode, each marker must be assigned to a token/flyer',
    moreFlyersThenMarkers: `The number of tokens/flyers selected for the initiative must be equal to or greater than the number of markers`,
    obligatoryFlyersPosition: `Each flyer must have a specific position assigned`,
    passedEndDate: 'End date cannot be passed',
    passedStartDate: 'Start date cannot be passed',
    endDateBeforeStartDate: 'End date cannot be earlier than start date',
    obligatoryBadgeField: 'Required if other badge fields are not empty',
    cannotDeleteBadge: `Cannot delete the badge if the initiative is published.`,
    cannotAddBadge: `Cannot add new badge if the initiative is published.`,
    fileSizeOvershoot: 'The file size exceeds the allowable size.',
    invalidEmail: 'It does not appear to be a valid email.',
    doubleCheckPsw: 'Passwords do not match.',
    notValidPsw: 'Inserted password seems not valid.',
    atLeast1Flyer: 'At least one flyer must be selected.',
    insertNumericValue: 'Enter a numeric character.',
    notValidValue: 'The entered value is not valid.',
    orderedFlyersValueBounds:
      'The value entered must be between 1 and the total number of flyers selected.',
    orderedFlyersAlreadyInsertedValue:
      'The entered value has already been assigned to another flyer.'
  },
  filters: {
    showFilters: 'Show filters',
    hideFilters: 'Hide filter',
    sortBy: 'Sort by',
    alphabetic: 'Alphabetic',
    flyersNotAssigned: 'Not assigned only',
    default: 'Default',
    creationDate: 'Creation Date',
    startsAfter: 'Starts after',
    startsBefore: 'Starts before',
    endsAfter: 'Ends after',
    endsBefore: 'Ends before',
    expiresAfter: 'Expires after',
    expiresBefore: 'Expires before',
    activeUsers: 'Active users',
    publishedInitiatives: 'Published only',
    userRole: 'Role',
    searchByName: 'Search by name',
    searchByUsername: 'Serach by username',
    searchByMail: 'Search by email',
    searchByActivationCode: 'Search by activation code'
  },
  mailVerification: {
    title: 'Verify your email',
    info: 'Please click on the button below to verify your email and complete the registration procedure.',
    success: `Email address succesfully verified.`,
    failed: `Something went wrong.`
  },
  authentication: {
    login: 'Login',
    logout: 'Logout',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    recoverAccess: 'Access Recovery',
    recoverAccessInfo: `Enter the email address associated with your back office account, and we will send you an email with the password recovery link.`,
    returnToLogin: 'Return to login',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    resetPasswordSuccess: 'Password reset succesfully!',
    resetPasswordFailure: 'Password reset failed.',
    changePassword: 'Change Password',
    resetRequestSuccess: 'Password reset request sent successfully!',
    resetRequestFailure: 'Password reset request failed',
    resetPasswordRequestSuccessInfo: `Password reset request has been sent successfully. Check you email inbox to complete the reset procedure.`,
    resetPasswordSuccessInfo: `Password has been reset successfully, now you can login with the new credentials.`,
    deleteAccount: 'Delete account'
  },
  navigation: {
    home: 'Home',
    communities: 'Communities',
    flyers: 'Flyers',
    account: 'Account',
    settings: 'Settings',
    users: 'Users',
    boUsers: 'Utenti Back Office',
    appUsers: 'Utenti App',
    trackingStatistics: 'Statistics',
    externalUrl: 'External link',
    externalUrlInfo:
      'You are trying to open the following external link. If you continue you will be directed to a different domain in a new tab. Proceed?'
  },
  settings: {
    chooseLanguage: 'Choose language',
    pageTitle: 'General Settings',
    pageSubtitle: `On this page you can change the general settings of the Back Office interface.`,
    languageOptions: 'Language Options',
    languageOptionsInfo: `Change the display language of the interface. The choice will not affect the compilation procedure of initiatives or flyers, in which it is possible to separately choose a specific input language.`
  },
  brand: {
    brand: 'Brand',
    brands: 'Brands',
    allBrands: 'All brands',
    selectBrand: 'Choose a Brand',
    searchBrand: 'Search for a brand',
    addBrand: 'Add Brand',
    addNewBrand: 'Add new Brand',
    websiteLink: 'Website link',

    brandInfo: 'Brand info',
    deleteBrand: 'Delete brand',
    partnershipInfo: 'Partnership info',
    texts: {
      brandSelectInfo:
        'Click on a card from the following list to go to the Brand management page.',
      brandNotFounfInfo: `Can't find your brand?`,
      addBrandInfo:
        'Fill in all the fields necessary to add a new Brand to the Wakapapa Back-office.',
      deleteBrand: 'Do you really want to cancel this brand? This operation is irreversible.',
      deleteBrandInfo: `You can decide to permanently remove this brand from the Wakapapa Back Office. You can always add it back later.`
    },
    formInputHelperTexts: {
      setBrandName: `Specify the brand name.`,
      setWebsiteLink: `Insert a reference link to the Brand website (optional).`,
      setLogo: `Upload an image (black on a white background) of the Brand logo.`
    },
    snackbar: {
      addNewBrandFailure: 'An error occurred while uploading Brand. Try again.',
      addNewBrandSuccess: 'Brand uploaded successfully!',
      deleteBrandSuccess: 'Brand successfully deleted.',
      deleteBrandError: 'An error occurred. Try again.',
      editBrandSuccess: 'Modification successful!',
      editBrandError: 'An error occurred. Try again.',
      editAssetSuccess: 'Asset updated successfully!',
      editAssetError: 'An error occurred and the Asset was not updated, please try again.'
    }
  },
  asset: {
    asset: 'Asset',
    setDefaultAsset: 'Set default asset',
    noAsset: 'No asset',
    assetList: 'Assets list',
    showAllAssets: 'Show all assets',
    addAsset: 'Create new asset',
    editAsset: 'Edit asset',
    deleteAsset: 'Delete asset',
    texts: {
      selectAsset: 'Select asset (or search for filename)',
      mandatoryToPublishInitiatives:
        'You must enter a default asset for the Brand if you want to create initiatives.',
      uploadAsset: 'Upload asset',
      assetInfo: `The 3D model that is rendered in Augmented Reality at the capture token.`,
      clickToDownload: `Click on the link to download the asset file of type USDZ.`,
      editAssetTitle: `Edit asset`,
      createAssetTitle: `New asset`,
      editAssetDescription: `On this page you can edit the template of an existing asset. The change will affect all initiatives to which this asset has been associated, whether they are public or not.
        Once the new asset has been loaded, click on the button at the top right to confirm the changes.`,
      createAssetDescription:
        'On this page you can create a new asset associated with your brand. Once the model has been loaded, click on the button at the top right to confirm the creation.',
      cannotCreateInitiatives: `You cannot create iniziative until you set a default asset for this brand.`,
      deleteAssetInfo: `Are you sure you want to delete this Asset? Each initiative associated with this asset will return to showing the default asset of the Brand.`
    },
    snackbar: {
      addAssetSuccess: 'Asset successfully added!',
      addAssetError: `An error occurred and the asset was not added, please try again.`,
      editAssetSuccess: 'Asset successfully edited!',
      editAssetError: `An error occurred and the asset was not edited, please try again.`,
      deleteAssetSuccess: 'Asset successfully deleted!',
      deleteAssetError: `An error occurred and the asset was not deleted, please try again.`
    }
  },
  flyer: {
    flyer: 'Flyer',
    flyers: 'Flyers',
    selectFlyer: 'Select flyer',
    searchFlyer: 'Search flyer',
    addFlyer: 'Add Flyer',
    editFlyer: 'Edit Flyer',
    createFlyer: 'Create Flyer',
    deleteFlyer: 'Delete Flyer',
    dragFlyer: 'Drag here a flyer',
    dragFirstFlyer: 'Drag here the first flyer',
    dragLastFlyer: 'Drag here the last flyer',
    orderFlyers: 'Order selected flyers',
    pageSections: {
      generalInfo: 'General info',
      codes: 'Codes',
      duration: 'Duration',
      cost: 'Cost'
    },
    texts: {
      flyersPageDescription: `On this page you can easily view and manage the flyers of any brand. Select a Brand in the filter section.`,
      flyersPageDescriptionAsBrand: `On this page you can easily view and manage your brand flyers.`,
      createPageDescription:
        'Create a new Flyer by carefully entering the data paying attention to their consistency. Once you have entered the data, click on the button at the top right to proceed with the creation.',
      editPageDescription:
        'On this page you can view and modify flyer data: enter the data carefully and pay attention to their consistency. Once you have entered the data, click on the button at the top right to confirm the changes.',
      deleteFlyerInfo: 'Do you really want to cancel this flyer? This operation is irreversible.',
      consistentBackErr: `If the cost in waka for this flyer is greater than 0, a code for the back of this flyer must also be present.`,
      notDeletable:
        'This flyer cannot be removed because it is associated with ongoing initiatives or campaigns.',
      orderFlyersInfo: `Each flyer must be assigned a number between 1 and the total number of selected flyers. The same number cannot be entered twice. Upon completion of the compilation, each flyer must have a position adjacent to that of those next to it, therefore each flyer (except for the extremes) must specify an integer number between its immediate successor and its immediate predecessor (Ex: 1-2-3 is valid, 1-3-4 is not).`,
      flyerShare: `Congratulations, a user shared a flyer with you! To find out how to use it, download the Wakapapa app now.`
    },
    formInputLabels: {
      front: 'Front',
      back: 'Back',
      code: 'Codice',
      qrCode: 'QrCode',
      wakaCost: 'Waka cost',
      link: 'Offer link',
      expirationDate: 'Expiration date'
    },
    formInputHelperTexts: {
      rememberToCompileImageForEachLanguage: `Remember that this field (image) must be filled in for each available language. Make sure you upload the image corresponding to the correct language.`,
      setFronImage: `Enter the image that will be shown in augmented reality when the Token is captured.`,
      setCode: `Set a promotional code for the flyer (optional if the cost is 0).`,
      setQrCode: `Enter a referral link which will later be encoded and displayed in the app as a QR image (optional).`,
      setOfferLink: `Insert a referral link to the offer page (optional).`,
      specifyExpirationDate: `Specify an expiration date (optional), after which this flyer will no longer be valid in augmented reality.`,
      setCost: `Set the cost in waka that the user will have to spend to save this flyer in their wallet.`
    },
    snackbar: {
      flyerUpdateSuccess: 'Flyer updated successfully!',
      flyerUpdateError: 'A problem has occurred and the Flyer has not been updated. Try again.',
      flyerAddSuccess: 'Flyer successfully created!',
      flyerAddError: 'There was a problem and the flyer was not created. Try again.',
      flyerDeleteSuccess: 'Flyer eliminated!',
      flyerDeleteError: 'There was a problem and the flyer was not removed. Try again.'
    }
  },
  communities: {
    communities: 'Communities',
    community: 'Community',
    communitiesList: 'Communities List',
    numberOfUsers: 'Number of Users',
    searchCommunity: 'Search Community',
    addCommunity: 'Add Community',
    editCommunity: 'Edit Community',
    deleteCommunity: 'Delete Community',
    info: 'Community Infos',
    texts: {
      editPageDescription:
        'On this page you can view and modify the Community data: enter the data carefully and pay attention to their consistency. Once you have entered the data, click on the button at the top right to confirm the changes.',
      createPageDescription:
        'Create a new Community by carefully entering the data paying attention to their consistency. Once you have entered the data, click on the button at the top right to proceed with the creation.',
      deleteCommunityInfo:
        'Do you really want to cancel this Community? This operation is irreversible.',
      communitiesListInfo:
        'Check the communities list, create a new Community or update the current ones.'
    },
    formInputLabels: {
      name: 'Name',
      code: 'Activation Code'
    },
    formInputHelperTexts: {
      insertName: 'Enter a community reference name.',
      specifyCode:
        'Specify an identification code that unites all users of the community. It should preferably be short and easy to remember.'
    },
    snackbar: {
      communityUpdateSuccess: 'Community changed successfully!',
      communityUpdateError:
        'A problem has occurred and the Community has not been updated. Try again.',
      communityAddSuccess: 'Community successfully created!',
      communityAddError: 'There was a problem and the Community was not created. Try again.',
      communityDeleteSuccess: 'Community eliminated!',
      communityDeleteError: 'There was a problem and the Community was not removed. Try again.'
    }
  },
  account: {
    pageTitle: 'Account Settings',
    pageSubtitle: `On this page you can view your Back Office account details and change your login password.`,
    email: 'Email',
    role: 'Role',
    createdAt: 'Created At',
    pageSections: {
      profileDetails: 'Profile Details',
      changePassword: 'Change Password',
      closeAccount: 'Delete Account',
      changePasswordDescription: 'Click to start the recovering password procedure.',
      closeAccountDescription:
        'You can decide to permanently remove your Back Office Wakapapa account. Click on the following button to start the procedure.'
    }
  },
  initiatives: {
    initiatives: 'Initiatives',
    registeredUsers: 'Registered users',
    changeLanguage: 'Scegli lingua di compilazione',
    fillForEachLanguage: 'Compile for each language',
    texts: {
      deleteInitiativeInfo:
        'Do you really want to cancel this initiative? This operation is irreversible.',
      changeLanguageInfo:
        'Change the language to fill in the text fields for each language available in the app.',
      fillForEachLanguageInfo:
        'If the box is checked, each text field will be filled in automatically for each language available in the app with the value entered in the selected language, overwriting what had already been written.'
    },
    initiativeCardTooltipTexts: {
      publishInitiative: `Make this initiative public. However, it will not be visible to public until the set start date is reached.`,
      cantPublishInitiative: `You cannot publish an initiative with a passed start date. Change the initiative start date first.`,
      unpublishInitiative: `Unpublish this initiative before it becomes visible to the public at the time of the start date.`,
      expiredInitiative: `The initiative has expired. You can still duplicate it or consult its specifications.`,
      onGoingInitiative: `The initiative is in progress.`,
      activeUsers: `Here is the number of users who have registered for the initiative so far.`
    },
    formInputLabels: {
      name: 'Name',
      description: 'Description',
      descriptionImage: 'Description image',
      listDescriptionImage: 'List description image',
      initiativeLogo: 'Initiative logo',
      goalDescription: 'Goal description',
      initiativeType: 'Initiative type',
      startDate: 'Start date',
      endDate: 'End date',
      prizeTitle: 'Prize title (max 7 characters)',
      prizeDescription: 'Prize description (max 30 characters)',
      unlockThreshold: 'Unlock threshold (n. of tokens)',
      wakaPrize: 'Waka prize',
      wakaTokens: 'Waka per token',
      badgeName: 'Badge name',
      badgeDescription: 'Badge description',
      badgeImage: 'Badge image',
      noFlyerMode: 'No flyer mode',
      advertising: 'Advertising initiative'
    },
    formInputHelperTexts: {
      rememberToCompileForEachLanguage: `Remember that these (textual) fields must be filled in for each available language.`,
      insertNameAndDescription: `To get started, digit the name of the initiative and a short description.`,
      insertName: `To get started, digit a name.`,
      insertLogo: `Insert an image (black on a white background) of the logo of the brand promoting the initiative.`,
      insertDescriptionImages: `Insert an image that best represents the initiative, and a version of it with specific proportions for its display in the list in the app.`,
      specifyInitiativeType: `Specifies the type of initiative. This flag is for information only and does not produce any actual changes.`,
      insertGoalDescription: `Enter a brief description of the goal to be achieved.`,
      insertPrizeTitleAndDescription: `Specify a title and a description of the reward that you receive after completing the initiative.`,
      specifyUnlockThreshold: `Specify the minimum number of tokens that must be captured to complete the initiative, unlock the reward and the badge.`,
      unlockThresholdBounds: `If the value entered is less than the number of placeholders, the user unlocks the rewards once the indicated threshold is reached. If, however, the entered value is greater than the number of placeholders, then the threshold will be updated with the number of placeholders entered.`,
      specifyWakaTokensAndPrize: `Specify the rewards (in waka): both for when the user captures a token, and for the final reward paid when completing the initiative.`,
      selectDates: `Select a start and end date for the initiative.`,
      rememberToPublish: `Remember that to make the initiative visible to users it is still necessary to publish it by clicking on the dedicated button on the summary card.`,
      addBadge: `Add an (optional) reward badge that will be shown once the user completes the initiative. But be careful: if you publish the initiative, you will no longer be able to delete the inserted badge or add a new one.`,
      validBadge: `A valid badge must necessarily contain an image, a name and a description (the latter two compiled for each available language).`,
      setMode: `Set the initiative mode.`,
      setAdvertising: `Specify whether or not this initiative can also be populated by advertising flyers from other brands' campaigns.`,
      setAsset: `Select a specific asset model for this initiative (optional).
      
      If no model is specified, the generic asset model of the Brand will be shown in correspondence with the capture tokens.`
    },
    orders: {
      alphabetical: 'Alphabetical',
      startDate: 'Start date',
      endDate: 'End date',
      published: 'Published'
    },
    snackbar: {
      initiativeUpdateSuccess: 'Initiative updated succesfully!',
      initiativeUpdateError: `An error occurred and the initiative was not updated, please try again.`,
      initiativePublishSuccess: `The initiative has been published! It will be visible to users when the start date arrives.`,
      initiativePublishError: `An error has occurred and the initiative has not been published.`
    },
    challenges: {
      challenges: 'Challenge',
      searchChallenge: 'Search Challenge',
      deleteChallenge: 'Delete Challenge',
      createChallenge: 'Create Challenge',
      texts: {
        editChallenge:
          'On this page you can view and modify the Challenge data: enter the data carefully and pay attention to their consistency. Once you have entered the data, click on the button at the top right to confirm the changes.',
        createChallenge:
          'Create a new Challenge by carefully entering the data paying attention to their consistency. Once you have entered the data, click on the button at the top right to proceed with the creation.',
        modeSingleInfo: `Select SINGLE mode if you want the user to collect the prize and the challenge to close once the unlock threshold is reached.`,
        modeRevolvingInfo: `Select REVOLVING mode if you want the token counter to reset once the unlock threshold is reached and the user can rejoin the challenge to get the prize again.`,
        noFlyersMode: `Click the box below to create a Challenge without flyers. Tokens can still be captured but no flyer will be shown to the user.`
      },
      pageSections: {
        generalInfo: 'General info',
        duration: 'Duration',
        prizes: 'Prizes',
        brand: 'Brand',
        flyers: 'Flyers',
        badge: 'Badge',
        mode: 'Mode'
      },
      snackbar: {
        challengeEditSuccess: 'Challenge changed successfully!',
        challengeEditError:
          'A problem has occurred and the Challenge has not been updated. Try again.',
        challengeAddSuccess: 'Challenge successfully created!',
        challengeAddError: 'There was a problem and the challenge was not created. Try again.',
        challengeDeleteSuccess: 'Challenge eliminated!',
        challengeDeleteError: 'There was a problem and the challenge was not removed. Try again.'
      }
    },
    quests: {
      quests: 'Quest',
      searchQuest: 'Search Quest',
      deleteQuest: 'Delete Quest',
      createQuest: 'Create Quest',
      markerName: 'Marker name',
      addMarker: 'Add marker',
      placeMarker: 'Place marker',
      moveMarker: 'Move marker',
      texts: {
        editPageDescription:
          'On this page you can view and modify the Quest data: enter the data carefully and pay attention to their consistency. You can place tokens on the map and assign flyers to them. Once you have entered the data, click on the button at the top right to confirm the changes.',
        createPageDescription:
          'Create a new Quest by carefully entering the data paying attention to their consistency. You can place tokens on the map and assign flyers to them. Once you have entered the data, click on the button at the top right to proceed with the creation.',
        inserLocation: `Enter the geographical coordinates in numerical format (e.g.: 42.123456, 12.25324357)`
      },
      pageSections: {
        generalInfo: 'General info',
        duration: 'Duration',
        prizes: 'Prizes',
        brand: 'Brand',
        flyers: 'Flyers',
        locations: 'Locations',
        badge: 'Badge',
        mode: 'Mode'
      },
      snackbar: {
        questEditSuccess: 'Quest changed successfully!',
        questEditError: 'A problem has occurred and the Quest has not been updated. Try again.',
        questAddSuccess: 'Quest successfully created!',
        questAddError: 'There was a problem and the Quest was not created. Try again.',
        questDeleteSuccess: 'Quest eliminated!',
        questDeleteError: 'There was a problem and the Quest was not removed. Try again.'
      },
      tokenAssignment: {
        mode: 'Token assignment mode',
        randomContent: 'Random content',
        randomContentDescription: `Each time you capture a token a flyer randomly drawn from the selected ones will be shown.`,
        firstFixed: 'First fixed',
        firstFixedDescription: `It is possible to decide which leaflet to show when the first token is captured.`,
        lastFixed: 'last fixed',
        lastFixedDescription: `It is possible to decide which leaflet to show when the last token is captured.`,
        firstAndLastFixed: 'First and Last fixed',
        firstAndLastFixedDescription: `It is be possible to decide which flyers to show when the first and last token is captured.`,
        tokenFixed: 'Location fixed',
        tokenFixedDescription: `Each marker (on the map) must be assigned a specific flyer. If you select more flyers than you actually assign, they will not be included in the initiative.`,
        ordered: 'Ordered',
        orderedDescription: `You specify an order for the flyers, which will be displayed in sequence as tokens are captured.`
      }
    },
    campaigns: {
      campaigns: 'Campaigns',
      newCampaign: 'New campaign',
      searchCampaign: 'Search Campaign',
      createCampaign: 'Create Campaign',
      deleteCampaign: 'Delete Campaign',
      pageSections: {
        generalInfo: 'General info',
        duration: 'Duration'
      },
      snackbar: {
        campaignEditSuccess: 'Campaign changed successfully!',
        campaignEditError:
          'A problem has occurred and the Campaign has not been updated. Try again.',
        campaignAddSuccess: 'Campaign successfully created!',
        campaignAddError: 'There was a problem and the campaign was not created. Try again.',
        campaignDeleteSuccess: 'Campaign eliminated!',
        campaignDeleteError: 'There was a problem and the challenge was not removed. Try again.'
      },
      texts: {
        editCampaign:
          'On this page you can view and modify the Campaign data. Once the data has been modified, click on the button at the top right to confirm the changes.',
        createCampaign: `On this page you can create a Campaign: a particular type of initiative that inserts flyers within Wakapapa's initiatives. Once you have entered the data, click on the button at the top right to proceed with the creation.`
      }
    }
  },
  contents: {
    contents: 'Contents'
  },
  users: {
    users: 'Users',
    username: 'Username',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    appUsers: 'Wakapapa App Users',
    newUser: 'New user',
    backOfficeUsers: 'Back Office Users',
    community: 'Community',
    usersLists: 'Users Lists',
    searchUser: 'Search for a user',
    addUser: 'Add User',
    earnedWaka: 'Waka Earned',
    editUser: 'Edit User',
    deleteUser: 'Delete User',
    texts: {
      usersListsInfo: 'Select tab to check App Users list, or Back Office Users list.',
      deleteUserInfo: 'Do you really want to cancel this User? This operation is irreversible.',
      createPageDescription:
        'Create a new User by carefully entering the data paying attention to their consistency. Once you have entered the data, click on the button at the top right to proceed with the creation.',
      editUsersPageDescription:
        'On this page you can view and modify the User data: enter the data carefully and pay attention to their consistency. Once you have entered the data, click on the button at the top right to confirm the changes.',
      editBusinessPageDescription:
        'On this page you can modify the User password. Once you have entered the password, click on the button at the top right to confirm the changes.',
      formsErrorInfo:
        'Some fields have compilation errors or are empty. Check that you have filled in each text field and that the values are correct.'
    },
    formPageSections: {
      info: 'User Infos',
      setPassword: 'Set password',
      privileges: 'Privileges'
    },
    formInputLabels: {
      image: 'Image',
      email: 'Email',
      role: 'Role',
      confirmPassword: 'Confirm Password'
    },
    formInputHelperTexts: {
      setImage: `Upload an image that allows you to visually identify the user.`,
      setUsername: `Set the username.`,
      insertEmail: `Set the email with which the user logs in to the application.`,
      setPassword: `Set a password with which the user logs in to the application.`,
      passwordCostraints: `A valid password must be:
      - At least 8 characters long;
      - Have both uppercase and lowercase letters;
      - Contain at least one number;
      - Have at least one special character (!@#$%^&*...);
      `,
      confirmPassword: `Type your password again to confirm it.`,
      setRole: `Set access privileges for the user: you can assign the role of admin (Wakpapa) or Brand. 
        
      The admin role generally has more powers, allows you to make certain changes to the initiatives and in general has privileges that a Brand user does not have.`,
      chooseBrand: `For Brand users it is mandatory to select a reference Brand to which the user will be associated.`
    },
    snackbar: {
      addUserSuccess: 'User successfully added!',
      addUserError: `A problem occurred and the user was not added. Try again.`,
      editUserSuccess: 'User updated successfully!',
      editUserError: `There was a problem and the user has not been updated. Try again.`,
      deleteUserSuccess: 'User deleted successfully!',
      deleteUserError: `A problem occurred and the user was not deleted. Try again.`
    }
  }
};

export default en;
