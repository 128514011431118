import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import BrandRepository from '../api/repositories/BrandRepository';
import FilesRepository from '../api/repositories/FilesRepository';
import {
  BrandsApiResponse,
  BrandsApiResponseSchema,
  EmbeddedBrand,
  EmbeddedBrandSchema
} from '../entities/Brand';
import { brandActions } from '../redux/be_interaction_store/slices/brand';
import { RootState } from '../redux/store';
import { RequestError, RequestParseError } from '../redux/utils';
import useAuthentication from './useAuthentication';

type BrandState = RootState['BEInteraction']['brand'];

type UseBrand = {
  currentBrand: BrandState['currentBrand'];
  brands: BrandState['brands'];

  readBrand: (brandId: number) => Promise<EmbeddedBrand | void>;
  readBrandRequest: BrandState['requests']['readBrand'];

  readMyBrand: () => Promise<EmbeddedBrand | void>;
  readMyBrandRequest: BrandState['requests']['readMyBrand'];

  readBrands: (
    page?: number,
    name?: string,
    sort?: string,
    concat?: boolean
  ) => Promise<EmbeddedBrand[] | void>;
  readBrandsRequest: BrandState['requests']['readBrands'];

  addBrand: (name: string, logo: string, link?: string) => Promise<boolean>;
  addBrandRequest: BrandState['requests']['addBrand'];

  updateBrand: (
    brandId: string,
    name: string,
    logo: File | string,
    link?: string | null
  ) => Promise<boolean>;
  updateBrandRequest: BrandState['requests']['updateBrand'];

  updateMyBrand: (name: string, logo: File | string, link?: string | null) => Promise<boolean>;
  updateMyBrandRequest: BrandState['requests']['updateBrand'];

  deleteBrand: (brandId: string) => Promise<boolean>;
  deleteBrandRequest: BrandState['requests']['deleteBrand'];
};

export default function useBrand(): UseBrand {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      BrandRepository.updateAuthToken(accessToken);
      BrandRepository.onRefreshToken = refreshToken;
    }
  }, [accessToken, refreshToken]);

  const currentBrand = useSelector((state: RootState) => state.BEInteraction.brand.currentBrand);
  const brands = useSelector((state: RootState) => state.BEInteraction.brand.brands);
  const readBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.readBrand
  );
  const readMyBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.readMyBrand
  );
  const readBrandsRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.readBrands
  );
  const addBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.addBrand
  );
  const updateBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.updateBrand
  );
  const updateMyBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.updateMyBrand
  );
  const deleteBrandRequest = useSelector(
    (state: RootState) => state.BEInteraction.brand.requests.deleteBrand
  );

  const readBrand: UseBrand['readBrand'] = useCallback(
    async (brandId) => {
      dispatch(brandActions.readBrandRequest());
      let response: unknown;

      try {
        response = await BrandRepository.get(brandId);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.readBrandError({ error: new RequestError(error as AxiosError) }));
      }

      const parseResult = EmbeddedBrandSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          brandActions.readBrandParseError({
            parseError: new RequestParseError(parseResult.error, 'UseBrand.readBrand')
          })
        );
        return;
      }

      dispatch(brandActions.readBrandSuccess(response as EmbeddedBrand));
      return response as EmbeddedBrand;
    },
    [dispatch]
  );

  const readMyBrand: UseBrand['readMyBrand'] = useCallback(async () => {
    dispatch(brandActions.readMyBrandRequest());
    let response: unknown;

    try {
      response = await BrandRepository.getMine();
    } catch (error) {
      console.warn(error);
      dispatch(brandActions.readMyBrandError({ error: new RequestError(error as AxiosError) }));
    }

    const parseResult = EmbeddedBrandSchema.safeParse(response);
    if (!parseResult.success) {
      console.warn(parseResult.error);
      dispatch(
        brandActions.readMyBrandParseError({
          parseError: new RequestParseError(parseResult.error, 'UseBrand.readMyBrand')
        })
      );
      return;
    }

    dispatch(brandActions.readMyBrandSuccess(response as EmbeddedBrand));
    return response as EmbeddedBrand;
  }, [dispatch]);

  const readBrands: UseBrand['readBrands'] = useCallback(
    async (page?: number, name?: string, sort?: string) => {
      dispatch(brandActions.readBrandsRequest());
      let response: unknown;

      try {
        response = await BrandRepository.getAll(page, name, sort);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.readBrandsError({ error: new RequestError(error as AxiosError) }));
      }

      const parseResult = BrandsApiResponseSchema.safeParse(response);
      if (!parseResult.success) {
        console.warn(parseResult.error);
        dispatch(
          brandActions.readBrandsParseError({
            parseError: new RequestParseError(parseResult.error, 'UseBrand.readBrands')
          })
        );
        return;
      }
      dispatch(brandActions.readBrandsSuccess(response as BrandsApiResponse));
      return (response as BrandsApiResponse).content;
    },
    [dispatch]
  );

  const addBrand: UseBrand['addBrand'] = useCallback(
    async (name: string, logo: string, link?: string) => {
      dispatch(brandActions.addBrandRequest());

      try {
        await BrandRepository.storeNewBrand(name, logo, link);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.addBrandError({ error: new RequestError(error as AxiosError) }));
        return false;
      }

      dispatch(brandActions.addBrandSuccess());
      return true;
    },
    [dispatch]
  );

  const updateBrand: UseBrand['updateBrand'] = useCallback(
    async (brandId: string, name: string, logo: File | string, link?: string | null) => {
      dispatch(brandActions.updateBrandRequest());

      const logoFormData = new FormData();
      if (typeof logo !== 'string') {
        logoFormData.append('file', logo as Blob, logo.name);
      }

      try {
        const relativeRemoteImgPath =
          typeof logo !== 'string' ? await FilesRepository.uploadFile(logoFormData) : logo;
        await BrandRepository.updateBrand(brandId, name, relativeRemoteImgPath, link);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.updateBrandError({ error: new RequestError(error as AxiosError) }));
        return false;
      }

      dispatch(brandActions.updateBrandSuccess());
      return true;
    },
    [dispatch]
  );

  const updateMyBrand: UseBrand['updateMyBrand'] = useCallback(
    async (name: string, logo: File | string, link?: string | null) => {
      dispatch(brandActions.updateMyBrandRequest());

      const logoFormData = new FormData();
      if (typeof logo !== 'string') {
        logoFormData.append('file', logo as Blob, logo.name);
      }

      try {
        const relativeRemoteImgPath =
          typeof logo !== 'string' ? await FilesRepository.uploadFile(logoFormData) : logo;
        await BrandRepository.updateMine(name, relativeRemoteImgPath, link);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.updateMyBrandError({ error: new RequestError(error as AxiosError) }));
        return false;
      }

      dispatch(brandActions.updateMyBrandSuccess());
      return true;
    },
    [dispatch]
  );

  const deleteBrand: UseBrand['deleteBrand'] = useCallback(
    async (brandId) => {
      dispatch(brandActions.deleteBrandRequest());

      try {
        await BrandRepository.deleteBrand(brandId);
      } catch (error) {
        console.warn(error);
        dispatch(brandActions.deleteBrandError({ error: new RequestError(error as AxiosError) }));
        return false;
      }

      dispatch(brandActions.deleteBrandSuccess());
      return true;
    },
    [dispatch]
  );

  return {
    currentBrand,
    brands,
    readBrand,
    readBrandRequest,
    readMyBrand,
    readMyBrandRequest,
    readBrands,
    readBrandsRequest,
    addBrand,
    addBrandRequest,
    updateBrand,
    updateBrandRequest,
    updateMyBrand,
    updateMyBrandRequest,
    deleteBrand,
    deleteBrandRequest
  };
}
