import * as z from 'zod';
import { DictionaryStringSchema, PageblePayloadSchema } from './Common';

export const FlyerSchema = z.object({
  id: z.number(),
  front: DictionaryStringSchema,
  back: z.string().nullable(),
  wakaCost: z.number(),
  link: z.string().nullable(),
  expiredAt: z.number().nullable(),
  initiativeCount: z.number().optional(),
  campaignCount: z.number().optional(),
  qrCode: z.string().nullable().optional()
});
export type Flyer = z.infer<typeof FlyerSchema>;

export const FlyersAPIResponseSchema = PageblePayloadSchema.extend({
  content: z.array(FlyerSchema)
});
export type FlyersApiResponse = z.infer<typeof FlyersAPIResponseSchema>;

const FlyerRequestSchema = z.object({
  brandId: z.number(),
  front: DictionaryStringSchema,
  back: z.string().nullable(),
  wakaCost: z.number(),
  link: z.string().nullable().optional(),
  expiredAt: z.number().nullable().optional()
});
export type FlyerRequest = z.infer<typeof FlyerRequestSchema>;
