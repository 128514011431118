import * as React from 'react';
import { AddBusiness, History, SortByAlpha, Tag } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { EmbeddedBrand } from '../../../../entities/Brand';
import { RoleIds } from '../../../../entities/User';
import useAssets from '../../../../hooks/useAssets';
import useBrand from '../../../../hooks/useBrand';
import useUser from '../../../../hooks/useUser';
import ActivityIndicator from '../../../components/ActivityIndicator';
import useBreadcrumbs from '../../../components/Breadcrumbs/useBreadcrumbs';
import NoDataBanner from '../../../components/NoDataBanner';
import OrderSelection from '../../../components/OrderSelection';
import { SearchBar } from '../../../components/SearchBar';
import { FlexBox } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import AddBrand from './AddBrand';
import Brand from './Brand';
import AddEditAsset from './Brand/AddEditAsset';
import AddEditCampaign from './Brand/AddEditCampaign';
import AddEditChallenge from './Brand/AddEditChallenge';
import AddEditQuest from './Brand/AddEditQuest';

export default function HomeRouteGuard() {
  const { me } = useUser();
  const { readMyBrand, currentBrand } = useBrand();

  const fetchMyBrand = async () => {
    await readMyBrand();
  };
  React.useEffect(() => {
    if (me?.role?.id === RoleIds.BRAND_USER_ID) fetchMyBrand();
  }, [me]);

  return me?.role?.id === RoleIds.BRAND_USER_ID ? (
    !currentBrand ? (
      <></>
    ) : (
      <Routes>
        <Route path="/home/brand/:id/*" element={<Brand />} />
        <Route
          path="/home/brand/:brandId/challenges/new-challenge"
          element={<AddEditChallenge mode="create" />}
        />
        <Route
          path="/home/brand/:brandId/challenges/:challengeId"
          element={<AddEditChallenge mode="edit" />}
        />
        <Route
          path="/home/brand/:brandId/quests/new-quest"
          element={<AddEditQuest mode="create" />}
        />
        <Route path="/home/brand/:brandId/quests/:questId" element={<AddEditQuest mode="edit" />} />
        <Route
          path="/home/brand/:brandId/campaigns/new-campaign"
          element={<AddEditCampaign mode="create" />}
        />
        <Route
          path="/home/brand/:brandId/campaigns/:campaignId"
          element={<AddEditCampaign mode="edit" />}
        />
        <Route path="*" element={<Navigate to={`/home/brand/${currentBrand.id}`} replace />} />
      </Routes>
    )
  ) : me?.role?.id === RoleIds.ADMIN_USER_ID ? (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/home/add_brand" element={<AddBrand />} />
      <Route path="/home/brand/:id/*" element={<Brand />} />
      <Route
        path="/home/brand/:brandId/challenges/new-challenge"
        element={<AddEditChallenge mode="create" />}
      />
      <Route
        path="/home/brand/:brandId/challenges/:challengeId"
        element={<AddEditChallenge mode="edit" />}
      />
      <Route
        path="/home/brand/:brandId/quests/new-quest"
        element={<AddEditQuest mode="create" />}
      />
      <Route path="/home/brand/:brandId/quests/:questId" element={<AddEditQuest mode="edit" />} />
      <Route
        path="/home/brand/:brandId/campaigns/new-campaign"
        element={<AddEditCampaign mode="create" />}
      />
      <Route
        path="/home/brand/:brandId/campaigns/:campaignId"
        element={<AddEditCampaign mode="edit" />}
      />
      <Route path="/home/brand/:brandId/new-asset" element={<AddEditAsset mode="create" />} />
      <Route path="/home/brand/:brandId/assets/:assetId" element={<AddEditAsset mode="edit" />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  ) : (
    <></>
  );
}

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brands, readBrands, readBrandsRequest } = useBrand();
  const { resetAssets } = useAssets();
  const theme = useTheme();
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const brandsContainer = document.getElementById('brands-container');
  const [infiniteScrollBrandsList, setInfiniteScrollBrandsList] = React.useState<EmbeddedBrand[]>(
    []
  );

  // Filters
  const [searchKey, setSearchKey] = React.useState('');
  const [sortValue, setSortValue] = React.useState<'default' | 'name' | 'created_at'>('default');
  const [sortModeAsc, setSortModeAsc] = React.useState<boolean>(true);

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('navigation:home'),
        path: '/home'
      };
    }, [t])
  );

  const fetchBrands = React.useCallback(
    async (page?: number, firstFetch?: boolean) => {
      const additionalBrands = await readBrands(
        page || undefined,
        searchKey || undefined,
        sortValue === 'default' ? undefined : `${sortModeAsc ? '' : '-'}${sortValue}`
      );
      if (!additionalBrands) return;
      if (firstFetch) {
        setInfiniteScrollBrandsList(additionalBrands);
      } else setInfiniteScrollBrandsList((list) => list.concat(additionalBrands));
    },
    [sortValue, sortModeAsc, searchKey]
  );

  React.useEffect(() => {
    fetchBrands(undefined, true);
    resetAssets();
  }, [fetchBrands]);

  React.useEffect(() => {
    if (!brandsContainer || readBrandsRequest.inProgress) return;
    if (
      brandsContainer?.scrollHeight - 250 <= brandsContainer?.clientHeight &&
      infiniteScrollBrandsList.length &&
      brands.pagination &&
      !brands.pagination?.last
    ) {
      fetchBrands((brands.pagination?.number || 0) + 1);
    }
  }, [
    fetchBrands,
    brandsContainer?.clientHeight,
    brandsContainer?.scrollHeight,
    infiniteScrollBrandsList.length,
    brands.pagination,
    readBrandsRequest.inProgress
  ]);

  const handleScroll = React.useCallback(
    async (e: any) => {
      const bottomReached =
        e.target.scrollHeight - e.target.scrollTop - 250 <= e.target.clientHeight;
      if (bottomReached && brands.pagination) {
        if (!brands.pagination.last && !readBrandsRequest.inProgress) {
          fetchBrands(brands.pagination.number + 1);
        }
      }
    },
    [brands.pagination, readBrandsRequest.inProgress, fetchBrands]
  );

  return (
    <div
      id="brands-container"
      style={{
        padding: 24,
        flex: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}
      onScroll={handleScroll}>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {t('brand:selectBrand')}
        </Typography>
        <FlexBox>
          <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
            {t('brand:texts:brandSelectInfo')} {t('brand:texts:brandNotFounfInfo')}
          </Typography>
          <Link
            variant="subtitle2"
            underline="hover"
            sx={{ ml: 1, cursor: 'pointer' }}
            onClick={() => navigate('/home/add_brand')}>
            {t('brand:addNewBrand')}
          </Link>
        </FlexBox>
      </Box>

      <Toolbar
        my={4}
        leftcomponent={
          <>
            <SearchBar
              value={searchKey}
              onChange={_.debounce((text) => {
                setSearchKey(text);
                fetchBrands(undefined, true);
              }, 800)}
              placeholder={t('brand:searchBrand')}
              backgroundColor={theme.palette.background.paper}
              sx={{
                height: 38,
                py: 0,
                minWidth: 250
              }}
            />

            <OrderSelection
              sortValue={sortValue}
              sortItems={[
                {
                  value: 'default',
                  label: t('filters:default'),
                  icon: ({ sx, fontSize }) => <Tag sx={sx} fontSize={fontSize} />
                },
                {
                  value: 'name',
                  label: t('filters:alphabetic'),
                  icon: ({ sx, fontSize }) => <SortByAlpha sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                },
                {
                  value: 'created_at',
                  label: t('filters:creationDate'),
                  icon: ({ sx, fontSize }) => <History sx={sx} fontSize={fontSize} />,
                  doubleOrder: true
                }
              ]}
              onSortValueChange={(value) =>
                setSortValue(value as 'default' | 'name' | 'created_at')
              }
              sortMode={sortModeAsc ? 'asc' : 'desc'}
              onSortModeClick={() => setSortModeAsc(!sortModeAsc)}
              sx={{
                flex: '1 0 200px'
              }}
            />
          </>
        }
        rightcomponent={
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/home/add_brand')}
            sx={{ ml: 'auto', color: 'white' }}
            startIcon={<AddBusiness sx={{ mr: 1 }} />}>
            {t('brand:addBrand')}
          </Button>
        }
      />

      {readBrandsRequest.inProgress && !brands.pagination ? (
        <ActivityIndicator />
      ) : infiniteScrollBrandsList.length ? (
        <>
          <FlexBox flexDirection="row" flexWrap="wrap" gap={2} px="6%">
            {infiniteScrollBrandsList.map((b) => (
              <Fade key={`brand-card-${b.id}`} in={true}>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: `calc(${tabletWidth ? '33% - 8px' : '20% - 13px'})`,
                    borderRadius: 4,
                    border: 'none'
                  }}>
                  <CardActionArea
                    onClick={() => navigate(`/home/brand/${b.id}`)}
                    sx={{ flex: 1, p: 2 }}>
                    <Typography
                      gutterBottom
                      variant="body2"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: 2,
                        py: 0.6
                      }}>
                      <span style={{ flex: 1 }}>
                        {b.name.charAt(0).toUpperCase() + b.name.slice(1)}
                      </span>
                    </Typography>

                    <Divider />

                    <FlexBox
                      gap={2}
                      sx={{
                        height: 160,
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}>
                      {b.logo && (
                        <img
                          src={b.logo}
                          alt={b.name + ' logo'}
                          style={{
                            maxHeight: '50%',
                            maxWidth: 180,
                            margin: 'auto'
                          }}
                        />
                      )}
                    </FlexBox>

                    {b.createdAt && (
                      <FlexBox
                        flexDirection="row"
                        flex={1}
                        gap={0.5}
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ opacity: 0.4, mb: -1, mt: 2 }}>
                        <Typography fontSize={10} sx={{ mb: -0.2 }}>
                          {new Date(b.createdAt).toLocaleDateString()}
                        </Typography>
                      </FlexBox>
                    )}
                  </CardActionArea>
                </Card>
              </Fade>
            ))}
            <Fade in={!brands.pagination?.last} timeout={0}>
              <Box sx={{ minWidth: `calc(${tabletWidth ? '33% - 8px' : '20% - 13px'})` }}>
                <Skeleton
                  variant="rounded"
                  height={'100%'}
                  width="100%"
                  sx={{ borderRadius: 4, minHeight: 250 }}
                />
              </Box>
            </Fade>
          </FlexBox>
        </>
      ) : (
        <NoDataBanner />
      )}
    </div>
  );
}
