import { Repository } from '../Repository';

class FilesRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api/files'
    });
  }

  async uploadFile(formData: FormData) {
    const { data } = await this.axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      }
    });
    return data;
  }

  async deleteFile(filename: string) {
    const { data } = await this.axios.delete('', {
      params: {
        filename
      }
    });
    return data;
  }
}

export default new FilesRepository();
