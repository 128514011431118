import React, { useCallback, useContext, useState } from 'react';
import { AddBusiness, Check, KeyboardArrowLeft } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBrand from '../../../../hooks/useBrand';
import { useBreadcrumbs } from '../../../components/Breadcrumbs';
import EditableCard from '../../../components/EditableCard';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import UploadImg from '../../../components/UploadImg';

export default function AddBrand() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addBrand, addBrandRequest } = useBrand();
  const { setSnackbar } = useContext(SnackbarContext);

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [logo, setLogo] = useState<string | null>(null);

  useBreadcrumbs(
    React.useMemo(() => {
      return {
        title: t('brand:addBrand'),
        path: '/home/add_brand'
      };
    }, [t])
  );

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const submit = useCallback(async () => {
    let success: boolean = false;
    if (logo && name) success = await addBrand(name, logo, website);

    openSnackBar(
      success,
      t('brand:snackbar:addNewBrandSuccess'),
      t('brand:snackbar:addNewBrandFailure')
    );
    if (success) navigate('/home');
  }, [logo, name]);

  const CustomTextField = (
    label: string,
    field: string,
    setter: React.Dispatch<React.SetStateAction<string>>,
    error?: boolean,
    errorSetter?: React.Dispatch<React.SetStateAction<boolean>>,
    multiline?: boolean
  ) => {
    return (
      <TextField
        size="small"
        label={label}
        error={error}
        InputLabelProps={{ shrink: true }}
        value={field}
        onChange={(e) => {
          setter(e.target.value);
        }}
        multiline={multiline}
        sx={
          multiline
            ? {
                '&>div': {
                  minHeight: '4rem',
                  height: '100%',
                  '&>textarea': {
                    marginBottom: 'auto'
                  }
                }
              }
            : undefined
        }
      />
    );
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between">
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {t('brand:addNewBrand')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {t('brand:texts:addBrandInfo')}
            </Typography>
          </Box>
        </FlexBox>

        <Toolbar
          my={4}
          rightcomponent={
            <LoadingButton
              disabled={!name || !logo}
              loading={addBrandRequest.inProgress}
              color="success"
              variant="contained"
              startIcon={<Check />}
              onClick={submit}>
              {t('common:confirm')}
            </LoadingButton>
          }
        />
      </Box>

      <EditableCard
        hideHeaderDivider
        title={t('brand:brand')}
        icon={<AddBusiness color="primary" />}
        sx={{ mx: 'auto', minWidth: '50vh' }}>
        <Stack gap={2} p={2} pt={0}>
          {/* Name */}
          <InfoText>
            <p>{t('brand:formInputHelperTexts:setBrandName')}</p>
          </InfoText>
          {CustomTextField(`${t('common:name')}*`, name, setName)}

          {/* Link */}
          <InfoText>
            <p>{t('brand:formInputHelperTexts:setWebsiteLink')}</p>
          </InfoText>
          {CustomTextField(`${t('brand:websiteLink')}`, website, setWebsite)}

          {/* Description Image */}
          <InfoText>
            <p>{t('brand:formInputHelperTexts:setLogo')}</p>
          </InfoText>
          <Box
            sx={{
              p: 2,
              borderRadius: 1,
              border: `1px solid ${'rgba(0, 0, 0, 0.23)'}`,
              position: 'relative'
            }}>
            <label
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '1rem',
                position: 'absolute',
                left: 0,
                top: 0,
                transform: 'translate(8px, -9px) scale(0.75)',
                transformOrigin: 'top left',
                background: 'white',
                paddingInline: 6,
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                letterSpacing: '0.00938em'
              }}>
              {t('common:logo') + '*'}
            </label>
            <UploadImg
              title={`upload image`}
              onDelete={() => setLogo(null)}
              onUploaded={(filename) => {
                setLogo(filename);
              }}
            />
          </Box>
        </Stack>
      </EditableCard>
    </FlexBox>
  );
}
