import React, { useContext, useEffect, useState } from 'react';
import { Check, DeleteForever, KeyboardArrowLeft, ViewInArTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Asset } from '../../../../../entities/Asset';
import useAssets from '../../../../../hooks/useAssets';
import PathFormatter from '../../../../../utils/PathFormatter';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../../components/Breadcrumbs';
import EditableCard from '../../../../components/EditableCard';
import { SnackbarContext } from '../../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../../components/StyledComponents';
import Toolbar from '../../../../components/Toolbar';
import UploadAsset from '../../../../components/UploadAsset';

interface AssetProps {
  mode: 'create' | 'edit';
}

export default function AddEditAsset(props: AssetProps) {
  const {
    findAsset,
    addAsset,
    addAssetRequest,
    updateAsset,
    updateAssetRequest,
    deleteAsset,
    deleteAssetRequest,
    getAssets
  } = useAssets();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assetId, brandId } = useParams();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');
  const defaultAsset = useSearchParams()[0].get('default') === 'true';

  // Generic states
  const [data, setData] = useState<Asset>();
  const [edited, setEdited] = useState(false);
  const [deleteAssetDialogOpened, setDeleteAssetDialogOpen] = React.useState(false);

  // Form states
  const [newAssetFilename, setNewAssetFilename] = useState(
    data?.file ? PathFormatter.absoluteToRelative(data.file) : undefined
  );

  const [newAssetAndroidFilename, setNewAssetAndroidFilename] = useState(
    data?.file ? PathFormatter.absoluteToRelative(data.file) : undefined
  );

  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('asset:texts:createAssetTitle'),
            path: `/home/brand/${brandId}/new-asset`
          }
        : {
            title: t('asset:texts:editAssetTitle'),
            path: `/home/brand/${brandId}/assets/${assetId}`
          };
    }, [assetId, props.mode])
  );

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const fecthAssetData = async () => {
    if (assetId) {
      const asset = await findAsset(parseInt(assetId));
      if (asset) setData(asset as Asset);
    }
  };

  useEffect(() => {
    fecthAssetData();
  }, [assetId]);

  useEffect(() => {
    setEdited(false);
    if (data) {
      setNewAssetFilename(PathFormatter.absoluteToRelative(data.file));
      if (data.fileAndroid) {
        setNewAssetAndroidFilename(PathFormatter.absoluteToRelative(data.fileAndroid));
      }
    }
  }, [data]);

  const triggerUpdateAsset = async () => {
    if (!assetId) return;

    if (brandId && data && newAssetFilename && newAssetAndroidFilename) {
      const payload = {
        brandId: parseInt(brandId),
        id: data.id,
        file: newAssetFilename,
        fileAndroid: newAssetAndroidFilename,
        isDefault: data.isDefault || undefined
      };
      const success = await updateAsset(parseInt(assetId), payload);
      openSnackBar(
        success,
        t('asset:snackbar:editAssetSuccess'),
        t('asset:snackbar:editAssetError')
      );
      fecthAssetData();
      if (success) {
        getAssets();
      }
    }
  };
  const triggerAddAsset = async () => {
    if (brandId && newAssetFilename && newAssetAndroidFilename) {
      const payload = {
        brandId: parseInt(brandId),
        file: newAssetFilename,
        fileAndroid: newAssetAndroidFilename
      };
      const success = await addAsset(payload);
      openSnackBar(success, t('asset:snackbar:addAssetSuccess'), t('asset:snackbar:addAssetError'));
      if (success) navigate(-1);
    }
  };
  const triggerDeleteAsset = async () => {
    if (assetId) {
      const success = await deleteAsset(parseInt(assetId));
      openSnackBar(
        success,
        t('asset:snackbar:deleteAssetSuccess'),
        t('asset:snackbar:deleteAssetError')
      );
      setDeleteAssetDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between" mb={3}>
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {props.mode === 'create'
                ? t('asset:texts:createAssetTitle')
                : t('asset:texts:editAssetTitle')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('asset:texts:createAssetDescription')
                : t('asset:texts:editAssetDescription')}
            </Typography>
          </Box>
        </FlexBox>
        <Toolbar
          my={4}
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                disabled={!newAssetFilename && !newAssetAndroidFilename}
                loading={addAssetRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddAsset}>
                {t('asset:addAsset')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                {!defaultAsset && (
                  <Button
                    sx={{ background: 'white' }}
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteForever />}
                    disabled={!data}
                    onClick={() => setDeleteAssetDialogOpen(true)}>
                    {t('asset:deleteAsset')}
                  </Button>
                )}
                <LoadingButton
                  loading={updateAssetRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateAsset}>
                  {t('asset:editAsset')}
                </LoadingButton>
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      {props.mode === 'create' || (props.mode === 'edit' && data) ? (
        <FlexBox
          flexDirection={'row'}
          gap={2}
          flex={1}
          alignItems={'flex-start'}
          width="90%"
          m="auto">
          <EditableCard
            sx={{ mx: 'auto', minWidth: '33%' }}
            hideHeaderDivider
            title={t('asset:asset')}
            headerSx={{
              pr: 0
            }}
            icon={<ViewInArTwoTone color="primary" />}>
            <InfoText mb={4}>
              <p>{t('asset:texts:uploadAsset')}:</p>
              <p>{t('asset:texts:assetInfo')}</p>
            </InfoText>
            <UploadAsset
              remoteFileName={data?.file ? PathFormatter.absoluteToRelative(data.file) : undefined}
              remoteAndroidFileName={
                data?.fileAndroid ? PathFormatter.absoluteToRelative(data.fileAndroid) : undefined
              }
              titleName={newAssetFilename ? 'Change asset' : 'Upload asset'}
              titleAndroidName={newAssetAndroidFilename ? 'Change asset' : 'Upload asset'}
              onMainUploaded={(filename) => {
                setNewAssetFilename(filename);
                setEdited(true);
              }}
              onMainDelete={() => {
                setNewAssetFilename(undefined);
                setEdited(true);
              }}
              onAndroidUploaded={(filename) => {
                setNewAssetAndroidFilename(filename);
                setEdited(true);
              }}
              onAndroidDelete={() => {
                setNewAssetAndroidFilename(undefined);
                setEdited(true);
              }}
            />
          </EditableCard>
        </FlexBox>
      ) : (
        <ActivityIndicator />
      )}

      {/* Delete Asset Dialog */}
      <Dialog open={deleteAssetDialogOpened} onClose={() => setDeleteAssetDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('asset:texts:deleteAssetInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteAssetDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteAssetRequest.inProgress}
              onClick={triggerDeleteAsset}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
