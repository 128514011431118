import React, { useCallback, useContext, useState } from 'react';
import { AccessTime, Business, Language, Store } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { EmbeddedBrand } from '../../entities/Brand';
import { RoleIds } from '../../entities/User';
import useBrand from '../../hooks/useBrand';
import useUser from '../../hooks/useUser';
import PathFormatter from '../../utils/PathFormatter';
import EditableCard from './EditableCard';
import { SnackbarContext } from './Snackbar/SnackbarProvider';
import { FlexBox } from './StyledComponents';
import UploadImg from './UploadImg';

interface BrandInfoCardProps {
  data: EmbeddedBrand;
}
export default function BrandInfoCard({ data }: BrandInfoCardProps) {
  const { t } = useTranslation();
  const { me } = useUser();
  const theme = useTheme();
  const { updateBrand, readBrand, updateMyBrand, readMyBrand } = useBrand();
  const { setSnackbar } = useContext(SnackbarContext);
  const [mode, setMode] = useState<'read' | 'edit' | 'loading'>('read');

  const [logo, setLogo] = useState<string>(PathFormatter.absoluteToRelative(data.logo));
  const [name, setName] = useState<string>(data.name);
  const [website, setWebsite] = useState<string | null>(data.link);

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const discardChanges = useCallback(() => {
    setLogo(PathFormatter.absoluteToRelative(data.logo));
    setName(data.name);
    setWebsite(data.link);
    setMode('read');
  }, [data]);

  const confirmChanges = useCallback(async () => {
    setMode('loading');
    const relativePathLogo = PathFormatter.absoluteToRelative(data.logo);
    const newLogo: File | string = logo === null ? relativePathLogo : logo;

    const success =
      me?.role?.id === RoleIds.BRAND_USER_ID
        ? await updateMyBrand(name, newLogo, website)
        : await updateBrand(data.id.toString(), name, newLogo, website);
    setMode('read');

    me?.role?.id === RoleIds.BRAND_USER_ID ? readMyBrand() : await readBrand(data.id);
    openSnackBar(success, t('brand:snackbar:editBrandSuccess'), t('brand:snackbar:editBrandError'));

    return success;
  }, [me, name, logo, website]);

  return (
    <EditableCard
      sx={{ flex: 1 }}
      title={`${t('common:informations')} `}
      mode={mode}
      onEditMode={() => setMode('edit')}
      onEditModeDiscard={discardChanges}
      onEditModeConfirm={confirmChanges}
      confirmDisabled={!name || !logo}
      icon={<Store htmlColor={theme.palette.tertiary.main} />}>
      {mode !== 'read' ? (
        <Box p={2} pt={1}>
          <UploadImg
            title="Change Logo"
            remoteFileName={PathFormatter.absoluteToRelative(data.logo ?? '')}
            onUploaded={(filename) => {
              setLogo(filename);
            }}
          />
        </Box>
      ) : (
        data.logo && (
          <FlexBox flexDirection="column" alignItems="center" px={2} mt={1}>
            <img
              src={PathFormatter.relativeToAbsolute(logo)}
              style={{
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '20vh',
                marginBlock: 16
              }}
            />
          </FlexBox>
        )
      )}

      <List
        sx={{
          py: 0,
          ' & span': {
            fontSize: 16
          },
          ' & div': {
            m: 0
          },

          ' & li': {
            my: 1,
            py: 0,
            minHeight: 40
          }
        }}>
        <ListItem>
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          {mode !== 'read' ? (
            <TextField
              fullWidth
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          ) : (
            <ListItemText>
              {`${t('common:name')}: `}
              <i>{name}</i>
            </ListItemText>
          )}
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Language />
          </ListItemIcon>
          {mode !== 'read' ? (
            <TextField
              fullWidth
              size="small"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="Website"
            />
          ) : (
            <ListItemText
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical'
              }}>
              {`${t('brand:websiteLink')}: `}
              <Link
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: 'pointer',
                  fontSize: 16,
                  wordBreak: 'break-all'
                }}
                onClick={() => window.open(data.link ?? '', '')}>
                {website}
              </Link>
            </ListItemText>
          )}
        </ListItem>

        {data.createdAt && (
          <ListItem>
            <ListItemIcon>
              <AccessTime />
            </ListItemIcon>

            <ListItemText>
              {`${t('account:createdAt')}: ${new Date(data.createdAt).toLocaleDateString()}`}
            </ListItemText>
          </ListItem>
        )}
      </List>
    </EditableCard>
  );
}
