import { combineReducers } from '@reduxjs/toolkit';
import campaignForm from './slices/campaignForm';
import challengeForm from './slices/challengeForm';
import questForm from './slices/questForm';

export const local = combineReducers({
  challengeForm,
  questForm,
  campaignForm
});
