import React, { useContext, useEffect, useState } from 'react';
import { Check, DeleteForever, InfoOutlined, KeyboardArrowLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Community, CommunityAddEdit } from '../../../../entities/Communities';
import useCommunities from '../../../../hooks/useCommunities';
import { communitiesActions } from '../../../../redux/be_interaction_store/slices/communities';
import ActivityIndicator from '../../../components/ActivityIndicator';
import { useBreadcrumbs } from '../../../components/Breadcrumbs';
import EditableCard from '../../../components/EditableCard';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, InfoText } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';

interface CommunityProps {
  mode: 'create' | 'edit';
}

export default function AddCommunity(props: CommunityProps) {
  const {
    communities,
    addCommunity,
    addCommunityRequest,
    updateCommunity,
    updateCommunityRequest,
    deleteCommunity,
    deleteCommunityRequest
  } = useCommunities();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communityId } = useParams();
  const { setSnackbar } = useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');

  const [data, setData] = useState<Community>();
  const [edited, setEdited] = useState(false);
  const [deleteCommunityDialogOpened, setDeleteCommunityDialogOpen] = React.useState(false);
  const [formErrorsDialogOpened, setFormErrorsDialogOpened] = React.useState(false);

  const [name, setName] = useState(data?.name);
  const [code, setCode] = useState<string | undefined | null>(data?.code ?? '');

  const [nameErr, setNameErr] = useState<string>();
  const [codeErr, setCodeErr] = useState<string>();

  useBreadcrumbs(
    React.useMemo(() => {
      return props.mode === 'create'
        ? {
            title: t('communities:addCommunity'),
            path: `/communities/new_community`
          }
        : {
            title: t('communities:editCommunity'),
            path: `/communities/${communityId}/edit`
          };
    }, [communityId, props.mode])
  );

  useEffect(() => {
    if (addCommunityRequest.httpError) {
      setSnackbar({
        opened: true,
        severity: 'error',
        msg: `${t('communities:snackbar:communityAddError')} Error:  
        ${
          (addCommunityRequest.httpError?.axiosError?.response?.data as any)?.error?.message ??
          'unknown'
        }`,
        onClose: () => dispatch(communitiesActions.addCommunitySuccess())
      });
      setCodeErr('Questo codice potrebbe essere già in uso.');
    }
  }, [addCommunityRequest.httpError]);

  useEffect(() => {
    if (updateCommunityRequest.httpError) {
      setSnackbar({
        opened: true,
        severity: 'error',
        msg: `${t('communities:snackbar:communityUpdateError')} Error:  
        ${
          (updateCommunityRequest.httpError?.axiosError?.response?.data as any)?.error?.message ??
          'unknown'
        }`,
        onClose: () => dispatch(communitiesActions.updateCommunitySuccess())
      });
      setCodeErr('Questo codice potrebbe essere già in uso.');
    }
  }, [updateCommunityRequest.httpError]);

  const fecthCommunityData = async () => {
    if (communityId) {
      const communityData = communities.data.find(
        (community) => community.id === parseInt(communityId)
      );
      if (communityData) setData(communityData as Community);
    }
  };

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  useEffect(() => {
    if (props.mode === 'edit') {
      fecthCommunityData();
    }
  }, [communityId]);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setCode(data.code);
    }
  }, [data]);

  const validation = () => {
    let validated = true;
    if (!name) {
      setNameErr(t('errors:obligatoryField'));
      validated = false;
    }
    if (!code && validated) {
      setCodeErr(t('errors:obligatoryField'));
      validated = false;
    }
    return validated;
  };

  const triggerAddCommunity = async () => {
    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && name && code) {
      const payload: CommunityAddEdit = {
        name,
        code
      };
      const success = await addCommunity(payload);
      if (success) {
        setSnackbar({
          opened: true,
          severity: 'success',
          msg: t('communities:snackbar:communityAddSuccess')
        });
        navigate(-1);
      }
    }
  };

  const triggerUpdateCommunity = async () => {
    if (!communityId) return;

    const dataValidated = validation();
    if (!dataValidated) {
      setFormErrorsDialogOpened(true);
      return;
    }

    if (dataValidated && name && code) {
      const payload: CommunityAddEdit = {
        name,
        code
      };
      const success = await updateCommunity(parseInt(communityId), payload);
      if (success) {
        setSnackbar({
          opened: true,
          severity: 'success',
          msg: t('communities:snackbar:communityUpdateSuccess')
        });
      }
    }
  };

  const triggerDeleteCommunity = async () => {
    if (communityId) {
      const success = await deleteCommunity(parseInt(communityId));
      openSnackBar(
        success,
        t('communities:snackbar:communityDeleteSuccess'),
        t('communities:snackbar:communityDeleteError')
      );
      setDeleteCommunityDialogOpen(false);
      if (success) navigate(-1);
    }
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1} overflow="auto">
      {/* Header */}
      <Box>
        <FlexBox alignItems="start" justifyContent="space-between">
          <IconButton size="small" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box flex={1}>
            <Typography variant="h6" fontWeight="bold">
              {props.mode === 'create'
                ? t('communities:addCommunity')
                : t('communities:editCommunity')}
            </Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
              {props.mode === 'create'
                ? t('communities:texts:createPageDescription')
                : t('communities:texts:editPageDescription')}
            </Typography>
          </Box>
        </FlexBox>
        <Toolbar
          my={4}
          rightcomponent={
            props.mode === 'create' ? (
              <LoadingButton
                loading={addCommunityRequest.inProgress}
                color="success"
                variant="contained"
                startIcon={<Check />}
                onClick={triggerAddCommunity}>
                {t('communities:addCommunity')}
              </LoadingButton>
            ) : props.mode === 'edit' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                {!data?.numberOfUsers && (
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ backgroundColor: 'white' }}
                    startIcon={<DeleteForever />}
                    disabled={!data}
                    onClick={() => setDeleteCommunityDialogOpen(true)}>
                    {t('communities:deleteCommunity')}
                  </Button>
                )}
                <LoadingButton
                  loading={updateCommunityRequest.inProgress}
                  color="success"
                  variant="contained"
                  startIcon={<Check />}
                  disabled={!edited}
                  onClick={triggerUpdateCommunity}>
                  {t('common:confirmChanges')}
                </LoadingButton>
              </FlexBox>
            ) : (
              <></>
            )
          }
        />
      </Box>

      {/* Body */}
      <FlexBox gap={1} flex={1} alignItems="flex-start" width="90%" m="auto">
        {props.mode === 'create' || (props.mode === 'edit' && data) ? (
          <EditableCard
            hideHeaderDivider
            sx={{ mx: 'auto', width: '33%' }}
            title={t('communities:info')}
            icon={<InfoOutlined color="primary" />}
            headerSx={{
              pr: 0
            }}>
            <Stack gap={2} p={2} pt={0}>
              {/* name */}
              <InfoText>
                <p>{t('communities:formInputHelperTexts:insertName')}</p>
              </InfoText>
              <TextField
                size="small"
                label={t('communities:formInputLabels:name')}
                error={Boolean(nameErr)}
                helperText={nameErr}
                InputLabelProps={{ shrink: true }}
                value={name}
                autoComplete="new-name"
                onChange={(e) => {
                  const newTextValue = e.target.value;
                  setName(newTextValue);
                  setNameErr(undefined);
                  setEdited(true);
                }}
              />

              {/* code */}
              <InfoText>
                <p>{t('communities:formInputHelperTexts:specifyCode')}</p>
              </InfoText>
              <TextField
                size="small"
                label={t('communities:formInputLabels:code')}
                error={Boolean(codeErr)}
                helperText={codeErr}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={code}
                onChange={(e) => {
                  const newTextValue = e.target.value;
                  setCode(newTextValue);
                  setCodeErr(undefined);
                  setEdited(true);
                }}
              />
            </Stack>
          </EditableCard>
        ) : (
          <ActivityIndicator />
        )}
      </FlexBox>

      {/* Form Errors Dialog */}
      <Dialog open={formErrorsDialogOpened} onClose={() => setFormErrorsDialogOpened(false)}>
        <Alert sx={{ p: 3 }} severity="warning" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('errors:compilationErrors')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setFormErrorsDialogOpened(false)} color="inherit">
              Ok
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Delete Community Dialog */}
      <Dialog
        open={deleteCommunityDialogOpened}
        onClose={() => setDeleteCommunityDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('communities:texts:deleteCommunityInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteCommunityDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteCommunityRequest.inProgress}
              onClick={triggerDeleteCommunity}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
