import React, { useCallback, useState } from 'react';

export type Breadcrumb = {
  path: string;
  title: string;
};

export type BreadcrumbsContextType = {
  breadcrumbs: Record<string, Breadcrumb>;
  add: (breadcrumb: Breadcrumb) => void;
};

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType>({
  breadcrumbs: {},
  add: () => {}
});

type BreadcrumbsProviderProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

export const BreadcrumbsProvider = (props: BreadcrumbsProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState({});
  const add = useCallback((breadcrumb: Breadcrumb) => {
    setBreadcrumbs((breadcrumbs) => ({ ...breadcrumbs, [breadcrumb.path]: breadcrumb }));
  }, []);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, add }}>
      {props.children}
    </BreadcrumbsContext.Provider>
  );
};
