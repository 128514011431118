import * as React from 'react';
import {
  Analytics,
  ArrowForward,
  Group,
  Groups,
  Home,
  Launch,
  Logout,
  MenuBookOutlined,
  Person,
  Settings
} from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  createTheme,
  CSSObject,
  styled,
  Theme,
  ThemeProvider,
  useTheme
} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/Actifyre_logo.svg';
import { ReactComponent as TextLogo } from '../../../assets/Actifyre_logo_text.svg';
import { RoleIds } from '../../entities/User';
import useAuthentication from '../../hooks/useAuthentication';
import useUser from '../../hooks/useUser';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { StyledTooltip } from './StyledComponents';

const drawerWidth = 240;
export const drawerHeight = 58;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  height: drawerHeight,
  '&>div': {
    minHeight: drawerHeight
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

export default function Drawer() {
  const { t } = useTranslation();
  const { signOut } = useAuthentication();
  const { me } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertRedirectUrl, setAlertRedirectUrl] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menu = React.useMemo(() => {
    return me
      ? _.compact([
          {
            label: t('navigation:home'),
            icon: <Home />,
            redirect: '/home'
          },
          {
            label: t('navigation:flyers'),
            icon: <MenuBookOutlined />,
            redirect: '/flyers',
            bottomDivider: true
          },
          me?.role?.id === RoleIds.ADMIN_USER_ID
            ? {
                label: t('navigation:users'),
                icon: <Group />,
                redirect: '/users'
              }
            : undefined,
          me?.role?.id === RoleIds.ADMIN_USER_ID
            ? {
                label: t('navigation:communities'),
                icon: <Groups />,
                redirect: '/communities'
              }
            : undefined,
          {
            label: t('navigation:trackingStatistics'),
            icon: <Analytics />,
            // redirect: `${process.env.REACT_APP_API_URL}/kibana`,
            redirect: '/stats',
            externalUrl: false,
            bottomDivider: true
          },
          {
            label: t('navigation:account'),
            icon: <Person />,
            redirect: '/account'
          },
          {
            label: t('navigation:settings'),
            icon: <Settings />,
            redirect: '/settings'
          }
        ])
      : [];
  }, [t, me]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={open}
        color="transparent"
        sx={{
          background: (theme) =>
            // `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.secondary.main})`
            `${theme.palette.secondary.main}`
        }}>
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 3,
              ...(open && { display: 'none' })
            }}>
            <MenuIcon />
          </IconButton>

          <Breadcrumbs sx={{ flex: 1 }} />

          <Logo width={35} height={35} color="white" />
        </Toolbar>
      </AppBar>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: '#44ecb6',
              light: '#90DDF4',
              dark: '#16B0DF'
            },
            secondary: {
              main: '#43ebb5',
              light: '#91F3D4',
              dark: '#17DE9F'
            },
            tertiary: {
              main: '#002a4d',
              light: '#0065B8',
              dark: '#001629'
            },
            background: {
              default: '#001b32',
              paper: '#002a4d'
            },
            mode: 'dark'
          }
        })}>
        <CustomDrawer
          variant="permanent"
          open={open}
          sx={{
            height: drawerHeight,
            '&>div': {
              borderRight: 'none'
            }
          }}>
          <DrawerHeader sx={{ minHeight: `${drawerHeight}px !important` }}>
            <TextLogo width={'68%'} style={{ marginRight: 'auto', marginLeft: 12 }} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menu.map((item, index) => (
              <div key={`drawer-item-${index}`}>
                <StyledTooltip
                  title={item.label}
                  arrow
                  placement="right"
                  disableHoverListener={open}>
                  <ListItemButton
                    selected={location.pathname.includes(item.redirect)}
                    onClick={() => {
                      if (item.externalUrl) {
                        setAlertRedirectUrl(item.redirect);
                        setAlertOpen(true);
                      } else {
                        navigate(item.redirect);
                        setOpen(false);
                      }
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: (theme) =>
                          location.pathname.includes(item.redirect)
                            ? theme.palette.primary.main
                            : theme.palette.text.primary
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        '&>span': {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: location.pathname.includes(item.redirect)
                            ? 'bold'
                            : 'initial',
                          color: (theme) =>
                            location.pathname.includes(item.redirect)
                              ? theme.palette.primary.main
                              : theme.palette.text.primary
                        }
                      }}>
                      {item.label}
                      {item.externalUrl && (
                        <Launch sx={{ height: 10, width: 10, ml: 1, mb: 0.5 }} />
                      )}
                    </ListItemText>
                  </ListItemButton>
                </StyledTooltip>
                {item.bottomDivider && <Divider sx={{ my: 1 }} />}
              </div>
            ))}
          </List>

          {/* Logout btn */}
          {open ? (
            <Button
              variant="contained"
              onClick={signOut}
              endIcon={<Logout />}
              sx={{
                backgroundColor: '#732634',
                color: 'white',
                marginTop: 'auto',
                borderRadius: 0,
                height: 38,
                transition: '0.2s',
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.error.dark
                }
              }}>
              {t('authentication:logout')}
            </Button>
          ) : (
            <StyledTooltip title={t('authentication:logout')} arrow placement="right">
              <IconButton
                onClick={signOut}
                sx={{
                  backgroundColor: '#732634',
                  color: 'white',
                  marginTop: 'auto',
                  borderRadius: 0,
                  height: 38,
                  transition: '0.2s',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.error.dark
                  }
                }}>
                <Logout />
              </IconButton>
            </StyledTooltip>
          )}
        </CustomDrawer>
      </ThemeProvider>

      {/* Dialog */}
      <Dialog open={alertOpen} onClose={() => setAlertOpen(false)}>
        <Alert sx={{ p: 3, backgroundColor: 'white' }} severity="info" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('navigation:externalUrl')}
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('navigation:externalUrlInfo')}
            <Box sx={{ my: 4, fontStyle: 'italic' }}>
              <Link
                variant="subtitle2"
                underline="hover"
                sx={{ cursor: 'pointer', fontSize: 16 }}
                onClick={() => window.open(alertRedirectUrl)}>
                {alertRedirectUrl}
              </Link>
            </Box>
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setAlertOpen(false)} color="inherit">
              {t('common:cancel')}
            </Button>
            <Button
              sx={{ color: 'white' }}
              onClick={() => {
                window.open(alertRedirectUrl);
                setAlertRedirectUrl(undefined);
                setAlertOpen(false);
              }}
              color="primary"
              variant="contained"
              endIcon={<ArrowForward />}>
              {t('common:go')}
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>

      {/* Backdrop */}
      <Box sx={{ flexGrow: 1 }}>
        <Backdrop sx={{ color: '#fff', zIndex: 100 }} open={open} onClick={() => setOpen(false)} />
      </Box>
    </Box>
  );
}
