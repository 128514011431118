import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandsApiResponse, EmbeddedBrand } from '../../../entities/Brand';
import { PageblePayload } from '../../../entities/Common';
import {
  mutateErrorState,
  mutateParseErrorState,
  mutateRequestState,
  mutateSuccessState,
  RequestError,
  RequestParseError,
  requestState
} from '../../utils';

const initialState = {
  currentBrand: undefined as EmbeddedBrand | undefined,
  brands: { data: [] as EmbeddedBrand[], pagination: undefined as PageblePayload | undefined },

  requests: {
    readBrand: requestState(),
    readMyBrand: requestState(),
    readBrands: requestState(),
    addBrand: requestState(),
    updateBrand: requestState(),
    updateMyBrand: requestState(),
    deleteBrand: requestState()
  }
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    // clear current Brand
    clearCurrentBrand(state) {
      state.currentBrand = initialState.currentBrand;
    },

    // Single Brand
    readBrandRequest(state) {
      mutateRequestState(state.requests.readBrand);
    },
    readBrandSuccess(state, action: PayloadAction<EmbeddedBrand>) {
      state.currentBrand = action.payload;
      mutateSuccessState(state.requests.readBrand);
    },
    readBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readBrand, action.payload.error);
    },
    readBrandParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readBrand, action.payload.parseError);
    },

    // read my Brand (for brand user)
    readMyBrandRequest(state) {
      mutateRequestState(state.requests.readMyBrand);
    },
    readMyBrandSuccess(state, action: PayloadAction<EmbeddedBrand>) {
      state.currentBrand = action.payload;
      mutateSuccessState(state.requests.readMyBrand);
    },
    readMyBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readMyBrand, action.payload.error);
    },
    readMyBrandParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readMyBrand, action.payload.parseError);
    },

    // Brands List
    readBrandsRequest(state) {
      mutateRequestState(state.requests.readBrands);
    },
    readBrandsSuccess(state, action: PayloadAction<BrandsApiResponse>) {
      state.brands.data = action.payload.content;
      const deepCopiedPayload = JSON.parse(JSON.stringify(action.payload));
      delete (deepCopiedPayload as any).content;
      state.brands.pagination = deepCopiedPayload;
      mutateSuccessState(state.requests.readBrands);
    },
    readBrandsError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.readBrands, action.payload.error);
    },
    readBrandsParseError(state, action: PayloadAction<{ parseError: RequestParseError }>) {
      mutateParseErrorState(state.requests.readBrands, action.payload.parseError);
    },

    // Add Brand
    addBrandRequest(state) {
      mutateRequestState(state.requests.addBrand);
    },
    addBrandSuccess(state) {
      mutateSuccessState(state.requests.addBrand);
    },
    addBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.addBrand, action.payload.error);
    },

    // Update Brand
    updateBrandRequest(state) {
      mutateRequestState(state.requests.updateBrand);
    },
    updateBrandSuccess(state) {
      mutateSuccessState(state.requests.updateBrand);
    },
    updateBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateBrand, action.payload.error);
    },

    // Update My Brand
    updateMyBrandRequest(state) {
      mutateRequestState(state.requests.updateMyBrand);
    },
    updateMyBrandSuccess(state) {
      mutateSuccessState(state.requests.updateMyBrand);
    },
    updateMyBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.updateMyBrand, action.payload.error);
    },

    // Delete Brand
    deleteBrandRequest(state) {
      mutateRequestState(state.requests.deleteBrand);
    },
    deleteBrandSuccess(state) {
      mutateSuccessState(state.requests.deleteBrand);
    },
    deleteBrandError(state, action: PayloadAction<{ error: RequestError }>) {
      mutateErrorState(state.requests.deleteBrand, action.payload.error);
    }
  }
});

// Action creators are generated for each case reducer function
export const brandActions = brandSlice.actions;

export default brandSlice.reducer;
