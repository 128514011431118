import React, { useState } from 'react';
import { Close, Upload, ViewInAr } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useFiles from '../../hooks/useFiles';
import PathFormatter from '../../utils/PathFormatter';
import { FlexBox, StyledTooltip } from './StyledComponents';

interface UploadAssetProps {
  titleName: string;
  titleAndroidName: string;
  remoteFileName?: string;
  remoteAndroidFileName?: string;
  onMainUpload?: () => void;
  onMainUploaded?: (filename: string) => void;
  onMainDelete?: () => void;
  onAndroidUpload?: () => void;
  onAndroidUploaded?: (filename: string) => void;
  onAndroidDelete?: () => void;
  disabled?: boolean;
}
export default function UploadAsset(props: UploadAssetProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { uploadFile } = useFiles();
  const [uploadingMainFiles, setUploadingMainFiles] = useState<FileList | null>();
  const [uploadingAndroidFiles, setUploadingAndroidFiles] = useState<FileList | null>();
  const [uploading, setUploading] = useState(false);

  const uploadImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onMainUpload) props.onMainUpload();
    if (e.target.files) {
      setUploading(true);
      setUploadingMainFiles(e.target.files);

      const filename = await uploadFile(e.target.files[0]);

      if (props.onMainUploaded && filename) props.onMainUploaded(filename);
      setUploading(false);
    }
  };

  const uploadAndroidImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onAndroidUpload) props.onAndroidUpload();
    if (e.target.files) {
      setUploading(true);
      setUploadingAndroidFiles(e.target.files);

      const filename = await uploadFile(e.target.files[0]);

      if (props.onAndroidUploaded && filename) props.onAndroidUploaded(filename);
      setUploading(false);
    }
  };

  return (
    <Box>
      <FlexBox alignItems="flex-end" sx={{ mb: 1.5, mt: 0.5 }} gap={1}>
        <ViewInAr
          sx={{ height: 40, width: 40 }}
          htmlColor={
            uploadingMainFiles || props.remoteFileName ? theme.palette.tertiary.main : 'lightgray'
          }
        />
        <ViewInAr
          sx={{ height: 40, width: 40 }}
          htmlColor={
            uploadingAndroidFiles || props.remoteAndroidFileName
              ? theme.palette.tertiary.main
              : 'lightgray'
          }
        />
        <Box flex={1}>
          <FlexBox alignItems="baseline">
            <Typography
              fontWeight={uploadingMainFiles || props.remoteFileName ? '600' : 'initial'}
              fontStyle={'italic'}
              fontSize={12}
              sx={{
                color: uploadingMainFiles || props.remoteFileName ? 'initial' : 'lightgray',
                mb: -0.1,
                wordBreak: 'break-all'
              }}>
              {uploadingMainFiles
                ? uploadingMainFiles[0].name
                : (props.remoteFileName &&
                    PathFormatter.absoluteToRelative(props.remoteFileName)) ??
                  t('asset:noAsset')}
            </Typography>
            {uploadingMainFiles && (
              <StyledTooltip arrow title={t('common:remove')}>
                <IconButton
                  disabled={props.disabled}
                  size="small"
                  sx={{ p: 0, ml: 'auto', transform: 'translate(0px, 4px)' }}
                  onClick={() => {
                    setUploadingMainFiles(null);
                    if (props.onMainDelete) props.onMainDelete();
                  }}>
                  <Close fontSize="small" />
                </IconButton>
              </StyledTooltip>
            )}
          </FlexBox>
          <FlexBox alignItems="baseline">
            <Typography
              fontWeight={uploadingAndroidFiles || props.remoteAndroidFileName ? '600' : 'initial'}
              fontStyle={'italic'}
              fontSize={12}
              sx={{
                color:
                  uploadingAndroidFiles || props.remoteAndroidFileName ? 'initial' : 'lightgray',
                mb: -0.1,
                wordBreak: 'break-all'
              }}>
              {uploadingAndroidFiles
                ? uploadingAndroidFiles[0].name
                : (props.remoteAndroidFileName &&
                    PathFormatter.absoluteToRelative(props.remoteAndroidFileName)) ??
                  t('asset:noAsset')}
            </Typography>
            {uploadingAndroidFiles && (
              <StyledTooltip arrow title={t('common:remove')}>
                <IconButton
                  disabled={props.disabled}
                  size="small"
                  sx={{ p: 0, ml: 'auto', transform: 'translate(0px, 4px)' }}
                  onClick={() => {
                    setUploadingAndroidFiles(null);
                    if (props.onAndroidDelete) props.onAndroidDelete();
                  }}>
                  <Close fontSize="small" />
                </IconButton>
              </StyledTooltip>
            )}
          </FlexBox>
          {uploading && (
            <Grow in={uploading}>
              <LinearProgress sx={{ mt: 1 }} />
            </Grow>
          )}
        </Box>
      </FlexBox>
      {/* @ts-ignore */}
      <Button
        disabled={props.disabled}
        variant="outlined"
        component="label"
        fullWidth
        sx={{
          minHeight: 100,
          flexDirection: 'column',
          border: '2px dashed !important'
        }}>
        <Upload />
        <div style={{ fontSize: 14 }}>{props.titleName}</div>
        <div style={{ textTransform: 'none', color: 'gray', fontSize: 11 }}>Only USDZ formats.</div>
        <input hidden accept=".usdz" type="file" onChange={uploadImg} />
      </Button>
      <Button
        disabled={props.disabled}
        variant="outlined"
        component="label"
        fullWidth
        sx={{
          minHeight: 100,
          flexDirection: 'column',
          border: '2px dashed !important'
        }}>
        <Upload />
        <div style={{ fontSize: 14 }}>{props.titleAndroidName}</div>
        <div style={{ textTransform: 'none', color: 'gray', fontSize: 11 }}>Only GLB formats.</div>
        <input hidden accept=".glb" type="file" onChange={uploadAndroidImg} />
      </Button>
    </Box>
  );
}
