import React, { useContext, useEffect, useState } from 'react';
import {
  Close,
  ControlPointDuplicate,
  DeleteForever,
  Edit,
  Link as LinkIcon,
  OpenWith,
  QrCode2,
  TimerOff,
  Toll
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DictionaryString, Language } from '../../entities/Common';
import { Flyer } from '../../entities/Flyer';
import useFlyers from '../../hooks/useFlyers';
import { currentLanguage } from '../../i18n';
import { flyersActions } from '../../redux/be_interaction_store/slices/flyer';
import PathFormatter from '../../utils/PathFormatter';
import NoDataBanner from './NoDataBanner';
import { SnackbarContext } from './Snackbar/SnackbarProvider';
import { FlexBox, StyledTooltip } from './StyledComponents';

export interface SelectButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

interface FlyerProps {
  brandId?: number;
  data: Flyer;
  toolbar?: boolean;
  selectable?: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
  selected?: boolean;
  selectComponent?: (props: SelectButtonProps) => any;
  imgMaxHeight?: number | string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  draggable?: boolean;
}
export default function FlyerCard(props: FlyerProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setSnackbar } = useContext(SnackbarContext);
  const { deleteFlyer, deleteFlyerRequest, addFlyer, readFlyers, readFlyersRequest } = useFlyers();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteFlyerDialogOpened, setDeleteFlyerDialogOpen] = React.useState(false);

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  useEffect(() => {
    if (deleteFlyerRequest.httpError) {
      setSnackbar({
        opened: true,
        severity: 'error',
        msg: `${t('flyer:sbackbar:flyerDeleteError')} Error:  
        ${
          (deleteFlyerRequest.httpError?.axiosError?.response?.data as any)?.error?.message ??
          'sconosciuto'
        }`,
        onClose: () => dispatch(flyersActions.deleteFlyerSuccess())
      });
    }
  }, [deleteFlyerRequest.httpError]);

  const handleDelete = async () => {
    setLoading(true);
    const success = await deleteFlyer(props.data.id);
    if (success) {
      setSnackbar({
        opened: true,
        severity: 'success',
        msg: 'Il volantino è stato eliminato correttamente!'
      });
    }
    if (success) {
      await readFlyers(props.brandId);
    }
    setLoading(false);
    setDeleteFlyerDialogOpen(false);
  };

  const handleDuplicate = async () => {
    setLoading(true);
    if (props.brandId) {
      const success = await addFlyer({
        brandId: props.brandId,
        front: Object.keys(props.data.front).reduce((accumulator, key) => {
          return {
            ...accumulator,
            [key]: PathFormatter.absoluteToRelative(props.data.front[key as Language])
          };
        }, {}) as DictionaryString,
        back: props.data.back,
        wakaCost: props.data.wakaCost,
        expiredAt: props.data.expiredAt,
        link: props.data.link
      });
      openSnackBar(
        success,
        'Volantino duplicato con successo!',
        'Si è verificato un errore e il volantino non è stato duplicato. Riprovare.'
      );
      if (success) {
        await readFlyers(props.brandId);
      }
    }
    setLoading(false);
  };

  const handleEdit = () => {
    navigate(`/flyers/${props.brandId}/${props.data.id}/edit`);
  };

  const handleSelectClick = async () => {
    setLoading(true);
    if (!props.selected) {
      if (props.onSelect) {
        await props.onSelect();
      }
    } else {
      if (props.onDeselect) {
        await props.onDeselect();
      }
    }
    setLoading(false);
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'flyer',
      item: { flyerId: props.data.id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      })
    }),
    []
  );

  return (
    <>
      <Grow in={true} timeout={Math.random() * 500 + 200}>
        <Card
          ref={props.draggable ? drag : null}
          variant="outlined"
          style={{
            opacity: isDragging ? 0.3 : 1,
            borderRadius: 16,
            backgroundColor: props.selected
              ? props.disabled
                ? 'rgba(0, 0, 0, 0.1)'
                : theme.palette.primary.light
              : 'white',
            transition: 'background-color 0.4s',
            display: 'flex',
            flexDirection: 'column'
          }}
          sx={props.sx}>
          {props.draggable && (
            <FlexBox sx={{ '&:hover': { cursor: 'grab' } }}>
              <OpenWith sx={{ mx: 'auto', mt: 1.5, mb: 0.5, color: '#395862' }} />
            </FlexBox>
          )}
          <FlexBox
            sx={{
              position: 'relative',
              maxWidth: 'calc(100% - 16px)',
              minHeight: 0,
              borderRadius: 3,
              m: 1,
              pb: 0
            }}>
            <Box
              sx={{
                position: 'absolute',
                m: '5%',
                top: 0,
                left: 0,
                height: '90%',
                width: '90%',
                backgroundImage: `url(${props.data.front[currentLanguage()]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: 3,
                filter: 'blur(8px) opacity(0.6)'
              }}
            />
            <CardMedia
              component="img"
              onClick={() => setModalOpen(true)}
              src={props.data.front[currentLanguage()]}
              sx={{
                zIndex: 1,
                borderRadius: 2,
                maxHeight: '100%',
                objectFit: 'contain',
                transition: '0.2s',
                ':hover': {
                  filter: 'brightness(1.4)',
                  opacity: 0.8,
                  cursor: 'pointer'
                }
              }}
            />
          </FlexBox>

          {props.toolbar && (
            <CardActions sx={{ py: 0.8 }}>
              <StyledTooltip
                title={
                  props.data.initiativeCount || props.data.campaignCount
                    ? t('flyer:texts:notDeletable')
                    : t('common:delete')
                }
                severity="error"
                arrow>
                <span style={{ marginRight: 4 }}>
                  <IconButton
                    size="small"
                    aria-label={t('common:delete')}
                    color="error"
                    onClick={() => setDeleteFlyerDialogOpen(true)}
                    disabled={
                      loading ||
                      props.disabled ||
                      Boolean(props.data.initiativeCount) ||
                      Boolean(props.data.campaignCount)
                    }>
                    <DeleteForever fontSize="small" />
                  </IconButton>
                </span>
              </StyledTooltip>

              <StyledTooltip title={t('common:duplicate')} arrow>
                <IconButton
                  size="small"
                  aria-label={t('common:duplicate')}
                  color="primary"
                  onClick={handleDuplicate}
                  disabled={loading || !props.brandId || props.disabled}>
                  <ControlPointDuplicate htmlColor={'primary'} fontSize="small" />
                </IconButton>
              </StyledTooltip>

              <FlexBox sx={{ flex: 1, justifyContent: 'flex-end' }}>
                <StyledTooltip title={t('common:edit')} arrow onClick={handleEdit}>
                  <IconButton
                    size="small"
                    aria-label={t('common:edit')}
                    disabled={loading || props.disabled}>
                    <Edit fontSize="small" />
                  </IconButton>
                </StyledTooltip>
              </FlexBox>
            </CardActions>
          )}

          {props.selectable && (
            <CardActionArea
              disabled={props.disabled}
              onClick={(e) => {
                e.stopPropagation();
                if (!loading) handleSelectClick();
              }}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <CardActions sx={{ py: 0, mt: -0.5 }}>
                {props.selectComponent ? (
                  <props.selectComponent
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!loading) handleSelectClick();
                    }}
                  />
                ) : (
                  <Checkbox
                    disabled={props.disabled}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    checked={props.selected}
                    size="small"
                    color={props.selected ? 'default' : 'primary'}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!loading) handleSelectClick();
                    }}
                  />
                )}
              </CardActions>
            </CardActionArea>
          )}
        </Card>
      </Grow>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 1,
            boxShadow: 24
          }}>
          <FlexBox flexDirection="column" position="relative" p={4}>
            <IconButton
              size="small"
              onClick={() => setModalOpen(false)}
              sx={{ position: 'absolute', top: 8, right: 8 }}>
              <Close />
            </IconButton>

            <FlexBox flexDirection="row" gap={1}>
              <FlexBox flexDirection="column" alignItems="center" gap={1}>
                <Typography variant="h6">{t('flyer:formInputLabels:front')}</Typography>
                <img
                  src={props.data.front[currentLanguage()]}
                  style={{
                    borderRadius: 16,
                    height: 'auto',
                    width: '30vh',
                    margin: 'auto',
                    boxShadow: theme.shadows[4]
                  }}
                />
              </FlexBox>
              <FlexBox flexDirection="column" alignItems="center" gap={1}>
                <Typography variant="h6">{t('flyer:formInputLabels:back')}</Typography>
                <FlexBox
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                  sx={{
                    width: '30vh',
                    height: '100%',
                    borderRadius: 4,
                    border: '1px solid lightgray'
                  }}>
                  {props.data.back || props.data.qrCode ? (
                    <>
                      <Typography sx={{ textAlign: 'center', wordBreak: 'break-all', p: 2 }}>
                        {props.data.back}
                      </Typography>

                      {props.data.qrCode && (
                        <FlexBox flexDirection="column">
                          <QrCode2 fontSize="large" sx={{ m: 'auto' }} />
                          <Typography
                            sx={{ m: 'auto', textAlign: 'center', wordBreak: 'break-all', px: 2 }}>
                            {props.data.qrCode}
                          </Typography>
                        </FlexBox>
                      )}
                    </>
                  ) : (
                    <NoDataBanner />
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <List>
              <ListItem>
                <ListItemIcon>
                  <Toll />
                </ListItemIcon>

                <ListItemText>
                  <b>{t('flyer:formInputLabels:wakaCost')}:</b>
                </ListItemText>
                <Box flex={10} border="1px dashed lightgray" />
                <ListItemText sx={{ textAlign: 'end' }}>{props.data.wakaCost} waka</ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>

                <ListItemText>
                  <b>{t('flyer:formInputLabels:link')}:</b>
                </ListItemText>
                <Box flex={10} border="1px dashed lightgray" />
                <ListItemText sx={{ textAlign: 'end' }}>
                  <Link
                    variant="subtitle2"
                    underline="hover"
                    sx={{
                      cursor: 'pointer',
                      fontSize: 16,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      maxWidth: 300
                    }}
                    onClick={() => window.open(props.data.link ?? '', '')}>
                    {PathFormatter.absoluteToRelative(props.data.link ?? '')}
                  </Link>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <TimerOff />
                </ListItemIcon>

                <ListItemText>
                  <b>{t('flyer:formInputLabels:expirationDate')}:</b>
                </ListItemText>
                <Box flex={10} border="1px dashed lightgray" />
                <ListItemText sx={{ textAlign: 'end' }}>
                  {props.data.expiredAt && new Date(props.data.expiredAt).toLocaleString()}
                </ListItemText>
              </ListItem>
            </List>

            <Button
              startIcon={<Edit />}
              variant="outlined"
              sx={{ mt: 4, alignSelf: 'center' }}
              onClick={() => {
                navigate(`/flyers/${props.brandId}/${props.data.id}/edit`);
              }}>
              {t('flyer:editFlyer')}
            </Button>
          </FlexBox>
        </Box>
      </Modal>

      {/* Delete Flyer Dialog */}
      <Dialog open={deleteFlyerDialogOpened} onClose={() => setDeleteFlyerDialogOpen(false)}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('flyer:texts:deleteFlyerInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={() => setDeleteFlyerDialogOpen(false)} color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteFlyerRequest.inProgress || readFlyersRequest.inProgress}
              onClick={handleDelete}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
}
