import React from 'react';
import { Close, Search } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { FlexBox } from './StyledComponents';

interface SearchBarProps {
  backgroundColor?: string;
  color?: string;
  onChange?: (text: string) => void;
  value?: string;
  placeholder?: string;
  fontSize?: number | string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  loading?: boolean;
}
export const SearchBar = (props: SearchBarProps) => {
  const [value, setValue] = React.useState(props.value);
  const [borderColor, setBorderColor] = React.useState('rgba(0,0,0, 0.25)');
  const [focused, setFocused] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    if (props.value === value || props.value === '') setValue(props.value);
  }, [props.value]);

  return (
    <FlexBox
      onMouseOver={() => setBorderColor('black')}
      onMouseLeave={() => setBorderColor('rgba(0,0,0, 0.25)')}
      sx={{
        alignItems: 'center',
        position: 'relative',
        p: 1,
        borderRadius: 1,
        border: `1px solid ${borderColor}`,
        background: props.backgroundColor,
        color: props.color,
        ...props.sx
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: -1,
          left: -1,
          width: 'calc(100% + 2px)',
          height: 'calc(100% + 2px)',
          pointerEvents: 'none',
          zIndex: 1,
          borderRadius: 1,
          boxSizing: 'border-box',
          border: `2px solid ${theme.palette.primary.main}`,
          opacity: focused ? 1 : 0
        }}
      />
      <Search sx={{ mx: 1 }} />
      <input
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
        }}
        value={value}
        onChange={(e) => {
          setValue((e.target as HTMLInputElement).value);
          if (props.onChange) props.onChange(e.target.value);
        }}
        placeholder={props.placeholder}
        disabled={props.disabled || props.loading}
        style={{
          color: props.color,
          fontSize: props.fontSize ?? 14,
          background: 'transparent',
          border: 'none',
          outline: 'none',
          width: '100%',
          height: '100%'
        }}
      />
      <FlexBox
        style={{ width: 36, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        {props.loading ? (
          <CircularProgress sx={{ maxWidth: 20, maxHeight: 20 }} />
        ) : (
          <Fade in={Boolean(value)}>
            <IconButton
              size="small"
              sx={{ color: props.color }}
              onClick={() => {
                setValue('');
                if (props.onChange) props.onChange('');
              }}>
              <Close fontSize="small" sx={{ color: props.color }} />
            </IconButton>
          </Fade>
        )}
      </FlexBox>
    </FlexBox>
  );
};
