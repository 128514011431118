import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AddLocationAlt,
  Check,
  ControlCamera,
  DeleteForever,
  LocationOn,
  MenuBook
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Flyer } from '../../entities/Flyer';
import { LocationRequest } from '../../entities/Location';
import { TokenAssignmentMode, TokenRequest } from '../../entities/Token';
import { RootState } from '../../redux/store';
import EditableCard from './EditableCard';
import FlyerCard from './FlyerCard';
import { FlexBox, InfoText, StyledTooltip } from './StyledComponents';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../../assets/marker-icon-2x.png'),
  iconUrl: require('../../../assets/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const InitialCoordinates: LatLngTuple = [41.29246, 12.5736108];

interface LocationsCardProps {
  brandId: number;
  tokens?: ({ flyer?: Flyer } & TokenRequest)[];
  onLocationAdd?: (locationToAdd: LocationRequest) => void;
  onLocationChanged?: (newLocation: LocationRequest, oldLocation: LocationRequest) => void;
  onLocationDeleted?: (locationToDelete: LocationRequest) => void;
  onAssignLocationToTkn?: (tknIndex: number, location: LocationRequest) => void;
  locations: LocationRequest[];
  error?: string;
  disableLocationsForDelete?: number[];
  disabled?: boolean;
}
export default function LocationsCard(props: LocationsCardProps) {
  const { t } = useTranslation();
  const markerRef: React.Ref<L.Marker<any>> = useRef(null);
  const [center, setCenter] = useState<LatLngTuple>(InitialCoordinates);
  const [zoom, setZoom] = useState<number>(6);
  const [centeredMarkerIndex, setCenteredMarkerIndex] = useState<number>(0);

  const [addingMarker, setAddingMarker] = useState(false);
  const [movingMarker, setMovingMarker] = useState<{
    startPosition: LocationRequest;
    endPosition: LocationRequest;
  }>();
  const [markers, setMarkers] = useState<LocationRequest[]>(props.locations);
  const [addingMarkerCoordinates, setAddingMarkerCoordinates] = useState<LatLngTuple>(
    InitialCoordinates as LatLngTuple
  );

  useEffect(() => {
    setMarkers(props.locations);
  }, [props.locations]);

  const setTokenName = (name: string, location: LocationRequest) => {
    if (props.onLocationChanged) props.onLocationChanged({ ...location, name }, location);
  };

  const addToken = () => {
    if (props.onLocationAdd)
      props.onLocationAdd({
        lat: addingMarkerCoordinates[0],
        lng: addingMarkerCoordinates[1],
        name: ''
      });
    setTimeout(() => {
      if (markerRef.current) markerRef.current.openPopup();
    }, 0);
    setAddingMarker(false);
  };

  const moveMarker = () => {
    if (props.onLocationChanged && movingMarker)
      props.onLocationChanged(movingMarker.endPosition, movingMarker.startPosition);
    setTimeout(() => {
      setMovingMarker(undefined);
    }, 300);
  };

  const deleteMarker = (marker: LocationRequest) => {
    if (markerRef.current) markerRef.current.closePopup();
    if (props.onLocationDeleted) props.onLocationDeleted(marker);
  };

  const MapEventsCatcher = useCallback(() => {
    useMapEvents({
      drag: (e) => {
        setAddingMarkerCoordinates([e.target.getCenter().lat, e.target.getCenter().lng]);
        setMovingMarker((marker) => {
          return marker
            ? {
                startPosition: marker?.startPosition,
                endPosition: {
                  ...movingMarker?.endPosition,
                  lat: e.target.getCenter().lat,
                  lng: e.target.getCenter().lng
                }
              }
            : undefined;
        });
        setCenter(e.target.getCenter());
        setCenteredMarkerIndex(0);
      },
      zoom: (e) => {
        setAddingMarkerCoordinates([e.target.getCenter().lat, e.target.getCenter().lng]);
        setMovingMarker((marker) => {
          return marker
            ? {
                startPosition: marker?.startPosition,
                endPosition: {
                  ...movingMarker?.endPosition,
                  lat: e.target.getCenter().lat,
                  lng: e.target.getCenter().lng
                }
              }
            : undefined;
        });
        setZoom(e.target.getZoom());
        setCenter(e.target.getCenter());
      }
    });
    return null;
  }, [movingMarker?.endPosition]);

  function UpdateMapView(props: { mapCentre: LatLngTuple; zoom: number }) {
    const map = useMap();
    map.setView(props.mapCentre, zoom);
    return null;
  }

  return (
    <EditableCard
      title={t('initiatives:quests:pageSections:locations')}
      icon={<LocationOn color="primary" />}
      headerSx={{ pr: 0 }}
      hideHeaderDivider
      sx={{
        border: (theme) => (props.error ? `1px solid ${theme.palette.error.main}` : 'none')
      }}
      additionalHeaderComponent={
        <Button
          disabled={props.disabled}
          disableElevation
          size="small"
          color={addingMarker || movingMarker ? 'success' : 'primary'}
          startIcon={addingMarker || movingMarker ? <Check /> : <AddLocationAlt />}
          onClick={() => {
            if (addingMarker) {
              addToken();
            } else if (movingMarker) {
              moveMarker();
            } else {
              setAddingMarker(true);
            }
          }}>
          {addingMarker || movingMarker
            ? t('initiatives:quests:placeMarker')
            : t('initiatives:quests:addMarker')}
        </Button>
      }>
      <Box sx={{ height: '80vh', position: 'relative', borderRadius: 2, overflow: 'hidden' }}>
        <MapContainer
          center={center}
          zoom={zoom}
          minZoom={2}
          worldCopyJump
          scrollWheelZoom={true}
          zoomControl={false}
          style={{ height: '100%', width: '100%' }}>
          <UpdateMapView mapCentre={center} zoom={zoom} />
          <MapEventsCatcher />

          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* Marker count badge */}
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 400,
              backgroundColor: 'white',
              borderRadius: 1.5,
              boxShadow: (theme) => theme.shadows[1]
            }}>
            <Button
              sx={{ minWidth: 0, color: 'black', fontSize: 14, fontWeight: 'bold' }}
              onClick={(e) => {
                e.preventDefault();
                const newCenter: LatLngTuple = [
                  markers[centeredMarkerIndex].lat,
                  markers[centeredMarkerIndex].lng
                ];
                setCenteredMarkerIndex((centeredMarkerIndex + 1) % markers.length);

                if (newCenter[0] && newCenter[1]) {
                  setCenter(newCenter as LatLngTuple);
                  setZoom(19);
                }
              }}>
              <LocationOn
                fontSize="small"
                sx={{ color: (theme) => theme.palette.tertiary.light }}
              />
              {markers.length}
            </Button>
          </Box>

          {/* Markers */}
          {markers.map((marker, index) =>
            _.isEqual(movingMarker?.startPosition, marker) ? (
              <DraggableMarker
                key={`moving-token`}
                initialLat={movingMarker?.endPosition.lat}
                initialLng={movingMarker?.endPosition.lng}
                onCoordinatesChanged={(lat, lng) =>
                  setMovingMarker((marker) => {
                    return marker
                      ? {
                          startPosition: marker?.startPosition,
                          endPosition: {
                            ...movingMarker?.endPosition,
                            lat,
                            lng
                          }
                        }
                      : undefined;
                  })
                }
                onConfirm={moveMarker}
              />
            ) : (
              <Marker
                key={`marker-${index}`}
                ref={markerRef}
                position={[marker.lat, marker.lng]}
                opacity={addingMarker || movingMarker ? 0.3 : 1}
                riseOnHover>
                <Popup key={`marker-popup-${index}`} closeButton={false}>
                  <Stack sx={{ m: -1, mr: -1.5 }} gap={1}>
                    <MarkerPopupContent
                      disableLocationDelete={
                        props.disableLocationsForDelete?.includes(marker.id ?? -1) || props.disabled
                      }
                      disableLocationMove={props.disabled}
                      disabled={props.disabled}
                      brandId={props.brandId}
                      marker={marker}
                      tokens={props.tokens}
                      name={marker.name}
                      onDelete={() => deleteMarker(marker)}
                      onMove={() => {
                        setMovingMarker({
                          startPosition: marker,
                          endPosition: marker
                        });
                      }}
                      onAssignLocationToToken={(tknIndex) => {
                        if (props.onAssignLocationToTkn)
                          props.onAssignLocationToTkn(tknIndex, marker);
                      }}
                      onEditName={(text) => setTokenName(text, marker)}
                    />
                  </Stack>
                </Popup>
              </Marker>
            )
          )}
          {addingMarker && addingMarkerCoordinates && (
            <DraggableMarker
              initialLat={addingMarkerCoordinates[0]}
              initialLng={addingMarkerCoordinates[1]}
              onCoordinatesChanged={(lat, lng) => setAddingMarkerCoordinates([lat, lng])}
              onConfirm={addToken}
            />
          )}
        </MapContainer>
      </Box>

      {props.error && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            my: 0.5,
            mx: '14px',
            display: props.error ? 'block' : 'none'
          }}>
          {props.error}
        </Typography>
      )}
    </EditableCard>
  );
}

interface DraggableMarkerProps {
  initialLat?: number;
  initialLng?: number;
  onCoordinatesChanged?: (lat: number, lng: number) => void;
  onConfirm?: () => void;
}
function DraggableMarker(props: DraggableMarkerProps) {
  const { t } = useTranslation();
  const [position, setPosition] = useState([props.initialLat, props.initialLng] as LatLngTuple);
  const markerRef = useRef<any>();

  useEffect(() => {
    setPosition([props.initialLat, props.initialLng] as LatLngTuple);
  }, [props.initialLat, props.initialLng]);

  useEffect(() => {
    if (markerRef.current) markerRef.current.openPopup();
  }, [markerRef.current]);

  const markerEventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          marker.openPopup();
          setPosition([marker.getLatLng().lat, marker.getLatLng().lng]);
          if (props.onCoordinatesChanged)
            props.onCoordinatesChanged(marker.getLatLng().lat, marker.getLatLng().lng);
        }
      }
    }),
    []
  );

  const inputLatChanged = _.debounce((lat: string) => {
    if (!lat) return;
    let newLat: number;
    try {
      newLat = parseFloat(lat.slice(0, -1));
    } catch (error) {
      console.error('An error occurred while converting latidute to number format');
      return;
    }
    if (isNaN(newLat)) return;
    newLat = newLat > 90 ? 90 : newLat < -90 ? -90 : newLat;
    setPosition((position) => {
      return [newLat, position[1]];
    });
    if (props.onCoordinatesChanged) props.onCoordinatesChanged(newLat, position[1]);
  }, 800);

  const inputLngChanged = _.debounce((lng: string) => {
    if (!lng) return;
    let newLng: number;
    try {
      newLng = parseFloat(lng.slice(0, -1));
    } catch (error) {
      console.error('An error occurred while converting latidute to number format');
      return;
    }
    if (isNaN(newLng)) return;
    newLng = newLng > 90 ? 90 : newLng < -90 ? -90 : newLng;
    setPosition((position) => {
      return [position[0], newLng];
    });
    if (props.onCoordinatesChanged) props.onCoordinatesChanged(position[0], newLng);
  }, 800);

  return (
    <Marker
      draggable={true}
      eventHandlers={markerEventHandlers}
      position={position}
      ref={markerRef}
      autoPanOnFocus
      riseOnHover>
      <Popup minWidth={300} autoClose={true}>
        <Stack gap={2} py={1} sx={{ mr: -0.5 }}>
          <InfoText sx={{ mb: 1 }}>{t('initiatives:quests:texts:insertLocation')}</InfoText>
          <NumericFormat
            fullWidth
            allowNegative
            label="Latitude"
            suffix="&deg;"
            size="small"
            customInput={TextField}
            InputLabelProps={{ shrink: true }}
            value={position[0]}
            onChange={(e) => {
              inputLatChanged(e.target.value);
            }}
          />
          <NumericFormat
            fullWidth
            allowNegative
            label="Longitude"
            suffix="&deg;"
            size="small"
            customInput={TextField}
            InputLabelProps={{ shrink: true }}
            value={position[1]}
            onChange={(e) => {
              inputLngChanged(e.target.value);
            }}
          />
        </Stack>
        <Button
          size="small"
          startIcon={<Check />}
          onClick={props.onConfirm}
          color="success"
          sx={{ mx: 'auto', display: 'flex' }}>
          {t('initiatives:quests:placeMarker')}
        </Button>
      </Popup>
    </Marker>
  );
}

interface MarkerPopupContentProps {
  brandId: number;
  marker: LocationRequest;
  tokens?: ({ flyer?: Flyer } & TokenRequest)[];
  name?: string;
  onDelete?: () => void;
  onMove?: () => void;
  onAssignLocationToToken?: (tknIndex: number) => void;
  onEditName?: (text: string) => void;
  popupWidth?: number | string;
  disableLocationDelete?: boolean;
  disableLocationMove?: boolean;
  disabled?: boolean;
}
function MarkerPopupContent(props: MarkerPopupContentProps) {
  const { t } = useTranslation();
  const questMode = useSelector((state: RootState) => state.local.questForm.inputData.modeSlug);
  const [mode, setMode] = useState<'view' | 'assign'>('view');
  const [name, setName] = useState<string>('');
  const [assignedTokenIndex, setAssignedTokenIndex] = useState<number | undefined>();
  const [assignedToken, setAssignedToken] = useState<{ flyer?: Flyer } & TokenRequest>();

  const assignLocationToToken = (tknIndex: number) => {
    if (props.onAssignLocationToToken) props.onAssignLocationToToken(tknIndex);
  };

  useEffect(() => {
    setAssignedTokenIndex(
      props.tokens?.findIndex((t) => t.locations?.find((l) => _.isEqual(l, props.marker)))
    );
    setAssignedToken(
      props.tokens?.find((t) => t.locations?.find((l) => _.isEqual(l, props.marker)))
    );
  }, [props.tokens, props.marker]);

  useEffect(() => {
    setName(props.name ?? '');
  }, [props.name]);

  return (
    <Stack sx={{ m: -1, mr: -1.5 }} gap={1}>
      <TextField
        disabled={props.disabled}
        autoFocus={!props.name}
        label={t('initiatives:quests:markerName')}
        multiline
        size="small"
        InputLabelProps={{ shrink: true }}
        value={name}
        sx={{ minWidth: 200, mx: 1, mt: 2, mb: -1 }}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => {
          if (props.onEditName) props.onEditName(name);
        }}
      />

      {questMode === TokenAssignmentMode.TOKEN_FIXED &&
        (mode === 'view' ? (
          assignedToken?.flyer && (
            <FlyerCard
              brandId={props.brandId}
              data={assignedToken.flyer}
              sx={{ mx: 'auto', mt: 1.5, maxWidth: 120 }}
            />
          )
        ) : (
          <FlexBox
            flexDirection="row"
            gap={1}
            sx={{
              m: 1,
              mb: -0.5,
              width: props.popupWidth,
              overflow: 'auto',
              justifyContent: 'flex-start'
            }}>
            {props.tokens?.map((tkn, index) => {
              return (
                tkn.flyer && (
                  <FlyerCard
                    sx={{
                      mb: 1,
                      minWidth: 92,
                      maxWidth: 'calc(10% - 10px)'
                    }}
                    key={`flyer-${tkn.flyerId}`}
                    data={tkn.flyer}
                    brandId={props.brandId}
                    selectable
                    onSelect={() => setAssignedTokenIndex(index)}
                    selected={assignedTokenIndex === index}
                    selectComponent={({ onClick }) => (
                      <Radio
                        onClick={onClick}
                        checked={assignedTokenIndex === index}
                        color={assignedTokenIndex === index ? 'default' : 'primary'}
                      />
                    )}
                  />
                )
              );
            })}
          </FlexBox>
        ))}

      <FlexBox flexDirection="row" justifyContent="space-between" m={1}>
        <StyledTooltip title={t('common:delete')} arrow>
          <IconButton
            size="small"
            color="error"
            onClick={props.onDelete}
            disabled={props.disableLocationDelete || props.disabled}>
            <DeleteForever />
          </IconButton>
        </StyledTooltip>

        {questMode === TokenAssignmentMode.TOKEN_FIXED &&
          (mode === 'view' ? (
            <LoadingButton
              disableElevation
              disabled={Boolean(!props.tokens?.length) || props.disabled}
              size="small"
              startIcon={<MenuBook />}
              sx={{ textTransform: 'none', px: 2, mx: 4 }}
              onClick={() => {
                setMode('assign');
              }}>
              {assignedToken ? 'Cambia volantino' : 'Assegna volantino'}
            </LoadingButton>
          ) : (
            <LoadingButton
              disableElevation
              size="small"
              color={'success'}
              startIcon={Boolean(props.tokens?.length) && <Check />}
              sx={{ textTransform: 'none', px: 2, mx: 4 }}
              onClick={() => {
                if (assignedTokenIndex || assignedTokenIndex === 0) {
                  assignLocationToToken(assignedTokenIndex);
                  setAssignedTokenIndex(undefined);
                }
                setMode('view');
              }}>
              {props.tokens?.length ? 'Conferma' : 'Ok'}
            </LoadingButton>
          ))}

        <StyledTooltip title={t('initiatives:quests:moveMarker')} arrow>
          <IconButton
            size="small"
            onClick={props.onMove}
            disabled={props.disableLocationMove || props.disabled}>
            <ControlCamera />
          </IconButton>
        </StyledTooltip>
      </FlexBox>
    </Stack>
  );
}
