import * as z from 'zod';
import { EmbeddedBrandSchema } from './Brand';
import { PageblePayloadSchema } from './Common';

export enum RoleIds {
  ADMIN_USER_ID = 1,
  BRAND_USER_ID = 2
}

export const UserRoleSchema = z.object({
  id: z.nativeEnum(RoleIds),
  name: z.string(),
  slug: z.string()
});
export type UserRole = z.infer<typeof UserRoleSchema>;

export const UserSchema = z.object({
  id: z.number(),
  email: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
  createdAt: z.number(),
  updatedAt: z.number().nullable().optional()
});

export type User = z.infer<typeof UserSchema>;

// app users
export const AppUserSchema = UserSchema.extend({
  username: z.string(),
  wallet: z.object({
    balance: z.number(),
    totalWaka: z.number().nullable().optional()
  }),
  community: z
    .object({
      id: z.number(),
      name: z.string(),
      code: z.string(),
      numberOfUsers: z.number().nullable().optional()
    })
    .nullable()
    .optional()
});

export type AppUser = z.infer<typeof AppUserSchema>;

export const AppUsersApiResponseSchema = PageblePayloadSchema.extend({
  content: z.array(AppUserSchema)
});
export type AppUsersApiResponse = z.infer<typeof AppUsersApiResponseSchema>;

export const AppUserRequestSchema = z.object({
  email: z.string().nullable().optional(),
  username: z.string(),
  password: z.string(),
  image: z.string().nullable().optional()
});
export type AppUserRequest = z.infer<typeof AppUserRequestSchema>;

// business users
export const BusinessUserSchema = UserSchema.extend({
  role: UserRoleSchema.nullable(),
  brand: EmbeddedBrandSchema.nullable().optional()
});
export type BusinessUser = z.infer<typeof BusinessUserSchema>;

export const BusinessUsersApiResponseSchema = PageblePayloadSchema.extend({
  content: z.array(BusinessUserSchema)
});
export type BusinessUsersApiResponse = z.infer<typeof BusinessUsersApiResponseSchema>;

export const BusinessUserAddSchema = z.object({
  email: z.string().nullable().optional(),
  password: z.string(),
  image: z.string().nullable().optional(),
  roleId: z.number().nullable().optional(),
  brandId: z.number().optional()
});
export type BusinessUserAdd = z.infer<typeof BusinessUserAddSchema>;

export const BusinessUserEditSchema = z.object({
  password: z.string()
});
export type BusinessUserEdit = z.infer<typeof BusinessUserEditSchema>;
