import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Languages
import en from './languages/en';
import it from './languages/it';

// Set default language to en on first app launch
if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'en');
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: { en, it }
  });

export default i18n;

export function currentLanguage(): 'it' | 'en' {
  const currentLanguage = i18n.resolvedLanguage;
  if (currentLanguage.includes('it')) {
    return 'it';
  }
  if (currentLanguage.includes('en')) {
    return 'en';
  }
  return 'en';
}

export const EmptyDictionaryString = { it: '', en: '' };
