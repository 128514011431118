import { Repository } from '../Repository';

class AuthenticationRepository extends Repository {
  constructor() {
    super({
      apiURL: `${process.env.REACT_APP_API_URL}`,
      resourceURL: '/api'
    });
  }

  async signIn(username: string, password: string) {
    const { data } = await this.axios.post('/auth/login/business', {
      username,
      password
    });
    return data;
  }

  async signOut() {
    const { data } = await this.axios.post('/auth/logout');
    return data;
  }

  async refreshToken(refreshToken: string) {
    const { data } = await this.axios.post(
      '/auth/refresh_token',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      }
    );
    return data;
  }

  async mailVerification(token: string) {
    const { data } = await this.axios.post(`/change_email/confirm/${token}`);
    return data;
  }

  async resetBusinessPassword(email: string) {
    const { data } = await this.axios.post(`/auth/password_reset/business/${email}`);
    return data;
  }

  async resetPassword(token: string, password: string) {
    const { data } = await this.axios.put(`/auth/password_reset/${token}`, { password });
    return data;
  }
}

export default new AuthenticationRepository();
