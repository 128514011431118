import * as React from 'react';
import { DeleteForever, PersonAdd } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DataGrid,
  GridApi,
  GridCellValue,
  GridColumns,
  GridRenderCellParams
} from '@mui/x-data-grid';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useUser from '../../../../hooks/useUser';
import { MaterialColors } from '../../../../utils/MaterialColors';
import useBreadcrumbs from '../../../components/Breadcrumbs/useBreadcrumbs';
import { SearchBar } from '../../../components/SearchBar';
import { SnackbarContext } from '../../../components/Snackbar/SnackbarProvider';
import { FlexBox, StyledTooltip } from '../../../components/StyledComponents';
import Toolbar from '../../../components/Toolbar';
import AddEditAppUser from './AddEditAppUser';
import AddEditBackofficeUser from './AddEditBackofficeUser';

export default function UsersRouteGuard() {
  return (
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/app" element={<Users type="app" />} />
      <Route path="/bo" element={<Users type="bo" />} />
      <Route path="/app/new_user" element={<AddEditAppUser mode="create" />} />
      <Route path="/app/:userId/edit" element={<AddEditAppUser mode="edit" />} />
      <Route path="/bo/new_user" element={<AddEditBackofficeUser mode="create" />} />
      <Route path="/bo/:userId/edit" element={<AddEditBackofficeUser mode="edit" />} />
    </Routes>
  );
}

interface UsersProps {
  type?: 'app' | 'bo';
}
function Users(props: UsersProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    appUsers,
    readAppUsers,
    readAppUsersRequest,
    backOfficeUsers,
    readBackOfficeUsers,
    readBackOfficeUsersRequest,
    deleteUser,
    deleteUserRequest,
    userRoles,
    readUserRoles,
    // readUserRolesRequest,
    me
  } = useUser();
  const { setSnackbar } = React.useContext(SnackbarContext);
  const tabletWidth = useMediaQuery('(max-width: 1280px)');

  useBreadcrumbs(
    React.useMemo(() => {
      return props.type === 'bo'
        ? {
            title: t('navigation:boUsers'),
            path: `users/bo`
          }
        : {
            title: t('navigation:appUsers'),
            path: `users/app`
          };
    }, [t, props.type])
  );

  const [tabValue, setTabValue] = React.useState(
    props.type === 'bo' ? 'bo_users_tab' : 'app_users_tab'
  );
  const [deleteUserDialog, setDeleteUserDialog] = React.useState({
    open: false,
    userType: '',
    userId: ''
  });

  // App users Filters
  const [appUsernameKey, setAppUsernameKey] = React.useState<string>('');
  const [appEmailKey, setAppEmailKey] = React.useState<string>('');
  const [appUsersOrder, setAppUsersOrder] = React.useState<string>('default');
  // Business users Filters
  const [businessEmailKey, setBusinessEmailKey] = React.useState<string>('');
  const [businessRole, setBusinessRole] = React.useState<number>(0);
  const [businessUsersOrder, setBusinessUsersOrder] = React.useState<string>('default');

  const avatarColors: string[] = React.useMemo(() => {
    return Array.from(
      { length: 10 },
      () => MaterialColors[Math.floor(Math.random() * MaterialColors.length)]
    );
  }, [appUsers.pagination?.number, backOfficeUsers.pagination?.number, tabValue]);

  const openSnackBar = (success: boolean, successMsg: string, errorMsg: string) => {
    setSnackbar({
      opened: true,
      severity: success ? 'success' : 'error',
      msg: success ? successMsg : errorMsg
    });
  };

  const fetchAppUsers = React.useCallback(
    async (page?: number) => {
      await readAppUsers(
        page,
        appUsernameKey || undefined,
        appEmailKey || undefined,
        appUsersOrder === 'default' ? undefined : appUsersOrder
      );
    },
    [appUsernameKey, appEmailKey, appUsersOrder]
  );

  const fetchBusinessUsers = React.useCallback(
    async (page?: number) => {
      await readBackOfficeUsers(
        page,
        businessEmailKey || undefined,
        businessRole || undefined,
        businessUsersOrder === 'default' ? undefined : businessUsersOrder
      );
    },
    [businessEmailKey, businessRole, businessUsersOrder]
  );

  React.useEffect(() => {
    if (tabValue === 'app_users_tab') {
      fetchAppUsers();
      navigate('/users/app');
    }
    if (tabValue === 'bo_users_tab') {
      fetchBusinessUsers();
      navigate('/users/bo');
    }
  }, [tabValue, fetchAppUsers, fetchBusinessUsers]);

  React.useEffect(() => {
    if (!userRoles.length) readUserRoles();
  }, [userRoles]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const rowHeight = React.useMemo(() => {
    const tableContainerRef = document.getElementById('users-table-container');
    if (tableContainerRef) return (tableContainerRef.clientHeight - 53 - 56 - 7) / 10;
  }, [document.getElementById('users-table-container')]);

  const isImage = (url?: string) => {
    if (url != null) {
      const formats = ['jpg', 'png', 'jpeg'];
      return formats.includes(url.split('.')[-1]);
    }
    return false;
  };

  const AppUsersData = {
    value: 'app_users_tab',
    rows: appUsers.data,
    pagination: appUsers.pagination,
    columns: [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'image',
        headerName: '',
        width: 75,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Avatar
            sx={{
              height: 34,
              width: 34,
              m: 'auto',
              backgroundColor:
                avatarColors[appUsers.data.findIndex((appUser) => appUser.id === params.id)]
            }}
            src={isImage(params.value) ? params.value : undefined}
            alt="/broken-image.jpg">
            {params.row.username.charAt(0).toUpperCase()}
          </Avatar>
        )
      },
      {
        field: 'username',
        headerName: t('users:username'),
        flex: 1
      },
      {
        field: 'email',
        flex: 1,
        headerName: t('users:formInputLabels:email')
      },
      {
        field: 'community',
        headerName: t('users:community'),
        flex: 1,
        valueFormatter: (params: any) => {
          const valueFormatted = params.value?.code;
          return valueFormatted;
        }
      },
      {
        field: 'wallet',
        headerName: t('users:earnedWaka'),
        flex: 1,
        valueFormatter: (params: any) => {
          const valueFormatted = params.value?.balance;
          return valueFormatted;
        }
      },
      {
        field: 'createdAt',
        headerName: t('users:createdAt'),
        type: 'date',
        valueFormatter: (params: any) => {
          const valueFormatted = new Date(params.value).toLocaleDateString();
          return `${valueFormatted}`;
        },
        flex: 1
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        width: 150,
        sortable: false,
        renderCell: (params: any) => {
          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          const onEditClick = (e: any) => {
            e.stopPropagation();
            return navigate(`/users/app/${thisRow.id}/edit`);
          };

          const onDeleteClick = (e: any) => {
            e.stopPropagation();
            return setDeleteUserDialog({
              open: true,
              userType: 'users',
              userId: thisRow.id as string
            });
          };

          return (
            <Stack direction="row" spacing={1}>
              <StyledTooltip title={t('common:edit')} arrow placement="left">
                <IconButton aria-label="edit" onClick={onEditClick}>
                  <EditIcon />
                </IconButton>
              </StyledTooltip>
              <StyledTooltip title={t('common:delete')} severity="error" arrow placement="right">
                <IconButton aria-label="delete" color="error" onClick={onDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              </StyledTooltip>
            </Stack>
          );
        }
      }
    ] as GridColumns,
    request: readAppUsersRequest,
    searchParam: 'username'
  };
  const BOUsersData = {
    value: 'bo_users_tab',
    rows: backOfficeUsers.data,
    pagination: backOfficeUsers.pagination,
    columns: [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'image',
        headerName: '',
        width: 75,
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Avatar
            sx={{
              height: 34,
              width: 34,
              m: 'auto',
              backgroundColor:
                avatarColors[backOfficeUsers.data.findIndex((boUser) => boUser.id === params.id)]
            }}
            src={isImage(params.value) ? params.value : undefined}
            alt="/broken-image.jpg">
            {params.row.email.charAt(0).toUpperCase()}
          </Avatar>
        )
      },
      {
        field: 'email',
        headerName: t('users:formInputLabels:email'),
        flex: 1
      },
      {
        field: 'role',
        headerName: t('users:formInputLabels:role'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: any) => {
          const valueFormatted = params?.value?.name;
          return `${valueFormatted}`;
        }
      },
      // TODO: uncomment in future releases
      // {
      //   field: 'brand',
      //   headerName: t('brand:brand'),
      //   flex: 1,
      //   sortable: false,
      //   renderCell: (params: GridRenderCellParams<string>) => params?.row.brand?.name
      // },
      {
        field: 'createdAt',
        headerName: t('users:createdAt'),
        type: 'date',
        valueFormatter: (params: any) => {
          const valueFormatted = new Date(params.value).toLocaleDateString();
          return `${valueFormatted}`;
        },
        flex: 1
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        width: 150,
        sortable: false,
        renderCell: (params: any) => {
          const api: GridApi = params.api;
          const id = params.id;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          const onEditClick = (e: any) => {
            e.stopPropagation();
            return navigate(`/users/bo/${thisRow.id}/edit`);
          };

          const onDeleteClick = (e: any) => {
            e.stopPropagation();
            return setDeleteUserDialog({
              open: true,
              userType: 'business',
              userId: thisRow.id as string
            });
          };

          return (
            <Stack direction="row" spacing={1}>
              <StyledTooltip title={t('common:edit')} arrow placement="left">
                <IconButton aria-label="edit" onClick={onEditClick}>
                  <EditIcon />
                </IconButton>
              </StyledTooltip>
              {id !== me?.id && (
                <StyledTooltip title={t('common:delete')} arrow placement="right" severity="error">
                  <IconButton aria-label="delete" color="error" onClick={onDeleteClick}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTooltip>
              )}
            </Stack>
          );
        }
      }
    ],
    request: readBackOfficeUsersRequest,
    searchParam: 'email'
  };

  return (
    <FlexBox flexDirection="column" p={3} flex={1}>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {t('users:usersLists')}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
          {t('users:texts:usersListsInfo')}
        </Typography>
      </Box>

      <TabContext value={tabValue}>
        <Toolbar
          mt={6}
          mb={2}
          rightcomponent={
            tabValue === 'app_users_tab' ? (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <SearchBar
                  backgroundColor="white"
                  value={appUsernameKey}
                  loading={readAppUsersRequest.inProgress || readBackOfficeUsersRequest.inProgress}
                  onChange={_.debounce((text) => setAppUsernameKey(text), 800)}
                  placeholder={t('filters:searchByUsername')}
                  sx={{ height: 23, border: '1px solid lightgray', width: 200 }}
                />
                <SearchBar
                  backgroundColor="white"
                  value={appEmailKey}
                  loading={readAppUsersRequest.inProgress || readBackOfficeUsersRequest.inProgress}
                  onChange={_.debounce((text) => setAppEmailKey(text), 800)}
                  placeholder={t('filters:searchByMail')}
                  sx={{ height: 23, border: '1px solid lightgray', width: 200 }}
                />
              </FlexBox>
            ) : (
              <FlexBox flexDirection={tabletWidth ? 'column' : 'row'} alignSelf="center" gap={1}>
                <SearchBar
                  backgroundColor="white"
                  value={businessEmailKey}
                  loading={readAppUsersRequest.inProgress || readBackOfficeUsersRequest.inProgress}
                  onChange={_.debounce((text) => setBusinessEmailKey(text), 800)}
                  placeholder={t('filters:searchByMail')}
                  sx={{ height: 23, border: '1px solid lightgray', width: 200 }}
                />
                {/* TODO: uncomment to enable user role filter */}
                {/* <FormControl
                  sx={{ flex: 1, minWidth: 200 }}
                  size="small"
                  disabled={readUserRolesRequest.inProgress}>
                  <InputLabel size="small">{t('filters:userRole')}</InputLabel>
                  <Select
                    sx={{
                      backgroundColor: 'white'
                    }}
                    value={businessRole}
                    label={t('filters:userRole')}
                    onChange={(e) => setBusinessRole(e.target.value as number)}>
                    <MenuItem key={`role-undefined`} value={0}>
                      <Typography sx={{ fontStyle: 'italic', opacity: 0.6 }}>
                        {t('common:all')}
                      </Typography>
                    </MenuItem>
                    {userRoles?.map((role: any) => (
                      <MenuItem key={`role-${role.id}`} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                <Fade in={tabValue === 'bo_users_tab'}>
                  <Button
                    startIcon={<PersonAdd sx={{ mr: 1 }} />}
                    variant="contained"
                    sx={{
                      color: 'white'
                    }}
                    onClick={() => navigate(`/users/bo/new_user`)}>
                    {t('users:addUser')}
                  </Button>
                </Fade>
              </FlexBox>
            )
          }>
          <TabList
            onChange={handleTabChange}
            sx={{ position: 'absolute', transform: 'translateY(calc(-50% + 1px))' }}>
            <Tab
              label={t('users:appUsers')}
              value={'app_users_tab'}
              sx={{ fontWeight: tabValue === 'app_users_tab' ? 'bold' : 'normal' }}
            />
            <Tab
              label={t('users:backOfficeUsers')}
              value={'bo_users_tab'}
              sx={{ fontWeight: tabValue === 'bo_users_tab' ? 'bold' : 'normal' }}
            />
          </TabList>
        </Toolbar>

        <Card
          id="users-table-container"
          sx={{
            flex: 1,
            mt: -0.2,
            typography: 'body1',
            border: 'none',
            px: 2,
            pt: 1,
            mx: '6%',
            borderRadius: 4
          }}
          variant="outlined">
          {/* App user's table */}
          <TabPanel value={AppUsersData.value} sx={{ p: 0, height: '100%' }}>
            <DataGrid
              rows={AppUsersData.rows.filter((r) => Boolean(r.username))}
              pagination
              paginationMode="server"
              columns={AppUsersData.columns}
              pageSize={AppUsersData.pagination?.size}
              rowsPerPageOptions={[AppUsersData.pagination?.size ?? 10]}
              page={AppUsersData.pagination?.number}
              rowCount={AppUsersData.pagination?.totalElements || 10}
              onPageChange={(page) => fetchAppUsers(page)}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              experimentalFeatures={{ newEditingApi: true }}
              sx={{
                border: 'none',
                '& .MuiDataGrid-virtualScroller, .css-1w5m2wr-MuiDataGrid-virtualScroller': {
                  overflow: 'hidden !important'
                }
              }}
              rowHeight={rowHeight}
              sortingMode="server"
              onSortModelChange={(sortModel) => {
                const sortData = sortModel[0];
                if (sortData) {
                  setAppUsersOrder(
                    `${sortData.sort === 'desc' ? '-' : ''}${
                      sortData.field === 'wallet'
                        ? 'waka'
                        : sortData.field === 'createdAt'
                        ? 'created_at'
                        : sortData.field
                    }`
                  );
                } else setAppUsersOrder('default');
              }}
            />
          </TabPanel>

          {/* Backoffice user's table */}
          <TabPanel value={BOUsersData.value} sx={{ p: 0, height: '100%' }}>
            <DataGrid
              rows={BOUsersData.rows.filter((r) => Boolean(r.email))}
              pagination
              paginationMode="server"
              columns={BOUsersData.columns}
              pageSize={BOUsersData.pagination?.size}
              rowsPerPageOptions={[BOUsersData.pagination?.size ?? 10]}
              page={BOUsersData.pagination?.number}
              rowCount={BOUsersData.pagination?.totalElements || 10}
              onPageChange={(page) => fetchBusinessUsers(page)}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              experimentalFeatures={{ newEditingApi: true }}
              sx={{
                border: 'none',
                '& .MuiDataGrid-virtualScroller, .css-1w5m2wr-MuiDataGrid-virtualScroller': {
                  overflow: 'hidden !important'
                }
              }}
              rowHeight={rowHeight}
              sortingMode="server"
              onSortModelChange={(sortModel) => {
                const sortData = sortModel[0];
                if (sortData) {
                  setBusinessUsersOrder(
                    `${sortData.sort === 'desc' ? '-' : ''}${
                      sortData.field === 'wallet'
                        ? 'waka'
                        : sortData.field === 'createdAt'
                        ? 'created_at'
                        : sortData.field
                    }`
                  );
                } else setBusinessUsersOrder('default');
              }}
            />
          </TabPanel>
        </Card>
      </TabContext>

      {/* Delete User Dialog */}
      <Dialog
        open={deleteUserDialog.open}
        onClose={() => setDeleteUserDialog({ open: false, userType: '', userId: '' })}>
        <Alert sx={{ p: 3 }} severity="error" variant="outlined">
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('common:attention')}!
          </AlertTitle>
          <DialogContentText id="alert-dialog-description">
            {t('users:texts:deleteUserInfo')}
          </DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button
              onClick={() => setDeleteUserDialog({ open: false, userType: '', userId: '' })}
              color="inherit">
              {t('common:no')}
            </Button>
            <LoadingButton
              loading={deleteUserRequest.inProgress}
              onClick={async () => {
                if (deleteUserDialog.userType && deleteUserDialog.userId) {
                  const success = await deleteUser(
                    deleteUserDialog.userType,
                    parseInt(deleteUserDialog.userId)
                  );
                  openSnackBar(
                    success,
                    t('users:snackbar:deleteUserSuccess'),
                    t('users:snackbar:deleteUserError')
                  );
                  deleteUserDialog.userType === 'users'
                    ? fetchAppUsers(appUsers.pagination?.number)
                    : fetchBusinessUsers(backOfficeUsers.pagination?.number);
                }
                setDeleteUserDialog({ open: false, userType: '', userId: '' });
              }}
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}>
              {t('common:yes')}, {t('common:delete')}
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </FlexBox>
  );
}
